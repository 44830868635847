import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import Config from '../config/Config';
import { app } from '../index';
import Storage, { TOKEN } from '../util/Storage';
function changeSpinVisible(spinVisible: boolean) {
  app._store!.dispatch({
    type: 'globals/changeSpinVisible',
    payload: { spinVisible },
  });
}
const instance = axios.create({
  baseURL: Config.REACT_APP_DOMAIN,
  // baseURL: 'http://service-wukong.cloudmvd.com/api/web',
  /* baseURL: 'http://192.168.1.114:9100/api/web', */
});
// 上电文拦截器
instance.interceptors.request.use(
  config => {
    changeSpinVisible(true);
    if (Storage.getStore(TOKEN)) {
      config.headers.Authorization = Storage.getStore(TOKEN);
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

export interface IResponse<T> {
  code: number;
  message?: string;
  result: T;
}

// 下电文拦截器
instance.interceptors.response.use(
  // tslint:disable-next-line:no-any
  (response: any) => {
    changeSpinVisible(false);
    if (response.data) {
      if (response.data.code === 0) {
        return response;
      } else {
        message.error(response.data.message);
      }
      if (response.data.code === 1001) {
        message.info('用户没有权限（令牌、用户名、密码错误）。');
        Storage.delStore(TOKEN);
        window.location.href = '/';
        changeSpinVisible(false);
      }
      // catch 可以指定类型catch
      throw response.data;
    }
  },
  (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          message.info('发出的请求有错误，服务器没有进行新建或修改数据的操作。');
          break;
        case 401: {
          message.info('用户没有权限（令牌、用户名、密码错误）。');
          Storage.delStore(TOKEN);
          window.location.href = '/';
          break;
        }

        case 403:
          message.info('用户得到授权，但是访问是被禁止的。');
          break;
        case 404:
          message.info('发出的请求针对的是不存在的记录，服务器没有进行操作。');
          break;
        case 406:
          message.info('请求的格式不可得。');
          break;
        case 410:
          message.info('请求的资源被永久删除，且不会再得到的。');
          break;
        case 422:
          message.info('当创建一个对象时，发生一个验证错误。');
          break;
        case 500:
          message.info('服务器发生错误，请检查服务器。');
          break;
        case 502:
          message.info('网关错误。');
          break;
        case 503:
          message.info('服务不可用，服务器暂时过载或维护。');
          break;
        case 504:
          message.info('网关超时。');
          break;
        default:
          message.info('未知错误');
      }
    } else {
      throw error;
    }
    changeSpinVisible(false);
  }
);

export default {
  // 因为axios 定义了返回值的类型 AxiosResponse 所以这里手动去掉一层
  post<T, D>(url: string, data?: D) {
    return instance.post<IResponse<T>>(url, data).then(res => {
      return res.data;
    });
  },
  get<T, D>(url: string, params?: D) {
    return instance.get<IResponse<T>>(url, { params }).then(res => {
      return res.data;
    });
  },
  delete<D>(url: string, params?: D) {
    return instance.delete(url, { params }).then(res => {
      return res.data;
    });
  },
  put<T, D>(url: string, data?: D) {
    return instance.put<IResponse<T>>(url, data).then(res => {
      return res.data;
    });
  },
};
