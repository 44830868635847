import { Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useModalVisible } from './../../util/UtilHooks';
import './ModalCmp.scss';
interface IModalCmpProps {
  visible: boolean;
  title?: string;
  closable?: boolean;
  children?: React.ReactElement;
  footerView?: React.ReactElement;
  colseModal?: () => void;
  okModal?: () => void;
}
export function ModalCmp(props: IModalCmpProps) {
  const { visible, colseModal, setVisible } = useModalVisible(props.visible);
  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    if (visible && props.okModal) {
      props.okModal();
    }
    if (!visible && props.colseModal) {
      props.colseModal();
    }
  }, [visible]);

  const normalFooter = (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Button type="primary" className="theme-primary-btn" onClick={footerConfirmClick}>
        确认
      </Button>
      <Button className="theme-normal-btn" onClick={footerCancelClick}>
        取消
      </Button>
    </div>
  );

  /**
   * 默认模态框确定
   */
  function footerConfirmClick() {
    if (props.okModal) {
      props.okModal();
    }
  }
  /**
   * 默认模态框取消
   */
  function footerCancelClick() {
    if (props.colseModal) {
      props.colseModal();
    }
  }
  const modal = (
    <Modal
      visible={visible}
      onCancel={colseModal}
      footer={(props.footerView && props.footerView) || normalFooter}
      className="normalModalCmp"
      centered={true}
      title={props.title ? props.title : '提示'}
      closable={props.closable}
    >
      {props.children}
    </Modal>
  );
  return modal;
}
