import axios from './RequestApi';
/**
 * @class 全局状态管理
 */
export default class HttpRequest {
  /**
   * 获取通知消息
   */
  public static getNotices<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/notify/getNotifyPage', data);
  }

  /**
   *  通知消息全部已读
   */
  public static updateNoticeAllRead<T>() {
    return axios.get<T, {}>('/user/logined/notify/markAllAsReadByUserId');
  }
}
