import { Button, Divider, Drawer, Input, Modal, Radio, Select, Steps, Tooltip } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import Pagination from 'antd/lib/pagination';
import { RadioChangeEvent } from 'antd/lib/radio';
import Table from 'antd/lib/table';
import React from 'react';
import HttpRequest from '../../../api/DataManager';
import SciManageApi from '../../../api/SciManageApi';
import AuthCmp from '../../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import DataShareItem from '../../../components/DataShareItem/DataShareItem';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../config/IconConfig';
import { IDataPackageItem, ITaskItem } from '../../../util/DataStruct';
import {
  IDictions,
  INormalList,
  ISeqsReq,
  IShareProjectItem,
  IShareSequenceItem,
  IShareTaskPkgReq,
  IWaitingShareDataItem,
  IWaitSharedReq,
} from '../../DataManager/CodeType';
import FormItem, { IItemType } from '../../DataManager/Component/FormItem/FormItem';
import {
  dataFormatList,
  genomeList,
  relevantAreaList,
  sampleRangeList,
  sampleTypeList,
  techPlatformList,
  typeList,
} from '../../DataManager/Component/LoadDataComponent/CodeType';
import SeqsModalComponent, { ISeqsModalType } from '../../DataManager/Component/SeqsModalComponent/SeqsModalComponent';
import { geneticFormTests, guid, imageFromTests } from '../../DataManager/DataMSetting';
import { IWaitState } from './CodeType';
import './SciWaitCmp.scss';
const { Step } = Steps;
const { Option } = Select;
/**
 * @param { form } 用来进行表单验证
 * @param {sharedAction} 分享成功后刷新🐷页面
 */

export interface IWaitSharedProp {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  sharedAction: () => void; // 分享成功后刷新🐷页面
  partsDiction: Map<string, IDictions>; // 网络返回的 部位字典
  devicesDiction: Map<string, IDictions>; // 网络返回的 设备字典
  projectId: number;
}

export class SciWaitCmp extends React.PureComponent<IWaitSharedProp, IWaitState> {
  public SequencesColums = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUID',
      key: 'seriesInstanceUID',
      render: (text: string, record: IShareSequenceItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '检查号',
      dataIndex: 'studyInstanceUID',
      key: 'studyInstanceUID',
      render: (text: string, record: IShareSequenceItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IShareSequenceItem) => {
        const dic = this.props.devicesDiction.get(record.modality);
        return <div> {dic ? dic.name : text} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IShareSequenceItem) => {
        const dic = this.props.partsDiction.get(record.bodyPart);
        return <div> {dic ? dic.name : text} </div>;
      },
    },
  ];
  constructor(props: IWaitSharedProp) {
    super(props);
    this.state = {
      steps: 0,
      waitItemsMap: new Map(),
      startShare: false,
      packageType: 1,
      sharePackageStr: '',
      seqsModalVisible: false,
      waitingPagination: { total: 0, pageSize: 3, current: 1 },
      totalSeqs: 0,
      waitDatas: [],
      selectedTask: [],
      seqModalType: ISeqsModalType.OneTask,

      packageDrawerVisible: false,
      isLoading: true,
      isShowSeqs: false,
    };
  }
  public componentDidMount() {
    this.getWaitingShareData();
  }
  public render() {
    const { startShare, steps, selectedTask } = this.state;
    const seqsItems: IShareSequenceItem[] = [];
    selectedTask.map(item => {
      seqsItems.push.apply(seqsItems, item.seqs);
    });
    return (
      <div className="sci-wait-component">
        {startShare ? this.sharedHeader() : null}
        {steps !== 0 ? null : this.shareListHeaderRender()}
        {this.sharedContentRender()}
        {startShare ? this.nextStepRender() : null}

        <SeqsModalComponent
          type={this.state.seqModalType}
          visiable={this.state.seqsModalVisible}
          waitItem={this.state.clickWaitItem}
          waitItems={this.state.selectedTask}
          onCloseModal={this.closeSeqModal}
          onOkModal={this.okCloseSeqModal}
          onOkMoreModal={this.okMoreCloseSeqModal}
          totalSeqs={this.state.totalSeqs}
          startShared={this.state.startShare}
          devicesDiction={this.props.devicesDiction}
          partsDiction={this.props.partsDiction}
        />

        <Drawer
          title="数据包详情"
          placement="right"
          width={700}
          closable={false}
          onClose={this.packageDrawerClose}
          visible={this.state.packageDrawerVisible}
        >
          <TradeDetailDrawer
            showOrderMessage={false}
            showPackageView={this.state.packageDrawerVisible}
            showSecurityBox={false}
            // tslint:disable-next-line:jsx-no-multiline-js
            dataPackage={
              this.state.sharePackageStr ? (JSON.parse(this.state.sharePackageStr) as IDataPackageItem) : undefined
            }
            // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
            onClose={() => {
              this.packageDrawerClose();
            }}
          />
        </Drawer>
        <Modal
          visible={this.state.isShowSeqs}
          className="seqs-modal-component"
          width="1170px"
          centered={true}
          cancelText="确认"
          okText="取消"
          title={'选中序列'}
          closable={false}
          // tslint:disable-next-line:jsx-no-multiline-js
          onCancel={() => {
            this.setState({ isShowSeqs: false });
          }}
          // tslint:disable-next-line:jsx-no-multiline-js
          onOk={() => {
            this.setState({ isShowSeqs: false });
          }}
        >
          <Table
            columns={this.SequencesColums}
            dataSource={seqsItems}
            style={{ backgroundColor: 'white' }}
            pagination={{ total: seqsItems.length, pageSize: 5 }}
            rowKey="seriesInstanceUID"
            // onChange={this.morehandleTableChange}
          />
        </Modal>
      </div>
    );
  }
  /**
   * 待共享数据
   */

  public getWaitingShareData = async (current?: number, taskName?: string) => {
    const { waitingPagination, waitItemsMap } = this.state;
    waitingPagination.current = current ? current : 1;
    this.setState({ isLoading: false });
    try {
      const { result } = await SciManageApi.getProxAiProjectPackages<
        INormalList<IWaitingShareDataItem[]>,
        IWaitSharedReq
      >({
        page: waitingPagination.current,
        pageSize: waitingPagination.pageSize,
        taskName,
        projectId: this.props.projectId,
      });
      if (result) {
        // const newWaitItemsMap = this.state.waitItemsMap;
        waitingPagination.current = result.current;
        waitingPagination.total = result.total;
        if (taskName) {
          waitItemsMap.clear();
        }
        result.records.map(item => {
          item.check = false; // 刚刚请求回来的数据全部设置为未选中
          item.seqs = [];
          item.current = result.current;
          waitItemsMap.set(item.id, item); // 把数据放在准备给 待共享组件map 里
          return item;
        });
        // waitItemsMap.values
        const datas: IWaitingShareDataItem[] = [];
        waitItemsMap.forEach(v => {
          datas.push(v);
        });
        this.setState({ waitingPagination, waitItemsMap, waitDatas: [...datas], isLoading: true });
      }
    } catch (error) {
      this.setState({ isLoading: true });
    }
  };
  /**
   *
   *  获取序列列表 用taskid
   */
  public getSeqs = async (item: IWaitingShareDataItem) => {
    const { waitItemsMap } = this.state;
    const { result } = await HttpRequest.getSeqsWithTaskId<ITaskItem[], ISeqsReq>({
      taskIds: item.id,
    });
    if (result && result.length > 0) {
      result.map(v => {
        if (v.taskId === item.id) {
          v.seriesEntityList.map(s => {
            s.noChected = !item.check;
            return s;
          });
          item.seqs = v.seriesEntityList;
        }
      });
    }

    waitItemsMap.set(item.id, item);
    const datas: IWaitingShareDataItem[] = [];
    waitItemsMap.forEach(v => {
      datas.push(v);
    });
    this.setState({
      waitItemsMap,
      clickWaitItem: item,
      waitDatas: [...datas],
    });
    this.calculatedTotalSeq();
  };

  /**
   * 待共享 分享数据包
   *
   */
  public shareTaskPackage = async (jsonDesc: string, isShare: string) => {
    const apricotProjectList = this.calculatedTotalSeq();
    const projectId = this.props.projectId;
    const { result } = await SciManageApi.saveProxAiData<string, IShareTaskPkgReq>({
      jsonDesc,
      apricotProjectList,
      isShare,
      taskId: guid(apricotProjectList[0].taskId),
      projectId,
    });
    if (result) {
      //
      this.setState({ steps: 0, selectedTask: [], startShare: false });
      this.getWaitingShareData();
    }
  };

  /**
   * 计算选中序列的数量,并且返回选中的任务 用来提交数据包
   */
  public calculatedTotalSeq = (): IShareProjectItem[] => {
    const { waitItemsMap } = this.state;
    let totalSeqs = 0;
    const projects: IShareProjectItem[] = [];
    waitItemsMap.forEach(task => {
      if (task.check) {
        const taskId = task.id;
        const seqs = task.seqs.filter((s: IShareSequenceItem) => {
          return !s.noChected;
        });
        const serialNumbers = seqs.map((s: IShareSequenceItem) => {
          return s.seriesInstanceUID;
        });
        totalSeqs += seqs.length;
        projects.push({ taskId, serialNumbers } as IShareProjectItem);
      }
    });

    this.setState({
      totalSeqs,
    });
    return projects;
  };

  /**
   * 获取所有选中的Task
   */
  public getSelectedProject = () => {
    const { waitItemsMap } = this.state;
    const projects: IWaitingShareDataItem[] = [];
    waitItemsMap.forEach(task => {
      if (task.check) {
        projects.push(task);
      }
    });

    this.setState({
      selectedTask: projects,
      startShare: projects.length > 0 ? true : false,
    });
  };

  /**
   * 分享过程
   */

  /**
   * 待共享列表
   */

  public sharedListRender = () => {
    const { waitDatas, waitingPagination } = this.state;

    const tableGroup: JSX.Element[] = [];
    waitDatas.forEach(item => {
      if (item.current === waitingPagination.current) {
        const e = (
          <DataShareItem
            key={item.id}
            projectId={this.props.projectId}
            checkBoxClick={this.watingSharedProjectCheckBoxClick}
            itemData={item}
            showSequenceClick={this.showSeqList}
          />
        );
        tableGroup.push(e);
      }
    });

    return (
      <div style={{ marginTop: '23px' }}>
        {tableGroup}
        <NoDataImg
          isLoading={this.state.isLoading}
          total={this.state.waitingPagination.total}
          msg="暂时还没有已完成的鉴影任务..."
        />
        <AuthComponent isShow={!!this.state.waitingPagination.total}>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px', paddingBottom: '10px' }}>
            <Pagination
              defaultCurrent={1}
              pageSize={waitingPagination.pageSize}
              total={waitingPagination.total}
              current={waitingPagination.current}
              onChange={this.waitingSharedTableChange}
            />
          </div>
        </AuthComponent>
      </div>
    );
  };
  /**
   * 批量共享按钮点击事件
   */
  public batchShareAction = () => {
    this.setState({
      startShare: true,
    });
  };

  /**
   * @todo SeqsModalComponent Action
   */
  /**
   * 关闭seq Modal框
   */
  public closeSeqModal = () => {
    this.setState({
      seqsModalVisible: false,
    });
  };
  /**
   * 点击Ok 关闭seq Modal 框
   */

  public okCloseSeqModal = (item: IWaitingShareDataItem) => {
    const { waitItemsMap } = this.state;
    waitItemsMap.set(item.id, item);
    const datas: IWaitingShareDataItem[] = [];
    waitItemsMap.forEach(v => {
      datas.push(v);
    });
    this.setState({
      seqsModalVisible: false,
      waitItemsMap,
      clickWaitItem: item,
      waitDatas: [...datas],
    });
  };
  /**
   * 序列模态框
   */
  public okMoreCloseSeqModal = (items: IWaitingShareDataItem[]) => {
    const { waitItemsMap } = this.state;
    items.forEach(item => {
      waitItemsMap.set(item.id, item);
    });
    const datas: IWaitingShareDataItem[] = [];
    waitItemsMap.forEach(v => {
      datas.push(v);
    });
    this.setState({
      seqsModalVisible: false,
      waitItemsMap,
      waitDatas: [...datas],
    });
    this.calculatedTotalSeq();
  };

  /**
   * 待共享数据分页处理
   */
  private waitingSharedTableChange = (page: number) => {
    //
    const { waitItemsMap, waitingPagination, waitDatas } = this.state;
    let hasNoCurrentPage = true;
    waitItemsMap.forEach(item => {
      if (item.current === page) {
        hasNoCurrentPage = false;
        return;
      }
    });
    if (!hasNoCurrentPage) {
      // 已经有当前页不请求
      waitingPagination.current = page;
      this.setState({
        waitDatas: [...waitDatas],
      });
      this.forceUpdate();
    } else {
      this.getWaitingShareData(page);
    }
  };
  /**
   * 待共享列表 checkBox 被点击
   */
  private watingSharedProjectCheckBoxClick = (checked: boolean, item: IWaitingShareDataItem) => {
    const { waitItemsMap } = this.state;
    item.check = checked;
    waitItemsMap.set(item.id, item);
    this.setState({ waitItemsMap });
    if (item.seqs.length === 0) {
      this.getSeqs(item);
    } else {
      item.seqs.map(s => {
        s.noChected = !checked;
        return s;
      });
    }

    this.getSelectedProject();
    this.calculatedTotalSeq();
  };
  /**
   * 显示序列被点击
   */
  private showSeqList = (item: IWaitingShareDataItem, checked: boolean) => {
    if (item.seqs.length === 0) {
      this.getSeqs(item);
    }
    this.setState({
      seqModalType: ISeqsModalType.OneTask,
      seqsModalVisible: true,
      clickWaitItem: item,
    });
  };

  /**
   * 取消共享
   */
  private cancelShared = () => {
    this.setState({
      steps: 0,
      startShare: false,
      waitItemsMap: new Map(),
    });
    this.getWaitingShareData();
  };

  /**
   * 下一步按钮Click 事件
   */

  private nextStepClick = (isShare: string) => {
    const { steps, packageType } = this.state;
    const { validateFields } = this.props.form;
    if (steps === 0) {
      this.setState({
        steps: steps + 1,
      });
    } else if (steps === 1) {
      validateFields(packageType === 1 ? geneticFormTests : imageFromTests, (err, values) => {
        if (!err) {
          const jsonDesc: string = JSON.stringify(values);
          this.setState({
            steps: steps + 1,
            sharePackageStr: jsonDesc,
          });
        }
      });
    } else {
      // steps ===2  分享action
      this.shareTaskPackage(this.state.sharePackageStr, isShare);
    }
  };

  /**
   * 显示已选项目的已选序列
   */

  private showAllSelTaskSeq = () => {
    this.getSelectedProject();
    this.setState({
      seqModalType: ISeqsModalType.MoreTasks,
      seqsModalVisible: true,
    });
  };
  /**
   *
   * 列表 表头
   */
  /**
   *
   * 基因 影像 Radio 切换
   */
  private dataTypeRadioChanage = (e: RadioChangeEvent) => {
    this.setState({
      packageType: e.target.value,
    });
  };
  /**
   * 所选序列数量点击事件
   */
  private sharedSeqNumClick = () => {
    //
    this.setState({
      isShowSeqs: true,
    });
  };

  /**
   * 关闭数据包详情
   */
  private packageDrawerClose = () => {
    this.setState({ packageDrawerVisible: false });
  };

  /**
   * 原始数据 按照序列号查询
   */

  private handleTableHeaderSearch = () => {
    const origSearchSeqId = this.props.form.getFieldValue('waitingTaskName');

    this.getWaitingShareData(1, origSearchSeqId);
  };
  /**
   * 表头
   */
  private shareListHeaderRender = () => {
    const {
      form: { getFieldDecorator },
      projectId,
    } = this.props;

    return (
      <TableHeader>
        <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
          <Form.Item label="标注任务名称:">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('waitingTaskName')(<Input placeholder="请输入" allowClear={true} />)}
          </Form.Item>
          <Button
            className="normal-search-button"
            onClick={this.handleTableHeaderSearch}
            style={{ marginLeft: '48px', marginRight: '64px' }}
          >
            查询
          </Button>
          <Button
            className="normal-search-button"
            onClick={this.showAllSelTaskSeq}
            style={{ marginLeft: '64px', paddingLeft: '11px', paddingRight: '11px' }}
            hidden={!this.state.startShare}
            disabled={this.state.totalSeqs < 1}
          >
            <div style={{ display: 'flex' }}>
              已选序列
              <span style={{ color: '#d6d6d6' }}>
                {'\xa0'}|{'\xa0'}
              </span>
              {this.state.totalSeqs}
            </div>
          </Button>
        </div>
        <AuthComponent isShow={!!this.state.waitingPagination.total}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }} hidden={this.state.startShare}>
            <AuthCmp permission="USER_PROJECT_MANAGE" projectId={projectId}>
              <Button className="normal-search-button" onClick={this.batchShareAction}>
                批量处理
              </Button>
            </AuthCmp>
          </div>
        </AuthComponent>
      </TableHeader>
    );
  };

  /**
   * 分享内容 分享过程 页面展示
   */
  private sharedContentRender = () => {
    const { steps } = this.state;
    if (steps === 0) {
      return this.sharedListRender();
    } else if (steps === 1) {
      return this.sharedFirstStepRender();
    } else if (steps === 2) {
      return this.isShareThirdStep();
    }
  };

  /**
   * 分享过程
   */
  private sharedHeader = () => {
    const { steps } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '26px' }} className="shareSteps">
        <div style={{ flex: 1 }} />
        <Steps current={steps} className="share-steps-container">
          <Step title="选择序列" />
          <Step title="设置项目数据包" />
          <Step title="开始生成项目数据包" />
        </Steps>
        <div
          style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '24px' }}
        >
          <Button className="normal-search-button" onClick={this.cancelShared}>
            取消处理
          </Button>
        </div>
      </div>
    );
  };
  /**
   * 下一步按钮
   */
  private nextStepRender = () => {
    const { steps, totalSeqs } = this.state;
    const nextBtnTitle = steps === 2 ? '开始生成' : '下一步';
    const nextDisable = totalSeqs !== 0 ? false : true;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '32px' }}>
        <Button
          type="primary"
          style={{ width: '96px' }}
          // tslint:disable-next-line:jsx-no-multiline-js
          onClick={() => {
            this.nextStepClick('SHARE');
          }}
          disabled={nextDisable}
          htmlType="submit"
        >
          {nextBtnTitle}
        </Button>
      </div>
    );
  };
  /**
   * 共享数据开始
   */
  private sharedFirstStepRender = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    // const project_agency_no = this.state.selectedProjectItems[0].nodeName;
    return (
      <Form>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }} className="project-name-container">
          {this.inputFromItem('项目名称', true, '输入项目名称', 'project_name', '', '提供一句话或短语，阐述项目内容')}
          <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.64)' }}>
            序列数量:
            <span style={{ color: '#00a1e6', fontSize: '16px', cursor: 'pointer' }} onClick={this.sharedSeqNumClick}>
              {this.state.totalSeqs}
            </span>
          </span>
        </div>
        <div style={{ display: 'flex', flex: 1, marginTop: '26px' }} className="project-name-container">
          <FormItem
            title={'项目说明'}
            itemType={IItemType.TextArea}
            required={true}
            message={'请输入项目说明'}
            fieldId={'project_overview'}
            initialValue={''}
            form={this.props.form}
            hintMessage={'提供一段说明文字，阐述项目的研究目标及相关信息'}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, marginTop: '26px' }}>
          <div style={{ width: '112px', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.64)' }} className="project-item-title">
              数据类型
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>:</div>
          </div>

          <Form.Item style={{ paddingLeft: '28px' }}>
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('project_data_type', {
              rules: [{ required: true, message: '请输入数据类型' }],
              initialValue: 1,
            })(
              <Radio.Group onChange={this.dataTypeRadioChanage}>
                <Radio value={1} style={{ color: 'rgba(0,0,0,0.64)' }}>
                  基因
                </Radio>
                <Divider type="vertical" style={{ marginLeft: '8px' }} />
                <Radio value={2} style={{ marginLeft: '16px', color: 'rgba(0,0,0,0.64)' }}>
                  影像
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </div>
        <div hidden={true}>
          {this.inputFromItem('项目名称', false, '请输入项目名称', 'project_agency_no', 'project_agency_no')}
        </div>
        {this.dataFromView()}
      </Form>
    );
  };
  /**
   * 共享数据第三部
   */
  private isShareThirdStep = () => {
    const seqs: IShareSequenceItem[] = [];
    this.state.selectedTask.forEach(t => {
      const taskseqs = t.seqs.filter(s => !s.noChected);
      seqs.push.apply(seqs, taskseqs);
    });
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '24px' }}>
          <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.64)' }}>序列数量: {this.state.totalSeqs}</span>
          <Divider type="vertical" />
          <span
            style={{ fontWeight: 'bold', color: '#00a1e6', cursor: 'pointer' }}
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              this.setState({ packageDrawerVisible: true });
            }}
          >
            数据详情
          </span>
        </div>
        <Table
          columns={this.SequencesColums}
          dataSource={seqs}
          style={{ backgroundColor: 'white' }}
          rowKey="orderCode"
          className="show-top-border"
        />
      </div>
    );
  };
  /**
   *  选中框
   */
  private selectedFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    options?: JSX.Element[],
    hintMessage?: string
  ) => {
    return (
      <FormItem
        title={title}
        required={required}
        message={message}
        fieldId={ckId}
        itemType={IItemType.Select}
        options={options}
        form={this.props.form}
        hintMessage={hintMessage}
      />
    );
  };
  /**
   * 创建input
   */
  private inputFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    initialValue?: string,
    hintMessage?: string
  ) => {
    return (
      <FormItem
        title={title}
        itemType={IItemType.HintInput}
        required={required}
        message={message}
        fieldId={ckId}
        initialValue={initialValue}
        form={this.props.form}
        hintMessage={hintMessage}
      />
    );
  };

  /**
   *  自动完成
   */
  private autoCompleteFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    hintMessage?: string,
    autoArr?: Array<{ value: string; tip?: string }>
  ) => (
    <FormItem
      title={title}
      required={required}
      message={message}
      fieldId={ckId}
      itemType={IItemType.autoComplete}
      form={this.props.form}
      hintMessage={hintMessage}
      autoArr={autoArr}
    />
  );
  /**
   * select选项生成
   */
  private optionCreat = (param: Array<{ value: string; tip?: string }>) => {
    return param.map(item => (
      <Option key={item.value} value={item.value}>
        {item.tip ? (
          <Tooltip
            placement="rightTop"
            overlayClassName="optTips"
            // tslint:disable-next-line:jsx-no-multiline-js
            title={
              <div className="optips">
                <h4>
                  <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6' }} />
                </h4>
                <Divider />
                {item.tip}
              </div>
            }
          >
            {item.value}
          </Tooltip>
        ) : (
          item.value
        )}
      </Option>
    ));
  };
  /**
   * 根据包类型决定渲染的 那种表单
   */
  private dataFromView = () => {
    return this.state.packageType === 1 ? this.geneticFromView() : this.imageFromView();
  };
  /***
   * 基因
   */
  private geneticFromView = () => {
    //
    return (
      <Form>
        <div style={{ alignItems: 'flex-end', position: 'relative', display: 'flex' }}>
          <span style={{ height: '1px', width: '110px', backgroundColor: 'rgba(214,214,214,0.64)' }} />
          <span className="">
            <span className="top">
              <i className="top-arrow1" />
              <i className="top-arrow2" />
            </span>
          </span>
          <span style={{ height: '1px', flex: 1, backgroundColor: 'rgba(214,214,214,0.64)' }} />
        </div>
        <div style={{ display: 'flex', marginTop: '20px', flexFlow: 'row wrap' }} className="form-content">
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '相关领域',
            true,
            '请选择相关领域',
            'project_relevant_area',
            this.optionCreat(relevantAreaList),
            '填写该项目隶属哪个领域'
          )}

          {// tslint:disable-next-line:jsx-no-multiline-js
          this.autoCompleteFromItem('项目类别', true, '请选择项目类别', 'project_type', '填写该项目的类别', typeList)}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '样品范围',
            true,
            '请选择样品范围',
            'project_sample_range',
            this.optionCreat(sampleRangeList),
            '填写项目样本的来源'
          )}

          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '样本类型',
            true,
            '请选择样本类型',
            'project_sample_type',
            this.optionCreat(sampleTypeList),
            '填写项目样本的类型'
          )}

          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '数据格式',
            true,
            '请选择数据格式',
            'project_data_format',
            '填写项目样本数据的存储格式',
            dataFormatList
          )}

          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '技术平台',
            true,
            '请选择技术平台',
            'project_tech_platform',
            '填写项目样本数据生成的技术平台',
            techPlatformList
          )}

          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '参考基因组',
            true,
            '请选择参考基因组',
            'project_genome',
            '填写项目研究所用的参考基因组版本号',
            genomeList
          )}

          {this.inputFromItem('物种', true, '请输入物种', 'project_organism', '', '填写该项目研究对象的种属关系')}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.inputFromItem(
            '疾病类型',
            true,
            '请输入疾病类型',
            'project_disease_type',
            '',
            ' 填写项目研究的目标表型'
          )}
        </div>
      </Form>
    );
  };
  /**
   * 影像
   */
  private imageFromView = () => {
    return (
      <Form>
        <div style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }} className="genetic-form-view  ">
          <span style={{ height: '1px', width: '110px', backgroundColor: 'rgba(214,214,214,0.64)' }} />
          <span className="">
            <b className="top-image">
              <i className="top-arrow1" />
              <i className="top-arrow2" />
            </b>
          </span>
          <span style={{ height: '1px', flex: 1, backgroundColor: 'rgba(214,214,214,0.64)' }} />
        </div>
        <div style={{ marginTop: '20px' }} className="image-view-container">
          <div style={{ display: 'flex' }}>
            <div>
              {this.inputFromItem('疾病名称', true, '请输入疾病名称', 'project_disease_type', '', ' 描述疾病名称')}
            </div>
            <div>
              {// tslint:disable-next-line:jsx-no-multiline-js
              this.inputFromItem(
                '标注方式',
                true,
                '请输入标注方式',
                'project_label_format',
                '',
                '标注文件的格式信息 如 .nii .xml .csv等'
              )}
            </div>
            <div>
              {// tslint:disable-next-line:jsx-no-multiline-js
              this.inputFromItem(
                '数据格式',
                true,
                '请输入数据格式',
                'project_data_format',
                '',
                '数据文件的格式信息 如 .dicom .jpg .png'
              )}
            </div>
          </div>

          <div>
            {// tslint:disable-next-line:jsx-no-multiline-js
            this.inputFromItem(
              '检测手段',
              true,
              '请输入检测手段',
              'project_testing_method',
              '',
              '影像文件的检测方式 如 CT MRI 眼底镜 等'
            )}
          </div>
        </div>
      </Form>
    );
  };
}

export default SciWaitCmp;
