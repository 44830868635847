import { Breadcrumb, Button, Checkbox, Col, Divider, Drawer, Icon, Input, message, Row, Table } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ProjectMembersApi from '../../api/ProjectMembersApi';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../components/NoDataImg/NoDataImg';
/* import NoDataImg from '../../components/NoDataImg/NoDataImg'; */
import IndexLayout from '../../layout/IndexLayout';
import {
  IEdit,
  IJoinParam,
  IPageParam,
  IParam,
  IPartParam,
  IProjectUserList,
  IProjectUserListResult,
  IRoleItem,
  IState,
} from './CodeType';
import { MemberSelectList } from './component/MemberSelect';
interface IProps extends FormComponentProps, RouteComponentProps<IPageParam> {}
class ProjectMembers extends React.PureComponent<IProps, IState> {
  public state = {
    newModal: false,
    visible: false,
    visibleDownload: false,
    pagination: { total: 0, pageSize: 10, current: 1 },
    isLoading: true,
    list: [],
    new: false,
    username: '',
    ipram: {} as IParam,
    roleList: [],
    roleIds: [0],
    headShowFlg: false,
    editId: -1,
  } as IState;
  private columns = [
    {
      title: '用户名',
      dataIndex: 'loginname',
      key: 'loginname',
    },
    {
      title: '角色',
      dataIndex: 'roleNames',
      key: 'roleNames',
      render: (text: string, record: IProjectUserList) => record.roleNames.join(' | '),
    },
    {
      title: '状态',
      dataIndex: 'projectUserStatus',
      key: 'projectUserStatus',
      render: (text: string, record: IProjectUserList) =>
        record.projectUserStatus === 1 ? <span className="open">已启用</span> : <span className="close">已禁用</span>,
    },
    {
      title: <div style={{ paddingLeft: '12px' }}>操作</div>,
      width: 150,
      key: 'action',
      className: 'myDataOpt',
      render: (text: string, record: IProjectUserList) => {
        // eslint-disable-next-line no-nested-ternary
        const btnGroup = (
          <Fragment>
            <a title="编辑" onClick={() => this.showDrawer(record)}>
              编辑
            </a>
            <Divider type="vertical" />
            <a title="禁用" onClick={() => this.forbidden(record)}>
              <span style={{ display: 'none' }}>{record.id}</span>
              {record.projectUserStatus === 1 ? '禁用' : '启用'}
            </a>
          </Fragment>
        );
        return btnGroup;
      },
    },
  ];
  public componentDidMount() {
    /* this.setState({ list: ListData, pagination: { total: 1, pageSize: 10, current: 1 } }); */
    this.getRoleList();
    this.getProjectMembers({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    // tslint:disable-next-line:no-any
    let options: any = [];
    options = this.state.roleList.map(item => {
      return {
        label: <span className="checkboxname drchb">{item.roleName}</span>,
        value: item.id,
        key: item.id,
      };
    });

    return (
      <IndexLayout>
        <div id="ProjectMembers">
          <div className="standard-p main">
            <div className="top">
              <a className="return-btn" onClick={this.gobackWithOption}>
                <Icon type="arrow-left" />
                <span>&nbsp;返回项目列表</span>
              </a>
              <Breadcrumb className="Breadcrumb">
                <Breadcrumb.Item href="javascript:void(0)" onClick={this.gobackWithOption}>
                  <Icon type="environment" />
                  <span>项目列表</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>项目成员</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="title">
              <span className="title-content">&nbsp;「{this.props.match.params.name}」项目成员列表</span>
              <Button className="new-mem" type="primary" onClick={this.newonShow}>
                引入成员
              </Button>
            </div>
            <div>
              <AuthComponent isShow={!!this.state.pagination.total || !!this.state.headShowFlg}>
                <div className="renderFormHeader">{this.renderFormHeader()}</div>
              </AuthComponent>
              <AuthComponent isShow={!!this.state.pagination.total}>
                <Table
                  columns={this.columns}
                  dataSource={this.state.list}
                  style={{ backgroundColor: 'white' }}
                  pagination={{ ...this.state.pagination }}
                  rowKey="id"
                  onChange={this.handleTableChange}
                />
              </AuthComponent>
              <NoDataImg
                isLoading={this.state.isLoading}
                total={this.state.pagination.total}
                msg="暂时还没有项目成员..."
              />
              <AuthComponent isShow={!this.state.pagination.total}>
                <Button className="new-mem-bt" type="primary" onClick={this.newonShow}>
                  引入成员
                </Button>
              </AuthComponent>
            </div>
          </div>
        </div>
        <AuthComponent isShow={!!this.state.newModal}>
          <MemberSelectList
            onOk={this.newonOK}
            onCancel={this.newonHide}
            visible={this.state.newModal}
            param={this.props.match.params}
            roleList={this.state.roleList}
            projectId={Number(this.props.match.params.pId)}
          />
        </AuthComponent>

        <Drawer
          // tslint:disable-next-line:jsx-no-multiline-js
          title={
            <h3>
              编辑角色
              <span className="editName" style={{ fontWeight: 300, marginLeft: 20, fontSize: 14 }}>
                用户名：{this.state.username}
              </span>
              <a href="javascript:void(0)" style={{ float: 'right', color: 'black' }} onClick={this.drclose}>
                <Icon type="close-circle" />
              </a>
            </h3>
          }
          closable={false}
          // tslint:disable-next-line:jsx-no-multiline-js
          onClose={() => {
            this.setState({ visible: false });
          }}
          width={720}
          visible={this.state.visible}
        >
          <Form>
            <Form.Item className="roleList" label={<span>请先选择您需要设置的角色</span>}>
              {getFieldDecorator('roleList', {
                /* tslint:disable-next-line:jsx-no-multiline-js */
                rules: [{ required: true, message: '请选择角色' }],
              })(<Checkbox.Group className="roleListCheckboxGroup" options={options} />)}
            </Form.Item>
            <Divider />
            <div>
              <Button onClick={this.onSubmit} type="primary" style={{ float: 'right' }}>
                确定
              </Button>
            </div>
          </Form>
        </Drawer>
      </IndexLayout>
    );
  }

  private renderFormHeader = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form layout="inline" className="table-header">
        <Row style={{ width: '100%', display: 'flex' }}>
          <Col style={{ display: 'flex', alignItems: 'center', width: '240px', marginRight: '54px', marginLeft: 24 }}>
            <Form.Item label="用户名">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('username')(<Input className="ipt" style={{ width: '156px' }} />)}
            </Form.Item>
          </Col>
          <Col md={8} sm={24} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              // htmlType="submit"
              className="normal-search-button"
              onClick={this.handleSubmit}
              style={{ width: '96px' }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  /**
   * 分页处理
   */
  private handleTableChange = (paginations: PaginationConfig) => {
    const { pagination } = this.state;
    pagination.current = paginations.current ? paginations.current : 1;
    this.setState(
      {
        pagination,
      },
      () => {
        this.getProjectMembers({
          pageNum: this.state.pagination.current,
          pageSize: 10,
          researchProjectId: Number(this.props.match.params.pId),
        });
      }
    );
  };
  /**
   *  查询表单提交
   */
  private handleSubmit = () => {
    this.props.form.validateFields(['username'], (err, values) => {
      this.getProjectMembers({
        ...values,
        ...{ pageNum: 1, pageSize: 10, researchProjectId: Number(this.props.match.params.pId) },
      });
    });
  };
  /**
   *  异步请求
   */
  private getProjectMembers = async (iparam: IPartParam) => {
    try {
      const { result } = await ProjectMembersApi.getProjectUserList<IProjectUserListResult, IPartParam>(iparam);
      this.setState(
        {
          list: result.records,
          pagination: { current: result.current, total: result.total, pageSize: result.size },
        },
        () => {
          if (!!this.state.pagination.total) {
            this.setState({ headShowFlg: true });
          }
        }
      );
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };
  /*  修改状态 */
  private statusedit = async (param: IEdit) => {
    const url = `/user/logined/researchProject/editProjectUserStatus?researchProjectId=${
      this.props.match.params.pId
    }&status=${param.status}&userId=${param.id}`;
    await ProjectMembersApi.editMember(url);
    this.getProjectMembers({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
    message.success(param.status === 1 ? '启用成功！' : '禁用成功！');
  };
  /* 点击修改 */
  private forbidden = (record: IProjectUserList) => {
    this.statusedit({
      loginName: record.loginname,
      mobile: record.mobile,
      status: record.projectUserStatus === 1 ? 2 : 1,
      id: record.id,
    });
  };
  /*  打开权限列表模态框 */
  private newonShow = () => {
    this.setState({ newModal: true });
  };
  /*
   * 引入接口调用
   */
  private jionUser = async (param: IJoinParam) => {
    await ProjectMembersApi.jionUser<{}, IJoinParam>(param);
    this.setState({ newModal: false });
    this.getProjectMembers({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
    message.success('设置成功！');
  };
  /*
   * 点击权限列表确定按钮
   */
  // tslint:disable-next-line:no-any
  private newonOK = async (values: any) => {
    /* await DataManagerApi.savePackageGrantList<null, string[], string>(arr, this.state.code); */
    const param = {
      researchProjectId: Number(this.props.match.params.pId),
      roleIds: values.roleList,
      userIds: values.memList.split(','),
    };
    this.jionUser(param);
  };
  /*  关闭权限列表模态框 */
  private newonHide = () => {
    this.setState({ newModal: false });
  };
  /* 显示抽屉 */
  private showDrawer = (record: IProjectUserList) => {
    const roleIds = record.roleNames.map(item => this.getRoleId(item) || 0);
    const arr = [] as number[];
    roleIds.forEach(item => {
      if (item !== 0) {
        arr.push(item);
      }
    });
    const { form } = this.props;
    this.setState({ editId: record.id });
    this.setState(
      {
        visible: true,
        username: record.loginname,
        roleIds: arr,
      },
      () => {
        form.setFieldsValue({
          roleList: arr,
        });
      }
    );
  };

  /* 关闭抽屉 */
  private onSubmit = () => {
    this.props.form.validateFields(['roleList'], (err, values) => {
      if (!err) {
        const param = {
          researchProjectId: Number(this.props.match.params.pId),
          roleIds: values.roleList,
          userIds: [this.state.editId],
        };
        this.jionUser(param);
        this.setState({
          visible: false,
        });
      }
    });
  };
  /* 关闭抽屉 */
  private drclose = () => {
    this.setState({
      visible: false,
    });
  };
  /**
   * 点击返回 按钮
   */
  private gobackWithOption = () => {
    this.props.history.goBack();
  };
  /**
   *  获取角色列表
   */
  private getRoleList = async () => {
    try {
      const { result } = await ProjectMembersApi.getRoleList<IRoleItem[]>();
      this.setState({
        roleList: result,
      });
    } catch (err) {
      message.error(err);
    }
  };
  /**
   *  获取角色id
   */
  private getRoleId = (roleName: string) => {
    if (this.state.roleList.length > 0) {
      for (const key in this.state.roleList) {
        if (this.state.roleList[key].roleName === roleName) {
          return this.state.roleList[key].id;
        }
      }
    }
  };
}
const ProjectMembersList = Form.create()(ProjectMembers);
export default ProjectMembersList;
