import { Button, Drawer, message, Row } from 'antd';
import React from 'react';
import Orders from '../../../api/OrderManageApi';
import Users from '../../../api/UserAccount';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import { IDataPackageItem, IUserGrade } from '../../../util/DataStruct';
import { IOrderDetailInfo, IVmJsonItem } from '../VMOrders/CodeType';
import { IProps, IState } from './CodeType';
import './OrderPay.scss';

class OrderPay extends React.PureComponent<IProps, {}> {
  public state = {
    detailVisible: false,
    payIsAvailable: false,
    orderDetail: {},
  } as IState;
  /**
   * 进入页面后获取用户积分和订单积分，比较大小然后判定用户是否有支付权限
   */
  public async componentDidMount() {
    const orderPrice = await Orders.getOrderDetail<IOrderDetailInfo, { code: string }>({
      code: this.props.match.params.id,
    });

    const urGrade = await Users.userGradeInfo<IUserGrade, {}>({});
    this.setState({
      payIsAvailable: (urGrade.result.coin || 0) >= (orderPrice.result.coinPrice || 0),
      orderDetail: orderPrice.result,
    });
  }
  public render() {
    const orderDetail = this.state.orderDetail;
    const orderPrice = orderDetail ? (orderDetail.coinPrice ? orderDetail.coinPrice : 0) : 0;
    return (
      <IndexLayout>
        <Row>
          <RouteHeader
            title="数据使用支付"
            titles={['首页', '数据检索', '数据使用支付']}
            routes={['/', '/datasearch', '/orderPay/:id']}
          />
          <Row className="order-pay-container">
            <div className="div-item">
              数据使用提交成功，请尽快确认预约！数据使用号：{orderDetail ? orderDetail.code : ''}{' '}
            </div>
            <div className="div-item">
              <span style={{ color: 'rgba(0, 161, 230, 1)', cursor: 'pointer' }} onClick={this.showDrawer}>
                数据使用详情
              </span>
            </div>
            <div className="div-item ">
              应付积分: <span style={{ color: 'rgba(0, 161, 230, 1)', fontWeight: 'bold' }}> {orderPrice}</span>(积分)
              {// tslint:disable-next-line:jsx-no-multiline-js
              this.state.payIsAvailable ? null : (
                <span style={{ color: 'rgba(208, 2, 27, 1)' }}>(当前账户余额不足)</span>
              )}
            </div>
            <div className="div-item">
              <Button
                style={{ height: '32px', marginBottom: '10px' }}
                type="primary"
                onClick={this.payForOrder}
                disabled={!this.state.payIsAvailable}
              >
                立即预约
              </Button>
            </div>
          </Row>
        </Row>
        <div>
          <Drawer
            title="数据使用详情"
            placement="right"
            width={700}
            closable={false}
            onClose={this.closeDrawer}
            visible={this.state.detailVisible}
          >
            {this.drawerOrderDetail()}
          </Drawer>
        </div>
      </IndexLayout>
    );
  }

  public drawerOrderDetail = () => {
    const vis = this.state.detailVisible;

    return vis && this.state.orderDetail ? this.advanceOrderHeader() : this.simpleOrderHeader();
  };
  public advanceOrderHeader = () => {
    const { orderData, orderVm } = this.state.orderDetail;

    const { packageInfo } = orderData;

    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    if (packageJson === undefined) {
      return <Row />;
    }

    const vmJson: IVmJsonItem = orderVm ? (orderVm.setupJson ? JSON.parse(orderVm.setupJson) : undefined) : undefined;
    if (vmJson === undefined) {
      return <Row />;
    }

    return (
      <TradeDetailDrawer
        showOrderMessage={true}
        showPackageView={true}
        showSecurityBox={true}
        dataPackage={packageJson}
        vmJson={vmJson}
        orderDetailInfo={this.state.orderDetail}
        // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
        onClose={() => {
          this.onClose();
        }}
      />
    );
  };
  public simpleOrderHeader = () => {
    return <Row />;
  };
  public showDrawer = () => {
    this.setState({
      detailVisible: true,
    });
  };

  public closeDrawer = () => {
    this.setState({
      detailVisible: false,
    });
  };

  public onClose = () => {
    this.setState({
      detailVisible: false,
    });
  };

  public payForOrder = async () => {
    /// 支付数据使用
    await Orders.pay<string, string>(this.props.match.params.id);
    message.success('预约成功', 2);
    this.props.history.push('/VMOrders');
  };
}
export default OrderPay;
