import axios from './RequestApi';
export default class UserGradesApi {
  /**
   *
   * @param data
   * 安全盒预约单详情
   */
  public static getTradeVmDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/detail/orderDataVm', data);
  }
  /**
   * 安全盒预约单详情
   */
  public static getTradeDataDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/detail/orderData', data);
  }
  /**
   * 数据预约单详情
   */
  public static getTradeDowloadDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/detail/userDownload', data);
  }
  /**
   *
   * @param data
   * 交易记录列表
   */
  public static getTransactions<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/auditLog/getLog', data);
  }

  /**
   * 积分列表
   * @param data
   */
  public static getUserGradesList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/account/flow', data);
  }
}
