import DataManager from './DataManager/DataManager';
import DataSearch from './DataSearch/DataSearch';
import DownloadCenter from './DownloadCenter/DownloadCenter';
import IndexPage from './IndexPage/IndexPage';
import MemberManage from './MemberManage/MemberManage';
import HospitalDetail from './MyData/HospitalDetail/HospitalDetail';
import MyData from './MyData/MyData/MyData';
import MyHospital from './MyData/MyHospital/MyHospital';
import MyPharmacy from './MyData/MyPharmacy/MyPharmacy';
import HarmacyDetail from './MyData/PharmacyDetail/PharmacyDetail';
import OrderCheck from './OrderPromise/OrderCheck/OrderCheck';
import orderPay from './OrderPromise/OrderPay/OrderPay';
import VMOrders from './OrderPromise/VMOrders/VMOrders';
import ProjectMembers from './ProjectMembers/ProjectMembers';
import ProjectTasks from './ProjectTasks/ProjectTasks';
import NewProject from './ProxAiManage/Component/NewProject/NewProject';
import NewProjectTask from './ProxAiManage/Component/NewProjectTask/NewProjectTask';
import ProjectTaskDeatil from './ProxAiManage/Component/ProjectTaskDeatil/ProjectTaskDeatil';
import ProxAiManage from './ProxAiManage/ProxAiManage';
import SciAppTools from './ScientificManage/ApplicationTools/SciAppTools';
import NewSCIProject from './ScientificManage/NewSCIProject/NewSCIProject';
import SciDataManage from './ScientificManage/SciDataManage/SciDataManage';
import ScientificManage from './ScientificManage/ScientificManage';
import SciMemberList from './ScientificManage/SciMemberList/SciMemberList';

import CreateSciTask from './SciProxAiManage/CreateSciTask/CreateSciTask';
import SciProxAi from './SciProxAiManage/SciProxAi';
import SecurityBox from './SecurityBox/SecurityBox';
import SecBoxManage from './SecurityBox/SecurityBoxManage/SecBoxManage';
import CommonOperation from './Users/Guide/CommonOperation';
import FQAPage from './Users/Guide/FAQPage';
import Introduction from './Users/Guide/Introduction';
import Login from './Users/Login/Login';
import TransactionLogs from './Users/TransactionLogs/TransactionLogs';
import UserGrades from './Users/UserGrades/UserGrades';
export interface IRouteConfigItem {
  path: string;
  name: string;
  // tslint:disable-next-line:no-any
  component?: React.ComponentType | any;
  icon?: string;
  status: string;
  routes?: IRouteConfigItem[];
  type?: 'leftmenu';
  usrTypes: string[];
  permissions?: string;
}

const Routes: IRouteConfigItem[] = [
  {
    path: '/',
    name: '首页',
    component: IndexPage,
    // icon: 'home',
    status: 'show',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/orderCheck/:id',
    name: '预约单信息核对',
    component: OrderCheck,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/orderPay/:id',
    name: '预约单支付',
    component: orderPay,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/mydata',
    name: '我的数据',
    component: MyData,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['1'],
  },
  {
    path: '/myhospital',
    name: '我的数据',
    component: MyHospital,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['4'],
  },

  {
    path: '/mypharmacy',
    name: '我的数据',
    component: MyPharmacy,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['3'],
  },
  {
    path: '/dataManager',
    name: '我的数据',
    component: DataManager,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['2', '5'],
  },
  {
    path: '/proxAiManage',
    name: '产品简介',
    component: ProxAiManage,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/newProject',
    name: '',
    component: NewProject,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/newProjectTask/:pId',
    name: '',
    component: NewProjectTask,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/projectTaskDeatil/:pId/:name',
    name: '',
    component: ProjectTaskDeatil,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },

  {
    path: '/memberManage',
    name: '成员管理',
    component: MemberManage,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['1', '2', '3', '4', '5'],
    permissions: 'USER_MEMBER_MANAGE',
  },
  {
    path: '/scientificManage',
    name: '科研项目',
    component: ScientificManage,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['5'],
  },
  {
    path: '/sciAppTools/:pId/:name',
    name: '应用工具',
    component: SciAppTools,
    icon: 'medataicon-shuju2',
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/sciProxAi/:pId/:name',
    name: '应用工具',
    component: SciProxAi,
    icon: 'medataicon-shuju2',
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },

  {
    path: '/securityBox',
    name: '安全盒',
    component: SecurityBox,
    icon: 'medataicon-shuju2',
    status: 'show',
    usrTypes: ['5'],
  },
  {
    path: '/newSCIProject',
    name: '',
    component: NewSCIProject,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/createSciTask/:pId',
    name: '',
    component: CreateSciTask,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },

  {
    path: '/sciDataManage/:pId/:name',
    name: '',
    component: SciDataManage,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/sciMemberList/:pId/:name',
    name: '',
    component: SciMemberList,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/hospitalDetail',
    name: '数据列表详情',
    component: HospitalDetail,
    icon: 'medataicon-jifen',
    status: 'hide',
    usrTypes: ['4'],
  },

  {
    path: '/pharmacyDetail',
    name: '数据列表详情',
    component: HarmacyDetail,
    icon: 'medataicon-jifen',
    status: 'hide',
    usrTypes: ['3'],
  },
  {
    path: '/DataSearch',
    name: '数据检索',
    component: DataSearch,
    icon: 'medataicon-gouwu',
    status: 'show',
    usrTypes: ['1', '2', '3', '4'],
  },
  {
    path: '/VMOrders',
    name: '数据使用',
    icon: 'medataicon-icon--copy-copy',
    component: VMOrders,
    status: 'show',
    usrTypes: ['1', '2', '3', '4'],
  },
  {
    path: '/DownloadCenter',
    name: '下载中心',
    icon: 'cloud-download',
    component: DownloadCenter,
    status: 'show',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/userGrades',
    name: '我的积分',
    component: UserGrades,
    icon: 'medataicon-icon',
    status: 'hide',
    type: 'leftmenu',
    usrTypes: ['1', '2', '3', '4'],
  },
  {
    path: '/transactionLogs',
    name: '我的日志',
    component: TransactionLogs,
    icon: 'medataicon-log-a-04',
    status: 'hide',
    type: 'leftmenu',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/guide',
    name: '使用指南',
    icon: 'medataicon-shuomingx',
    status: 'hide',
    type: 'leftmenu',
    usrTypes: ['1', '2', '3', '4', '5'],
    routes: [
      {
        path: '/Introduction',
        name: '产品简介',
        component: Introduction,
        // icon: 'medataicon-jifen',
        status: 'hide',
        type: 'leftmenu',
        usrTypes: ['1', '2', '3', '4', '5'],
      },
      {
        path: '/CommonOperation',
        name: '常用指南',
        component: CommonOperation,
        // icon: 'medataicon-jifen',
        status: 'hide',
        type: 'leftmenu',
        usrTypes: ['1', '2', '3', '4', '5'],
      },
      {
        path: '/FQAPage',
        name: 'FAQ',
        component: FQAPage,
        // icon: 'medataicon-jifen',
        status: 'hide',
        type: 'leftmenu',
        usrTypes: ['1', '2', '3', '4', '5'],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    icon: 'login',
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/ProjectMembers/:pId/:name',
    name: 'ProjectMembers',
    component: ProjectMembers,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/ProjectTasks/:pId/:name',
    name: 'ProjectTasks',
    component: ProjectTasks,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
  {
    path: '/secBoxManage/:pId/:name',
    name: 'SecBoxManage',
    component: SecBoxManage,
    status: 'hide',
    usrTypes: ['1', '2', '3', '4', '5'],
  },
];

export default Routes;
