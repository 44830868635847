import { Col, Divider, Row } from 'antd';
import React from 'react';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
// import './CommonOperation.scss';

// const { Link } = Anchor;

interface IProps {
  id?: string;
}
interface IAnchorColors {
  quickColorName: string;
  boxUseColorName: string;
}
interface IState {
  anchorColors: IAnchorColors;
}
class CommonOperation extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      anchorColors: { quickColorName: 'main-color', boxUseColorName: 'black-color' },
    };
  }

  public render() {
    const anchorColors = this.state.anchorColors;
    return (
      <IndexLayout type="leftmenu" className="IntroductionMain">
        <Row id="CommonOperation">
          <Col span={20}>
            <div className="FAQ-left-container">
              <Row>
                <Col>
                  <RouteHeader
                    title="常用指南"
                    titles={['首页', '使用指南', '常用指南']}
                    routes={['/', '/guide/Introduction', '/guide/CommonOperation']}
                  />
                </Col>
              </Row>
              <Row className="standard">
                <div className="operation-guide-top-title" id="data-quickBuy">
                  <span id="quickBuyContainer">快速预约数据 </span>
                </div>
                <div className="operation-guide-second-title"> 1）点击顶部导航栏”数据检索” </div>
                <div style={{ marginTop: '21px' }} className="quickBuy-pic">
                  <img src={require('./../../../resource/dataBuy1.png')} className="imgFullWidth" />
                </div>
                <div className="operation-guide-second-title"> 2）选择所需要的数据包，点击”预约使用”</div>
                <div style={{ marginTop: '21px' }}>
                  <img src={require('./../../../resource/dataBuy2.png')} className="peration-guide-image" />
                </div>
                <div style={{ marginTop: '16px' }}>
                  点击“查看详情”可以查看数据包的详细信息，也可以下载数据包内的“示例”；
                </div>
                <div className="operation-guide-second-title">
                  3）点击“预约使用”后，进入数据使用确认页面，根据所需要的配置，选择自己所需要的“安全盒”；
                </div>
                <div style={{ marginTop: '21px' }}>
                  <img src={require('./../../../resource/dataBuy3.png')} className="peration-guide-image" />
                </div>
                <div style={{ marginTop: '16px' }}>
                  在数据包所在位置，首次下单的时候，用户需要购买新的安全盒，如用户再次预约此位置的数据包，系统会自动判断用户的账号是否有可用的安全盒，如有，则无需重新购买安全盒；
                </div>
                <div className="operation-guide-second-title">
                  4）点击“提交数据使用”，联系业务员完成预约，可开始使用数据；
                </div>
                <Divider dashed={true} />
              </Row>
              <Row className="standard">
                <div className="operation-guide-top-title" id="data-quickBuy">
                  <span id="securityBoxUse">安全盒的使用 </span>
                </div>
                <div className="operation-guide-second-title">1）点击顶部导航栏”数据使用”； </div>
                <div style={{ marginTop: '21px' }}>
                  <img src={require('./../../../resource/dataBuy2.png')} className="peration-guide-image" />
                </div>
                <div className="operation-guide-second-title">
                  2）选择“可使用”状态的数据使用，点击“进入安全盒”，根据提示的输入安全盒用户名和密码进入安全盒
                </div>
                <div style={{ marginTop: '21px' }}>
                  <img src={require('./../../../resource/enterInBox.png')} className="peration-guide-image" />
                  <div>
                    <img
                      src={require('./../../../resource/boxOperation3.png')}
                      className="peration-guide-image"
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                </div>

                <div className="operation-guide-second-title">3）个人数据上传到安全盒；</div>
                <div style={{ marginTop: '21px' }}>
                  <img src={require('./../../../resource/getDataImage3.png')} className="peration-guide-image" />
                </div>
                <div style={{ marginTop: '16px' }}>
                  在数据包所在位置，首次下单的时候，用户需要购买新的安全盒，如用户再次预约此位置的数据包，系统会自动判断用户的账号是否有可用的安全盒，如有，则无需重新购买安全盒；
                </div>
                <div>
                  <div className="operation-guide-second-title">4）从安全盒下载数据；</div>
                  <div>
                    <div className="operation-guide-third-title"> 点击顶部导航“下载中心”</div>
                    <div style={{ marginTop: '21px' }}>
                      <img src={require('./../../../resource/getDataImage1.png')} className="peration-guide-image" />
                    </div>
                  </div>
                  <div>
                    <div className="operation-guide-third-title">
                      点击“下载申请”，选择需要下载申请的数据使用，输入下载说明，点击“提交”，系统会对所需下载的内容进行审核；
                    </div>
                    <div style={{ marginTop: '21px' }}>
                      <img src={require('./../../../resource/getDataImage1.png')} className="peration-guide-image" />
                      <div>
                        <img
                          src={require('./../../../resource/boxOperation7.png')}
                          className="peration-guide-image"
                          style={{ marginTop: '15px' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="operation-guide-third-title" id="OpContainer">
                      点击“下载中心”，选择通过审核的下载申请，按提示进行下载；
                    </div>
                    <div style={{ marginTop: '21px' }}>
                      <img src={require('./../../../resource/boxOperation8.png')} className="peration-guide-image" />
                    </div>
                  </div>

                  <Divider dashed={true} />
                </div>
              </Row>
            </div>
          </Col>
          <Col span={4}>
            <div className="FAQ-right-container">
              <p
                className={'lengthControl ' + anchorColors.quickColorName}
                style={{ fontWeight: 'bolder', cursor: 'pointer' }}
                onClick={this.quickBuyContainerClick}
              >
                快速预约数据
              </p>
              <p className="lengthControl">1.数据包选择</p>
              <p className="lengthControl">2.数据使用确认与提交</p>
              <p
                className={'lengthControl ' + anchorColors.boxUseColorName}
                style={{ fontWeight: 'bolder', cursor: 'pointer' }}
                onClick={this.securityBox}
              >
                安全盒使用
              </p>

              <p className="lengthControl">登录安全盒</p>
              <p className="lengthControl">上传数据到安全盒</p>
              <p className="lengthControl">从安全盒下载数据</p>
            </div>
          </Col>
        </Row>
      </IndexLayout>
    );
  }

  public quickBuyContainerClick = () => {
    // 找到锚点
    this.containerScroll('quickBuyContainer');
    this.setState({
      anchorColors: { quickColorName: 'main-color', boxUseColorName: 'black-color' },
    });
  };
  public securityBox = () => {
    // 找到锚点
    this.containerScroll('securityBoxUse');
    this.setState({
      anchorColors: { quickColorName: 'black-color', boxUseColorName: 'main-color' },
    });
  };

  public containerScroll = (eId: string) => {
    const anchorElement = document.getElementById(eId);
    // 如果对应id的锚点存在，就跳转到锚点
    if (anchorElement) {
      anchorElement.scrollIntoView();
    }
  };
}
export default CommonOperation;
