import { Col, Drawer, Row, Table, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import TransactionLogsApi from '../../../api/TransactionLogsApi';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import DescriptionItem from '../../../components/DescriptionItem/DescriptionItem';
import SecurityBoxView from '../../../components/InfoDrawer/SecurityBoxView';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import { IDataPackageItem, IOrderVm } from '../../../util/DataStruct';
import {
  drawerTitles,
  IDowloadTradeDetail,
  INormalList,
  IOrderData,
  IOrderDetailInfo,
  IParamForTrans,
  ITransactionItem,
  ITransactionState,
  IVmJsonItem,
  OrderStatusType,
  stateInit,
} from './CodeType';
const TabPane = Tabs.TabPane;

interface IProps extends FormComponentProps, RouteComponentProps {}

class TransactionLogs extends React.PureComponent<IProps, ITransactionState> {
  public state = stateInit as ITransactionState;
  /* 点击详情按钮事件 */
  public tableRowClick = async (record: ITransactionItem) => {
    const type = this.state.tabType;

    if (type === '1') {
      // 交易

      const { result } = await TransactionLogsApi.getTradeVmDetail<IOrderDetailInfo, { code: string }>({
        code: record.refCode || '',
      });
      this.setState({
        drawerType: record.subType,
        drawerVisible: true,
        drawerTitle: drawerTitles[record.subType - 1],
        tradeVmDetail: result,
      });
    } else if (type === '2') {
      // record.type  2 数据数据使用 3 安全盒数据使用
      const drawerType = record.type === 2 ? 7 : 6;

      this.setState({
        selectedOrderItem: record,
        drawerType,
        drawerVisible: true,
        drawerTitle: drawerTitles[drawerType - 1],
      });

      record.type === 2
        ? this.tradeOrderDataDetail(String(record.refCode))
        : this.tradeOrderVmDetail(String(record.refCode));
    } else {
      // 下载
      this.setState({
        selectedDowloadItem: record,
      });
      const { result } = await TransactionLogsApi.getTradeDowloadDetail<IDowloadTradeDetail, { code: string }>({
        code: record.refCode || '',
      });
      this.setState({
        drawerType: 8,
        drawerVisible: true,
        drawerTitle: drawerTitles[7],
        dowloadDetail: result,
      });
    }
  };
  /* 下载记录表头定义 */
  // tslint:disable-next-line:member-ordering
  public columnsDowload = [
    {
      title: '下载编号',
      dataIndex: 'refCode',
      key: 'refCode',
    },
    {
      title: '机构编号',
      dataIndex: 'serverCode',
      key: 'serverCode',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        let value = text;
        OrderStatusType.forEach(item => {
          if (item.key === text) {
            value = item.value;
          }
        });
        return <span>{value}</span>;
      },
    },
    {
      title: '时间',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: '详情',
      key: 'action',
      onCellClick: this.tableRowClick,
      render: (text: string) => {
        return <a>详情</a>;
      },
    },
  ];
  /* 数据使用记录表头定义 */
  // tslint:disable-next-line:member-ordering
  public columnsOrder = [
    {
      title: '数据使用号',
      dataIndex: 'refCode',
      key: 'refCode',
    },
    {
      title: '类型',
      key: 'type',
      dataIndex: 'type',
      render: (val: number) => {
        return <span> {val === 2 ? '数据预约' : '安全盒预约'} </span>;
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        let value = text;
        OrderStatusType.forEach(item => {
          if (item.key === text) {
            value = item.value;
          }
        });
        return <span>{value}</span>;
      },
    },
    {
      title: '时间',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: '详情',
      key: 'action',
      onCellClick: this.tableRowClick,
      render: (text: string) => <a>详情</a>,
    },
  ];

  public componentDidMount() {
    this.fetchData(1, this.state.tabType === '2' ? '2,3' : this.state.tabType);
  }
  /* 详情分类显示 */
  public drawerAdvance = () => {
    switch (this.state.drawerType) {
      case 1:
        return this.rechargeDrawerRender();
      case 2:
        return this.dataPackageDrawerRender();
      case 3:
        return this.tradeDataOrderDetailDrawerRander();
      case 4:
        return this.tradeVmOrderDetailDrawerRender(); // 安全盒购买 详情

      case 5:
        return this.tradeVmOrderRechargeDrawerRander();
      case 6:
        return this.orderVmDetailDrawerRander();
      case 7:
        return this.orderDataDetailDrawerRander();
      case 8:
        return this.dowloadDetailDrawerRander();

      default:
        return;
    }
  };
  /* 充值 */
  public rechargeDrawerRender = () => {
    // 充值
    return (
      <Row>
        <Row className="recharge">
          <span> 用户: </span>
          <span> 上海基诺链 </span>
        </Row>
        <Row className="recharge">
          <span> 支付方式: </span>
          <span> 线下支付 </span>
        </Row>
        <Row className="recharge">
          <span> 支付积分: </span>
          <span> 1000 万 </span>
        </Row>
        <Row className="recharge">
          <span> 获取积分: </span>
          <span> 100 积分 </span>
        </Row>
      </Row>
    );
  };

  /**
   * 数据包详情
   */
  public dataPackageDrawerRender = () => {
    // 数据包详情
    if (this.state.tradeVmDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = this.state.tradeVmDetail.orderData;
    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    return (
      <Row>
        <TradeDetailDrawer
          showOrderMessage={false}
          showPackageView={true}
          showSecurityBox={false}
          dataPackage={packageJson}
          onClose={this.closeDrawer}
        />
      </Row>
    );
  };
  /**
   * 数据使用详情
   */
  public tradeDataOrderDetailDrawerRander = () => {
    if (this.state.tradeVmDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = this.state.tradeVmDetail.orderData;
    const tradeDataDetail = this.state.tradeVmDetail;

    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    if (packageJson === undefined) {
      return <Row />;
    }
    return (
      <Row>
        <TradeDetailDrawer
          showOrderMessage={true}
          showPackageView={true}
          showSecurityBox={false}
          dataPackage={packageJson}
          orderDetailInfo={tradeDataDetail}
          onClose={this.closeDrawer}
        />
      </Row>
    );
  };
  /**
   * 安全盒详情
   */
  public tradeVmOrderDetailDrawerRender = () => {
    if (this.state.tradeVmDetail === undefined) {
      return <Row />;
    }
    const { orderVm } = this.state.tradeVmDetail;
    const tradeDataDetail = this.state.tradeVmDetail;

    const vmJson: IVmJsonItem = orderVm ? (orderVm.setupJson ? JSON.parse(orderVm.setupJson) : undefined) : undefined;
    if (vmJson === undefined) {
      return <Row />;
    }
    return (
      <Row id="TransactionLogs">
        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <DescriptionItem
            title="数据使用编号:"
            type="mid"
            content={tradeDataDetail ? (tradeDataDetail.code ? tradeDataDetail.code : '') : ''}
          />
          <DescriptionItem
            title="可用时间(天):"
            type="mid"
            content={tradeDataDetail ? (tradeDataDetail.days ? tradeDataDetail.days : '') : ''}
          />
        </Row>

        <Row>
          <SecurityBoxView vmJson={vmJson} />
        </Row>
      </Row>
    );
  };
  /**
   * 安全盒详情
   */
  public tradeVmOrderRechargeDrawerRander = () => {
    return this.tradeVmOrderDetailDrawerRender();
  };
  /**
   * 数据详情
   */
  public orderDataDetailDrawerRander = () => {
    const orderDataDetail = this.state.orderData;
    if (orderDataDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = orderDataDetail;

    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    if (packageJson === undefined) {
      return <Row />;
    }
    const parentOrder = orderDataDetail.parentOrder;

    const orderDetailInfo = parentOrder ? { ...parentOrder, code: orderDataDetail.code } : parentOrder;

    return (
      <Row>
        <TradeDetailDrawer
          showOrderMessage={true}
          showPackageView={true}
          showSecurityBox={false}
          dataPackage={packageJson}
          orderDetailInfo={orderDetailInfo}
          onClose={this.closeDrawer}
        />
      </Row>
    );
  };
  /**
   * 安全盒详情
   */
  public orderVmDetailDrawerRander = () => {
    const orderVm = this.state.orderVmDetail;
    const vmJson: IVmJsonItem = orderVm ? (orderVm.setupJson ? JSON.parse(orderVm.setupJson) : undefined) : undefined;
    if (vmJson === undefined) {
      return <Row />;
    }
    const parentOrder = orderVm ? orderVm.parentOrder : undefined;

    return (
      <Row>
        <TradeDetailDrawer
          showOrderMessage={true}
          showPackageView={false}
          showSecurityBox={true}
          vmJson={vmJson}
          orderDetailInfo={parentOrder}
          onClose={this.closeDrawer}
        />
      </Row>
    );
  };
  /**
   * 下载数据详情
   */
  public dowloadDetailDrawerRander = () => {
    const { dowloadDetail } = this.state;

    const { selectedDowloadItem } = this.state;
    return (
      <Row>
        <Row>
          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem
              title="数据使用编号:"
              type="mid"
              content={dowloadDetail ? (dowloadDetail.orderVmCode ? dowloadDetail.orderVmCode : '') : ''}
            />
            <DescriptionItem
              title="机构编号:"
              type="mid"
              content={dowloadDetail ? (dowloadDetail.serverCode ? dowloadDetail.serverCode : '') : ''}
            />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem
              title="下载编号:"
              type="mid"
              content={selectedDowloadItem ? (selectedDowloadItem.refCode ? selectedDowloadItem.refCode : '') : ''}
            />
            <DescriptionItem
              title="状态:"
              type="mid"
              content={selectedDowloadItem ? (selectedDowloadItem.status ? selectedDowloadItem.status : '') : ''}
            />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem
              title="处理日期:"
              type="mid"
              content={selectedDowloadItem ? (selectedDowloadItem.created ? selectedDowloadItem.created : '') : ''}
            />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem
              title="用户说明:"
              type="long"
              content={dowloadDetail ? (dowloadDetail.userRemark ? dowloadDetail.userRemark : '') : ''}
            />
          </Row>
        </Row>
      </Row>
    );
  };

  public render() {
    const orderItems = this.state.transactions.records;
    const dowloadItems = this.state.downloads.records;
    const { total, size, current } = this.state.pagination;
    return (
      <div id="TransactionLogs">
        <IndexLayout type="leftmenu">
          <Row style={{ backgroundColor: 'white' }}>
            <Row>
              <Col>
                <RouteHeader title="我的日志" titles={['首页', '我的日志']} routes={['/', '/transactionLogs']} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Tabs defaultActiveKey="2" onChange={this.tableOnchange} type="card" style={{ marginTop: '10px' }}>
                  <TabPane tab="预约记录" key="2">
                    <Row>
                      <Col span={24}>
                        <AuthComponent isShow={!!this.state.totalOrder}>
                          <Table
                            columns={this.columnsOrder}
                            dataSource={orderItems}
                            style={{ backgroundColor: 'white' }}
                            rowKey="id"
                            onChange={this.handleTableChange}
                            pagination={{ total, pageSize: size, current }}
                          />
                        </AuthComponent>
                        <NoDataImg isLoading={this.state.isloadingOrder} total={this.state.totalOrder} />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="下载记录" key="4">
                    <Row>
                      <Col span={24}>
                        <AuthComponent isShow={!!this.state.totalDowload}>
                          <Table
                            columns={this.columnsDowload}
                            dataSource={dowloadItems}
                            style={{ backgroundColor: 'white' }}
                            rowKey="id"
                            onChange={this.handleTableChange}
                            pagination={{ total, pageSize: size, current }}
                          />
                        </AuthComponent>
                        <NoDataImg isLoading={this.state.isloadinglDowload} total={this.state.totalDowload} />
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Row>

          <Drawer
            title={this.state.drawerTitle}
            placement="right"
            width={664}
            closable={false}
            onClose={this.closeDrawer}
            visible={this.state.drawerVisible}
          >
            {this.drawerView()}
          </Drawer>
        </IndexLayout>
      </div>
    );
  }
  /** 处理逻辑Actions */

  public tableOnchange = (value: string) => {
    /** tab label onClick   */
    this.setState({
      tabType: value,
    });

    this.fetchData(1, value === '2' ? '2,3' : value);
  };
  /* 选中项发生变化事件 */
  public handleTableChange = (pagination: PaginationConfig) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current ? pagination.current : 1;

    this.setState({
      pagination: pager,
    });
    this.fetchData(pagination.current, this.state.tabType === '2' ? '2,3' : this.state.tabType);
  };

  /* 获取列表数据 */
  public fetchData = async (currentPage?: number, type?: string) => {
    const param = { pageNum: currentPage, pageSize: this.state.pageSize, type: type || '' };
    if (type === '2,3') {
      try {
        this.setState({
          isloadingOrder: false,
        });
        const { result } = await TransactionLogsApi.getTransactions<INormalList<ITransactionItem[]>, IParamForTrans>(
          param
        );
        const { total, size, current } = result;
        this.setState({
          totalOrder: result.total,
          transactions: result,
          pagination: { total, size, current },
          isloadingOrder: true,
        });
      } catch (err) {
        this.setState({
          isloadingOrder: true,
        });
      }
    } else if (type === '4') {
      try {
        this.setState({
          isloadinglDowload: false,
        });
        const { result } = await TransactionLogsApi.getTransactions<INormalList<ITransactionItem[]>, IParamForTrans>(
          param
        );
        const { total, size, current } = result;
        this.setState({
          totalDowload: result.total,
          downloads: result,
          pagination: { total, size, current },
          isloadinglDowload: true,
        });
      } catch (err) {
        this.setState({
          isloadinglDowload: true,
        });
      }
    }
  };

  /*   fetch Data with type */

  public tradeOrderDataDetail = async (code: string) => {
    //  数据使用 数据数据使用详情
    const { result } = await TransactionLogsApi.getTradeDataDetail<IOrderData, { code: string }>({
      code,
    });
    this.setState({
      orderData: result,
    });
  };
  /* 数据使用 安全盒数据使用详情 */
  public tradeOrderVmDetail = async (code: string) => {
    // 数据使用 安全盒数据使用详情
    const { result } = await TransactionLogsApi.getTradeVmDetail<IOrderVm, { code: string }>({
      code,
    });
    this.setState({
      orderVmDetail: result,
    });
  };
  /* 弹出抽屉事件 */
  public drawerView = () => {
    // 1 充值 2数据包 3数据数据使用详情4 数据使用详情 5下载详情

    return this.drawerAdvance();
  };
  /* 打开抽屉 */
  public showDrawer = () => {
    this.setState({
      drawerVisible: true,
      drawerTitle: drawerTitles[this.state.drawerType - 1],
    });
  };
  /* 关闭抽屉 */
  public closeDrawer = () => {
    this.setState({
      drawerVisible: false,
    });
  };
}

export default TransactionLogs;
