import { Button, Form, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AuthComponent from '../../components/AuthComponent/AuthComponent';

import NoDataImg from '../../components/NoDataImg/NoDataImg';
import { IconFont } from '../../config/IconConfig';
import IndexLayout from '../../layout/IndexLayout';
import Storage from '../../util/Storage';
import { INormalList } from '../DataManager/CodeType';

import DataManagerApi from '../../api/DataManager';

import SciManageApi from '../../api/SciManageApi';
import { initalState, IProxAiManageState, ISciProxAiTaskItem } from './CodeType';
import './SciProxAi.scss';
interface ISciProxAiProps extends FormComponentProps, RouteComponentProps<{ pId: string; name: string }> {}
class SciProxAiPage extends React.Component<ISciProxAiProps, IProxAiManageState> {
  public projectColumns = [
    {
      title: '任务标题',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '任务详情',
      dataIndex: 'remark',
      key: 'remark',
      render: (text: string, record: ISciProxAiTaskItem) => {
        return (
          <div className="nowarp-elips" style={{ width: '300px' }} title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '所属成员',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '任务样本数量',
      dataIndex: 'seriesTotalNum',
      key: 'seriesTotalNum',
    },
    {
      title: '已提交序列数',
      dataIndex: 'seriesSubmittedNum',
      key: 'seriesSubmittedNum',
    },
  ];
  // public state = initalState as IProxAiManageState;
  constructor(props: ISciProxAiProps) {
    super(props);
    this.state = { ...initalState };
  }
  public componentDidMount() {
    this.getProjects();
  }

  public render() {
    return (
      <div className="proxai-manage-continer">
        <IndexLayout>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.props.history.goBack}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回应用工具</span>
              </div>
              <div style={{ color: 'rgba(0,0,0,0.45)' }}>
                <IconFont type={'medataicon-icon_location'} className="located-icon" />
                <span
                  onClick={() => this.props.history.go(-2)}
                  style={{ marginLeft: '10px', marginRight: '5px', cursor: 'pointer' }}
                >
                  项目列表
                </span>
                /
                <span style={{ marginLeft: '5px' }} onClick={this.props.history.goBack}>
                  应用工具
                </span>
                /<span style={{ marginLeft: '5px' }}>鉴影管理</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="project-message-title">
                <div className="dot" /> {`「${this.props.match.params.name}」的鉴影任务列表`}
              </div>
              <div className="header-action-container">
                <Button className="theme-normal-btn" onClick={this.linkPage} style={{ marginRight: '32px' }}>
                  进入鉴影
                </Button>
                <Button className="theme-primary-btn" onClick={this.addOneProjectTask}>
                  创建鉴影任务
                </Button>
              </div>
            </div>
          </div>
          <AuthComponent isShow={!!this.state.projectItems.length}>
            <Table
              columns={this.projectColumns}
              dataSource={this.state.projectItems}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...this.state.proPagination }}
              rowKey="id"
              onChange={this.handleHasTableChanage}
            />
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.proPagination.total} />
          <AuthComponent isShow={!this.state.projectItems.length}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className="theme-normal-btn" onClick={this.addOneProjectTask}>
                创建鉴影任务
              </Button>
            </div>
          </AuthComponent>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 获取项目列表
   */
  private getProjects = async (page: number = 1) => {
    const { proPagination } = this.state;
    proPagination.current = page;
    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getAppToolsProxaiTasks<
        INormalList<ISciProxAiTaskItem[]>,
        { page: number; pageSize: number; projectId: string }
      >({
        page: proPagination.current,
        pageSize: proPagination.pageSize,
        projectId: this.props.match.params.pId,
      });

      if (result) {
        proPagination.total = result.total;
        this.setState({
          proPagination,
          projectItems: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { proPagination } = this.state;
    proPagination.current = pagination.current || 1;
    this.setState({ proPagination });
    this.getProjects(proPagination.current);
  };
  /**
   * 点击创建鉴影任务
   */
  private addOneProjectTask = () => {
    Storage.setStore('PROJECTITEMS', JSON.stringify(this.state));
    this.props.history.push(`/createSciTask/${this.props.match.params.pId}`);
  };
  /**
   * 跳转鉴影
   */
  private linkPage = async () => {
    try {
      const { result } = await DataManagerApi.getApricotUrl<string>();
      const token = Storage.getStore('token');
      window.open(result + token);
    } catch (err) {
      // this.setState({ isLinkJY: false, iconToolCard: 'medataicon-toolGray' });
    }
  };
}
const SciProxAi = Form.create()(SciProxAiPage);
export default SciProxAi;
