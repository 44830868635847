import { IOptionUser } from '../../DataManager/Component/HasSharedDataComponent/CodeType';
import { IMemeberItem, IPagination } from '../../ScientificManage/CodeType';

export interface ICreateSciTaskState {
  seqPagination: IPagination;
  availableSeqs: ISciTaskSeqItem[];
  selectedSeqs: number[];
  members: IMemeberItem[];
  userItems: IOptionUser[];
  partDics: Map<string, string>;
  isLoading: boolean;
  isBack: boolean;
}

export interface ISciTaskSeqItem {
  bodyPart: string;
  fileType: string;
  id: number;
  importDate: string;
  modality: string;
  seriesInstanceUid: string;
  studyDate: string;
  studyInstanceUID: string;
  userName: string;
}

export const initalCreatSicTaskState: ICreateSciTaskState = {
  selectedSeqs: [],
  members: [],
  availableSeqs: [],
  userItems: [],
  seqPagination: { total: 0, pageSize: 10, current: 1 },
  partDics: new Map(),
  isLoading: true,
  isBack: false,
};
