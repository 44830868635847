import { Button, message, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import InputPassword from '../../../components/InputPassword/InputPassword';
import './ResetPassward.scss';
interface IResetPasswardProps extends FormComponentProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any> /* 表单参数 */;
  visible: boolean /* 模态框开关参数*/;
  onClose: () => void /* 模态框关闭函数参数 */;
  onConfirm: (pwd: string) => void /* 模态框确定函数参数 */;
}

class ResetPassward extends React.PureComponent<IResetPasswardProps, {}> {
  public render() {
    const { form, visible } = this.props;

    /* 模态框确定取消控件 */
    const footerRender = () => (
      <div className="psd-modal-footer">
        <Button type="primary" className="action-btn" onClick={this.okClick}>
          确定
        </Button>
        <Button className="action-btn" onClick={this.cancelClick} style={{ marginLeft: '34px' }}>
          取消
        </Button>
      </div>
    );
    return (
      <Modal
        visible={visible}
        width="580px"
        className="reset-passward-container"
        title="重置密码"
        closable={false}
        footer={footerRender()}
      >
        <InputPassword title={'新密码'} errorMessage={'输入密码'} form={form} fileID={'pwd'} />
        <InputPassword title={'确认密码'} errorMessage={'输入确认密码'} form={form} fileID={'comfirmpwd'} />
      </Modal>
    );
  }

  /* 点击确定 */
  private okClick = () => {
    //
    const { validateFields } = this.props.form;
    validateFields(['pwd', 'comfirmpwd'], (err, values) => {
      if (!err) {
        if (values.pwd === values.comfirmpwd) {
          // 密码相同
          this.props.onConfirm(values.pwd);
        } else {
          message.error('确认密码错误');
        }
      }
    });
  };
  private cancelClick = () => {
    this.props.onClose();
  };
}

export default ResetPassward; // 创建form实例
