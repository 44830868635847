import axios from './RequestApi';
export default class ProxAiManageApi {
  /**
   *  获取项目列表
   * @param data
   */
  public static getProjects<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/projectPage', data);
  }
  /**
   *
   * @param data 新增项目
   */
  public static addProject<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/jy/project/save', data);
  }

  /**
   *
   * 新增Task
   */
  public static addTask<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/jy/task/save', data);
  }

  /**
   * 供应商列表 - 成员列表
   */
  public static getProjectMembers<T>() {
    return axios.get<T, {}>('/user/logined/apricot/jy/vendorList');
  }
  /**
   * 鉴影模板列表
   */
  public static getProxAiTemplates<T>() {
    return axios.get<T, {}>('/user/logined/apricot/jy/jyTemplateList');
  }
  /**
   *
   * 获取可用序列列表
   */
  public static getAvailableSeqs<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/dr/rawDataInfo', data);
  }
  /**
   * 获取项目下可用序列列表 用于创建任务
   */
  public static getProjectSeqs<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/project/seriesPage', data);
  }
  /**
   * 项目下 任务的查询
   */
  public static getProjectTasks<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/project/taskPage', data);
  }
}
