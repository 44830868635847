import { Button, Carousel, Modal } from 'antd';
import React from 'react';
import { IconFont } from '../../../../config/IconConfig';
import AuthComponent from './../../../../components/AuthComponent/AuthComponent';
import './SwiperGuide.scss';
/**
 * 我的数据上传数据引导轮播图，
 * 由于弹框是和根元素同级，不方便修改样式
 * 所以单独抽出
 */
interface IProps {
  isShow: boolean;
  isUsb: boolean;
  onCloseModal: () => void;
}
// tslint:disable-next-line:no-any
let slider: any;
class SwiperGuide extends React.Component<IProps> {
  public state = {
    swiperIndex: 0,
    swiperNames: ['移动硬盘接入', '数据和病例命名', '数据打包'],
  } as {
    swiperNames: string[];
    swiperIndex: number;
  };

  public render() {
    return (
      <Modal
        title={this.swiperTitRender()}
        width={1000}
        visible={this.props.isShow}
        footer={[this.swiperFooterRender()]}
        closable={true}
        onCancel={this.returnGuide}
        className="SwiperGuide"
        // closeIcon={<IconFont />}
      >
        <Carousel
          // tslint:disable-next-line:jsx-no-multiline-js
          ref={el => {
            slider = el;
          }}
          dots={false}
          className="swiper_box"
        >
          {// tslint:disable-next-line:jsx-no-multiline-js
          [1, 2, 3].map(item => (
            <img key={item} className="swi_img" src={require(`../../../../resource/data${item}.svg`)} alt="" />
          ))}
        </Carousel>
      </Modal>
    );
  }

  /**
   * 下一步切换轮播图引导
   */
  private nextSwiper = () => {
    if (this.state.swiperIndex === 2) {
      this.returnGuide();
      return;
    }
    slider.slick.slickNext();
    this.setState({
      swiperIndex: this.state.swiperIndex += 1,
    });
  };
  /**
   * 渲染轮播图页脚
   */
  private swiperFooterRender = () => {
    return (
      <div key="1" className="footer_box">
        <div className="cir_box">
          {// tslint:disable-next-line:jsx-no-multiline-js
          [0, 1, 2].map(item => {
            return (
              <div key={item} className={this.state.swiperIndex === item ? 'cir_s sele_bor' : 'cir_s'}>
                <AuthComponent isShow={this.state.swiperIndex === item}>
                  <div className="cir_b" />
                </AuthComponent>
              </div>
            );
          })}
        </div>
        <AuthComponent isShow={this.state.swiperIndex === 0}>
          <div className="footer_desc">将移动硬盘插入Medata节点服务器</div>
        </AuthComponent>
        <AuthComponent isShow={this.state.swiperIndex === 1}>
          <div className="footer_desc">
            <div>病例和编号需要具有唯一性</div>
            <div className="div">数据命名需要包涵文件夹的名称</div>
            <div> 针对数据的补充描述请在Excel模板中添加</div>
          </div>
        </AuthComponent>
        <AuthComponent isShow={this.state.swiperIndex === 2}>
          <div className="footer_desc">将命名好的数据放入对应的文件夹中，压缩文件为上传做准备</div>
        </AuthComponent>
        <Button type="primary" onClick={this.nextSwiper} className="btn1">
          {this.state.swiperIndex === 2 ? '开始上传' : '下一步'}
        </Button>
        <div className="ren_box" onClick={this.returnGuide}>
          跳过
        </div>
      </div>
    );
  };
  /**
   * 渲染轮播头标题
   */
  private swiperTitRender = () => {
    return (
      <div className="title_box">
        <div>
          Setp {this.state.swiperIndex + 1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {this.state.swiperNames[this.state.swiperIndex]}
        </div>
        <div className="lef">
          <AuthComponent isShow={!this.props.isUsb}>
            <div className="ti_b">硬盘未接入</div>
          </AuthComponent>
          <IconFont className={this.props.isUsb ? 'headerIcon' : ''} type="medataicon-yidongyingpan-" />
        </div>
      </div>
    );
  };

  /**
   * 跳过教程
   */
  private returnGuide = () => {
    this.props.onCloseModal();
    this.setState({
      swiperIndex: 0,
    });
  };
}
export default SwiperGuide;
