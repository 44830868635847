import { Button, Divider, Drawer, Form, Input, Modal, Radio, Switch, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SciManageApi from '../../../api/SciManageApi';
import { IRoleItem } from '../../../api/UserAccount';
import VMManage from '../../../api/VMManage';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import Storage, { USERROLEITEMS } from '../../../util/Storage';
import { INormalList } from '../../DataManager/CodeType';
import { ISafetyBoxInfo } from '../../OrderPromise/VMOrders/CodeType';
import { IVmBoxConsoleInfo } from '../CodeType';
import {
  DATA_USER_TAB_ROW_ATIONS,
  IDataUserItem,
  IDataUserItemAction,
  IDataUserReq,
  InitState,
  ISecBoxManageState,
} from './CodeType';
import './SecBoxManage.scss';
import SecBoxPkgCmp from './SecBoxPkgCmp';
interface ISciDataManageProps extends FormComponentProps, RouteComponentProps<{ pId: string; name: string }> {}
class SecBoxManagePage extends React.Component<ISciDataManageProps, ISecBoxManageState> {
  public DataUserColumn = [
    {
      title: '用户名',
      dataIndex: 'loginname',
      key: 'loginname',
      render: (text: string, record: IDataUserItem) => {
        return (
          <div className="sequences-uuid-table divTitle" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '安全盒编号',
      dataIndex: 'vmInstanceNum',
      key: 'vmInstanceNum',
      render: (text: string, record: IDataUserItem) => {
        return (
          <div className="sequences-uuid-table divTitle" title={text}>
            {text || '/'}
          </div>
        );
      },
    },
    {
      title: '网络开关',
      dataIndex: 'networkSwitch',
      key: 'networkSwitch',
      render: (text: string, record: IDataUserItem) => {
        return (
          <div className="sequences-uuid-table" title={text}>
            {text !== null ? (
              <Switch
                checked={record.networkSwitch === 1 ? true : false}
                disabled={record.networkSwitch === 1 ? false : true}
                // tslint:disable-next-line:jsx-no-multiline-js
                onChange={checked => {
                  this.switchChange(checked, record);
                }}
              />
            ) : (
              '/'
            )}
          </div>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'vmInstanceId',
      key: 'vmInstanceId',
      render: (text: string, record: IDataUserItem) => {
        const actions = record.vmInstanceNum
          ? DATA_USER_TAB_ROW_ATIONS
          : [{ title: '开启安全盒', type: IDataUserItemAction.openBox }];
        return (
          <div className="table-row-action-container">
            {actions.map((item, index) => {
              return (
                <div key={index} className="action">
                  <div
                    onClick={
                      // tslint:disable-next-line:jsx-no-multiline-js
                      () => {
                        this.rowClickAction(record, item.type);
                      }
                    }
                  >
                    {record.isStartVm ? '正在开启...' : item.title}
                  </div>
                  <div hidden={index === actions.length - 1}>
                    <Divider type="vertical" />
                  </div>
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];
  public state = InitState as ISecBoxManageState;
  public componentDidMount() {
    //
    this.getDataUserList();
  }
  public render() {
    const laodPkgfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.hintModalOkClick}>
          确定关闭
        </Button>
        <Button className="ant-btn-no " onClick={this.hintModalCancelClick}>
          取消
        </Button>
      </div>
    );
    const laodBoxfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.loadModalOkClick}>
          前往安全盒
        </Button>
        <Button className="ant-btn-no " onClick={this.loadModalCancelClick}>
          取消
        </Button>
      </div>
    );
    const boxInfo = this.state.vmBoxInfo;
    const smbInfo = (
      <p>
        4.smb/ftp账号: {boxInfo && boxInfo.smbUsername}, 密码: {boxInfo && boxInfo.smbPassword}
      </p>
    );
    return (
      <div className="scientific-data-manage-continer">
        <IndexLayout>
          <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.props.history.goBack}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回项目列表</span>
              </div>
              <div style={{ color: 'rgba(0,0,0,0.45)', cursor: 'pointer' }}>
                <IconFont type={'medataicon-icon_location'} className="located-icon" />
                <span onClick={this.props.history.goBack} style={{ marginLeft: '10px', marginRight: '5px' }}>
                  项目列表
                </span>
                /<span style={{ marginLeft: '5px' }}>安全盒</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="project-message-title">
                <div style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                  <div className="dot" /> {`「${this.props.match.params.name}」项目数据列表`}
                </div>
              </div>
            </div>
          </div>
          <div className="header">
            <div className="list-type-left-container">
              <div style={{ width: '100%', height: '1px', backgroundColor: 'rgba(214, 214, 214, 0.6)' }} />
            </div>
            <Radio.Group
              defaultValue={1}
              value={this.state.currentRadio}
              buttonStyle="solid"
              onChange={this.handleTypeGroupChanage}
              className="radio-more-type"
            >
              <Radio.Button value={1}>数据分析人员</Radio.Button>
              <Radio.Button value={4}>项目数据包</Radio.Button>
            </Radio.Group>
            <div className="list-type-left-container">
              <div style={{ width: '100%', height: '1px', backgroundColor: 'rgba(214, 214, 214, 0.6)' }} />
            </div>
          </div>
          <div style={{ marginTop: '32px' }}> {this.showListView()}</div>
          <Modal
            visible={this.state.hintModalVisible}
            className="load-pkg-modal"
            width="560px"
            centered={true}
            title={'提示'}
            footer={laodPkgfooter}
            closable={true}
            onCancel={this.hintModalCancelClick}
          >
            <div className="modal-content-view">
              网络关闭后系统会自动挂载数据
              <div style={{ color: 'rgba(166, 32, 36, 1)' }}> 网络一旦关闭将无法再打开</div>
            </div>
          </Modal>
          <Drawer
            title="安全盒信息"
            placement="right"
            width={560}
            closable={false}
            onClose={() => this.setState({ boxMsgDrawerVisible: false })}
            visible={this.state.boxMsgDrawerVisible}
          >
            <TradeDetailDrawer
              showOrderMessage={false}
              showPackageView={false}
              showSecurityBox={true}
              vmJson={this.state.clickBoxMsg ? this.state.clickBoxMsg : undefined}
              onClose={() => this.setState({ boxMsgDrawerVisible: false })}
            />
          </Drawer>
          <Modal
            visible={this.state.safetyBoxVisible}
            className="load-pkg-modal"
            width="560px"
            centered={true}
            title={'提示'}
            footer={laodBoxfooter}
            closable={true}
            onCancel={this.loadModalCancelClick}
          >
            <div style={{ paddingTop: '30px', paddingLeft: '150px' }}>
              <p>
                1.用户名：{boxInfo && boxInfo.username}, 密码:{boxInfo && boxInfo.password}
              </p>
              <p>
                2.FTP地址: {boxInfo && boxInfo.ftpAddress}, FTP端口:{boxInfo && boxInfo.ftpPort}
              </p>
              <p>3.数据挂载地址: {boxInfo && boxInfo.ftpDataDir}</p>
              {boxInfo && boxInfo.smbUsername ? smbInfo : ''}
            </div>
          </Modal>
        </IndexLayout>
      </div>
    );
  }

  /**
   *  条件显示列表组件
   */
  private showListView = () => {
    const type = Number(this.state.currentRadio);

    if (type === 1) {
      return this.dataUserTableView();
    } else {
      return <SecBoxPkgCmp form={this.props.form} projectId={Number(this.props.match.params.pId)} />;
    }
  };

  /**
   * 用数据分析人员列表
   */

  private dataUserTableView = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <TableHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
              <Form.Item label="用户名:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('sci_data_user_name')(
                  <Input className="original-input" placeholder="请选择" style={{ width: '240px' }} allowClear={true} />
                )}
              </Form.Item>
              <Button
                className="normal-search-button"
                onClick={this.handleUserSearchAction}
                style={{ marginLeft: '40px', fontWeight: 'bold', marginRight: '60px' }}
              >
                查询
              </Button>
            </div>
          </div>
        </TableHeader>
        <AuthComponent isShow={!!this.state.userPagination.total}>
          <Table
            columns={this.DataUserColumn}
            dataSource={this.state.dataUserItems}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.userPagination }}
            rowKey="id"
            onChange={this.handleMemberTableChanage}
          />
        </AuthComponent>
        <NoDataImg isLoading={this.state.isLoading} total={this.state.userPagination.total} msg="暂无数据分析人员..." />
      </div>
    );
  };

  /**
   * 列表分页处理
   */
  private handleMemberTableChanage = (pagination: PaginationConfig) => {
    const { userPagination } = this.state;
    userPagination.current = (pagination && pagination.current) || 1;
    this.setState(
      {
        userPagination: { ...userPagination },
      },
      () => {
        this.getDataUserList();
      }
    );
  };

  /**
   * 处理页面Radio 切换
   */
  private handleTypeGroupChanage = (e: RadioChangeEvent) => {
    this.setState({
      currentRadio: e.target.value,
    });
  };

  /**
   * 获取数据分析人员列表
   */

  private getDataUserList = async () => {
    const { userPagination } = this.state;
    const { getFieldValue } = this.props.form;
    const username = getFieldValue('sci_data_user_name');
    const userRoles: IRoleItem[] = JSON.parse(Storage.getStore(USERROLEITEMS));
    let projectRoleId;
    userRoles.forEach(item => {
      if (item.code === 'DATA_ANALYST') {
        projectRoleId = item.id;
      }
    });
    const { result } = await SciManageApi.getDataUserList<INormalList<IDataUserItem[]>, IDataUserReq>({
      pageSize: userPagination.pageSize,
      pageNum: userPagination.current,
      researchProjectId: Number(this.props.match.params.pId),
      projectRoleId,
      username,
    });
    if (result) {
      userPagination.total = result.total;
      this.setState({
        isLoading: true,
        userPagination,
        dataUserItems: [...result.records],
      });
      this.forceUpdate();
    }
  };

  /**
   * 分析人员用户名筛选
   */
  private handleUserSearchAction = () => {
    this.getDataUserList();
  };

  /**
   * switch change 事件
   * @param checked switch 的切换状态
   * @param checked  当前切换的数据分析人员信息 的切换状态
   */
  private switchChange = async (checked: boolean, item: IDataUserItem) => {
    this.setState({ clickUserItem: item, hintModalVisible: true });
  };

  /**
   *  表格row Action
   */

  // tslint:disable-next-line:no-any
  private rowClickAction = (item: IDataUserItem, type: IDataUserItemAction) => {
    if (item.isStartVm) {
      return;
    }

    if (!item.vmInstanceNum) {
      item.isStartVm = true;
    }
    this.setState({ clickUserItem: item }, () => {
      switch (type) {
        case IDataUserItemAction.checkedBox:
          this.setState({ boxMsgDrawerVisible: true });
          this.getSecurityBoxMsg(item.vmInstanceId);
          break;
        case IDataUserItemAction.gotoBox:
          this.openBox();
          break;
        case IDataUserItemAction.openBox: {
          this.startSecurityBox();
          const newList = this.state.dataUserItems.map(e => {
            if (e.id === item.id) {
              return item;
            } else {
              return e;
            }
          });
          this.setState({
            dataUserItems: [...newList],
          });
          break;
        }
      }
    });
  };

  /**
   * 开启安全盒
   */

  private startSecurityBox = async () => {
    const { clickUserItem } = this.state;
    if (clickUserItem) {
      const url = `/user/logined/vmInstance/cloneVm?researchProjectId=${clickUserItem &&
        clickUserItem.researchProjectId}&ownUserId=${clickUserItem.id}`;
      const { result } = await VMManage.startSecurityBox(url);
      if (result) {
        this.getDataUserList();
      }
    }
  };

  /**
   * 提示模态框Ok
   */

  private hintModalOkClick = async () => {
    const { clickUserItem } = this.state;
    if (clickUserItem) {
      const { result } = await SciManageApi.changeNetworkStatus({
        vmInstanceId: clickUserItem.vmInstanceId,
      });
      if (result) {
        this.hintModalCancelClick();
      }
    }
    this.getDataUserList();
  };

  /**
   * 提示模态框 cancel
   */

  private hintModalCancelClick = () => {
    this.setState({
      hintModalVisible: false,
    });
  };
  /**
   * 获取安全盒详情
   */
  private getSecurityBoxMsg = async (vmInstanceId: number) => {
    const { result } = await VMManage.getSecurityBoxMsg({ vmInstanceId });
    if (result) {
      this.setState({
        clickBoxMsg: JSON.parse(result.distributeSetupJson),
      });
    }
  };

  /**
   * 打开安全盒
   */
  private openBox = async () => {
    const { clickUserItem } = this.state;
    const { result } = await VMManage.getVmInfo<ISafetyBoxInfo[], { researchProjectId: number }>({
      researchProjectId: Number(this.props.match.params.pId),
    });
    if (result) {
      const vmBoxInfos = result.filter(s => {
        return s.ownUserId === clickUserItem!.id;
      });

      this.setState({
        vmBoxInfo: vmBoxInfos[0],
        safetyBoxVisible: true,
      });
    }
  };

  /**
   * 安全盒弹框的确认按钮
   */
  private handleOk = async () => {
    const { clickUserItem } = this.state;
    const { result } = await VMManage.getVmInstanceConsoleInfo<IVmBoxConsoleInfo>({
      vmInstanceId: (clickUserItem && clickUserItem.vmInstanceId) || 0,
    });

    window.open(
      result.novncUrl +
        '?CSRFPreventionToken=' +
        result.csrfPreventionToken +
        '&node=' +
        result.node +
        '&PVEAuthCookie=' +
        result.pveAuthCookie +
        '&vmid=' +
        result.vmid
    );
    this.setState({ safetyBoxVisible: false });
  };
  /**
   * 挂载模态框OKCLick
   */
  private loadModalOkClick = () => {
    this.handleOk();
    this.setState({
      safetyBoxVisible: false,
    });
  };
  /**
   * 挂载模态框 关闭
   */
  private loadModalCancelClick = () => {
    this.setState({
      safetyBoxVisible: false,
    });
  };
}
const SecBoxManage = Form.create()(SecBoxManagePage);
export default SecBoxManage;
