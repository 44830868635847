import { Card, Divider, Icon, Menu, Row } from 'antd';
import { Link } from 'dva/router';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import Routes, { IRouteConfigItem } from '../../pages/Routes';
import Storage, { LOGINNAME, NODENAME } from '../../util/Storage';
// import './LeftMenu.scss';

// const IconFont = Icon.createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_1166688_helgc8z15jm.js',
// });

interface IMenuItem {
  path: string;
  name: string;
  icon: string;
  hideInMenu?: string;
  routes?: IMenuItem[];
}
interface IProps {
  menuData?: IMenuItem[];
  openKeys: string[];
  onOpenChange: (openKeys: string[]) => void;
  mode: 'vertical' | 'vertical-left' | 'vertical-right' | 'horizontal' | 'inline' | undefined;
  theme: 'light' | 'dark' | undefined;
}

class LeftMenu extends React.Component<IProps, {}> {
  /* icon嵌入 */
  public toIcon = (iconName: string | undefined) => {
    return iconName ? (
      iconName.includes('icon-') ? (
        <IconFont className="icon-formenu" type={iconName} />
      ) : (
        <Icon className="icon-formenu" type={iconName} />
      )
    ) : (
      <span>&#12288;&nbsp;&nbsp;&nbsp;</span>
    );
  };

  public render() {
    const loginname = Storage.getStore(LOGINNAME);
    const nodename = Storage.getStore(NODENAME);
    const pathName = location.href.split('#')[1];

    const submenu = (routes: IRouteConfigItem[], path: string) => {
      return routes.map((item: IRouteConfigItem) => {
        if (item.type === 'leftmenu') {
          if (item.routes) {
            return <Menu.SubMenu key={path + item.path}>{submenu(item.routes, path + item.path)}</Menu.SubMenu>;
          } else {
            return (
              <Menu.Item key={path + item.path}>
                {this.toIcon(item.icon)}
                <span>{item.name}</span>
                <Link to={path + item.path} />
              </Menu.Item>
            );
          }
        }
      });
    };
    const permissions: string[] = JSON.parse(Storage.getStore('permissions') || '[]');
    /* menu构成 */
    const menus = Routes.map((route, i) => {
      const title = (item: IRouteConfigItem) => (
        <span>
          {this.toIcon(item.icon)}
          <span>{item.name}</span>
        </span>
      );
      if (route.type === 'leftmenu') {
        // 判断该用户是否有积分权限
        if (route.name !== '我的积分' || permissions.includes('USER_ACCOUNT_MANAGE')) {
          if (route.routes) {
            return (
              <Menu.SubMenu className="top-item" key={route.path} title={title(route)}>
                {submenu(route.routes, route.path)}
              </Menu.SubMenu>
            );
          } else {
            return (
              <Menu.Item className={'top-item'} key={route.path}>
                {this.toIcon(route.icon)}
                <span>{route.name}</span>
                <Link to={route.path} />
              </Menu.Item>
            );
          }
        }
      }
    });
    const head = (
      <Card className="center headerCard">
        <IconFont type="medataicon-qiatong" className="headerIcon" style={{ fontSize: '7em', marginBottom: '0.1em' }} />
        <h3 className="center" style={{ marginTop: 37, fontSize: 14 }}>
          <Row className="center">
            <div
              style={{ float: 'left', width: 95, textAlign: 'right', verticalAlign: 'middle', color: '#000' }}
              className="lengthControl"
              title={loginname ? loginname : ''}
            >
              {loginname}
            </div>
            <Divider type="vertical" style={{ float: 'left', height: '1em', margin: '0 15px' }} />
            <div
              style={{ float: 'left', width: 95, verticalAlign: 'middle', color: '#000' }}
              className="lengthControl"
              title={nodename ? nodename : ''}
            >
              {nodename}
            </div>
          </Row>
        </h3>
      </Card>
    );
    return (
      <div id="LeftMenu">
        <Menu
          mode={this.props.mode}
          theme={this.props.theme}
          defaultOpenKeys={['/guide']}
          openKeys={this.props.openKeys}
          onOpenChange={this.props.onOpenChange}
          /* onClick={this.onItemClick} */
          selectedKeys={[pathName]}
          className="LeftMenu"
        >
          <Menu.Item key="header" className="headerItem">
            {head}
          </Menu.Item>
          {menus}
        </Menu>
      </div>
    );
  }
}

export default LeftMenu;
