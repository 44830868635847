import { Button, Card, Col, Divider, Drawer, Form, Row, Select, Switch, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HttpRequest from '../../../api/MyDataApi';
import InfoDrawer from '../../../components/InfoDrawer/InfoDrawer';
import ProjectStatus from '../../../components/ProjectStatus/ProjectStatus';
import QuestionDetail from '../../../components/QuestionDetail/QuestionDetail';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import DataSet from '../../../util/DataSet';
import { IDataPackageItem } from '../../../util/DataStruct';

import {
  IHospital,
  IItemList,
  IMyDataProjectDetail,
  INormalList,
  IPharmacyDetailstate,
  IQuestionnaireDetail,
  IRelationHospital,
} from './CodeType';
import './PharmacyDetail.scss';

interface IPharmacyDetailprops extends FormComponentProps, RouteComponentProps {}

const { Option } = Select;

class PharmacyDetail extends React.PureComponent<IPharmacyDetailprops, IPharmacyDetailstate> {
  public state = {
    current: 1,
    questionVisible: false,
    questionName: '',
    detectionVisible: false,
    patientName: '',
    packageVisible: false,
    pharmacyName: '',
    headerTitle: '基因测序',
    pkgDiscription: '',
    hosipitalOption: [],
    currentPharmacyCode: '',
    currentPharmacyName: '',
    hospitalListVisible: false,
    collectorNum: '',
    usedCollectorNum: '',
    pagination: { total: 0, pageSize: 10, current: 1 },
    relateHospitalList: [],
    hospitalList: [],
    pharmacyDetailList: [],
    detectionLog: [],
    relationHospitalList: [],
    isLoading: true,
  } as IPharmacyDetailstate;
  public columns = [
    {
      title: '病例',
      dataIndex: 'name',
      width: 400,
      render: (text: string, record: IMyDataProjectDetail) => {
        return <div>{record.patient.genochainCode}</div>;
      },
    },
    {
      title: '唾液盒状态',
      key: 'statusStr',

      render: (record: IMyDataProjectDetail) => {
        return <ProjectStatus status={record.step} />;
      },
    },
    {
      title: <div style={{ paddingLeft: '1em' }}>操作</div>,
      className: 'table-opt-area',
      key: 'action',
      width: 300,
      render: (text: string, record: IMyDataProjectDetail) => {
        const genInfo = (
          <span>
            <Divider type="vertical" className="optline" />
            <a onClick={this.handlePackage.bind(this, record.project.pkg.id)}>基因信息</a>
          </span>
        );
        const unGenInfo = (
          <span>
            <Divider type="vertical" className="optline" />
            <a className="unClick">基因信息</a>
          </span>
        );

        const btnGroup = (
          <div>
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={this.handleQuestion.bind(
                this,
                record.project.code,
                record.patient.id,
                record.project.questionnaire.name
              )}
            >
              查看问卷
            </a>
            <Divider type="vertical" className="optline" />
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={this.handelDetection.bind(
                this,
                record.detectNo,
                record.patient.id,
                record.patient.genochainCode
              )}
            >
              检测记录
            </a>
            {record.step === 9 ? genInfo : unGenInfo}
          </div>
        );
        return btnGroup;
      },
    },
  ];

  /**
   *  获取问卷
   */
  public handleQuestion = async (code: string, patientId: number, questionName: string) => {
    const { result } = await HttpRequest.getPharmacyQuestionDetail<
      IQuestionnaireDetail,
      { code?: string; patientId?: number }
    >({
      code,
      patientId,
    });
    if (result) {
      this.setState({
        questionDetail: result,
        questionName,
        questionVisible: true,
      });
    }
  };

  /**
   * 获取检测信息
   */
  public handelDetection = async (detectCode: string, patientId: number, patientName: string) => {
    const { result } = await HttpRequest.getPharmacyDetections<
      IQuestionnaireDetail[],
      { detectCode?: string; patientId?: number }
    >({
      detectCode,
      patientId,
    });
    if (result) {
      this.setState({
        detectionLog: [...result],
        patientName,
        detectionVisible: true,
      });
    }
  };

  /**
   * 获取数据包详情
   */
  public handlePackage = async (id: number) => {
    const { result } = await HttpRequest.getPackageInfo<IItemList, { id: number }>({
      id,
    });
    if (result) {
      this.setState({
        packageDetail: result,
        packageVisible: true,
      });
    }
  };
  /**
   * 问题关闭
   */
  public questionClose = () => {
    this.setState({
      questionVisible: false,
    });
  };
  /**
   * 检测信息关闭
   */
  public detectionClose = () => {
    this.setState({
      detectionVisible: false,
    });
  };
  /**
   * 数据详情关闭
   */
  public packageClose = () => {
    this.setState({
      packageVisible: false,
    });
  };
  /**
   * 提交
   */
  public handleSubmit = () => {
    this.getPharmacys();
  };

  /**
   * 获取药厂列表
   */
  public getPharmacys = async (pageNum?: number) => {
    const { pagination } = this.state;
    pageNum = pageNum ? pageNum : 1;
    pagination.current = pageNum;
    const hospitalCode = this.props.form.getFieldValue('hospitalSelected');
    const projectCode = sessionStorage.getItem('pharmacy-code');

    try {
      this.setState({ isLoading: false });
      const { result } = await HttpRequest.getPharmacyDetail<
        INormalList<IMyDataProjectDetail[]>,
        { pageNum?: number; pageSize?: number; code: string | null; hospitalCode?: string }
      >({
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
        code: projectCode,
        hospitalCode,
      });

      if (result) {
        if (result.records.length === 0) {
          pagination.total = result.total;
          this.setState({
            pharmacyDetailList: [],
            pagination,
          });
        }

        const item: IMyDataProjectDetail = result.records[0];
        const headerTitle = item.project.name;
        const pharmacyName = item.project.pharmacy.name;
        const pkg: IDataPackageItem = item.project.pkg.jsonDesc ? JSON.parse(item.project.pkg.jsonDesc) : undefined;
        pagination.total = result.total;
        this.setState({
          pharmacyDetailList: [...result.records],
          headerTitle,
          pharmacyName,
          pkgDiscription: pkg ? (pkg.project_overview ? pkg.project_overview : '') : '',
          pagination,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 切换页码
   */
  public handleTableChange = (pagination: PaginationConfig) => {
    this.getPharmacys(pagination.current);
  };

  /**
   * 获取医院列表
   */
  public getHospitalList = async () => {
    const pharmacyCode = sessionStorage.getItem('pharmacy-code');
    const { result } = await HttpRequest.getHospitalRelatedPharmacys<
      INormalList<IHospital[]>,
      { pageNum?: number; pageSize?: number; pharmacyCode: string | null }
    >({
      pharmacyCode,
      pageNum: 1,
      pageSize: 1000,
    });
    if (result) {
      this.setState({
        relateHospitalList: [...result.records],
      });
    }
  };
  /**
   * 根据医院筛选
   */
  public hospitalListFilter = async () => {
    const code = sessionStorage.getItem('pharmacy-code');
    const { result } = await HttpRequest.getHospitalFiter<
      INormalList<IHospital[]>,
      { pageNum?: number; pageSize?: number; code: string | null }
    >({
      pageNum: 1,
      pageSize: 1000,
      code,
    });
    if (result) {
      this.setState({
        hospitalList: [...result.records],
      });
      this.hospitalListOption();
    }
  };

  public componentDidMount() {
    const collectorNum = sessionStorage.getItem('pharmacy-collectorNum');
    const usedCollectorNum = sessionStorage.getItem('pharmacy-usedCollectorNum');

    this.setState({
      collectorNum,
      usedCollectorNum,
    });

    this.getPharmacys();
    this.getHospitalList();
    this.hospitalListFilter();
  }
  /**
   * 关闭医院列表模态框
   */
  public hospitalListClose = () => {
    this.setState({
      hospitalListVisible: false,
    });
  };
  /**
   * 医院列表分页
   */
  public handleHospitalList = async () => {
    const pcode = sessionStorage.getItem('pharmacy-code');

    const { result } = await HttpRequest.getRelationHospitals<
      INormalList<IRelationHospital[]>,
      { pageNum?: number; pageSize?: number; code: string | null }
    >({
      code: pcode,
      pageNum: 1,
      pageSize: 100,
    });
    if (result) {
      this.setState({
        currentPharmacyCode: pcode,
        hospitalListVisible: true,
        relationHospitalList: [...result.records],
      });
    }
  };
  /**
   * onPermissionChange
   */
  public onPermissionChange = async (hospitalCode: string, checked: boolean, event: Event) => {
    const { result } = await HttpRequest.allocatePermissions({
      code: this.state.currentPharmacyCode,
      hospitalCode,
      hasPermissions: checked,
    });
    if (result) {
      this.handleHospitalList();
    }
  };
  /**
   * 医院列表
   */
  public hospitalList() {
    const { relationHospitalList } = this.state;
    let render: JSX.Element | JSX.Element[];

    if (relationHospitalList.length > 0) {
      // tslint:disable-next-line:no-any
      render = relationHospitalList.map((v: any, index: number) => {
        return (
          <tr key={index}>
            <td>{v.name}</td>
            <td style={{ textAlign: 'right', paddingRight: '32px' }}>{v.usedCollectorNum ? v.usedCollectorNum : 0}</td>
            <td>
              <Switch checked={v.hasPermissionToUse} onChange={this.onPermissionChange.bind(this, v.code)}>
                <a>{v.code}</a>
              </Switch>
            </td>
          </tr>
        );
      });
    } else {
      render = <div />;
    }

    return (
      <div>
        <table className="mypharmacyhospitallist" style={{ tableLayout: 'fixed', width: '100%' }}>
          <tr>
            <th>医院名称</th>
            <th style={{ textAlign: 'right', paddingRight: '32px' }}>已收集病例</th>
            <th>使用权限</th>
          </tr>
          {render}
        </table>
        <Button
          className="back-btn"
          type="primary"
          style={{ float: 'right', marginRight: '24px' }}
          onClick={this.hospitalListClose}
        >
          返回
        </Button>
      </div>
    );
  }
  /**
   * 检测记录
   */
  public detectionLog = () => {
    const { detectionLog } = this.state;
    // tslint:disable-next-line:no-any
    const render = detectionLog.map((item: any, index: number) => {
      return (
        <div className="logList" key={index}>
          <span>{item.created}</span>
          <span className="lineContainer">
            <Divider style={{ width: '100px', marginTop: '0px', marginBottom: '0px' }} dashed={true} />
          </span>

          <span>{item.statusStr}</span>
        </div>
      );
    });

    const nerender = (
      <div>
        {render}

        <Button
          className="back-btn"
          type="primary"
          style={{ float: 'right', marginRight: '24px', marginTop: '0.5px' }}
          onClick={this.detectionClose}
        >
          返回
        </Button>
      </div>
    );

    return nerender;
  };
  /**
   * 初始化医院的 Options
   */
  public hospitalListOption = () => {
    const optionlist: JSX.Element[] = [];
    const { hospitalList } = this.state;

    hospitalList.forEach(item => {
      const element = (
        <Option key={item.id} value={item.code}>
          {item.name}
        </Option>
      );
      optionlist.push(element);
    });

    this.setState({
      hosipitalOption: optionlist,
    });
  };
  public renderForm() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form layout="inline" className="DataSearchForm">
        <Row style={{ width: '100%', display: 'flex', paddingLeft: '24px' }}>
          <Col style={{ display: 'flex', alignItems: 'center', width: '240px', marginRight: '54px' }}>
            <Form.Item>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('hospitalSelected')(
                <Select
                  style={{ width: '156px' }}
                  placeholder="医院"
                  allowClear={true}
                  // onChange={this.onHospitalChange}
                >
                  {this.state.hosipitalOption}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={8} sm={24} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              // htmlType="submit"
              className="normal-search-button"
              onClick={this.handleSubmit}
              style={{ width: '96px' }}
            >
              查询
            </Button>
            {/* <Button style={{ marginLeft: 8 }}>重置</Button> */}
          </Col>
        </Row>
      </Form>
    );
  }
  public render() {
    const { pharmacyDetailList, packageDetail, questionDetail, pagination } = this.state;

    const packageJsonInfo = packageDetail ? DataSet.dataInfoSet(packageDetail) : {};

    const btnGroup = (
      <div>
        <Button onClick={this.packageClose} type="primary">
          返回
        </Button>
      </div>
    );
    const header = this.state.headerTitle;
    return (
      <IndexLayout>
        <div className="page-container pharmacyDetail" id="PharmacyDetail">
          <RouteHeader
            title={header}
            titles={['首页', '我的数据', '项目详情']}
            routes={['/', '/mypharmacy', '/pharmacyDetail']}
          />
          <Card bordered={false} className="prjectinfo">
            <label>采集器（个）：</label>
            {this.state.collectorNum}
            <a className="prjectinfo-line" />
            <label>已使用采集器（个）：</label>
            {this.state.usedCollectorNum}
            <a className="prjectinfo-line" />
            <a className="prjectinfo-btn" onClick={this.handleHospitalList}>
              查看医院
            </a>
          </Card>
          <Card bordered={false} className="prjectdesc">
            <label>{this.state.pkgDiscription}</label>
          </Card>
          <Card bordered={false} className="pharmacyDetailCard">
            <div className="queryform">
              <Row className="queryform-row">
                <Col className="queryform-col">{this.renderForm()}</Col>
              </Row>
            </div>
            <Table
              columns={this.columns}
              dataSource={pharmacyDetailList}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...pagination }}
              rowKey="orderCode"
              onChange={this.handleTableChange}
            />
          </Card>
          <QuestionDetail
            title={this.state.questionName}
            questionDetail={questionDetail ? questionDetail : ({} as IQuestionnaireDetail)}
            onClose={this.questionClose}
            visible={this.state.questionVisible}
          />
          <Drawer
            title={'检测记录' + this.state.patientName}
            placement="right"
            closable={true}
            width="410px"
            className="detectionLog"
            onClose={this.detectionClose}
            visible={this.state.detectionVisible}
          >
            {this.detectionLog()}
          </Drawer>
          <InfoDrawer
            data={packageJsonInfo}
            visible={this.state.packageVisible}
            onClose={this.packageClose}
            btnGroup={btnGroup}
            demoUrl={packageDetail ? packageDetail.demoUrl : ''}
            caseNumberHidden={true}
            allData={packageDetail}
            exampleHidden={true}
          />
          <Drawer
            title={this.state.currentPharmacyName + '医院列表'}
            placement="right"
            closable={true}
            width="564px"
            className="mypharmacy-hospitallist"
            onClose={this.hospitalListClose}
            visible={this.state.hospitalListVisible}
          >
            {this.hospitalList()}
          </Drawer>
        </div>
      </IndexLayout>
    );
  }
}
const DataSearchList = Form.create()(PharmacyDetail);
export default DataSearchList;
