import { Button, Card, Col, Drawer, Row, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { SubscriptionAPI } from 'dva';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import RouteHeader from '../../../components/routeHeader/RouteHeader';

import { PaginationConfig } from 'antd/lib/table';
import Users from '../../../api/UserAccount';
import UserGradesApi from '../../../api/UserGradesApi';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import GradesDetailDrawer from '../../../components/InfoDrawer/GradesDetailDrawer';

import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import IndexLayout from '../../../layout/IndexLayout';
import { IDataPackageItem } from '../../../util/DataStruct';
import {
  IMyDatastate,
  INormalList,
  IOrderDetailInfo,
  IState,
  ITradeListReq,
  IUserGrade,
  IUserGradeTradeItem,
  stateInit,
  STATETYPE,
} from './CodeType';

interface IUserGradesProps extends FormComponentProps, RouteComponentProps, SubscriptionAPI {
  user: IState;
}

const { Option } = Select;

class UserGrades extends React.PureComponent<IUserGradesProps, IMyDatastate> {
  /* 表格头 */
  public columns = [
    {
      title: '数据使用编号',
      dataIndex: 'refCode',
      key: 'refCode',
    },
    {
      title: '时间',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: '交易类型',
      dataIndex: 'type',
      key: 'type',
      render: (text: string, record: IUserGradeTradeItem) => {
        const typeStr = STATETYPE.filter(val => {
          if (Number(val.key) === record.type) {
            return true;
          }
          return false;
        });

        return <span> {typeStr ? typeStr[0].value : ''}</span>;
      },
    },
    {
      title: '积分',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: IUserGradeTradeItem) => {
        return (
          <span className={record.amount > 0 ? 'status-plus' : 'status-minus'}>
            {record.amount > 0 ? '+' + record.amount : record.amount}
          </span>
        );
      },
    },
    {
      title: <h4 className="action-title">操作</h4>,
      width: 100,
      key: 'action',
      className: 'myDataOpt center',
      render: (text: string, record: IUserGradeTradeItem) => {
        // eslint-disable-next-line no-nested-ternary
        const btnGroup = (
          <Fragment>
            <div className="myDataOptBtn">
              <a
                // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
                onClick={() => {
                  this.showDetail(record);
                }}
                title="详情"
              >
                查看详情
              </a>
            </div>
          </Fragment>
        );
        return btnGroup;
      },
    },
  ];
  public state = stateInit;

  /* 点击查看详情事件 */
  public showDetail = async (item: IUserGradeTradeItem) => {
    this.setState({
      clickItem: item,
      drawerType: item.type,
    });
    //  数据使用 数据数据使用详情
    const { result } = await UserGradesApi.getTradeVmDetail<IOrderDetailInfo, { code: string }>({
      code: item.refCode,
    });
    this.setState(
      {
        tradeVmDetail: result,
      },
      () => {
        this.showDrawer();
      }
    );
  };
  /* 显示抽屉 */
  public showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  /* 收起抽屉 */
  public onClose = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * 提交查询表单
   */
  public handleSubmit = () => {
    this.getGradeList();
  };

  public componentDidMount() {
    this.getUserGradeInfo();
    this.getGradeList();
  }
  /* 检索栏 */
  public renderForm() {
    const statusSelect = STATETYPE.map(item => (
      <Option key={item.key} value={item.key}>
        {item.value}
      </Option>
    ));
    return (
      <Row className="table-header" style={{ display: 'flex', alignItems: 'center' }}>
        <span>交易类型: </span>
        <Select placeholder="请选择" allowClear={true} onChange={this.tradeTypeOptionChange} className="select-form">
          {statusSelect}
        </Select>

        <Button className="normal-search-button" onClick={this.handleSubmit}>
          查询
        </Button>
      </Row>
    );
  }
  public render() {
    const { total, pageSize, current } = this.state.pagination;
    const { userGrade } = this.state;
    const typeStr = STATETYPE.filter(val => {
      if (Number(val.key) === this.state.drawerType) {
        return true;
      }
      return false;
    });
    return (
      <div id="UserGrades">
        <IndexLayout type="leftmenu">
          <div className="user-grade-container">
            {/* <AuthComponent permission="USER_ACCOUNT_MANAGE"> */}
            <RouteHeader title="我的积分" titles={['首页', '我的积分']} routes={['/', '/userGrades']} />
            {/* </AuthComponent> */}
            <Card bordered={false} className="myDataCard">
              <Row>
                <div className="header-line">
                  总积分:
                  <span className="all-score">{userGrade ? userGrade.coin : 0} </span>
                  充值积分:
                  <span className="score-plus">+{userGrade ? userGrade.refillCoin : 0}</span>
                  数据所得:
                  <span className="score-plus">+{userGrade ? userGrade.dataIncomeCoin : 0}</span>
                  消耗积分:
                  <span className="score-minus">-{userGrade ? userGrade.spendCoin : 0}</span>
                </div>
              </Row>
              <Row className="searchAera">
                <Col>{this.renderForm()}</Col>
              </Row>
              <AuthComponent isShow={!!this.state.total}>
                <Table
                  columns={this.columns}
                  dataSource={this.state.gradesList}
                  style={{ backgroundColor: 'white' }}
                  rowKey="id"
                  onChange={this.handleTableChange}
                  pagination={{ total, pageSize, current }}
                />
              </AuthComponent>
              <NoDataImg isLoading={this.state.isLoading} total={this.state.total} />
            </Card>

            <Drawer
              title={typeStr[0].value}
              placement="right"
              closable={false}
              width={675}
              visible={this.state.visible}
              onClose={this.onClose}
              className="grade-drawer"
            >
              <div className="grade-drawer-content">{this.drawerView()}</div>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                  style={{ height: '32px', marginTop: '10px', marginRight: '24px' }}
                  type="primary"
                  onClick={this.onClose}
                >
                  返回
                </Button>
              </div>
            </Drawer>
          </div>
        </IndexLayout>
      </div>
    );
  }
  /* 抽屉选择 */
  public drawerView = () => {
    /**  { key: '1', value: '充值' },{ key: '2', value: '安全盒购买' },{ key: '3', value: '数据检索' },{ key: '4', value: '数据检索结算' },{ key: '5', value: '安全盒购买结算' },{ key: '6', value: '平台抽成' },{ key: '7', value: '数据退款' },{ key: '8', value: '安全盒退款' }, */

    switch (this.state.drawerType) {
      case 1:
        return this.rechargeDrawerRender(); // 充值

      case 2:
        return this.dataShopDrawerRender(); // 数据检索
      case 3:
        return this.vmShopDrawerRender(); // 安全盒购买
      case 4:
        return this.dataTradeDrawerRender(); // 数据检索结算

      case 5:
        return this.vmShopTradeDrawerRender(); // 安全盒购买结算
      case 6:
        return this.plantRechargeRender(); // 平台抽成
      case 7:
        return this.dataReturnTradeDrawerRender(); // 数据退款
      case 8:
        return this.vmReturnTradeDrawerRender(); // 安全盒退款

      default:
        return;
    }
  };

  /* 充值 */
  public rechargeDrawerRender = () => {
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }
    return (
      <Row>
        <GradesDetailDrawer
          showGradeOrder={true}
          showPackageView={false}
          showSecurityBox={false}
          gradeOrder={tradeVmDetail}
          coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
        />
      </Row>
    );
  };
  /* 安全盒购买 */
  public vmShopDrawerRender = () => {
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }

    const vmJson = tradeVmDetail.orderVm.setupJson ? JSON.parse(tradeVmDetail.orderVm.setupJson) : undefined;
    return (
      <Row>
        <ErrorBoundary>
          <GradesDetailDrawer
            showGradeOrder={true}
            hiddenContactNumber={true}
            showPackageView={false}
            showSecurityBox={true}
            gradeOrder={tradeVmDetail}
            vmJson={vmJson}
            coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
          />
        </ErrorBoundary>
      </Row>
    );
  };
  /* 数据检索 */
  public dataShopDrawerRender = () => {
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = tradeVmDetail.orderData;
    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    return (
      <Row>
        <ErrorBoundary>
          <GradesDetailDrawer
            showGradeOrder={true}
            hiddenContactNumber={true}
            showPackageView={true}
            showSecurityBox={false}
            dataPackage={packageJson}
            gradeOrder={tradeVmDetail}
            coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
          />
        </ErrorBoundary>
      </Row>
    );
  };
  /* 数据检索结算 */
  public dataTradeDrawerRender = () => {
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = tradeVmDetail.orderData;
    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    return (
      <Row>
        <ErrorBoundary>
          <GradesDetailDrawer
            showGradeOrder={true}
            showPackageView={true}
            showSecurityBox={false}
            dataPackage={packageJson}
            gradeOrder={tradeVmDetail}
            coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
          />
        </ErrorBoundary>
      </Row>
    );
  };
  /**
   * 详情
   */

  public vmShopTradeDrawerRender = () => {
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }

    const vmJson = tradeVmDetail.orderVm ? JSON.parse(tradeVmDetail.orderVm.setupJson) : undefined;
    return (
      <Row>
        <GradesDetailDrawer
          showGradeOrder={true}
          showPackageView={false}
          showSecurityBox={true}
          hiddenContactNumber={true}
          gradeOrder={tradeVmDetail}
          vmJson={vmJson}
          coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
        />
      </Row>
    );
  };
  /* 平台抽成 */
  public plantRechargeRender = () => {
    if (this.state.tradeVmDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = this.state.tradeVmDetail.orderData;
    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    return (
      <Row>
        <GradesDetailDrawer
          showPackageView={true}
          showSecurityBox={false}
          dataPackage={packageJson}
          coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
        />
      </Row>
    );
  };
  /**
   * // 数据退款
   */
  public dataReturnTradeDrawerRender = () => {
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }
    const { packageInfo } = tradeVmDetail.orderData;
    const packageJson: IDataPackageItem = packageInfo
      ? packageInfo.jsonDesc
        ? JSON.parse(packageInfo.jsonDesc)
        : undefined
      : undefined;
    return (
      <Row>
        <ErrorBoundary>
          <GradesDetailDrawer
            showGradeOrder={true}
            showPackageView={true}
            showSecurityBox={false}
            dataPackage={packageJson}
            gradeOrder={tradeVmDetail}
            coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
          />
        </ErrorBoundary>
      </Row>
    );
  };
  /* 安全盒退款 */
  public vmReturnTradeDrawerRender = () => {
    // 安全盒退款
    const { tradeVmDetail } = this.state;
    if (tradeVmDetail === undefined) {
      return <Row />;
    }

    const vmJson = tradeVmDetail.orderVm ? JSON.parse(tradeVmDetail.orderVm.setupJson) : undefined;
    return (
      <Row>
        <ErrorBoundary>
          <GradesDetailDrawer
            showGradeOrder={true}
            showPackageView={false}
            showSecurityBox={true}
            gradeOrder={tradeVmDetail}
            vmJson={vmJson}
            coinPrice={this.state.clickItem ? this.state.clickItem.amount : 0}
          />
        </ErrorBoundary>
      </Row>
    );
  };

  /* selectedType  onChange*/
  public tradeTypeOptionChange = (value: number) => {
    //
    this.setState({
      typeList: value,
    });
  };
  /**
   * 处理 table 切换
   */
  public handleTableChange = (pag: PaginationConfig) => {
    const { pagination } = this.state;
    pagination.current = pag ? (pag.current ? pag.current : 1) : 1;

    this.setState({
      pagination,
    });
    this.getGradeList();
  };

  private getUserGradeInfo = async () => {
    const { result } = await Users.userGradeInfo<IUserGrade, {}>({});
    if (result) {
      this.setState({
        userGrade: result,
      });
    }
  };
  /**  获取积分列表 */
  private getGradeList = async () => {
    const { pagination, typeList } = this.state;
    const param = { pageNum: pagination.current, pageSize: pagination.pageSize, type: typeList };
    try {
      this.setState({
        isLoading: false,
      });
      const { result } = await UserGradesApi.getUserGradesList<INormalList<IUserGradeTradeItem[]>, ITradeListReq>(
        param
      );
      if (result) {
        pagination.current = result.current;
        pagination.total = result.total;
        this.setState({
          gradesList: result.records ? result.records : [],
          pagination,
          isLoading: true,
          total: result.total,
        });
      }
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };
}

export default UserGrades;
