import dva, { IDvaInstance } from 'dva';
// import React from 'react';
// import App from './App';
import './index.scss';
import globals from './models/Global';
// import personalCenter from './pages/OrderPromise/models/DataOrderModels';

import router from './Router';

import * as Sentry from '@sentry/browser';
import Config from './config/Config';

Sentry.init({ dsn: Config.SENTRY_DSN, environment: Config.SENTRY_ENVIRONMENT });

export const app: IDvaInstance = dva();
app.model(globals);
app.router(router);
// app.router(() => <App />);
// 启动应用
app.start('#root');

// export const app = dva({
//     initialState: { global: JSON.parse(localStorage.getItem("global") || "{}") },
//     // tslint:disable-next-line:no-any
//     onStateChange: (state?: any) => {
//       localStorage.setItem("global", JSON.stringify(state.global));
//     }
//   });
