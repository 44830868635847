import { Card, Divider, Form, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HttpRequest from '../../../api/MyDataApi';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import InfoDrawer from '../../../components/InfoDrawer/InfoDrawer';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import { IDataPackageItem } from '../../../util/DataStruct';
import { INormalList } from '../../DataManager/CodeType';
import { IMyDataProjectList, IMyHospitalState, IPkgInfo } from './CodeType';
import './MyHospital.scss';
// const statusMap: Array<'success' | 'error'> = ['success', 'error'];
// const status = ['有权限', '没权限', '未知'];
interface IMyHospitalprops extends FormComponentProps, RouteComponentProps {}

class MyHospital extends React.PureComponent<IMyHospitalprops, IMyHospitalState> {
  public state = {
    current: 1,
    visible: false,
    pagination: { total: 0, pageSize: 10, current: 1 },
    myHospitalList: [],
    isLoading: true,
  } as IMyHospitalState;
  public columns = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '数据格式',
      key: 'dataFormat',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div>{jsonPkg ? jsonPkg.project_data_format : ''}</div>;
      },
    },
    {
      title: '数据类型',
      key: 'type',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div>{jsonPkg ? (jsonPkg.project_data_type === 1 ? '基因' : '影像') : ''}</div>;
      },
    },
    {
      title: <div style={{ textAlign: 'right', width: '150px', paddingRight: '50px' }}>数量</div>,
      key: 'num',
      width: 150,
      className: 'table-num',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div style={{ paddingRight: '50px' }}>{jsonPkg ? jsonPkg.project_amount : ''}</div>;
      },
    },
    {
      title: <div>病种</div>,
      key: 'dis',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div>{jsonPkg ? jsonPkg.project_disease_type : ''}</div>;
      },
    },
    {
      title: <div>等级</div>,
      key: 'project_score',
      width: 150,
      // tslint:disable-next-line:no-any
      sorter: (a: any, b: any) => JSON.parse(a.pkg.jsonDesc).project_score - JSON.parse(b.pkg.jsonDesc).project_score,
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div style={{ paddingRight: '50px' }}>{jsonPkg ? jsonPkg.project_score : ''}</div>;
      },
    },

    {
      title: <div style={{ paddingLeft: '1em' }}>操作</div>,
      width: 230,
      className: 'table-opt-area',
      key: 'action',
      render: (text: string, record: IMyDataProjectList) => {
        const btnGroup = (
          <div className="btngroup">
            <a onClick={this.toProjectDetail.bind(this, record.code, record.pharmacy.name, record.hasPermissionToUse)}>
              项目详情
            </a>
            <Divider type="vertical" className="optline" />
            <a onClick={this.handleDetail.bind(this, record.pkg.id)}>数据详情</a>
          </div>
        );
        return btnGroup;
      },
    },
  ];

  public componentDidMount() {
    this.getHospitals();
  }
  /**
   *
   * 权限保存
   */
  public toProjectDetail(code: string, pharmacyName: string, hasPermissionToUse: boolean) {
    const permission = hasPermissionToUse === true ? '有权限' : '无权限';

    sessionStorage.setItem('hospital-code', code);
    sessionStorage.setItem('hospital-pharmacyName', pharmacyName);
    sessionStorage.setItem('hospital-hasPermissionToUse', permission);
    window.open('#/hospitalDetail');
    // this.props.history.push({ pathname: 'hospitalDetail' });
  }
  /**
   * 显示详情
   */
  public handleDetail = async (id: number) => {
    const { result } = await HttpRequest.getPackageInfo<IPkgInfo, { id: number }>({
      id,
    });
    if (result) {
      this.setState({
        packageDetail: result,
        visible: true,
      });
    }
  };
  /**
   * 关闭详情
   */
  public onClose = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * 获取医院列表
   */
  public getHospitals = async (pageNum?: number) => {
    const { pagination } = this.state;
    pageNum = pageNum ? pageNum : 1;
    try {
      this.setState({ isLoading: false });
      const { result } = await HttpRequest.getMyHospitals<
        INormalList<IMyDataProjectList[]>,
        { pageNum: number; pageSize: number }
      >({
        pageNum,
        pageSize: pagination.pageSize ? pagination.pageSize : 10,
      });
      if (result) {
        //
        pagination.current = result.current;
        pagination.total = result.total;
        if (result.records.length > 0) {
          this.setState({
            myHospitalList: [...result.records],
            pagination,
            isLoading: true,
          });
        }
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 页面变化
   */
  public handleTableChange = (pagination: PaginationConfig) => {
    this.getHospitals(pagination.current);
  };
  // project_name: '项目名称',
  // project_overview: '项目描述',
  // project_design: '项目设计'
  public render() {
    const { myHospitalList, packageDetail, pagination } = this.state;
    const detailJson = packageDetail && JSON.parse(packageDetail.jsonDesc);
    return (
      <IndexLayout>
        <div className="page-container" id="MyHospital">
          <RouteHeader title="我的数据" titles={['首页', '我的数据']} routes={['/', '/myhospital']} />
          <AuthComponent isShow={!!this.state.pagination.total}>
            <Card bordered={false} className="myHospitalCard">
              <Table
                columns={this.columns}
                dataSource={myHospitalList}
                style={{ backgroundColor: 'white' }}
                pagination={{ ...pagination }}
                rowKey="orderCode"
                onChange={this.handleTableChange}
              />
            </Card>
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.pagination.total} />

          <InfoDrawer
            data={detailJson}
            visible={this.state.visible}
            onClose={this.onClose}
            demoUrl={packageDetail && packageDetail.demoUrl}
            allData={packageDetail}
          />
        </div>
      </IndexLayout>
    );
  }
}
const MyHospitalList = Form.create()(MyHospital);
export default MyHospitalList;
