import { Button, Col, Collapse, Form, InputNumber, message, Row, Select } from 'antd';
import React from 'react';
import OrderManageApi from '../../../api/OrderManageApi';
import VMManage from '../../../api/VMManage';
import InfoDrawer from '../../../components/InfoDrawer/InfoDrawer';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';

import { IDataPackageItem } from '../../../util/DataStruct';
import CheckVmListItemView from './../Component/CheckVmListItemView';
import {
  IOrderDataVmItem,
  IOrderSubmitReq,
  IOrderUserVmItem,
  IPackageItem,
  IPackageReq,
  IProps,
  IState,
  IVmConfigItem,
} from './CodeType';
import './OrderCheck.scss';
const FormItem = Form.Item;
const { Option } = Select;
const { Panel } = Collapse;

class OrderCheckForm extends React.PureComponent<IProps, {}> {
  public state = {
    visible: false,
    newSecurityBoxVisible: true,
    inputDays: 0,
    mySecurityBox: [],
    VMs: [],
  } as IState;

  public async componentDidMount() {
    const vms = await VMManage.getVMs<IVmConfigItem[]>();
    const { result } = await OrderManageApi.getOrderUserPackage<IOrderDataVmItem[], { packageId: string }>({
      packageId: this.props.match.params.id,
    });

    this.setState({
      newSecurityBoxVisible: result.length ? true : false,
      mySecurityBox: result,
      VMs: vms.result,
    });
    if (result.length) {
      this.pushBoxViewRender();
    }
    this.getPackageWithId(this.props.match.params.id);
  }

  public render() {
    const {
      form: { getFieldValue },
    } = this.props;
    const configItems = this.state.VMs;
    const { packageCheckItem, checkPackageItems } = this.state;
    const detailJson = packageCheckItem && packageCheckItem.jsonDesc ? JSON.parse(packageCheckItem.jsonDesc) : {};
    const btnGroup = (
      <div>
        <Button onClick={this.onClose} type="primary">
          返回
        </Button>
      </div>
    );

    const days: number = getFieldValue('days') && getFieldValue('days') >= 0 ? getFieldValue('days') : 0;
    const selectvalue: string = getFieldValue('select');
    const selectConfig = selectvalue ? configItems.find(item => item.vmConfigCode === selectvalue) : undefined;

    const vmprice = selectConfig ? selectConfig.vmCoinPrice * Number(days) : 0; // 安全盒的价格
    const vmMoney = this.state.newSecurityBoxVisible ? 0 : vmprice;
    const pkgMoney = packageCheckItem ? packageCheckItem.coinPrice : 0;

    return (
      <IndexLayout>
        <div id="orderCheck">
          <Form layout="horizontal">
            <Row style={{ backgroundColor: 'white' }}>
              <Row>
                <Col span={24}>
                  <RouteHeader
                    title="填写并核对数据使用信息"
                    titles={['首页', '数据检索', '数据使用信息核对']}
                    routes={['/', '/datasearch', '/orderCheck/:id']}
                  />
                </Col>
              </Row>
              <Row className="order-check-container">
                <Row>
                  <Col span={4} className="order-check-package-container">
                    <span style={{ fontSize: '16px', color: 'rgba(0,0,0,1)', fontWeight: 'bold' }}>数据信息：</span>
                    <Row style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                      <span className="dataItem">
                        项目名称:
                        <span style={{ marginLeft: '10px' }}>
                          {checkPackageItems ? checkPackageItems.project_name : ''}
                        </span>
                      </span>
                      <span className="dataItem">
                        数据格式:
                        <span style={{ marginLeft: '10px' }}>
                          {checkPackageItems ? checkPackageItems.project_data_format : ''}
                        </span>
                      </span>
                      <span className="dataItem">
                        数量:
                        <span style={{ marginLeft: '10px' }}>
                          {checkPackageItems ? checkPackageItems.project_amount : ''}
                        </span>
                      </span>
                      <span className="dataItem">
                        等级:
                        <span style={{ marginLeft: '10px' }}>
                          {checkPackageItems ? checkPackageItems.project_score : ''}{' '}
                        </span>
                      </span>
                      <span className="dataItem">
                        数据积分:
                        <span style={{ marginLeft: '10px' }}>{packageCheckItem ? packageCheckItem.coinPrice : ''}</span>
                        <span style={{ marginLeft: '5px' }}>(积分) </span>
                      </span>
                      <span
                        className="dataItem"
                        style={{ color: '#00A1E6', marginTop: '16px', cursor: 'pointer' }}
                        onClick={this.showDrawer}
                      >
                        数据详情
                      </span>
                    </Row>
                  </Col>
                  <Col span={20} className="order-check-box-container">
                    <Col span={19} className="order-security-box">
                      <Row style={{ marginTop: '16px' }}>
                        <span
                          style={{ fontSize: '16px', color: 'rgba(0,0,0,1)', fontWeight: 'bold', marginTop: '16px' }}
                        >
                          安全盒
                        </span>
                        <span style={{ marginLeft: '8px' }}> (安全盒平台) </span>
                      </Row>

                      <Row>
                        {this.state.newSecurityBoxVisible ? this.mySecurityBoxRender() : this.securityBoxNewRender()}
                      </Row>
                    </Col>
                    <Col span={5} className="order-check-right-container">
                      <Row style={{ fontSize: '16px', color: 'rgba(0,0,0,1)', marginTop: '16px' }}>总积分:</Row>
                      <Row className="rowItem">
                        数据积分: <span style={{ marginLeft: '10px' }}> {pkgMoney}</span>
                      </Row>
                      <Row className="rowItem">
                        安全盒积分: <span style={{ marginLeft: '10px' }}>{vmMoney}</span>
                      </Row>
                      <Row className="rowItem">
                        总积分:
                        <span
                          // tslint:disable-next-line:jsx-no-multiline-js
                          style={{
                            fontWeight: 'bold',
                            fontSize: '24px',
                            color: 'rgba(0, 161, 230, 1)',
                            marginLeft: '10px',
                          }}
                        >
                          {pkgMoney + vmMoney}
                        </span>
                        <span style={{ marginLeft: '10px' }}>积分 </span>
                      </Row>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} className="order-check-package-container" />
                  <Col span={20} className="order-check-box-container">
                    <Col span={19} className="order-security-box" style={{ height: '42px' }} />
                    <Col
                      span={5}
                      className="order-check-right-container"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Button
                        style={{ height: '32px', marginBottom: '10px' }}
                        type="primary"
                        onClick={this.handleSubmit}
                      >
                        提交数据使用
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Row>
              <Row style={{ marginTop: '24px', backgroundColor: 'white' }} className="order-check-collapse-container">
                <Col span={24}>
                  <Collapse bordered={false} expandIcon={this.expandIconRender} accordion={true}>
                    <Panel header="为什么要购买安全盒?" key="1" className="customPanelStyle">
                      <p>
                        当获得选购数据的数据使用权限后，”安全盒”提供了一套通过实现数据所有权和数据使用权分离，以确保数据流通过程安全可控的产品技术方案，购买的”安全盒”是为数据提供可以使用的媒介。
                      </p>
                    </Panel>
                    <Panel header=" 提交数据使用后多久可以使用数据呢？" key="2" className="customPanelStyle">
                      <p>
                        当平台接收到“安全盒”订单的请求后，平台会尽快开启一个“安全盒”给到用户，用户可以通过订单状态查看是否可以使用数据。
                      </p>
                    </Panel>
                    <Panel header="我如何获取我在安全盒内的运算结果？" key="3" className="customPanelStyle">
                      <p>
                        进入下载中心选择对应的安全盒订单，填写下载内容的基本描述后申请下载。平台审核通过后在点击下载获取FTP账号密码使用FTP工具进行下载操作。
                      </p>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Row>
          </Form>
          <div>
            <InfoDrawer
              data={detailJson}
              visible={this.state.visible}
              onClose={this.onClose}
              btnGroup={btnGroup}
              allData={packageCheckItem}
              demoUrl={packageCheckItem ? packageCheckItem.demoUrl : ''}
            />
          </div>
        </div>
      </IndexLayout>
    );
  }

  /**
   * 显示数据包详情抽屉
   */
  private showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   * 关闭数据包详情抽屉
   */

  private onClose = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * 安全盒下拉选择
   */
  private vmSelectChange = (val: string) => {
    const selectConfig = this.state.VMs.find(item => item.vmConfigCode === val);

    this.setState({
      selectConfig,
    });
  };
  /**
   * 天数输入框
   */
  private inputDaysChange = (value: number | undefined) => {
    //
    if (value) {
      this.setState({
        inputDays: value,
      });
    }
  };

  /**
   * 提交判断是什么样的安全盒
   */
  private handleSubmit = (e: React.MouseEvent) => {
    /**  提交表单 */
    if (this.state.newSecurityBoxVisible) {
      // 使用原来的 安全盒
      const selectItem = this.state.selectedUserVmItem;
      if (selectItem === undefined) {
        return;
      } else {
        this.submit(undefined, undefined, selectItem.id);
      }
    } else {
      // 新 安全盒
      this.props.form.validateFields(err => {
        if (!err) {
          const { getFieldsValue } = this.props.form;
          const { days } = getFieldsValue(['days']);

          this.submit(days, this.state.selectConfig ? this.state.selectConfig.vmConfigCode : '0');
        }
      });
    }
  };

  /**
   * 提交订单
   */
  private submit = async (days?: number, vmConfigCode?: string, orderVmId?: number) => {
    const { result } = await OrderManageApi.createOrder<string, IOrderSubmitReq>({
      days,
      packageId: this.props.match.params.id,
      vmConfigCode,
      orderVmId,
    });
    message.success('数据使用提交成功', 3);
    this.props.history.push('/orderPay/' + result);
  };

  /**
   * 获取数据包详情
   */
  private getPackageWithId = async (id: string) => {
    const { result } = await OrderManageApi.getPackageDetail<IPackageItem, IPackageReq>({
      id,
    });
    if (result) {
      const packageJson: IDataPackageItem = result ? JSON.parse(result.jsonDesc) : undefined;
      this.setState({ packageCheckItem: result, checkPackageItems: packageJson });
    }
  };

  /**
   * 获取安全盒配置下来框
   */
  private vmConfigOptionsRender = () => {
    return this.state.VMs.map(v => {
      const { cpu_cores, memory_size, disk_space } = JSON.parse(v.vmConfigJson);
      return (
        <Option key={v.id} value={v.vmConfigCode}>
          {`cpu${cpu_cores}核内存${memory_size}磁盘${disk_space}G`}
        </Option>
      );
    });
  };

  /**
   * 介绍说明是那条下拉icon
   */
  private expandIconRender = () => {
    return <IconFont type="medataicon-xiala-copy-copy-copy" className="arrow" />;
  };

  /**
   * 切换显示已有或者新的安全盒
   */
  private changeSecurityBox = () => {
    this.setState({
      newSecurityBoxVisible: !this.state.newSecurityBoxVisible,
    });
  };
  /**
   * 渲染我的安全盒
   */
  private mySecurityBoxRender = () => {
    return (
      <Row>
        <Row style={{ marginTop: '16px' }}>
          <Col span={12}>
            <span>当前节点可用安全盒:</span>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span onClick={this.changeSecurityBox} style={{ color: '#00A1E6', cursor: 'pointer' }}>
              购买新的安全盒
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>{this.state.listViews}</Row>
        <Row style={{ marginBottom: '30px' }}>
          安全盒积分:
          <span style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 1)', marginLeft: '10px', marginRight: '10px' }}>
            {0}
          </span>
          (积分)
        </Row>
      </Row>
    );
  };

  /**
   *  渲染购买新的安全盒
   */
  private securityBoxNewRender = () => {
    const { getFieldDecorator } = this.props.form;
    const days = this.state.inputDays >= 0 ? this.state.inputDays : 0;
    const price = this.state.selectConfig ? this.state.selectConfig.vmCoinPrice : 0;
    const vmmoney = price ? price + '*' + days + ' (' + price * days + ')' : 0;
    return (
      <Row>
        <Col>
          <Row style={{ marginTop: '16px' }}>
            <Col span={12}>
              <span>配置选择:</span>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <a onClick={this.changeSecurityBox}>选择已有安全盒</a>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col span={16}>
              <FormItem label="类型">
                {// tslint:disable-next-line:jsx-no-multiline-js
                getFieldDecorator('select', {
                  rules: [{ required: true, message: '选择安全盒配置' }],
                })(
                  <Select
                    placeholder="选择安全盒配置"
                    style={{ width: '60%', height: '32px' }}
                    onChange={this.vmSelectChange}
                  >
                    {this.vmConfigOptionsRender()}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="使用时长(天)">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator(`days`, {
                  rules: [{ required: true, message: '时间不能为空' }],
                })(
                  <InputNumber
                    placeholder="请输入使用时长"
                    min={1}
                    style={{ width: '80%', height: '32px', borderRadius: '16px' }}
                    // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
                    onChange={value => {
                      this.inputDaysChange(value);
                    }}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row style={{ marginBottom: '30px' }}>
            安全盒积分:
            <span style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 1)', marginLeft: '10px', marginRight: '10px' }}>
              {vmmoney}
            </span>
            (积分)
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * 我的安全盒选择事件
   */
  private tableRowSelected = (item?: IOrderUserVmItem) => {
    // 行被点击
    if (item === undefined) {
      return;
    }
    this.setState({
      selectedUserVmItem: item,
    });
    const userPackageVmList = this.state.mySecurityBox;
    const listView: JSX.Element[] = [];
    userPackageVmList.map((value, index) => {
      let selectStatus = value === item ? true : false;
      if (this.state.selectedUserVmItem === item) {
        selectStatus = false;
        this.setState({
          selectedUserVmItem: undefined,
        });
      }

      const view = (
        <CheckVmListItemView
          key={index}
          selected={this.tableRowSelected}
          dataItem={value}
          selectStatus={selectStatus}
        />
      );
      listView.push(view);
    });
    this.setState({
      listViews: listView,
    });
  };

  /**
   * 我的安全盒列表渲染
   */
  private pushBoxViewRender = () => {
    const userPackageVmList = this.state.mySecurityBox;
    const listView: JSX.Element[] = [];
    const status: boolean[] = [];
    userPackageVmList.map((item, index) => {
      const view = (
        <CheckVmListItemView key={index} selected={this.tableRowSelected} dataItem={item} selectStatus={false} />
      );
      listView.push(view);
      status.push(false);
    });
    this.setState({
      listViews: listView,
    });
  };
}
const OrderCheck = Form.create()(OrderCheckForm);
export default OrderCheck;
