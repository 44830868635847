import axios from './RequestApi';
/**
 * @class 下载中心
 */
export default class HttpRequest {
  /**
   * 获取下载中心列表
   */
  public static getDownLoadDatas<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/userDownload/getUserDownloadPage', data);
  }

  /**
   * 下载数据
   */
  public static dowloadData<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/userDownload/getUserDownloadMessage', data);
  }

  /**
   * 上传数据
   */
  public static upLoadData<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/userDownload/userDownloadSave', data);
  }
  /**
   * 用户数据下载申请保存
   * /web/user/logined/userDownload/userDownloadSaveByVmInstanceId
   */

  public static upLoadDataVmInstanceId(data: IUpLoadVmReq) {
    return axios.post<string, IUpLoadVmReq>('/user/logined/userDownload/userDownloadSaveByVmInstanceId', data);
  }
}

export interface IUpLoadVmReq {
  code?: string;
  userRemark: string;
  vmInstanceId: number;
}
