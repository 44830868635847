import { WrappedFormUtils } from 'antd/lib/form/Form';
import { IVmJsonItem } from '../../../util/DataStruct';
import { ISafetyBoxInfo } from '../../OrderPromise/VMOrders/CodeType';
import { IPagination } from '../../ProxAiManage/CodeType';
import { IPkgItem } from '../../ScientificManage/SciPackageComponet/CodeType';

/**
 * 数据分析人员数据结构
 */
export interface IDataUserItem {
  blockchainUserId: string;
  code: string;
  created: string;
  credit: number;
  head: string;
  id: number;
  isMain: true;
  loginname: string;
  mainUserId: number;
  medataCoin: number;
  mobile: string;
  nickname: string;
  nodeCode: string;
  nodeName: string;
  password: string;
  researchProjectId: number;
  roleNames: string[];
  status: number;
  strStatus: string;
  token: string;
  type: number;
  updated: string;
  vmInstanceId: number;
  vmInstanceNum: string;
  networkSwitch: number;
  isStartVm: boolean;
}

export interface IDataUserReq {
  pageSize: number;
  pageNum: number;
  researchProjectId: number;
  username?: string;
  projectRoleId?: number;
}
export interface ISecBoxManageState {
  dataUserItems: IDataUserItem[];
  userPagination: IPagination;
  currentRadio: number;
  isLoading: boolean;
  hintModalVisible: boolean;
  clickUserItem?: IDataUserItem;
  boxMsgDrawerVisible: boolean;
  clickBoxMsg?: IVmJsonItem;
  safetyBoxVisible: boolean;
  vmBoxInfo?: ISafetyBoxInfo;
  startVm: boolean; // 是否正在开启安全盒
}

export const InitState = {
  dataUserItems: [],
  userPagination: { total: 0, pageSize: 10, current: 1 },
  currentRadio: 1,
  isLoading: false,
  hintModalVisible: false,
  boxMsgDrawerVisible: false,
  safetyBoxVisible: false,
  startVm: false,
};

export enum IDataUserItemAction {
  openBox,
  checkedBox,
  gotoBox,
}
export const DATA_USER_TAB_ROW_ATIONS = [
  { title: '查看安全盒', type: IDataUserItemAction.checkedBox },
  { title: '进入安全盒', type: IDataUserItemAction.gotoBox },
];
export interface ISciPkgState {
  pkgPagination: IPagination;
  pkgItems: IPkgItem[]; // 数据包list
  selectedPkgItem: number[];
  clickItem?: IPkgItem;
  isLoading: boolean; // 是否显示加载loading
  loadModalVisable: boolean;
  loadFailUser: string[];
}
export interface ISciPkgProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  projectId: number;
}
export const InitPkgState = {
  pkgPagination: { total: 0, pageSize: 7, current: 1 },
  pkgItems: [],
  selectedPkgItem: [],
  loadFailUser: [],
  isLoading: true,
  loadModalVisable: false,
};
