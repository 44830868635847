import { Button, Col, Divider, Drawer, Form, message, Modal, Pagination, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import Orders from '../../../api/OrderManageApi';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import TableCard from '../../../components/TableCard/TableCard';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import { IDataPackageItem } from '../../../util/DataStruct';
import NoDataImg from './../../../components/NoDataImg/NoDataImg';
import {
  IDataList,
  IItemList,
  IMyOrders,
  IMyOrdersprops,
  IMyOrdersstate,
  IOrderDetailInfo,
  IOrderParams,
  ISafetyBoxInfo,
  IVmConsoleInfo,
  IVmJsonItem,
} from './CodeType';
// import { IDataPackageItem, IVmJsonItem } from '../../../util/DataStruct';

const { Option } = Select;
const typeColumn = [
  { key: 1, label: '待预约' },
  { key: 2, label: '准备中' },
  { key: 3, label: '待使用' },
  { key: 4, label: '数据已挂载' },
  { key: 5, label: '暂停使用' },
  { key: 6, label: '销毁' },
];

/**
 * 数据使用
 */
class VMOrders extends React.PureComponent<IMyOrdersprops, {}> {
  public state = {
    visible: false,
    currentOrderCode: '',
    visibleDownload: false,
    safetyBoxVisible: false,
    isShowPwd: false,
    currentRecord: {},
    orders: [],
    orderParams: {
      pageNum: 1,
      pageSize: 10,
    },
    total: 0,
    isLoading: false,
    VNDetail: {},
  } as IMyOrdersstate;

  public componentDidMount() {
    this.getOrders();
  }
  public render() {
    const smbInfo = (
      <p>
        4.smb/ftp账号: {this.state.VNDetail.smbUsername}, 密码: {this.state.VNDetail.smbPassword}
      </p>
    );

    return (
      <div id="VMOrders">
        <IndexLayout>
          <div className="page-container">
            <RouteHeader
              title="数据使用"
              titles={['首页', '数据使用']}
              routes={['/', '/order']}
              hiddenBreadcrumb={true}
            />
            <div className="clear" />
            {this.headerFilterRender()}
            {this.tableCard()}
            <NoDataImg isLoading={this.state.isLoading} total={this.state.total} />
            <AuthComponent isShow={!!this.state.total}>
              <Pagination total={this.state.total} className="table-pagination" onChange={this.OrdersChange} />
            </AuthComponent>
          </div>
          <Drawer
            title="数据使用详情"
            placement="right"
            width={664}
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            {this.orderDetailDrawerRender()}
          </Drawer>
          <Drawer
            title="下载申请"
            placement="right"
            closable={true}
            width={564}
            visible={this.state.visibleDownload}
            onClose={this.downLoadClose}
          >
            {this.downLoadFormRender()}
          </Drawer>
          <Modal title="提示" visible={this.state.safetyBoxVisible} onOk={this.handleOk} onCancel={this.boxClose}>
            <div>
              <p>
                1.用户名：{this.state.VNDetail.username}, 密码:{this.state.VNDetail.password}
              </p>
              <p>
                2.FTP地址: {this.state.VNDetail.ftpAddress}, FTP端口:{this.state.VNDetail.ftpPort}
              </p>
              <p>3.数据挂载地址: {this.state.VNDetail.ftpDataDir}</p>
              {this.state.VNDetail.smbUsername ? smbInfo : ''}
            </div>
          </Modal>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 获取订单列表
   */
  private getOrders = async () => {
    this.setState({
      isLoading: false,
    });
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      try {
        const { result } = await Orders.getOrders<IDataList, IOrderParams>({ ...values, ...this.state.orderParams });
        this.setState({
          orders: result.records,
          total: result.total,
          isLoading: true,
        });
      } catch (err) {
        this.setState({
          isLoading: true,
        });
      }
    });
  };

  /**
   * 关闭安全盒详情弹框
   */
  private onClose = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * 打开下载申请抽屉
   */
  private handleDownLoad(orderCode: string) {
    this.setState({
      currentOrderCode: orderCode,
      visibleDownload: true,
    });
  }

  /**
   * 关闭申请
   */
  private downLoadClose = () => {
    this.setState({
      visibleDownload: false,
    });
  };

  /**
   * 确认下载申请
   */
  private downLoadApp = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        await Orders.createDownLoad<IItemList, { code: string; userRemark: string }>({
          code: this.state.currentOrderCode,
          userRemark: values.remark,
        });
        this.downLoadClose();
        message.success('下载申请成功');
      }
    });
  };

  /**
   * 修改立即跳转的弹框
   */
  private gotoPayFor = (orderId: string) => {
    this.props.history.push('/orderPay/' + orderId);
    // this.props.dispatch(routerRedux.push('/orderPay/' + orderId));
  };
  /**
   *  关闭抽屉详情弹框
   */
  private boxClose = () => {
    this.setState({
      safetyBoxVisible: false,
    });
  };
  /**
   * 显示密码
   */
  private changeShowPwd = () => {
    this.setState({
      isShowPwd: !this.state.isShowPwd,
    });
  };

  /**
   * 打开安全盒
   */
  private openBox = async (id: number) => {
    const { result } = await Orders.getVNstatus<string, { id: number }>({ id });
    if (result === 'running') {
      const res = await Orders.getVNDetail<ISafetyBoxInfo, number>(id);
      res.result.vmId = res.result.id;
      this.setState({
        VNDetail: res.result,
        safetyBoxVisible: true,
        currentOrderId: id,
      });
    } else {
      message.warning('安全盒尚未启动，请稍后再试');
    }
  };

  /**
   * 安全盒弹框的确认按钮
   */
  private handleOk = async () => {
    const { result } = await Orders.getVNOrderConsoleDetail<IVmConsoleInfo, number>(this.state.currentOrderId);

    window.open(
      result.novncUrl +
        '?CSRFPreventionToken=' +
        result.CSRFPreventionToken +
        '&node=' +
        result.node +
        '&PVEAuthCookie=' +
        result.PVEAuthCookie +
        '&vmid=' +
        result.vmid
    );
    this.setState({ safetyBoxVisible: false });
  };

  /**
   * 订单状态option render
   */
  private orderStatusOptionRender = () => {
    return typeColumn.map(item => (
      <Option key={item.key} value={item.key}>
        {item.label}
      </Option>
    ));
  };

  /**
   * 头部过筛选框渲染
   */
  private headerFilterRender() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form layout="inline" className="queryform">
        <Row className="queryform-row">
          <Col className="queryform-col">
            <Form.Item label="预约单状态:">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('status')(
                <Select className="queryform-select" placeholder="请选择" allowClear={true}>
                  {this.orderStatusOptionRender()}
                </Select>
              )}
            </Form.Item>
            <Button className="normal-search-button" onClick={this.getOrders}>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  /**
   * order列表分页
   */
  private OrdersChange = (pageNum: number) => {
    this.setState({ orderParams: { ...this.state.orderParams, pageNum } }, () => this.getOrders());
  };
  /**
   * 下载申请
   */
  private downLoadFormRender() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form layout="inline" className="DataSearchForm" style={{ width: '100%' }}>
        <b>数据使用编号：</b>
        {this.state.currentOrderCode}
        <Form.Item style={{ width: '100%', paddingBottom: '30px', borderBottom: '1px #E8E8E8 solid' }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator('remark', {
            rules: [
              {
                required: true,
                message: '点击输入下载说明',
              },
            ],
          })(<TextArea rows={8} placeholder="请输入下载申请说明" style={{ width: '516px', marginTop: '20px' }} />)}
        </Form.Item>
        <Row className="downloadAppFooter">
          <Col style={{ float: 'right', marginTop: '15px' }}>
            <Button type="primary" onClick={this.downLoadApp}>
              提交
            </Button>
            <Button style={{ marginLeft: '15px' }} onClick={this.downLoadClose}>
              返回
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }

  /**
   * 查看订单详情
   */
  private getOrderDetail = async (record: IMyOrders) => {
    const code = record.orderCode;
    const { result } = await Orders.getOrderDetail<IOrderDetailInfo, { code: string }>({ code });
    this.setState({
      orderDetail: result,
      visible: true,
      currentRecord: record,
      currentOrderId: record.orderVmId,
    });
  };
  /**
   *
   */
  private userInfoView = () => {
    // const { userInfo } = this.props.myOrders;
    if (!this.state.VNDetail || !this.state.VNDetail.username) {
      return null;
    }

    const userRowHidden = this.state.VNDetail.username ? false : true;
    const pwd =
      this.state.isShowPwd === true
        ? this.state.VNDetail.password
        : new Array(this.state.VNDetail.password.length).fill('*');

    return (
      <Row
        className="vmDetails"
        style={{ paddingLeft: '58px', paddingTop: '8px', marginBottom: '100px' }}
        hidden={userRowHidden}
      >
        <label style={{ marginRight: '25px' }}>用户名：{this.state.VNDetail.username}</label>
        <label>
          初始密码：
          {pwd}
        </label>
        <IconFont
          className="isshowpwd"
          onClick={this.changeShowPwd}
          type={this.state.isShowPwd === true ? 'medataicon-kejian' : 'medataicon-bukejian'}
        />
      </Row>
    );
  };

  /**
   *  订单详情抽屉渲染
   */
  private orderDetailDrawerRender = () => {
    if (this.state.orderDetail) {
      const packageJson: IDataPackageItem = JSON.parse(this.state.orderDetail.orderData.packageInfo.jsonDesc || '');
      const vmJson: IVmJsonItem = JSON.parse(this.state.orderDetail.orderVm.setupJson || '');
      return (
        <Row>
          <TradeDetailDrawer
            showOrderMessage={true}
            showPackageView={true}
            showSecurityBox={true}
            dataPackage={packageJson}
            vmJson={vmJson}
            orderDetailInfo={this.state.orderDetail}
            onClose={this.onClose}
            statues={this.state.currentRecord ? this.state.currentRecord.status : 0}
            openBox={() => this.openBox(this.state.orderDetail.orderVm.id)}
          />
          <Row>
            {this.userInfoView()}
            <Row className="drawerFooter" />
          </Row>
        </Row>
      );
    }
  };

  /**
   * 订单table按钮 渲染
   */
  private btnGroupRender = (record: IMyOrders) => {
    // 1 待支付 2 准备中 3 待使用  4 已挂载 5 已暂停 6 已销毁

    let isDownLoad = false; // 下载
    let isToBox = false; // 安全盒可用

    if (record.status >= 3 && record.status <= 5) {
      isDownLoad = true;
    }
    if (record.status >= 3 && record.status <= 4) {
      isToBox = true;
    }
    if (record.status === 6) {
      isDownLoad = false;
      isToBox = false;
    }
    // console.log('isDownLoad' + isDownLoad + 'allDisable' + allDisable + 'record' + record.status);
    const orderDetailBtn = (
      <a style={{ flex: 1 }} className="custom-card-button">
        <Button
          // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
          onClick={() => {
            this.getOrderDetail(record);
          }}
          title="查看详情"
          className="center no-box-shadow custom-card-button-text"
        >
          <IconFont type={'medataicon-xiangqing'} style={{ fontSize: '1.5em', marginRight: '0.3em', marginLeft: 0 }} />
          查看详情
        </Button>
      </a>
    );
    const downLoadBtn = (
      <a style={{ flex: 1 }} className={'custom-card-button'}>
        <Button
          // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
          onClick={() => {
            this.handleDownLoad(record.orderCode);
          }}
          title="下载申请"
          disabled={!isDownLoad} // 不许下载,全部不能用 一个有效就不行
          className="center no-box-shadow custom-card-button-text "
        >
          <IconFont type={'medataicon-xiazai1'} style={{ fontSize: '1.5em', marginRight: '0.3em', marginLeft: 0 }} />
          下载申请
        </Button>
      </a>
    );
    const toSafetyBox = (
      <a style={{ flex: 1 }} className={'custom-card-button'}>
        <Button
          // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
          onClick={() => {
            this.openBox(record.orderVmId);
          }}
          title="打开安全盒"
          disabled={!isToBox}
          className="center no-box-shadow custom-card-button-text "
        >
          <IconFont
            type={'medataicon-huabanfuben'}
            style={{ fontSize: '1.5em', marginRight: '0.3em', marginLeft: 0 }}
          />
          打开安全盒
        </Button>
      </a>
    );

    const payforBtn = (
      <a style={{ width: '363px' }} className="custom-card-button">
        <AuthComponent permission="USER_ORDER_WRITE">
          <Button
            // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
            onClick={() => {
              this.gotoPayFor(record.orderCode);
            }}
            className="center no-box-shadow custom-card-button-text "
          >
            立即预约
          </Button>
        </AuthComponent>
      </a>
    );
    if (record.status === 1) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '64px' }}>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>{orderDetailBtn}</div>

          <Divider type="vertical" className="btnline" />

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}> {payforBtn}</div>
        </div>
      );
    }

    if (record.status === 3) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', height: '64px' }}>
          {orderDetailBtn}
          <Divider type="vertical" className="btnline" />
          {downLoadBtn}
          <Divider type="vertical" className="btnline" />

          {toSafetyBox}
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '64px' }}>
        {orderDetailBtn}
        <Divider type="vertical" className="btnline" />
        {downLoadBtn}
        <Divider type="vertical" className="btnline" />

        {toSafetyBox}
      </div>
    );
  };

  /**
   * 订单table渲染
   */
  private tableCard = () => {
    if (this.state.orders) {
      // tslint:disable-next-line:no-any
      const tableCardGroup = this.state.orders.map((v: any, index: number) => {
        return <TableCard key={index} typeColumn={typeColumn} btnGroup={this.btnGroupRender(v)} data={v} />;
      });
      return tableCardGroup;
    }
  };
}
const DataSearchList = Form.create()(VMOrders);
export default DataSearchList;
