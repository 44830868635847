import { Model } from 'dva';
import { routerRedux } from 'dva/router';
import Storage from '../util/Storage';
export default {
  namespace: 'globals',
  state: {
    pathname: '',
    spinVisible: false,
    openSubId: [],
  },
  effects: {
    *changeSubMenuConfig({ payload, callback }, { put }) {
      yield put({ type: 'openChange', payload });
      if (callback) {
        callback(payload);
      }
    },
  },
  reducers: {
    openChange(state, action) {
      return {
        ...state,
        openSubId: action.payload,
      };
    },
  },
  subscriptions: {
    // 每次路有变化都会触发
    routerChange({ history, dispatch }) {
      history.listen(({ pathname }) => {
        dispatch({ type: 'globals/saveCurrentPath', payload: { pathname } });
        if (pathname !== '/login' && !Storage.getStore('token')) {
          dispatch(routerRedux.replace('/login'));
        }
      });
    },
  },
} as Model;
