export interface IItemList {
  [index: string]: string | number | undefined;
  jsonDesc: string;
  packageJsonDesc: string;
}
export interface IDataList {
  records?: IItemList[];
  searchCount?: boolean;
  size?: number;
  total?: number;
  current?: number;
}
export interface IDataItem {
  [Index: string]: string | number | undefined;
  type: number;
  disease: string;
}
class DataSet {
  public static dataArrSet = (data: IDataList) => {
    const arr: IDataItem[] = [];
    if (data.records) {
      data.records.forEach(element => {
        const item = { ...JSON.parse(element.jsonDesc), id: element.id, serverName: element.serverName };
        item.statusStr = element.statusStr;
        item.coinPrice = element.coinPrice;
        item.canFreeToUse = element.canFreeToUse;
        item.userType = element.userType;
        arr.push(item);
      });
    }
    return arr;
  };
  public static dataInfoSet = (data: IItemList) => JSON.parse(data.jsonDesc);
  public static packageInfoSet = (data: IItemList) => JSON.parse(data.packageJsonDesc);
}
export default DataSet;
