import { Button, Divider, Modal, Radio, Select, Tooltip } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';

import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import FileTree, { ITreeData } from '../../../../components/FileTree/FilePathTree';
import { IconFont } from '../../../../config/IconConfig';
import { IDictions, IExcel, ILoadDataSaveReq } from '../../CodeType';
import FormItem, { IItemType } from '../FormItem/FormItem';
import AuthComponent from './../../../../components/AuthComponent/AuthComponent';
import Storage from './../../../../util/Storage';
import { guid } from './../../DataMSetting';
import {
  dataFormatList,
  geneticFormTests,
  genomeList,
  IFormItemState,
  imageFromTests,
  relevantAreaList,
  sampleRangeList,
  sampleTypeList,
  techPlatformList,
  typeList,
} from './CodeType';
import './LoadComonent.scss';

const { Option } = Select;
interface ILoadComonentProps {
  partDictions: Map<string, IDictions>; // 用来存储对于 部位,或者设备对应的字典
  deviceDictions: Map<string, IDictions>;
  treeData?: ITreeData[];
  excelData?: ITreeData[];
  visiable: boolean;
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  loadDataClick: (item: ILoadDataSaveReq) => void;
  cancelUpdataClick: () => void;
  showGuide?: () => void;
  submit?: (id: number) => void;
  isUsb: boolean;
  dataType: number; // 1共享数据,2原始数据
  bottomBtn?: JSX.Element;
  geneList?: IExcel[];
  imageList?: IExcel[];
}

class LoadComonent extends React.PureComponent<ILoadComonentProps, IFormItemState> {
  constructor(props: ILoadComonentProps) {
    super(props);
    this.state = {
      packageType: 1,
      steps: 0,
      isShare: 'SHARE',
      shareType: 1,
      dataTypeForExel: 1,
      isJD: true,
    };
  }

  public componentDidMount() {
    this.setState({
      isJD: Storage.getStore('usertype') === '5',
    });
  }
  public render() {
    const { filePath, steps } = this.state;
    const { dataType } = this.props;
    const btnTitle = dataType === 2 ? '导入' : dataType === 1 ? (steps === 0 ? '下一步' : '完成') : '下载';
    const footer = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          type="primary"
          className="action-btn"
          onClick={dataType !== 3 ? this.nextBtnClick : this.download}
          disabled={dataType === 3 || filePath ? false : true}
        >
          {btnTitle}
        </Button>
        <Button className="action-btn" onClick={this.cancelBtnClick}>
          {dataType === 3 ? '返回' : '取消'}
        </Button>
      </div>
    );
    const modalTitle =
      this.props.dataType === 2
        ? '原始数据导入'
        : this.props.dataType === 1
        ? steps === 0
          ? '共享数据导入'
          : '项目信息'
        : 'Excel模版下载';
    const headerView = (
      <div style={{ flex: 1, display: 'flex' }} className="tit">
        <div style={{ flex: 1 }} />
        <div style={{ flex: this.props.dataType === 3 ? '1 1 100%' : 1, textAlign: 'center' }}>{modalTitle}</div>
        {/*  tslint:disable-next-line:jsx-no-multiline-js */}
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.props.dataType !== 3 ? (
            <div className="yp">
              <AuthComponent isShow={!this.props.isUsb}>
                <div className="ti_b">硬盘未接入</div>
              </AuthComponent>
              <IconFont
                className={this.props.isUsb ? 'headerIcon' : ''}
                type="medataicon-yidongyingpan-"
                style={{ fontSize: '20px' }}
              />
              <AuthComponent isShow={!this.state.isJD && this.props.dataType !== 2}>
                <Divider type="vertical" style={{ marginLeft: '10px', marginRight: '10px' }} />
              </AuthComponent>
            </div>
          ) : (
            ''
          )}
          <AuthComponent isShow={!this.state.isJD && this.props.dataType !== 2}>
            <IconFont
              onClick={this.showGuide}
              type="medataicon-asmkticon0247"
              style={{ fontSize: '24px', color: '#00a1e6' }}
              className={this.props.dataType === 3 ? 'help' : ''}
            />
          </AuthComponent>
          {this.props.dataType === 3 ? <Divider type="vertical" className="excel-div" /> : ''}
        </div>
      </div>
    );
    return (
      <Modal
        visible={this.props.visiable}
        className="updata-modal-component"
        width={this.props.dataType === 2 ? 480 : steps === 0 ? 480 : 880}
        centered={true}
        cancelText="确认"
        okText="取消"
        title={headerView}
        closable={this.props.dataType === 3 ? true : false}
        onCancel={this.cancelBtnClick}
        footer={footer}
        maskClosable={false}
      >
        {this.showContentView()}
      </Modal>
    );
  }
  /**
   * 分享内容
   */
  public showContentView = () => {
    const { steps } = this.state;
    const { dataType } = this.props;

    if (dataType === 1) {
      // 共享数据
      if (steps === 0) {
        return this.waitSharedContentView();
      }
      return this.shareFormView();
    } else if (dataType === 2) {
      // 原始数据
      return this.originalDataShareRender();
    } else {
      return this.excDlRender();
    }
  };
  /**
   * 待共render
   */
  public waitSharedContentView = () => {
    const { shareType } = this.state;
    return (
      <div>
        <div style={{ display: 'flex={true}', marginTop: '20px', justifyContent: 'center' }}>
          <div style={{ display: 'flex', width: '400px' }}>
            <div style={{ color: 'rgba(0, 0, 0, 0.64)', fontWeight: 'bold', width: '75px' }} className="text-last">
              共享状态
            </div>
            <div style={{ marginLeft: '5px', marginRight: '10px' }}>:</div>
            <div>
              <Radio.Group onChange={this.radioGroupChange} value={shareType}>
                <Radio value={1}>立即共享</Radio>

                <Divider type="vertical" style={{ marginLeft: '8px', marginRight: '8px' }} />
                <Radio value={2} style={{ marginLeft: '16px' }}>
                  暂不共享
                </Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        <div style={{ marginTop: '30px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ color: 'rgba(0, 0, 0, 0.64)', fontWeight: 'bold' }} className="text-last">
              数据文件
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '9px' }}>:</div>

            {
              // tslint:disable-next-line:jsx-no-multiline-js
              <FileTree
                treeData={this.props.treeData ? this.props.treeData : []}
                onSelect={this.fileTreeOnSelect}
                interValue={this.state.filePath}
              />
            }
          </div>

          <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
            <div style={{ color: 'rgba(0, 0, 0, 0.64)', fontWeight: 'bold' }} className="text-last">
              Excel文件
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '9px' }}>:</div>

            {
              // tslint:disable-next-line:jsx-no-multiline-js
              <FileTree
                treeData={this.props.excelData ? this.props.excelData : []}
                onSelect={this.excelTreeOnSelect}
                interValue={this.state.excelPath}
              />
            }
          </div>
          {this.props.bottomBtn}
        </div>
      </div>
    );
  };
  /**
   * 判断是否有数据上传权限
   */

  /**
   * 分享 view
   */
  public shareFormView = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          {this.inputFromItem('项目名称', true, '输入项目名称', 'project_name', '', '提供一句话或短语，阐述项目内容')}
        </div>

        <div style={{ display: 'flex', flex: 1, marginTop: '16px' }}>
          <FormItem
            title={'项目说明'}
            itemType={IItemType.TextArea}
            required={true}
            message={'请输入项目说明'}
            fieldId={'project_overview'}
            initialValue={''}
            form={this.props.form}
            hintMessage={'提供一段说明文字，阐述项目的研究目标及相关信息'}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, marginTop: '16px', alignItems: 'center' }}>
          <div style={{ width: '114px', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.64)' }} className="project-item-title">
              数据类型
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>:</div>
          </div>
          <Form.Item style={{ paddingLeft: '16px' }}>
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('project_data_type', {
              rules: [{ required: true, message: '请输入数据类型' }],
              initialValue: 1,
            })(
              <Radio.Group onChange={this.packageGroupChange}>
                <Radio value={1} style={{ color: 'rgba(0,0,0,0.64)' }}>
                  基因
                </Radio>
                <Divider type="vertical" style={{ marginLeft: '8px' }} />
                <Radio value={2} style={{ marginLeft: '16px', color: 'rgba(0,0,0,0.64)' }}>
                  影像
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </div>
        {this.state.packageType === 1 ? this.geneticFromView() : this.imageFormView()}
      </div>
    );
  };
  /**
   * genertic
   */
  public geneticFromView = () => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }} className="genetic-form-view">
          <span
            style={{ height: '1px', width: '110px', backgroundColor: 'rgba(214,214,214,0.64)', marginLeft: '24px' }}
          />
          <span className="">
            <span className="top">
              <i className="top-arrow1" />
              <i className="top-arrow2" />
            </span>
          </span>
          <span style={{ height: '1px', flex: 1, backgroundColor: 'rgba(214,214,214,0.64)' }} />
        </div>
        <div style={{ display: 'flex', marginTop: '0px', flexFlow: 'row wrap' }} className="form-content">
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '相关领域',
            true,
            '请选择相关领域',
            'project_relevant_area',
            this.optionCreat(relevantAreaList),
            '填写该项目隶属哪个领域'
          )}
          {this.autoCompleteFromItem('项目类别', true, '请选择项目类别', 'project_type', '填写该项目的类别', typeList)}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '样品范围',
            true,
            '请选择样品范围',
            'project_sample_range',
            this.optionCreat(sampleRangeList),
            '填写项目样本的来源'
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '样本类型',
            true,
            '请选择样本类型',
            'project_sample_type',
            this.optionCreat(sampleTypeList),
            '填写项目样本的类型'
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '数据格式',
            true,
            '请选择数据格式',
            'project_data_format',
            '填写项目样本数据的存储格式',
            dataFormatList
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '技术平台',
            true,
            '请选择技术平台',
            'project_tech_platform',
            '填写项目样本数据生成的技术平台',
            techPlatformList
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '参考基因组',
            true,
            '请选择参考基因组',
            'project_genome',
            '填写项目研究所用的参考基因组版本号',
            genomeList
          )}
          <div className="form-item-container" />
          {this.inputFromItem('物种', true, '请输入物种', 'project_organism', '', '填写该项目研究对象的种属关系')}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.inputFromItem(
            '疾病类型',
            true,
            '请输入疾病类型',
            'project_disease_type',
            '',
            ' 填写项目研究的目标表型'
          )}
        </div>
      </div>
    );
  };
  /**
   * image
   */
  public imageFormView = () => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }} className="genetic-form-view">
          <span style={{ height: '1px', width: '110px', backgroundColor: 'rgba(214,214,214,0.64)' }} />
          <span className="">
            <b className="top-image">
              <i className="top-arrow1" />
              <i className="top-arrow2" />
            </b>
          </span>
          <span style={{ height: '1px', flex: 1, backgroundColor: 'rgba(214,214,214,0.64)' }} />
        </div>
        <div style={{ display: 'flex', marginTop: '0px', flexDirection: 'column' }}>
          {this.inputFromItem('疾病名称', true, '请输入疾病名称', 'project_disease_type', '', ' 描述疾病名称')}
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.inputFromItem(
            '标注方式',
            true,
            '请输入标注方式',
            'project_label_format',
            '',
            '标注文件的格式信息 如 .nii .xml .csv等'
          )}
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.inputFromItem(
            '数据格式',
            true,
            '请输入数据格式',
            'project_data_format',
            '',
            '数据文件的格式信息 如 .dicom .jpg .png'
          )}
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.inputFromItem(
            '检测手段',
            true,
            '请输入检测手段',
            'project_testing_method',
            '',
            '影像文件的检测方式 如 CT MRI 眼底镜 等'
          )}
        </div>
      </div>
    );
  };

  /**
   * 原始数据分享
   */

  public originalDataShareRender = () => {
    const { partDictions, deviceDictions } = this.props;
    const equips: JSX.Element[] = [];
    deviceDictions.forEach(item => {
      const option = (
        <Option key={item.id} value={item.value}>
          {item.name}
        </Option>
      );
      equips.push(option);
    });
    const bodys: JSX.Element[] = [];
    partDictions.forEach(item => {
      const option = (
        <Option key={item.id} value={item.value}>
          {item.name}
        </Option>
      );
      bodys.push(option);
    });

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {this.selectedFromItem('检查设备', true, '请选择检查设备', 'modality', equips)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {this.selectedFromItem('检查部位', true, '请选择检查部位', 'bodyPart', bodys)}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          <div style={{ color: 'rgba(0, 0, 0, 0.64)', fontWeight: 'bold' }} className="text-last">
            数据文件
          </div>
          <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '9px' }}>:</div>

          {
            // tslint:disable-next-line:jsx-no-multiline-js
            <FileTree
              treeData={this.props.treeData ? this.props.treeData : []}
              onSelect={this.fileTreeOnSelect}
              interValue={this.state.filePath}
            />
          }
        </div>
      </div>
    );
  };
  /**
   * excel模板下载
   */
  public excDlRender = () => {
    /* const { shareType } = this.state; */
    return (
      <div style={{ display: 'flex={true}', marginTop: '20px', justifyContent: 'center' }}>
        <div style={{ display: 'flex', width: '400px' }}>
          <div style={{ color: 'rgba(0, 0, 0, 0.64)', fontWeight: 'bold', width: '75px' }} className="text-last">
            数据类型
          </div>
          <div style={{ marginLeft: '5px', marginRight: '10px' }}>:</div>
          <div>
            <Radio.Group onChange={this.typeGroupChange} value={this.state.dataTypeForExel}>
              <Radio value={1}>基因</Radio>

              <Divider type="vertical" style={{ marginLeft: '-8px', marginRight: '8px' }} />
              <Radio value={2}>影像</Radio>
            </Radio.Group>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.selectedFromItem(
            this.state.dataTypeForExel === 1 ? '样本类型' : this.state.dataTypeForExel === 2 ? '疾病' : '',
            true,
            `${this.state.dataTypeForExel === 1 ? '请选择样本类型' : '请选择疾病'}`,
            'excelType',
            this.excelCreat(this.state.dataTypeForExel === 1 ? this.props.geneList || [] : this.props.imageList || []),
            '',
            `${this.state.dataTypeForExel === 1 ? '请选择样本类型' : '请选择疾病'}`
          )}
        </div>
      </div>
    );
  };
  /**
   * 目录改变
   */
  public fileTreeOnSelect = (filePath: string) => {
    //
    this.setState({
      filePath,
    });
  };
  /**
   * excel目录改变
   */
  public excelTreeOnSelect = (excelPath: string) => {
    //
    this.setState({
      excelPath,
    });
  };

  /**
   * 数据 Radio Change
   */
  public radioGroupChange = (e: RadioChangeEvent) => {
    const shareType = e.target.value;
    const isShare = shareType === 2 ? 'NOT_SHARE' : 'SHARE';
    this.setState({
      shareType: e.target.value,
      filePath: '',
      isShare,
    });
  };
  /**
   * typeGroupChange
   */

  public typeGroupChange = (e: RadioChangeEvent) => {
    if (e.target.value === 1) {
      this.props.form.resetFields();
      this.setState({ dataTypeForExel: 1 });
    } else {
      this.props.form.resetFields();
      this.setState({ dataTypeForExel: 2 });
    }
  };
  /**
   * packageGroupChange
   */

  public packageGroupChange = (e: RadioChangeEvent) => {
    this.setState({
      packageType: e.target.value,
    });
  };
  public download = () => {
    const { validateFields } = this.props.form;
    validateFields(['excelType'], (err, values) => {
      if (!err) {
        if (this.props.submit) {
          this.props.submit(values.excelType);
        }
      }
    });
  };
  public nextBtnClick = () => {
    // 下一步 点击
    const { steps, packageType, filePath, isShare, excelPath } = this.state;

    const { validateFields } = this.props.form;
    const { dataType } = this.props;
    const path = filePath ? filePath : '';
    if (dataType === 1) {
      // 1待共享数据 显示表格
      if (steps === 0) {
        this.setState({
          steps: steps + 1,
        });
      } else {
        // 进行数据验证,并开始返回结果

        const testArr = packageType === 1 ? geneticFormTests : imageFromTests;
        validateFields([...testArr], (err, values) => {
          if (!err) {
            //  表单验证通过  把结果打包成字符串 传出去
            const packJsonStr = String(JSON.stringify(values));
            const item = {
              uploadFilePath: path,
              isShare,
              jsonDescStr: packJsonStr,
              taskType: '1',
              taskId: guid(path),
              source: 'WEB',
              excelFilePath: excelPath,
            } as ILoadDataSaveReq;
            this.props.loadDataClick(item);
            this.cancelBtnClick();
          }
        });
      }
    } else {
      // 原始数据 导入

      validateFields(['bodyPart', 'modality'], (err, values) => {
        if (!err) {
          //  表单验证通过  把结果打包成字符串 传出去
          const item = {
            taskType: '2',
            uploadFilePath: path,
            source: 'WEB',
            bodyPart: values.bodyPart,
            modality: values.modality,
            taskId: guid(path),
            isShare,
          };
          this.props.loadDataClick(item);
          this.cancelBtnClick();
        }
      });
    }
  };
  /**
   * 取消按钮
   */
  public cancelBtnClick = () => {
    // 重置
    this.setState({
      steps: 0,
      packageType: 1,
      shareType: 1,
      filePath: undefined,
    });
    this.props.cancelUpdataClick();
  };
  /**
   *  选中框
   */
  public selectedFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    options?: JSX.Element[],
    hintMessage?: string,
    placeholder?: string
  ) => {
    return (
      <FormItem
        title={title}
        required={required}
        message={message}
        fieldId={ckId}
        itemType={IItemType.Select}
        options={options}
        form={this.props.form}
        hintMessage={hintMessage}
        placeholder={placeholder}
      />
    );
  };
  /**
   * 输入框
   */
  public inputFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    initialValue?: string,
    hintMessage?: string
  ) => {
    return (
      <FormItem
        title={title}
        itemType={IItemType.HintInput}
        required={required}
        message={message}
        fieldId={ckId}
        initialValue={initialValue}
        form={this.props.form}
        hintMessage={hintMessage}
      />
    );
  };

  /**
   *  选中框
   */
  public autoCompleteFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    hintMessage?: string,
    autoArr?: Array<{ value: string; tip?: string }>
  ) => (
    <FormItem
      title={title}
      required={required}
      message={message}
      fieldId={ckId}
      itemType={IItemType.autoComplete}
      form={this.props.form}
      hintMessage={hintMessage}
      autoArr={autoArr}
    />
  );

  /**
   * 选择是否共享数据状态
   */
  public selectShareState = (isShare: string) => {
    this.setState({ isShare });
  };

  /**
   * select选项生成
   */
  public optionCreat = (param: Array<{ value: string; tip?: string }>) => {
    return param.map(item => (
      <Option key={item.value} value={item.value}>
        {item.tip ? (
          <Tooltip
            placement="rightTop"
            overlayClassName="optTips"
            // tslint:disable-next-line:jsx-no-multiline-js
            title={
              <div className="optips">
                <h4>
                  <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6' }} />
                </h4>
                <Divider />
                {item.tip}
              </div>
            }
          >
            {item.value}
          </Tooltip>
        ) : (
          item.value
        )}
      </Option>
    ));
  };

  /**
   * Excel模版选项生成
   */
  public excelCreat = (param: Array<{ id: number; name: string }>) => {
    return param.map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  };

  /**
   * 显示 指南
   */

  private showGuide = () => {
    if (this.props.showGuide) {
      this.props.showGuide();
    }
  };
}

export default LoadComonent; // 创建form实例
