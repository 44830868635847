import { Button, Checkbox, Col, Drawer, Input, message, Row, Select } from 'antd';

import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import InputPassword from '../../../components/InputPassword/InputPassword';
import { IconFont } from '../../../config/IconConfig';
import Storage from '../../../util/Storage';
import FormItem, { IItemType } from '../../DataManager/Component/FormItem/FormItem';
import { IMemberAccount, IMemberNewReq, IMemberRole, IMemberRoleReq } from '../CodeType';
import './MemberDrawer.scss';

const Option = Select.Option;
export enum IDrawerType {
  NewAccount,
  EditAccount,
  NewRole,
  EditRole,
}

interface IMemberDrawerProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  visiable: boolean;
  title: string;
  type: IDrawerType;
  item?: IMemberAccount | IMemberRole;
  permission: Map<string, string>;
  roles?: IMemberRole[];
  // tslint:disable-next-line:no-any
  okClick: (type: IDrawerType, values: any) => void;
  onClose: () => void;
}
interface IMDrawerState {
  psdStatute: boolean;
  conPsdStute: boolean;
  showCreateRole: boolean;
}

const titles = ['+账号创建', '编辑账号', '+角色创建', '角色编辑'];

class MemberDrawer extends React.PureComponent<IMemberDrawerProps, IMDrawerState> {
  /* base64*/
  public static encodeBase64Content(content: string) {
    return new Buffer(content).toString('base64');
  }
  public state = {
    psdStatute: true,
    conPsdStute: true,
    showCreateRole: false,
    isJD: Storage.getStore('usertype') === '5',
  };

  public componentWillReceiveProps(nextProps: IMemberDrawerProps) {
    const { form } = this.props;
    if (nextProps.visiable !== this.props.visiable) {
      form.resetFields();
    }
  }

  public componentDidMount() {
    const { form } = this.props;
    form.resetFields();
  }
  public render() {
    const { type } = this.props;

    /* 抽屉下方按钮组 */
    const footerRender = () => (
      <div className="m-drawer-footer ">
        <Button type="primary" className="action-btn" onClick={this.confirmBtnClick}>
          确定
        </Button>
        <Button className="action-btn" onClick={this.closeModal} style={{ marginLeft: '34px' }}>
          取消
        </Button>
      </div>
    );
    return (
      <Drawer
        visible={this.props.visiable}
        className="member-drawer"
        width="564px"
        title={titles[type]}
        closable={false}
        onClose={this.closeModal}
      >
        {this.content()}
        {footerRender()}
      </Drawer>
    );
  }
  /* 内容 */
  public content = () => {
    const { type } = this.props;

    switch (type) {
      case IDrawerType.EditAccount:
        return this.editAccount();
      case IDrawerType.NewRole:
        return <div className="content-body "> {this.newRole()} </div>;
      case IDrawerType.EditRole:
        return this.editRole();
      default:
        return this.newAccount();
    }
  };
  /* 新建账户 */
  public newAccount = () => {
    const { form, roles } = this.props;
    const { showCreateRole } = this.state;
    /* 角色选择列表 */
    const roleOptionsRender = () =>
      roles
        ? roles.map(items => {
            return (
              <Option key={items.id} value={items.id}>
                {items.roleName}
              </Option>
            );
          })
        : undefined;

    return (
      <div className="content-body">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <FormItem title={'用户名'} required={true} message={'输入用户名'} fieldId={'loginName'} form={form} />
            </div>
            <div style={{ flex: 1, marginLeft: '20px' }}>
              <FormItem title={'登录账号'} required={true} message={'输入登录账号'} fieldId={'mobile'} form={form} />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
            <div style={{ flex: 1 }}>
              <InputPassword title={'密码'} form={form} errorMessage={'请输入密码'} fileID={'pwd'} />
            </div>
            <div style={{ flex: 1, marginLeft: '20px' }}>
              <InputPassword title={'确认密码'} form={form} errorMessage={'请输入确认密码'} fileID={'comfirmpwd'} />
            </div>
          </div>
          <AuthComponent isShow={!this.state.isJD}>
            <div style={{ marginTop: '14px' }}>
              {// tslint:disable-next-line:jsx-no-multiline-js
              showCreateRole ? (
                <div>
                  {this.newRole()}
                  <div
                    style={{ cursor: 'pointer', marginTop: '17px', color: '#00a1e6' }}
                    onClick={this.showSelectedRoles}
                  >
                    选择已有角色
                  </div>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '14px' }}>
                  <AuthComponent isShow={!this.state.isJD}>
                    <div style={{ flex: 1 }}>
                      <FormItem
                        title={'角色'}
                        required={true}
                        message={'请选择角色'}
                        fieldId={'roleId'}
                        itemType={IItemType.Select}
                        options={roleOptionsRender()}
                        form={form}
                        formItemStyle={{ width: '156px', paddingLeft: '10px' }}
                      />
                    </div>

                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                      <Button
                        type="primary"
                        onClick={this.createRoles}
                        style={{ marginLeft: '48px', marginRight: '64px', marginTop: '10px' }}
                      >
                        + 创建角色
                      </Button>
                    </div>
                  </AuthComponent>
                </div>
              )}
            </div>
          </AuthComponent>
        </div>
      </div>
    );
  };
  /* 编辑账户 */
  public editAccount = () => {
    const { form, item, roles } = this.props;
    const { showCreateRole } = this.state;
    /* 角色选择列表 */
    const roleOptionsRender = () =>
      roles
        ? roles.map(items => {
            //
            return (
              <Option key={items.id} value={items.id}>
                {items.roleName}
              </Option>
            );
          })
        : undefined;
    return (
      <div className="content-body">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>
              <FormItem
                title={'用户名'}
                required={true}
                message={'输入用户名'}
                fieldId={'editloginName'}
                form={form}
                initialValue={item ? (item as IMemberAccount).user.loginname : ''}
              />
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <div className="edit-account-mobile-title">登录账号</div>
              <div style={{ marginLeft: '5px' }}>:</div>
              <div style={{ marginLeft: '10px' }}> {item ? (item as IMemberAccount).user.mobile : ''}</div>
            </div>
          </div>

          <div style={{ marginTop: '14px' }}>
            {// tslint:disable-next-line:jsx-no-multiline-js
            showCreateRole ? (
              <div>
                {this.newRole()}
                <div
                  style={{ cursor: 'pointer', marginTop: '17px', color: '#00a1e6' }}
                  onClick={this.showSelectedRoles}
                >
                  选择已有角色
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '14px' }}>
                <AuthComponent isShow={!this.state.isJD}>
                  <div style={{ flex: 1 }}>
                    <FormItem
                      title={'角色'}
                      required={true}
                      message={'请选择角色'}
                      fieldId={'roleId'}
                      itemType={IItemType.Select}
                      options={roleOptionsRender()}
                      form={form}
                      formItemStyle={{ width: '156px', paddingLeft: '10px' }}
                      initialValue={(item as IMemberAccount).role.id}
                    />
                  </div>

                  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <Button
                      type="primary"
                      onClick={this.createRoles}
                      style={{ marginLeft: '48px', marginRight: '64px', marginTop: '10px' }}
                    >
                      + 创建角色
                    </Button>
                  </div>
                </AuthComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  /* 新建角色 */
  public newRole = () => {
    const { getFieldDecorator } = this.props.form;
    const checkboxs: JSX.Element[] = [];
    this.props.permission.forEach((v, k) => {
      const col: JSX.Element = (
        <Col key={k} span={8} style={{ marginBottom: '24px' }}>
          <Checkbox value={k}>{v}</Checkbox>
        </Col>
      );
      checkboxs.push(col);
    });
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <FormItem title={'名称'} required={true} message={'输入名称'} fieldId={'roleName'} form={this.props.form} />
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
          <div style={{ paddingBottom: '24px' }}>请勾选允许用户使用的功能 </div>
          <Form.Item style={{ flex: 1, paddingLeft: '16px', paddingRight: '24px' }}>
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('permissions', {
              rules: [{ required: false, message: '选择权限' }],
            })(
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>{checkboxs}</Row>
              </Checkbox.Group>
            )}
          </Form.Item>
        </div>
      </div>
    );
  };
  /* 编辑角色 */
  public editRole = () => {
    const { getFieldDecorator } = this.props.form;
    const { item } = this.props;
    const checkboxs: JSX.Element[] = [];
    this.props.permission.forEach((v, k) => {
      const col: JSX.Element = (
        <Col span={8} style={{ marginBottom: '24px' }}>
          <Checkbox value={k}>{v}</Checkbox>
        </Col>
      );
      checkboxs.push(col);
    });

    return (
      <div className="role-edit-container">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <FormItem
              title={'名称'}
              required={true}
              message={'输入名称'}
              fieldId={'roleName'}
              form={this.props.form}
              initialValue={item ? (item as IMemberRole).roleName : ''}
            />
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
          <div style={{ paddingBottom: '24px' }}>请勾选允许用户使用的功能 </div>
          <Form.Item style={{ flex: 1, paddingLeft: '16px', paddingRight: '24px' }}>
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('permissions', {
              rules: [{ required: false, message: '选择权限' }],
              initialValue: (item as IMemberRole).permissions,
            })(
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>{checkboxs}</Row>
              </Checkbox.Group>
            )}
          </Form.Item>
        </div>
      </div>
    );
  };
  /** 密码输入框 */
  public inputPassword = (title: string, fieldId: string, messages: string) => {
    const { getFieldDecorator } = this.props.form;
    const { psdStatute, conPsdStute } = this.state;
    const statue = fieldId === 'passward' ? psdStatute : conPsdStute;

    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }} className="input-container">
        <div className="form-item-title">{title}</div>
        <Form.Item style={{ flex: 1, paddingLeft: '16px', paddingRight: '24px' }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required: true, message: messages }],
          })(
            <Input
              placeholder="点击输入"
              type={statue ? 'password' : 'text'}
              className="passward-input"
              suffix={
                <div
                  onClick={
                    // tslint:disable-next-line:jsx-no-multiline-js
                    () => {
                      this.passwordClick(fieldId);
                    }
                  }
                >
                  {statue ? <IconFont type="medataicon-bukejian" /> : <IconFont type="medataicon-kejian" />}
                </div>
              }
            />
          )}
        </Form.Item>
      </div>
    );
  };
  /* 点击确定按钮 */
  public confirmBtnClick = () => {
    const { type, item } = this.props;
    const { validateFields } = this.props.form;
    const { showCreateRole } = this.state;
    const newAccountA = ['loginName', 'mobile', 'pwd', 'comfirmpwd', 'roleId']; // 检查角色是否选中
    const newAccountB = ['loginName', 'mobile', 'pwd', 'comfirmpwd', 'roleName', 'permissions']; // 检查角色内容
    if (type === IDrawerType.NewRole) {
      // 新建角色
      validateFields(['roleName', 'permissions'], (err, values) => {
        if (!err) {
          //
          this.props.okClick(type, values as IMemberRoleReq);
        }
      });
    } else if (type === IDrawerType.EditRole) {
      // 编辑角色
      validateFields(['roleName', 'permissions'], (err, values) => {
        if (!err) {
          const roleItem = {
            roleName: values.roleName,
            permissions: [...values.permissions],
            id: item ? (item as IMemberRole).id : undefined,
          } as IMemberRoleReq;
          this.props.okClick(type, roleItem);
        }
      });
    } else if (type === IDrawerType.NewAccount) {
      // 新建账号，表单判断和处理
      validateFields(showCreateRole ? newAccountB : newAccountA, (err, values) => {
        if (!err) {
          if (values.comfirmpwd !== values.pwd) {
            // 两次密码不同
            message.error('确认密码错误');
            return;
          } else {
            if (values.pwd.length < 6) {
              message.error('密码不能低于 6 位');
            } else {
              // 密码相同
              if (showCreateRole) {
                // 同时需要新建角色
                const roleItem = { roleName: values.roleName, permissions: [...values.permissions] } as IMemberRoleReq;
                const accountReq = {
                  loginName: values.loginName,
                  mobile: values.mobile,
                  pwd: MemberDrawer.encodeBase64Content(values.pwd),
                  roleItem,
                  status: 1,
                } as IMemberNewReq;
                this.props.okClick(type, accountReq);
              } else {
                const accountReq = {
                  loginName: values.loginName,
                  mobile: values.mobile,
                  pwd: MemberDrawer.encodeBase64Content(values.pwd),
                  roleId: values.roleId,
                  status: 1,
                } as IMemberNewReq;
                this.props.okClick(type, accountReq);
              }
            }
          }
        }
      });
    } else {
      // 编辑账号
      const editAccountA = ['editloginName', 'roleId']; // 检查角色是否选中
      const editAccountB = ['editloginName', 'roleName', 'permissions']; // 检查角色内容
      /* 表单判断和处理 */
      validateFields(showCreateRole ? editAccountB : editAccountA, (err, values) => {
        if (!err) {
          if (showCreateRole) {
            // 同时需要新建角色

            const roleItem = { roleName: values.roleName, permissions: [...values.permissions] } as IMemberRoleReq;
            const accountReq = {
              loginName: values.editloginName,
              mobile: (item as IMemberAccount).user.mobile,
              roleItem,
              status: 1,
            } as IMemberNewReq;
            this.props.okClick(type, accountReq);
          } else {
            const accountReq = {
              mobile: (item as IMemberAccount).user.mobile,
              loginName: values.editloginName,
              roleId: values.roleId,
              status: 1,
            } as IMemberNewReq;
            this.props.okClick(type, accountReq);
          }
        }
      });
    }
  };
  /* 创建角色 */
  public createRoles = () => {
    this.setState({
      showCreateRole: true,
    });
  };

  /* 是否创建角色 */
  public showSelectedRoles = () => {
    //
    this.setState({
      showCreateRole: false,
    });
  };
  /* 密码 icon 点击*/
  public passwordClick = (fieldId: string) => {
    // 密码 icon 点击事件
    // ...
    const { psdStatute, conPsdStute } = this.state;

    if (fieldId === 'passward') {
      this.setState({
        psdStatute: !psdStatute,
      });
    } else {
      this.setState({
        conPsdStute: !conPsdStute,
      });
    }
  };
  /* 关闭抽屉方法 */
  private closeModal = () => {
    this.props.onClose();
  };
}

export default MemberDrawer; // 创建form实例
