export interface INormalList<T> {
  // 以后列表都用这个
  pages: number;
  size: number;
  total: number;
  current: number;
  records?: T;
}

export interface IDowloadTradeDetail {
  id: number;
  userRemark: string;
  serverCode: string;
  orderVmCode: number;
}
export interface IParamForTrans {
  pageNum?: number;
  pageSize?: number;
  type: string;
  status?: number;
}
export interface IParentOrder {
  // 总预约单
  code: string;
  contractNumber: number;
  created: string;
  dataOrderId: number;
  days: number;
  deleted: string;
  id: number;
  coinPrice: number;
  availableDays: number;
  updated: string;
  vmOrderId: number;
}
export interface IPackageInfo {
  categoryName: string;
  demoUrl: string;
  id: number;
  jsonDesc: string;
  serverName: string;
  status: number;

  title: string;
  usedNum: number;
}
export interface IOrderDetailInfo extends IParentOrder {
  // 预约单 详情
  orderVm: IOrderVm; // 安全盒预约单
  orderData: IOrderData; // 数据预约单
}

export interface IOrderData {
  code: string;
  created: string;
  id: number;
  packageInfo: IPackageInfo;
  parentOrder?: IParentOrder;
  status: number;
  strStatus: string;
  updated: string;
}
export interface IOrderVm {
  // 安全盒 预约单
  id: number;
  code: string;
  node: string;
  userId: number;
  serverId: number;
  created: string;
  updated: string;
  setupJson: string;
  vmid: number;
  status: number;
  statusStr: string;
  parentOrder?: IParentOrder;
}

export interface IPagination {
  total: number;
  pages?: number;
  size: number;
  current: number;
}
export interface ITransactionItem {
  consumerId?: string;
  refCode?: string;
  created: string;
  subType: number; // 如1为充值，2为数据被使用，3为数据检索，4为安全盒购买，5为续费
  intValue: number; // 积分 .积分
  status?: string;
  type?: number;
  nodeName: string;
  id: number;
}
export interface ITransactionState {
  drawerVisible: boolean;
  drawerTitle: string;
  tabType: string; // 记录选择的 tab
  drawerType: number; // 1 充值 2数据包详情 3数据预约单详情 4'虚拟预约单详情', 5'虚拟预约单续费', 6安全盒预约单详情, 7 预约单数据预约单详情 8下载详情
  tradeVmDetail: IOrderDetailInfo;
  dowloadDetail: IDowloadTradeDetail;
  transactions: INormalList<ITransactionItem[]>;
  downloads: INormalList<ITransactionItem[]>;
  orderData: IOrderData; // 数据预约单
  orderVmDetail?: IOrderVm;
  orderDataDetail?: IOrderData;
  // 分页
  pagination: IPagination;
  pageSize: number; // 页面大小
  isloadingOrder: boolean;
  isloadinglDowload: boolean;
  totalTrade: number; // 交易数据条数
  totalOrder: number; // 预约单条数
  totalDowload: number; // 下载条数
  selectedDowloadItem?: ITransactionItem; // 点击的下载 item
  selectedOrderItem?: ITransactionItem;
}

export interface IVmJsonItem {
  // 安全盒 json
  cpu_type: string;
  cpu_cores: number;
  cpu_frequency: string;
  memory_size: number; // MB 2400 = 2.4G
  memory_frequency: number; // MHZ 2400 = 2.4G
  gpu_core_type: string;
  gpu_core_frequency: string;
  gpu_memory_type: string;
  disk_space: number; // MB
  os_system: string;
  os_system_version: string;
}
export interface ITransactionItem {
  consumerId?: string;
  refCode?: string;
  created: string;
  subType: number; // 如1为充值，2为数据被使用，3为数据检索，4为安全盒购买，5为续费
  intValue: number; // 积分 .积分
  status?: string;
  type?: number;
  nodeName: string;
  id: number;
}

export const drawerTitles = [
  '充值详情',
  '数据包详情',
  '数据预约详情',
  '安全盒预约详情',
  '安全盒预约续费',
  '安全盒预约详情',
  '数据预约详情',
  '下载详情',
];

export const OrderStatusType = [
  { key: 'WAIT_PAY', value: '待预约' },
  { key: 'CAN_USED', value: '可使用' },
  { key: 'CAN_NOT_USED', value: '不能使用' },
  { key: 'IN_PREPARATION', value: '准备中' },
  { key: 'WAIT_USE', value: '待使用' },
  { key: 'DATA_MOUNTED', value: '数据已挂载' },
  { key: 'STOP_USE', value: '暂停使用' },
  { key: 'DESTORYED', value: '已销毁' },
  { key: 'INIT_FAILED', value: '安全盒初始化失败' },
  { key: 'TO_BE_AUDITED', value: '待审批' },
  { key: 'AUDIT_SUCCED', value: '已通过' },
  { key: 'AUDIT_FAILED', value: '未通过' },
  { key: 'CANCLED', value: '已取消' },
];
export const iTransactionItem = {
  consumerId: '',
  refCode: '',
  created: '',
  subType: 0, // 如1为充值，2为数据被使用，3为数据检索，4为安全盒购买，5为续费
  intValue: 0, // 积分 .积分
  status: '',
  type: 0,
  nodeName: '',
  id: 0,
};
export const orderVm = {
  id: 0,
  code: '',
  node: '',
  userId: 0,
  serverId: 0,
  created: '',
  updated: '',
  setupJson: '',
  vmid: 0,
  status: 0,
  statusStr: '',
};
export const orderData = {
  code: '',
  created: '',
  id: 0,
  packageInfo: {
    categoryName: '',
    demoUrl: '',
    id: 0,
    jsonDesc: '',
    serverName: '',
    status: 0,
    title: '',
    usedNum: 0,
  },
  parentOrder: {
    code: '',
    contractNumber: 0,
    created: '',
    dataOrderId: 0,
    days: 0,
    deleted: '',
    id: 0,
    coinPrice: 0,
    availableDays: 0,
    updated: '',
    vmOrderId: 0,
  },
  status: 0,
  strStatus: '',
  updated: '',
};
export const stateInit = {
  drawerVisible: false,
  drawerTitle: '',
  drawerType: 1,
  tabType: '2',
  // 分页
  pageSize: 10,
  totalTrade: 0,
  isloadingOrder: false,
  isloadinglDowload: false,
  totalOrder: 0,
  totalDowload: 0,
  pagination: { total: 0, size: 10, current: 1, pages: 1 },
  selectedDowloadItem: iTransactionItem, // 点击的下载 item
  selectedOrderItem: iTransactionItem,
  tradeVmDetail: {
    orderVm,
    orderData,
    code: '',
    contractNumber: 0,
    created: '',
    dataOrderId: 0,
    days: 0,
    deleted: '',
    id: 0,
    coinPrice: 0,
    availableDays: 0,
    updated: '',
    vmOrderId: 0,
  },
  dowloadDetail: {
    id: 0,
    userRemark: '',
    serverCode: '',
    orderVmCode: 0,
  },
  transactions: {
    pages: 0,
    size: 0,
    total: 0,
    current: 0,
    records: [
      {
        consumerId: '',
        refCode: '',
        created: '',
        subType: 0,
        intValue: 0,
        status: '',
        type: 0,
        nodeName: '',
        id: 0,
      },
    ],
  },
  downloads: {
    pages: 0,
    size: 0,
    total: 0,
    current: 0,
    records: [
      {
        consumerId: '',
        refCode: '',
        created: '',
        subType: 0,
        intValue: 0,
        status: '',
        type: 0,
        nodeName: '',
        id: 0,
      },
    ],
  },
  orderData,
};
