import { Checkbox, Form, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { IGrant } from '../../CodeType';
import './GrantSet.scss';
interface IPropsForGrant extends FormComponentProps {
  grants: IGrant[];
  onCancel: () => void;
  onOk: (arr: string[]) => void;
  visible: boolean;
}
interface IStateForGrant {
  loading: boolean;
}

/* 修改权限弹出框组件 */

class GrantSetForm extends React.PureComponent<IPropsForGrant, IStateForGrant> {
  /* 确定提交事件 */
  public handleSubmit = (e: React.SyntheticEvent) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.props.onOk(values.mobileList); // 调用父组件给的onOk方法并传入Form的参数。
    });
  };
  /* Cancel关闭modal框事件 */
  public onCancel = () => {
    this.props.form.resetFields(); // 重置Form表单的内容
    this.props.onCancel(); // 调用父组件给的方法
  };
  public arrInit = () => {
    /* 选中项目获取 */
    const optionsDefault: string[] = [];
    if (this.props.grants) {
      this.props.grants.forEach(item => {
        if (item.grant) {
          optionsDefault.push(item.user.mobile);
        }
      });
    }
    return optionsDefault;
  };
  public render = () => {
    const { getFieldDecorator } = this.props.form;
    /* checkbox列表获取 */
    // tslint:disable-next-line:no-any
    let options: any = [];
    if (this.props.grants) {
      options = this.props.grants.map(item => {
        return {
          label: (
            <span className="checkboxname" title={item.user.loginname}>
              {item.user.loginname}
            </span>
          ),
          value: item.user.mobile,
        };
      });
    }
    return (
      <Modal
        onOk={this.handleSubmit}
        onCancel={this.onCancel}
        visible={this.props.visible}
        okText="确定"
        cancelText="取消"
        title={<h4 className="modal-title">权限分配</h4>}
        className="GrantSet"
      >
        <Form>
          <Form.Item className="textAreaForm" label={'请勾选使用该数据的账号'}>
            {/* tslint:disable-next-line:jsx-no-multiline-js */}
            {getFieldDecorator('mobileList', { initialValue: this.arrInit() })(
              <Checkbox.Group className="checkboxGroup" options={options} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  };
}
export const GrantSet = Form.create()(GrantSetForm);
