import React from 'react';
import Storage from '../../util/Storage';
export interface IProps {
  children: React.ReactNode;
  permission?: string;
  isShow?: boolean;
}

/**
 * 控制子组件是否显示,permission和isShow不能同时传入
 * @param { children } 子组件
 * @param { permission } 当前组件代表的权限key 如果传入为!key的形式则为没有该key是显示类
 * @param { isShow } 是否显示子组件
 *
 * <AuthComponent isShow={true}>
 *  这里可以放任何组件, isShow 为true 就是显示，类似于 vue 中的 v-if 这个指令
 * </AuthComponent>
 *
 * 下版本修改此组件
 */
class AuthComponent extends React.Component<IProps, {}> {
  public render() {
    return this.controlReader();
  }

  public componentDidMount() {
    if (this.props.permission && typeof this.props.isShow === 'boolean') {
      throw new Error('permission和isShow不能同时传入！');
    }
  }

  private controlReader = () => {
    const permissions: string[] = JSON.parse(Storage.getStore('permissions') || '[]');
    if (this.props.permission) {
      if (permissions.includes(this.props.permission)) {
        return this.props.children;
      }
      const per = this.props.permission.split('!');
      if (per[1] && !permissions.includes(per[1])) {
        return this.props.children;
      }
    }
    if (this.props.isShow) {
      return this.props.children;
    }
    return '';
  };
}
export default AuthComponent;
