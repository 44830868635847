import { Carousel, Col, Divider, Row } from 'antd';
import React from 'react';
import IndexLayout from '../../../layout/IndexLayout';
// import './Introduction.scss';

class Introduction extends React.PureComponent<{}, { CarouselWidth: number }> {
  public state = {
    CarouselWidth: 0,
  };
  constructor(props: Readonly<{}>) {
    super(props);
  }
  public componentDidMount() {
    if (document.getElementById('colForCarousel')) {
      const colForCarousel = document.getElementById('colForCarousel');
      this.setState({
        CarouselWidth: colForCarousel ? colForCarousel.offsetWidth : 0,
      });
    }
  }

  public render() {
    return (
      <IndexLayout type="leftmenu">
        <div id="Introduction">
          <Row style={{ width: '100%', paddingLeft: 24, paddingRight: 24 }} className="IntroductionMain">
            <Col id="colForCarousel" span={24}>
              <div className="Introduction" style={{ height: '100%', width: this.state.CarouselWidth }}>
                <Carousel className="Carousel" effect="fade">
                  <div className="sheet1 sheet">
                    <img src={require('./../../../resource/introImg1.svg')} alt="" className="blockCenter" />
                    <dl className="description">
                      <dt>
                        <h3>1.1 我的数据介绍:</h3>
                      </dt>
                      <dd>我的数据主要用来展示用户拥有的数据，点击顶部导航”我的数据“可以查看用户所拥有的数据；</dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>1.2 我的数据列表:</h3>
                      </dt>
                      <dd>用户拥有的所有数据包，可以点击并选择“数据类型”“状态”进行数据包的筛选‘；</dd>

                      <dd>数据格式：数据包内存放数据的格式；</dd>
                      <dd>等级：按照数据质量进行等级划分，满分为5级；</dd>
                      <dd>病种：数据所属疾病种类；</dd>
                      <dd>数量：数据包内所包含病例的数量；</dd>
                      <dd>
                        查看详情：点击“查看详情”可以看到数据包的详细描述；点击“下载示例”：用户可以查看所选数据包内数据示例；
                      </dd>
                    </dl>
                  </div>
                  <div className="sheet2 sheet">
                    <img src={require('./../../../resource/introImg2.svg')} alt="" className="blockCenter" />
                    <dl className="description">
                      <dt>
                        <h3>2.1 数据与安全盒介绍:</h3>
                      </dt>
                      <dd>
                        数据：用户通过Medata平台可以访问并使用到的数据，目前Medata平台的数据均以“数据包”的形式展示；
                      </dd>
                      <dd>安全盒：Medata平台为用户提供的使用数据的安全盒平台；</dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>2.2 数据检索:</h3>
                      </dt>
                      <dd>点击顶部导航“数据检索“可以选择并预约所需要的数据；</dd>
                      <dd>项目名称：数据包所属项目的名称；</dd>
                      <dd>数据格式：数据包内存放数据的格式；</dd>
                      <dd>等级：按照数据质量进行等级划分，满分5级；</dd>
                      <dd>病种：数据所属疾病种类；</dd>
                      <dd>数量：数据宝内所包含病例的数量；</dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>2.3 数据与安全盒数据使用提交:</h3>
                      </dt>
                      <dd>
                        在“数据检索”页面，选择所需要的数据包，点击”预约使用“进入“数据数据使用和安全盒数据使用”核对页面；
                      </dd>
                      <dd>数据数据使用：展示所要预约的“数据包”的信息，点击“查看详情”可以查看数据包的详细信息；</dd>
                      <dd>
                        安全盒数据使用：为用户在数据包所在位置提供使用环境，用户可以根据自己所需运行的条件（也就是安全盒操作平台），用户可以根据自己所需的配置需求选择相应的安全盒类型以及所需要使用的时长；
                      </dd>
                      <dd>
                        在数据包所在位置，首次下单的时候，用户需要购买新的安全盒，如用户再次预约此位置的数据包，系统会自动判断用户的账号是否有可用的安全盒，如有，则无需重新购买安全盒；
                      </dd>
                    </dl>
                  </div>
                  <div className="sheet3 sheet">
                    <img src={require('./../../../resource/introImg3.svg')} alt="" className="blockCenter" />
                    <dl className="description">
                      <dt>
                        <h3>3.1 数据使用介绍:</h3>
                      </dt>
                      <dd>点击顶部导航“数据使用”，进入“数据使用”页面，此页面展示用户所提交的所有数据使用信息。</dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>3.2 数据使用列表说明:</h3>
                      </dt>
                      <dd>数据使用编号：用户所提交数据使用对应的唯一编号；</dd>
                      <dd>数据使用日期：用户提交数据使用的时间;</dd>

                      <dd>机构名称：数据所在位置；</dd>
                      <dd>状态：数据使用对应的安全盒的使用状态；</dd>
                      <dd>状态为“待审核”“准备中”：用户下单之后需要系统为用户提供准备服务；</dd>
                      <dd>状态为“待使用”：为用户提供的安全盒环境已准备完成；</dd>
                      <dd>状态为“数据已挂载”：用户所预约的数据已挂载至安全盒，用户可以开始在安全盒内使用数据；</dd>
                      <dd>状态为“暂停使用”：安全盒关闭，用户也可随时重启安全盒继续使用；</dd>
                      <dd>状态为“销毁”：安全盒到期，用户所使用的安全盒已自动销毁；</dd>
                      <dd>可用时间(天)：用户可使用安全盒的剩余时间；</dd>
                      <dd>数据使用详情：点击可以查看数据使用的数据和安全盒详细信息；</dd>
                      <dd>
                        下载申请：点击“下载申请”按钮，选择需要提出下载申请的数据使用，输入下载说明，点击“提交”，完成下载申请；申请进度可以在“下载中心”页面跟踪；
                      </dd>
                      <dd>进入安全盒：用户使用安全盒的入口；</dd>
                    </dl>
                  </div>
                  <div className="sheet4 sheet">
                    <img src={require('./../../../resource/introImg4.svg')} alt="" className="blockCenter" />
                    <dl className="description">
                      <dt>
                        <h3>4.1 安全盒使用介绍:</h3>
                      </dt>
                      <dd>
                        点击顶部导航“数据使用”或者“安全盒”，可进入相应安全盒，根据所提示的安全盒账号和密码进入安全盒；
                      </dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>4.2 上传算法操作；</h3>
                      </dt>
                      <dd>
                        用户可以通过FTP的方式将自己的算法或者文件上传至安全盒，用户点击“进入安全盒”，根据提示的FTP地址进行相应上传操作；
                      </dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>4.3 安装算法（有网络）；</h3>
                      </dt>
                      <dd>
                        用户数据使用在“待使用”状态下，用户将上传的算法在smb目录的ftp中找到，存放至安全盒内并通过网络进行依赖包的安装调试工作。
                      </dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>4.4 挂载数据（无网络）；</h3>
                      </dt>
                      <dd>
                        用户数据使用在“数据已挂载”状态下，用户可以在smb目录的medata中找到已预约的数据包，在此状态中为了确保数据安全，用户无法访问网络。
                      </dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>4.5 使用数据；</h3>
                      </dt>
                      <dd>
                        用户需要将smb目录的medata中的数据保存到安全盒中，然后就可以使用上传的模型和算法对数据进行训练等操作。
                      </dd>
                    </dl>
                  </div>
                  <div className="sheet5 sheet">
                    <img src={require('./../../../resource/introImg5.svg')} alt="" className="blockCenter" />
                    <dl className="description">
                      <dt>
                        <h3>5.1 下载中心介绍:</h3>
                      </dt>
                      <dd>
                        用户点击顶部导航“下载中心”可以进入下载管理页面，用户可以对安全盒内的计算结果提出”下载申请”；“下载列表”为用户提供下载申请进入跟踪，以及下载的入口；
                      </dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>5.2 下载申请:</h3>
                      </dt>
                      <dd>
                        点击“下载申请”按钮，选择需要提出下载申请的数据使用，输入下载说明，点击“提交”，完成下载申请；
                      </dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>5.3 下载列表:</h3>
                      </dt>
                      <dd>下载编号：用户提交下载申请的唯一编号；</dd>
                      <dd>数据使用编号：用户提出下载申请对应的数据使用编号；</dd>
                      <dd>申请日期：用户提交下载申请的日期；</dd>

                      <dd>机构编号：用户提交下载申请对应的数据所在位置；</dd>
                      <dd>申请状态：用户提交下载申请的进度；</dd>
                      <dd>申请状态为：“待审核”：正在审核用户的下载申请；</dd>
                      <dd>申请状态为“已通过“：用户可以下载所需要下载的内容；</dd>
                      <dd>申请状态为“未通过”：用户所提交的下载申请被拒绝；</dd>
                      <Divider dashed={true} />
                      <dt>
                        <h3>5.4 下载结果:</h3>
                      </dt>
                      <dd>可下载：点击”可下载”按钮，用户可以根据提示进行相应的运算结果下载；</dd>
                    </dl>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </IndexLayout>
    );
  }
}
export default Introduction;
