import { IDictions, IOriginalShareDataItem } from '../../DataManager/CodeType';
import { IOptionUser } from '../SciPackageComponet/CodeType';

export interface ISciDataManageState {
  currentRadio: number;
  partsDiction: Map<string, IDictions>; // 网络返回的 部位字典
  devicesDiction: Map<string, IDictions>; // 网络返回的 设备字典
  loadDataVisible: boolean; // 显示导入原始数据
  loadOrignalDatas: IOriginalShareDataItem[];
  updataModalVisable: boolean; // 上传临时文件
  userItems: IOptionUser[];
  isLoading: boolean;
  projectAuths: Set<string>;
}
export const initSciDataManageState: ISciDataManageState = {
  currentRadio: 1,
  partsDiction: new Map(),
  devicesDiction: new Map(),
  loadDataVisible: false,
  loadOrignalDatas: [],
  updataModalVisable: false,
  userItems: [],
  isLoading: true,
  projectAuths: new Set(),
};
/**
 *
 * 项目管理(USER_PROJECT_MANAGE)
 * 原始数据管理(USER_PROJECT_ORIGINAL_MANAGE)
 * 临时数据管理(USER_PROJECT_INTERMEDIATE_MANAGE)
 * 鉴影任务管理(USER_PROJECT_JY_TASK_MANAGE)
 * 任务管理(USER_PROJECT_TASK_MANAGE)
 * 应用工具管理(USER_PROJECT_APPLICATION_MANAGE)
 */
export const PROJECTITEMSLIST = [
  'USER_PROJECT_MANAGE',
  'USER_PROJECT_ORIGINAL_MANAGE',
  'USER_PROJECT_INTERMEDIATE_MANAGE',
  'USER_PROJECT_JY_TASK_MANAGE',
  'USER_PROJECT_TASK_MANAGE',
  'USER_PROJECT_INTERMEDIATE_MANAGE',
];
