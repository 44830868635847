import axios from './RequestApi';
/**
 * @class 首页
 */
export default class HttpRequest {
  /**
   * 首页右边数据获取
   */
  public static getIndexData<T>() {
    return axios.get<T, {}>('/getIndexData');
  }
}
