import { AutoComplete, Divider, Form, Input, InputNumber, Select, Tooltip } from 'antd';

import { WrappedFormUtils } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { IconFont } from '../../../../config/IconConfig';
import './FormItem.scss';
export enum IItemType {
  Input = 'input',
  InputNumber = 'inputNumber',
  Select = 'select',
  HintInput = 'hintInput',
  TextArea = 'textArea',
  autoComplete = 'autoComplete',
}

interface IFormItemProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  title: string;
  fieldId: string;
  message: string;
  required: boolean;
  initialValue?: string | number;
  options?: JSX.Element[];
  itemType?: IItemType;
  titleStyle?: {};
  formItemStyle?: {};
  hintMessage?: string;
  placeholder?: string;
  // tslint:disable-next-line:no-any
  autoArr?: Array<{ value: string; tip?: string }>;
}
interface IFormItemState {
  id?: string;
  auValue?: string;
}
const { Option } = AutoComplete;
class FormItem extends React.PureComponent<IFormItemProps, IFormItemState> {
  public state = {
    auValue: '',
  };
  public getItemsValue = () => {
    // 3、自定义方法，用来传递数据（需要在父组件中调用获取数据）
    const valus = this.props.form.getFieldsValue(); // 4、getFieldsValue：获取一组输入控件的值，如不传入参数，则获取全部组件的值
    return valus;
  };
  public render() {
    const { itemType } = this.props;
    if (itemType && itemType === 'select') {
      return this.selectedItem();
    } else if (itemType && itemType === 'inputNumber') {
      return this.inputNumberItem();
    } else if (itemType && itemType === 'hintInput') {
      return this.hintInputItem();
    } else if (itemType && itemType === 'textArea') {
      return this.textAreaItem();
    } else if (itemType && itemType === 'autoComplete') {
      return this.autoComplete();
    }
    return this.inputItem();
  }
  /**
   * inputItem
   */
  public inputItem = () => {
    const { form, title, fieldId, message, required, initialValue, titleStyle, formItemStyle } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }} className="form-item-container">
        <div className="form-item-title" style={{ ...titleStyle }}>
          {title}
        </div>
        <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>:</div>
        <Form.Item colon={false} style={{ flex: 1, paddingLeft: '16px', ...formItemStyle }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required, message }],
            initialValue,
          })(<Input placeholder="点击输入" className="form-item-input" />)}
        </Form.Item>
      </div>
    );
  };

  public hintInputItem = () => {
    const {
      form,
      title,
      fieldId,
      message,
      required,
      initialValue,
      titleStyle,
      formItemStyle,
      hintMessage,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className="form-item-container">
        <Tooltip
          placement="topLeft"
          title={<span className="titit">{hintMessage}</span>}
          overlayClassName="tooltip-custom"
        >
          <div className="hint-form-item-title">
            <div style={{ marginRight: '10px' }}>
              <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6', fontSize: '14px' }} />
            </div>
            <div className="form-item-title" style={{ ...titleStyle }}>
              {title}
            </div>

            <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>:</div>
          </div>
        </Tooltip>
        <Form.Item colon={false} style={{ flex: 1, paddingLeft: '16px', ...formItemStyle }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required, message }],
            initialValue,
          })(<Input placeholder="点击输入" className="form-item-input" />)}
        </Form.Item>
      </div>
    );
  };

  public textAreaItem = () => {
    const {
      form,
      title,
      fieldId,
      message,
      required,
      initialValue,
      titleStyle,
      formItemStyle,
      hintMessage,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className="form-TextArea-container">
        <Tooltip
          placement="topLeft"
          title={<span className="titit">{hintMessage}</span>}
          overlayClassName="tooltip-custom"
        >
          <div className="hint-form-item-title">
            <div style={{ marginRight: '10px' }}>
              <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6', fontSize: '14px' }} />
            </div>
            <div className="form-item-title" style={{ ...titleStyle }}>
              {title}
            </div>

            <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>:</div>
          </div>
        </Tooltip>

        <Form.Item colon={false} style={{ flex: 1, paddingLeft: '16px', ...formItemStyle }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required, message }],
            initialValue,
          })(<TextArea placeholder="点击输入" style={{ minHeight: '100px' }} />)}
        </Form.Item>
      </div>
    );
  };

  /**
   * inputNumber
   */
  public inputNumberItem = () => {
    const { form, title, fieldId, message, required, initialValue, formItemStyle } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }} className="form-item-container">
        <div className="form-item-title">{title}:</div>
        {/* <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>:</div> */}
        <Form.Item colon={false} style={{ flex: 1, paddingLeft: '16px', ...formItemStyle }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required, message }],
            initialValue,
          })(<InputNumber placeholder="点击输入" className="form-item-input" min={0} />)}
        </Form.Item>
      </div>
    );
  };
  /**
   * select
   */
  public selectedItem = () => {
    const {
      form,
      title,
      fieldId,
      message,
      required,
      options,
      formItemStyle,
      initialValue,
      hintMessage,
      titleStyle,
      placeholder,
    } = this.props;

    const { getFieldDecorator } = form; // 1、将getFieldDecorator 解构出来，用于和表单进行双向绑定
    return (
      <div className="form-item-container">
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {hintMessage ? (
          <Tooltip
            placement="topLeft"
            title={<span className="titit">{hintMessage}</span>}
            overlayClassName="tooltip-custom"
          >
            <div className="hint-form-item-title">
              <div style={{ marginRight: '10px' }}>
                <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6', fontSize: '14px' }} />
              </div>
              <div className="form-item-title" style={{ ...titleStyle }}>
                {title}
              </div>

              <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>:</div>
            </div>
          </Tooltip>
        ) : (
          ''
        )}
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {!hintMessage ? (
          <div style={{ color: 'rgba(0,0,0,0.64)', fontWeight: 'bold' }} className="form-item-title">
            {title}
          </div>
        ) : (
          ''
        )}
        {!hintMessage ? <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>:</div> : ''}
        <Form.Item>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required, message }],
            initialValue,
          })(
            <Select placeholder={placeholder} className="selected-item" style={{ ...formItemStyle }}>
              {options}
            </Select>
          )}
        </Form.Item>
      </div>
    );
  };
  /**
   * AutoComplete
   */
  public autoComplete = () => {
    const { form, title, fieldId, message, required, autoArr, formItemStyle, hintMessage } = this.props;
    const { auValue } = this.state;
    const { getFieldDecorator } = form; // 1、将getFieldDecorator 解构出来，用于和表单进行双向绑定
    const renderOption = (item: { tip?: string; value: string }) =>
      item.tip ? (
        <Option key={item.value} value={item.value}>
          <Tooltip
            placement="rightTop"
            overlayClassName="optTips"
            // tslint:disable-next-line:jsx-no-multiline-js
            title={
              <div className="optips">
                <h4>
                  <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6' }} />
                </h4>
                <Divider />
                {item.tip}
              </div>
            }
          >
            {item.value}
          </Tooltip>
        </Option>
      ) : (
        item.value
      );

    return (
      <div className="form-item-container">
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {hintMessage ? (
          <Tooltip
            placement="topLeft"
            title={<span className="titit">{hintMessage}</span>}
            overlayClassName="tooltip-custom"
          >
            <div className="hint-form-item-title">
              <div style={{ marginRight: '10px' }}>
                <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6', fontSize: '14px' }} />
              </div>
              <div className="form-item-title">{title}</div>

              <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>:</div>
            </div>
          </Tooltip>
        ) : (
          ''
        )}
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {!hintMessage ? (
          <div style={{ color: 'rgba(0,0,0,0.64)', fontWeight: 'bold' }} className="form-item-title">
            {title}
          </div>
        ) : (
          ''
        )}
        {!hintMessage ? <div style={{ fontWeight: 'bold', marginLeft: '5px' }}>:</div> : ''}
        <Form.Item>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fieldId, {
            rules: [{ required, message }],
            initialValue: auValue,
          })(
            <AutoComplete
              placeholder="请选择"
              className="autoComplete-item"
              style={{ ...formItemStyle }}
              dataSource={autoArr ? autoArr.map(renderOption) : []}
              optionLabelProp="value"
              // tslint:disable-next-line:jsx-no-multiline-js
              filterOption={(inputValue, option) => {
                if (option.key) {
                  return (
                    option.key
                      .toString()
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  );
                }
              }}
            />
          )}
        </Form.Item>
      </div>
    );
  };
}

export default FormItem; // 创建form实例
