import { Button, Checkbox, Icon, Input, message, Pagination, Popover, Rate, Tooltip } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataSearchApi from '../../api/DataSearchApi';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import InfoDrawer from '../../components/InfoDrawer/InfoDrawer';
import NoDataImg from '../../components/NoDataImg/NoDataImg';
import IndexLayout from '../../layout/IndexLayout';
import { IconFont } from './../../config/IconConfig';
import { IAggMap, IDataList, IDetail, IEsData, IItemList, IParam, IState } from './CodeType';

class DataSearch extends React.PureComponent<RouteComponentProps, {}> {
  public state = {
    datas: [],
    visible: false,
    total: 0,
    isLoading: false,
    filterIndex: '基因',
    reqData: {
      pageSize: 10,
      pageNum: 1,
      queryStr: '',
    },
    queryStr: '',
    eggs: [],
    eggsMap: new Map(),
    detail: {},
    input: '',
    selectMroe: '',
  } as IState;

  /* 详情抽屉关闭 */
  public onClose = () => {
    this.setState({
      visible: false,
    });
  };

  public render() {
    const { detail } = this.state;
    const detailJson = detail.jsonDesc ? JSON.parse(detail.jsonDesc) : {};

    const btnGroup = (
      <div>
        <Button onClick={this.onClose} type="primary">
          返回
        </Button>
      </div>
    );
    return (
      <IndexLayout>
        <div id="DataSearch">
          <div className="dataSearchTab">
            <div className="dataSearch_box">
              <div className="left_box">{this.filterRender()}</div>
              <div className="right_box_co">
                <div className="serch_box">
                  <Input
                    className="ipt"
                    onPressEnter={() => this.getDatas()}
                    onChange={this.listenInput}
                    placeholder="请输入"
                  />

                  <Button className="normal-search-button marLfet" onClick={() => this.getDatas()}>
                    搜索
                  </Button>
                </div>
                <AuthComponent isShow={!!this.state.total}>
                  <div className="right_box">
                    <div>{this.dataRender()}</div>
                  </div>
                </AuthComponent>
                <AuthComponent isShow={!this.state.total}>
                  <div className="right_none">
                    <NoDataImg isLoading={this.state.isLoading} total={this.state.total} />
                  </div>
                </AuthComponent>
                <AuthComponent isShow={!!this.state.total}>
                  <Pagination total={this.state.total} onChange={this.pageChange} className="ant-table-pagination" />
                </AuthComponent>
              </div>
            </div>
          </div>
          <div>
            <InfoDrawer
              data={detailJson}
              visible={this.state.visible}
              onClose={this.onClose}
              btnGroup={btnGroup}
              demoUrl={detail.demoUrl}
              allData={detail}
            />
          </div>
        </div>
      </IndexLayout>
    );
  }

  /* 初期化 */
  public async componentDidMount() {
    this.getDatas();
  }

  /**
   * 监听输入框的值
   */
  // tslint:disable-next-line:no-any
  private listenInput = (e: any) => {
    this.setState({
      input: e.target.value,
    });
  };

  /* 获取数据列表 */
  private getDatas = async () => {
    try {
      this.setState({
        isLoading: false,
      });
      const reqData = this.state.reqData;
      reqData.queryStr = this.state.queryStr;
      if (this.state.input.length) {
        reqData.queryStr = this.state.eggsMap.size
          ? `${this.state.queryStr} AND ${this.state.input}`
          : `${this.state.input}`;
      }

      reqData.queryStr = reqData.queryStr.replace(/\s+/g, '').length
        ? reqData.queryStr + ' AND (status:2)'
        : '(status:2)'; // status 为已上架
      const { result } = await DataSearchApi.getPackages<IDataList, IParam>(reqData);
      this.setState({
        total: result.page.total,
        datas: result.page.records,
        eggs: result.aggList,
        isLoading: true,
        reqData: { ...reqData },
      });
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };

  /**
   * 渲染数据列表
   */
  private dataRender = () => {
    return this.state.datas.map((item: IItemList, i: number) => {
      const jsonDesc = JSON.parse(item.jsonDesc);
      return (
        <div key={i} className="data_box">
          <div className="data_content">
            <div className="data_header">
              <div className="illness_name">
                {jsonDesc.project_name} &nbsp; &nbsp;&nbsp;
                {item.canFreeToUse && (item.userType === 3 || item.userType === 4) ? '(药厂已授权)' : ''}
              </div>
              <div>
                等级：
                <Rate disabled={true} defaultValue={Number(jsonDesc.project_score)} />
              </div>
            </div>
            <div className="data_detail">
              <div className="data_detail_b">
                <div className="da_ri">
                  <div className="de_key">机构编号：</div>
                  <div className="de_val">{jsonDesc.project_agency_no}</div>
                </div>
                <div className="da_ri">
                  <div className="de_key">病种：</div>
                  <div className="de_val">{jsonDesc.project_disease_type || '暂无'}</div>
                </div>
                <div className="da_ri">
                  <div className="de_key">数据格式：</div>
                  <div className="de_val">{jsonDesc.project_data_format}</div>
                </div>
                <div className="da_ri">
                  <div className="de_key">数量：</div>
                  <div className="de_val">{jsonDesc.project_amount}</div>
                </div>
              </div>
              <div className="mo_b">
                金额：<span>{item.coinPrice || 0}</span> (积分)
              </div>
            </div>
            <div className="bottom_box">
              <div className="bottom_content">
                <div className="icon_box rigBor">
                  <div className="icon_hover" onClick={() => this.detailView(item.id)}>
                    <IconFont type={'medataicon-xiangqing'} />
                    <span className="icon_na">查看详情</span>
                  </div>
                </div>
                <div className="icon_box">
                  <AuthComponent permission="USER_ORDER_WRITE">
                    <div className="icon_hover" onClick={() => this.orderCommit(item.id)}>
                      <span className="icon_na">立即预约</span>
                    </div>
                  </AuthComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  /**
   * 筛选选条件渲染
   */
  private filterRender = () => {
    return (
      <div className="filter_box">
        <div className="filter_name">
          {// tslint:disable-next-line:jsx-no-multiline-js
          ['基因', '影像'].map((item, i) => (
            <div key={item} className="filter_item" onClick={() => this.changeFilter(item)}>
              <span className={item === this.state.filterIndex ? 'sele' : ''}>{item}</span>
              <AuthComponent isShow={item === this.state.filterIndex}>
                <div className="bor" />
              </AuthComponent>
            </div>
          ))}
        </div>
        <div style={{ paddingTop: '68px' }}>
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.state.eggs
            .filter(item => item.projectDataType === this.state.filterIndex)
            .map((item: IAggMap, i: number) => (
              <div className={!i || !(i % 2) ? 'filter_item_box' : 'filter_item_box white'} key={i}>
                <div className="filter_con">
                  <div className="fi_b_t">{item.alias}</div>
                  <Tooltip overlayStyle={{ background: 'white' }} title={item.desc} overlayClassName="tooltip-custom">
                    <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6' }} />
                  </Tooltip>
                </div>

                {// tslint:disable-next-line:jsx-no-multiline-js
                item.data.map((itemChild: IEsData, childI: number) => (
                  <AuthComponent
                    key={itemChild.value}
                    isShow={item.bucketName === this.state.selectMroe ? true : childI < 3}
                  >
                    <AuthComponent isShow={itemChild.value !== '_other_'}>
                      <div className="checko_box">
                        <div className="check_pad">
                          <div className="check_fle">
                            <Checkbox
                              className="check"
                              // tslint:disable-next-line:jsx-no-multiline-js
                              onChange={() =>
                                this.selectCheckbox(item.bucketName, itemChild.value, itemChild.subexpression)
                              }
                            />
                            <Popover placement="bottomLeft" title={itemChild.value} content={itemChild.valueDesc}>
                              <div className="popov">{itemChild.value}</div>
                            </Popover>
                          </div>
                          <div>({itemChild.docCount})</div>
                        </div>
                      </div>
                    </AuthComponent>
                  </AuthComponent>
                ))}
                <AuthComponent isShow={item.bucketName === this.state.selectMroe}>
                  <div className="checko_box" onClick={() => this.filterMore(item.bucketName)}>
                    <div className="check_pad">
                      <div className="check_fle">收起</div>
                      <IconFont type={'medataicon-xiala-copy-copy-copy-copy'} />
                    </div>
                  </div>
                </AuthComponent>
                <AuthComponent isShow={item.bucketName !== this.state.selectMroe && item.data.length > 4}>
                  <div className="checko_box" onClick={() => this.filterMore(item.bucketName)}>
                    <div className="check_pad">
                      <div className="check_fle">更多</div>
                      <Icon type="right" />
                    </div>
                  </div>
                </AuthComponent>
              </div>
            ))}
        </div>
      </div>
    );
  };

  /**
   * 查看更多
   */

  private filterMore = (selectMroe: string) => {
    if (this.state.selectMroe === selectMroe) {
      if (this.state.eggsMap.has(selectMroe)) {
        message.info('该大类中, 已经选择分类, 不能收起');
        return;
      }
    }
    this.setState({ selectMroe: this.state.selectMroe === selectMroe ? '' : selectMroe });
  };

  /**
   * 基因影像切换
   */
  private changeFilter = (filterIndex: string) => {
    this.setState({ filterIndex });
  };

  /* 翻页 */
  private pageChange = async (page: number, pageSize?: number) => {
    if (page) {
      const reqData = this.state.reqData;
      reqData.pageNum = page;
      this.setState({ reqData: { ...reqData } }, () => this.getDatas());
    }
  };

  /**
   * 选择过滤条件并且根据桶名，把一个桶里的东西放到一起
   * @param { buckeKey } 桶名
   * @param { valKey } 桶里面每一个具体的值
   * @param { subexpression } 子表达式
   */
  private selectCheckbox = (buckeKey: string, valKey: string, subexpression: string) => {
    const eggsMap = this.state.eggsMap;
    const expression = `${subexpression}:"${valKey}"`;
    const vals = eggsMap.get(buckeKey);
    if (vals) {
      const i = vals.indexOf(expression);
      if (i < 0) {
        vals.push(expression);
      } else {
        vals.splice(i, 1);
      }
      vals.length ? eggsMap.set(buckeKey, [...vals]) : eggsMap.delete(buckeKey);
    } else {
      eggsMap.set(buckeKey, [expression]);
    }
    this.setState({ eggsMap }, () => {
      this.groupby();
    });
  };

  /**
   * 拿到需要给后台的公式
   */
  private groupby = () => {
    let queryStr = '';
    Array.from(this.state.eggsMap.values()).forEach((item, i) => {
      queryStr += `${item.length ? `(${item.join(' OR ')})` : `${item.join(' OR ')}`} ${
        this.state.eggsMap.size === i + 1 ? '' : 'AND'
      } `;
    });
    // 如果选中又取消掉话会出现一个空，那么就只剩一个(),但是我们希望他是空的
    const space = queryStr.replace(/\s+/g, '');
    if (space === '()') {
      queryStr = '';
    }

    this.setState({ queryStr }, () => this.getDatas());
  };

  /*  详情显示 */
  private detailView = async (id: number) => {
    const { result } = await DataSearchApi.getMallInfos<IDetail, { id: number }>({ id });
    this.setState({
      detail: result,
      visible: true,
    });
  };

  /* 提交购买请求 */
  private orderCommit = (id: number) => {
    this.props.history.push('/orderCheck/' + id);
  };
}

export default DataSearch;
