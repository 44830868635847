import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';

import { Buffer } from 'buffer';

import * as React from 'react';
import UserAccount from '../../../api/UserAccount';

import { IconFont } from './../../../config/IconConfig';
import { app } from './../../../index';
import router from './../../../Router';
import Storage, {
  LOGINNAME,
  NODENAME,
  TOKEN,
  USERID,
  USERPERMISSION,
  USERROLEITEMS,
  USERTYPE,
} from './../../../util/Storage';
import { ILoginFormProps, ILoginResponse, ILoginState, IResult } from './CodeType';
class LoginForm extends React.Component<ILoginFormProps, ILoginState> {
  private static encodeBase64Content(content: string) {
    return new Buffer(content).toString('base64');
  }
  public state = {
    username: '',
    password: '',
    remember: true,
    pswStatue: true,
  };

  public render() {
    const { pswStatue } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div id="Login">
        <Row className="login-container" type="flex">
          <Form>
            <Form.Item className="login-logo">
              <div>
                <div>
                  <IconFont type="medataicon-logozuhe" />
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator(`username`, {
                rules: [{ required: true, message: '登录账号不能为空' }],
              })(
                <Input
                  // tslint:disable-next-line:jsx-no-multiline-js
                  addonBefore={<IconFont type="medataicon-yonghuming1" className="inputBefore" />}
                  className="inputSet"
                  placeholder="输入登录账号"
                  onChange={this.userNameChange}
                  // tslint:disable-next-line:jsx-no-multiline-js
                  suffix={<div>{<IconFont type="" />}</div>}
                />
              )}
            </Form.Item>
            <Form.Item>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator(`password`, {
                rules: [{ required: true, message: '密码不能为空' }],
                initialValue: this.state.password,
              })(
                <Input
                  // tslint:disable-next-line:jsx-no-multiline-js
                  addonBefore={<IconFont type="medataicon-mima2" className="inputBefore" />}
                  className="inputSet"
                  type={pswStatue ? 'password' : 'text'}
                  placeholder="请输入密码"
                  // tslint:disable-next-line:jsx-no-multiline-js
                  suffix={
                    <div onClick={this.passwordClick}>
                      {<IconFont type={pswStatue ? 'medataicon-bukejian' : 'medataicon-kejian'} />}
                    </div>
                  }
                />
              )}
            </Form.Item>
            <Form.Item className="toFlex">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator(`remember`, {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox className="rememberCheckbox">记住密码</Checkbox>)}
            </Form.Item>
            <Form.Item className="btn-submit center-set">
              <Button type="primary" className="login-form-button" onClick={this.handleSubmit}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </Row>
        <Row className="login-bottom-container center-set">
          <Row className="indexMenuContent">
            <Col span={24}>
              <Row className="center-set">
                <div className="up-foot">
                  <div className="left-word">
                    <a href="http://beian.miit.gov.cn/" target="_blank">
                      沪ICP备18041253号
                    </a>
                  </div>
                  <span>|</span>
                  <div className="right-word">沪公网安备 31010102005090号</div>
                </div>
              </Row>

              <Row className="center-set">版权所有 Copyright 上海基诺联生物科技有限公司 All Rights Reserved</Row>
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
  /**
   * 用户名变更
   */
  private userNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userName = event.target.value;
    if (userName) {
      const password = Storage.getStore(userName);
      if (password) {
        this.setState({
          password,
        });
      }
    }
  };

  /**
   * 修改密码可视状态
   */
  private passwordClick = () => {
    const { pswStatue } = this.state;

    this.setState({
      pswStatue: !pswStatue,
    });
  };

  /* 表单提交 */
  private handleSubmit = (e: React.SyntheticEvent) => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { result } = await UserAccount.login<IResult, ILoginResponse>({
          mobile: values.username,
          password: LoginForm.encodeBase64Content(values.password),
        });
        message.success('登录成功', 3);
        Storage.setStore(values.username, values.password); // 存储 用户名密码
        Storage.setStore(USERPERMISSION, JSON.stringify(result.permissions)); // 存储 用户名密码
        Storage.setStore(TOKEN, result.token); /* 储存token */
        Storage.setStore(USERID, result.user.id + ''); /* 储存用户id */
        Storage.setStore(LOGINNAME, result.user.loginname); /* 储存用户名 */
        Storage.setStore(NODENAME, result.node.name); /* 储存节点名 */
        Storage.setStore(USERTYPE, result.user.type + ''); /* 储存用户类型 */
        app.router(router);
        app.start('#root');
        this.props.history.push('/');
        this.getRoleItems();
      }
    });
  };

  /**
   * 登录后获取权限角色
   */
  private getRoleItems = async () => {
    const { result } = await UserAccount.getRoleList();
    if (result) {
      Storage.setStore(USERROLEITEMS, JSON.stringify(result));
    }
  };
}
const Login = Form.create()(LoginForm);
export default Login;
