import { FormComponentProps } from 'antd/lib/form';
import { SubscriptionAPI } from 'dva';

export interface IIndexData {
  id?: number;
  cases: number;
  genes: number;
  image: number;
  diseaseType: number;
}

export interface INoticeItem {
  id: number;
  userId: number;
  text: string;
  created: number;
  deleted: string;
  unread: boolean;
  type: number;
  strType: string;
}

export interface IUpdataPsdReq {
  newPassword: string;
  surePassword: string;
  oldPassword: string;
}

export interface INoticeList {
  records?: INoticeItem[];
  searchCount?: boolean;
  total?: number;
}
export interface IState {
  visible: boolean;
  unread: boolean;
  homeData: {};
  notices?: INoticeItem[];
  updatePsdVisible: boolean;
  isJD: boolean;
}
export interface IProps extends SubscriptionAPI, FormComponentProps {
  type?: 'leftmenu' | 'Index';
  globals: { collapsed: boolean; openSubId: string[] };
}
export const stateInit = {
  visible: false,
  unread: false,
  isJD: true,
  homeData: {} as IIndexData,
  notices: [],
  updatePsdVisible: false,
};
