import { Col, Divider, Drawer, Row } from 'antd';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import DescriptionItem from '../DescriptionItem/DescriptionItem';
import dictionary, { IDicItem } from './CodeType';
import './InfoDrawer.scss';
declare type EventType = React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>;

interface IProps {
  // tslint:disable-next-line:no-any
  data: any;
  visible: boolean;
  onClose?: (e: EventType) => void;
  pageName?: 'Orders' | 'Grades' | 'logs';
  // tslint:disable-next-line:no-any
  allData: any; // 整个详情
  btnGroup?: JSX.Element;
  demoUrl?: string | number;
  caseNumberHidden?: boolean;
  exampleHidden?: boolean;
}

interface IState {
  visible: boolean;
}

class InfoDrawer extends React.Component<IProps, IState> {
  public state = {
    visible: false,
  };
  // tslint:disable-next-line:no-any
  public upRender = (data: any, dic: IDicItem) => {
    const renderJsx: JSX.Element[] = [];

    for (const key in dic) {
      if (key === 'project_overview' || key === 'file_structure' || key === 'project_name') {
        renderJsx.push(<DescriptionItem key={key} title={dic[key]} content={data[key] || '暂无'} type="long" />);
      } else if (key === 'project_amount') {
        renderJsx.push(
          <div key={key}>
            {/*  tslint:disable-next-line:jsx-no-multiline-js */}
            {this.props.caseNumberHidden ? null : (
              <DescriptionItem title={dic.project_amount} content={data.project_amount || '暂无'} />
            )}
          </div>
        );
      } else if (key === 'project_data_type') {
        renderJsx.push(<DescriptionItem key={key} title="数据类型" content={this.props.allData.categoryName} />);
      } else {
        renderJsx.push(<DescriptionItem key={key} title={dic[key]} content={data[key] || '暂无'} />);
      }
    }
    return renderJsx;
  };
  // tslint:disable-next-line:no-any
  public downRender = (data: any) => (
    <div>
      <Row>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {this.props.exampleHidden ? null : (
          <Row>
            <Col span={18}>
              <h3>示例：</h3>
            </Col>
            <Col span={6}>
              <a href={this.props.demoUrl + ''}>
                <IconFont type="medataicon-xiazai1" />
                下载示例
              </a>
            </Col>
          </Row>
        )}
      </Row>
      {/* tslint:disable-next-line:jsx-no-multiline-js*/}
      {data ? (
        data.project_templet_images ? (
          <Row gutter={32} className="img-group">
            <Col span={12}>
              <img src={data.project_templet_images[0]} alt="" />
            </Col>
            <Col span={12}>
              <img src={data.project_templet_images[1]} alt="" />
            </Col>
            <Col span={12}>
              <img src={data.project_templet_images[2]} alt="" />
            </Col>
            <Col span={12}>
              <img src={data.project_templet_images[3]} alt="" />
            </Col>
          </Row>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </div>
  );

  public render() {
    const { data } = this.props;
    if (data === undefined || !this.props.allData) {
      return null;
    }
    const content =
      this.props.allData.categoryName === '基因'
        ? this.upRender(data, dictionary[0])
        : this.upRender(data, dictionary[1]);

    return (
      <Drawer
        className="InfoDrawer"
        width={450}
        placement="right"
        title="详情"
        onClose={this.props.onClose}
        visible={this.props.visible}
      >
        {content}
        <Divider dashed={true} />
        {this.downRender(data)}
        {this.props.btnGroup ? <div className="DrawerBtnGroup">{this.props.btnGroup}</div> : ''}
      </Drawer>
    );
  }
}
export default InfoDrawer;
