import * as Sentry from '@sentry/browser';
import { ErrorInfo } from 'react';
import React from 'react';
interface IErrorBoundaryProps {
  //
  id?: number;
}
interface IErrorBoundaryState {
  hasError: boolean;
  eventId: string;
}

class ErrorBoundary extends React.PureComponent<IErrorBoundaryProps, IErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error) {
    //
    return { hasError: true };
  }
  public state = {
    hasError: false,
    eventId: '',
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // 错误发送sentry 后台
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);

      this.setState({ eventId });
    });
  }

  public render() {
    if (this.state.hasError) {
      return <h1 />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
