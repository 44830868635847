import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { IconFont } from '../../config/IconConfig';

import { IWaitingShareDataItem } from '../../pages/DataManager/CodeType';
import AuthCmp from '../AuthCmp/AuthCmp';
// import AuthComponent from './../AuthComponent/AuthComponent';
import './DataShareItem.scss';
interface IDataShareItemProps {
  itemData: IWaitingShareDataItem;
  projectId?: number;
  checkBoxClick: (checked: boolean, item: IWaitingShareDataItem) => void;
  showSequenceClick: (item: IWaitingShareDataItem, checked: boolean) => void;
}
interface IDataShareItemState {
  checked: boolean;
  itemData: IWaitingShareDataItem;
}
/* 序列列表组件 */
class DataShareItem extends React.PureComponent<IDataShareItemProps, IDataShareItemState> {
  constructor(props: IDataShareItemProps) {
    super(props);
    this.state = {
      checked: props.itemData.check,
      itemData: props.itemData,
    };
  }
  public componentWillReceiveProps(nextProps: IDataShareItemProps) {
    if (nextProps.itemData.check !== this.state.checked) {
      this.setState({
        checked: nextProps.itemData.check,
      });
    }
  }
  /**
   * checkBox x选中
   */
  public checkBoxOnchanage = (e: CheckboxChangeEvent) => {
    this.props.checkBoxClick(e.target.checked, this.state.itemData);
    this.setState({
      checked: !this.state.checked,
    });
  };

  public render() {
    const { checked } = this.state;
    return (
      <div className="data-share-item-container">
        {this.headerView(checked)}
        {this.shareContent()}
      </div>
    );
  }
  /**
   * header
   */
  public headerView = (check: boolean) => {
    //
    const { itemData } = this.props;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} className="share-header">
        <div style={{ flex: 1 }}>
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.props.projectId ? (
            <AuthCmp permission="USER_PROJECT_MANAGE" projectId={Number(this.props.projectId)}>
              <Checkbox onChange={this.checkBoxOnchanage} checked={check} />
            </AuthCmp>
          ) : (
            <Checkbox onChange={this.checkBoxOnchanage} checked={check} />
          )}

          <span> {itemData.endDate}</span>
          <span style={{ marginLeft: '54px' }}>
            <span style={{ fontWeight: 'bold' }}> 编号:</span>
            {itemData.id}
          </span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', flex: 1 }}>
          <span>
            <span style={{ fontWeight: 'bold', marginRight: '8px' }}> 数量:</span>{' '}
            {itemData.seriesNum ? itemData.seriesNum : 0}
          </span>
        </div>
      </div>
    );
  };
  /**
   * 内容
   */
  public shareContent = () => {
    const { itemData } = this.props;
    return (
      <div className="share-container">
        <div style={{ flex: '3' }}>
          <span style={{ marginLeft: '54px' }}> {itemData.name} </span>
          <span style={{ marginLeft: '47px' }}>
            <span style={{ fontWeight: 'bold', marginRight: '8px' }}> 说明:</span>
            {itemData.remark}
          </span>
        </div>
        <div
          style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', color: '#00a1e6', cursor: 'pointer' }}
          // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
          onClick={() => {
            this.props.showSequenceClick(this.props.itemData, this.state.checked);
          }}
        >
          序列列表
          <span
            style={{ marginLeft: '8px', marginRight: '35px', cursor: 'pointer' }}
            // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
            onClick={() => {
              this.props.showSequenceClick(this.props.itemData, this.state.checked);
            }}
          >
            <IconFont type="medataicon-xiala-copy-copy-copy" style={{ fontSize: '24px' }} />
          </span>
        </div>
      </div>
    );
  };
}

export default DataShareItem;
