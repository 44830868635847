import { Button, Form, message, Modal, Select, Table } from 'antd';
import { PaginationConfig, TableRowSelection } from 'antd/lib/table';
import React from 'react';
import SciManageApi from '../../../api/SciManageApi';
import VMManage, { ISignalPkgLoadReq } from '../../../api/VMManage';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { INormalList } from '../../DataManager/CodeType';
import { IPkgItem, IPkgItemsReq, PKG_DATA_TYPE_OPTION } from '../../ScientificManage/SciPackageComponet/CodeType';
import { InitPkgState, ISciPkgProps, ISciPkgState } from './CodeType';
import './SecBoxPkgCmp.scss';
export class SecBoxPkgCmp extends React.PureComponent<ISciPkgProps, ISciPkgState> {
  public hasShareColumns = [
    {
      title: '项目数据包名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: '150px',
    },
    {
      title: '数据格式',
      dataIndex: 'dataFormat',
      key: 'dataFormat',
    },
    {
      title: '数据类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '数量',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '疾病类型',
      dataIndex: 'disease',
      key: 'disease',
      width: '100px',
    },
    {
      title: '数据包状态',
      dataIndex: 'status',
      key: 'status',
      render: (type: number, record: IPkgItem) => {
        const color = record.status === 0 ? 'rgba(208, 2, 27, 1)' : 'rgba(0, 0, 0, 0.65)';
        return <span style={{ color }}> {record.status === 0 ? '未挂载' : '已挂载'}</span>;
      },
    },
    {
      title: <div>操作</div>,
      key: 'action',
      render: (text: string, record: IPkgItem) => {
        return (
          <div onClick={() => this.cellClick(record)} style={{ color: '#00a1e6', cursor: 'pointer' }}>
            {record.status === 0 ? '挂载' : '取消挂载'}
          </div>
        );
      },
    },
  ];

  public state = InitPkgState as ISciPkgState;

  public componentDidMount() {
    this.getSciPkgItems();
  }

  /**
   * 对象转数组
   */
  // tslint:disable-next-line:no-any
  public objToArr = (obj: any) => {
    const arr = [];
    for (const i in obj) {
      if (obj[i]) {
        arr.push({ title: i, content: obj[i] });
      }
    }
    return arr;
  };
  public render() {
    const records = this.state.pkgItems;
    const { getFieldDecorator } = this.props.form;
    const laodPkgfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.loadModalOkClick}>
          我知道了
        </Button>
      </div>
    );
    const rowSelection: TableRowSelection<IPkgItem> = {
      // 已选序列列表
      onChange: this.selectedPkg,
      selectedRowKeys: this.state.selectedPkgItem,
    };
    return (
      <div className="sci-package-component">
        <TableHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
              <Form.Item label="数据类型:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('sci_pkg_type')(
                  <Select className="original-input" placeholder="请选择" style={{ width: '240px' }} allowClear={true}>
                    {PKG_DATA_TYPE_OPTION}
                  </Select>
                )}
              </Form.Item>
              <Button
                className="normal-search-button"
                onClick={this.handlePkgSearchAction}
                style={{ marginLeft: '40px', fontWeight: 'bold', marginRight: '60px' }}
              >
                查询
              </Button>
            </div>
            <Button
              className="theme-primary-btn"
              onClick={this.batchLoadPkg}
              style={{ marginLeft: '40px', fontWeight: 'bold' }}
            >
              批量挂载数据
            </Button>
          </div>
        </TableHeader>
        <AuthComponent isShow={!!this.state.pkgPagination.total}>
          <Table
            columns={this.hasShareColumns}
            dataSource={records}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.pkgPagination }}
            rowSelection={rowSelection}
            rowKey="packageId"
            onChange={this.handleHasTableChanage}
          />
        </AuthComponent>
        <NoDataImg
          isLoading={this.state.isLoading}
          total={this.state.pkgPagination.total}
          msg="暂时还没有项目数据包..."
        />
        <Modal
          visible={this.state.loadModalVisable}
          className="load-pkg-modal"
          width="560px"
          centered={true}
          title={'挂载失败'}
          footer={laodPkgfooter}
          closable={true}
          onCancel={this.moreModalCancel}
        >
          <div className="modal-content-view"> {this.loadFailUserView()} </div>
        </Modal>
      </div>
    );
  }

  /**
   * 已共享数据 网络请求
   */
  private getSciPkgItems = async () => {
    const { pkgPagination } = this.state;
    const { getFieldValue } = this.props.form;
    const pkgType = getFieldValue('sci_pkg_type');

    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getProjectPackageDatas<INormalList<IPkgItem[]>, IPkgItemsReq>({
        pageNum: 1,
        pageSize: 1000,
        projectId: this.props.projectId,
        type: pkgType,
      });
      if (result) {
        pkgPagination.current = result.current;
        pkgPagination.total = result.total;
        this.setState({
          pkgItems: [...result.records],
          pkgPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 列表分页处理
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { pkgPagination } = this.state;
    pkgPagination.current = (pagination && pagination.current) || 1;
    this.setState(
      {
        pkgPagination: { ...pkgPagination },
      },
      () => {
        this.getSciPkgItems();
      }
    );
  };

  /**
   *  已共享数据 筛选
   */
  private handlePkgSearchAction = () => {
    this.getSciPkgItems();
  };
  /**
   * 原始数据row  点击事件, 针对不同按钮点击事件,用IHasSharedCellClickType 枚举进行区分
   */
  private cellClick = (item: IPkgItem) => {
    this.setState({
      clickItem: item,
    });

    this.signalPkgLoad({
      packageId: item.packageId,
      researchProjectId: item.researchProjectId,
      status: item.status === 0 ? 1 : 0,
    });
  };

  /**
   * 单个数据包挂载
   */
  private signalPkgLoad = async (pkg: ISignalPkgLoadReq) => {
    const { result } = await VMManage.signalPkgLoad({ ...pkg });

    if (result.length) {
      // 有挂载不成功的
      this.setState({
        loadModalVisable: pkg.status === 0 ? false : true,
        loadFailUser: [...result],
      });
    }
    this.getSciPkgItems();
  };
  /**
   * 批量挂载数据包
   */

  private loadPkg = async (packageIds: number[]) => {
    const { result } = await VMManage.pkgLoad({
      packageIds,
      status: 1,
      researchProjectId: this.props.projectId,
    });
    if (result.length) {
      // 有挂载不成功的
      this.setState({
        loadModalVisable: true,
        loadFailUser: [...result],
      });
    }
    this.getSciPkgItems();
  };
  /**
   * 挂载模态框OKCLick
   */
  private loadModalOkClick = () => {
    this.setState({
      loadModalVisable: false,
    });
  };

  /**
   * 下拉框挂载位置
   */

  private moreModalCancel = () => {
    this.setState({ loadModalVisable: false });
  };
  /**
   * 批量挂载数据
   */

  private batchLoadPkg = () => {
    const { selectedPkgItem } = this.state;
    if (selectedPkgItem.length) {
      this.loadPkg(selectedPkgItem);
    } else {
      message.error('先选择需要挂载数据');
    }
  };

  /**
   * 选中变化
   */
  private selectedPkg = (selectedRowKeys: string[] | number[], selectedRows: IPkgItem[]) => {
    this.setState({
      selectedPkgItem: selectedRowKeys as number[],
    });
  };
  /**
   * 挂载失败的提示UI
   */

  private loadFailUserView = () => {
    const userViews: JSX.Element[] = this.state.loadFailUser.map((item, index) => {
      return (
        <div key={index} style={{ display: 'inline-block', width: '25%', marginBottom: '16px' }}>
          {item}
        </div>
      );
    });
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
          <span> 以下用户安全盒数据挂载失败:</span>
          <span style={{ color: '#a62024' }}>只有在安全盒网络关闭下才能挂载数据 </span>
        </div>
        <div>{userViews}</div>
      </div>
    );
  };
}

export default SecBoxPkgCmp;
