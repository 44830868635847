import { Button, Card, Divider, Drawer, Form, Switch, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HttpRequest from '../../../api/MyDataApi';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import { IDataPackageItem } from '../../../util/DataStruct';
import { INormalList } from '../../DataManager/CodeType';
import { IItemList, IMyDataProjectList, IMyPharmacyState } from './CodeType';
import './MyPharmacy.scss';
interface IMyPharmacyprops extends FormComponentProps, RouteComponentProps {}
class MyPharmacy extends React.PureComponent<IMyPharmacyprops, IMyPharmacyState> {
  public state = {
    current: 1,
    visible: false,
    hospitalListVisible: false,
    currentPharmacyCode: '',
    currentPharmacyName: '',
    myPharmacyList: [],
    pagination: { total: 0, pageSize: 10, current: 1 },
    pharmacyPagination: { total: 0, pageSize: 10, current: 1 },
    relationHospitalList: [],
    isLoading: true,
  } as IMyPharmacyState;
  public columns = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '数据格式',
      key: 'dataFormat',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div>{jsonPkg ? jsonPkg.project_data_format : ''}</div>;
      },
    },
    {
      title: '数据类型',
      key: 'type',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div>{jsonPkg ? (jsonPkg.project_data_type === 1 ? '基因' : '影像') : ''}</div>;
      },
    },
    {
      title: <div style={{ textAlign: 'right', width: '150px', paddingRight: '50px' }}>数量</div>,
      key: 'num',

      className: 'table-num',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div style={{ paddingRight: '50px' }}>{jsonPkg ? jsonPkg.project_amount : ''}</div>;
      },
    },
    {
      title: <div>病种</div>,
      key: 'dis',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div>{jsonPkg ? jsonPkg.project_disease_type : ''}</div>;
      },
    },
    {
      title: <div style={{ textAlign: 'right', paddingRight: '50px', width: '150px' }}>等级</div>,
      key: 'project_score',

      className: 'table-num',
      render: (text: string, record: IMyDataProjectList) => {
        const jsonPkg: IDataPackageItem = record.pkg.jsonDesc ? JSON.parse(record.pkg.jsonDesc) : undefined;
        return <div style={{ paddingRight: '50px' }}>{jsonPkg ? jsonPkg.project_score : ''}</div>;
      },
    },
    {
      title: <div style={{ paddingLeft: '1em' }}>操作</div>,

      className: 'table-opt-area',
      key: 'action',
      render: (text: string, record: IMyDataProjectList) => {
        const btnGroup = (
          <div className="btngroup">
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.toProjectDetail(record.code, record.collectorNum, record.usedCollectorNum);
              }}
            >
              项目详情
            </a>
            <Divider type="vertical" className="optline" />
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.handleHospitalList(record.code, record.name);
              }}
            >
              医院列表
            </a>
            <Divider type="vertical" className="optline" />
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.handleDetail(record.pkg.id);
              }}
            >
              数据详情
            </a>
          </div>
        );
        return btnGroup;
      },
    },
  ];
  /**
   *
   * 项目详情点击
   */

  public toProjectDetail(code: string, collectorNum: number, usedCollectorNum: number) {
    sessionStorage.setItem('pharmacy-code', code);
    sessionStorage.setItem('pharmacy-collectorNum', collectorNum.toString());
    sessionStorage.setItem('pharmacy-usedCollectorNum', usedCollectorNum.toString());
    window.open('#/pharmacyDetail');
    // this.props.history.push({ pathname: 'pharmacyDetail' });
  }

  /**
   * 医院列表分页
   */
  public handleHospitalList = async (code: string, name: string) => {
    const { pagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const { result } = await HttpRequest.getRelationHospitals<
        INormalList<IMyDataProjectList[]>,
        { pageNum?: number; pageSize?: number; code?: string }
      >({
        code,
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      });
      if (result) {
        this.setState({
          currentPharmacyCode: code,
          currentPharmacyName: name,
          hospitalListVisible: true,
          relationHospitalList: [...result.records],
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };
  /**
   * hospitalListClose
   */
  public hospitalListClose = () => {
    this.setState({
      hospitalListVisible: false,
    });
  };
  /**
   * 显示数据包详情
   */
  public handleDetail = async (id: number) => {
    const { result } = await HttpRequest.getPackageInfo<IItemList, { id: number }>({
      id,
    });
    if (result) {
      this.setState({
        packageDetail: result,
        visible: true,
      });
    }
  };
  /**
   * onClose
   */
  public onClose = () => {
    this.setState({
      visible: false,
    });
  };

  /**
   * 权限修改
   */
  public onPermissionChange = async (hospitalCode: string, checked: boolean, event: Event) => {
    const { result } = await HttpRequest.allocatePermissions({
      code: this.state.currentPharmacyCode,
      hospitalCode,
      hasPermissions: checked,
    });
    if (result) {
      this.handleHospitalList(this.state.currentPharmacyCode, this.state.currentPharmacyName);
    }
  };
  /**
   * 数据列表
   */
  public getMyPharmacys = async (pageNum?: number) => {
    pageNum = pageNum ? pageNum : 1;
    const { pharmacyPagination } = this.state;
    pharmacyPagination.current = pageNum;
    this.setState({
      isLoading: false,
    });
    const { result } = await HttpRequest.getMyPharmacys<
      INormalList<IMyDataProjectList[]>,
      { pageNum?: number; pageSize?: number }
    >({
      pageNum: pharmacyPagination.current,
      pageSize: pharmacyPagination.pageSize,
    });
    if (result) {
      pharmacyPagination.current = result.current;
      pharmacyPagination.total = result.total;
      this.setState({
        myPharmacyList: [...result.records],
        pharmacyPagination,
        isLoading: true,
      });
    }
  };

  /**
   * 分页处理
   */
  public handleTableChange = (pagination: PaginationConfig) => {
    this.getMyPharmacys(pagination.current);
  };
  public componentDidMount() {
    this.getMyPharmacys();
  }

  /**
   * 医院列表render
   */
  public getHospitalsRender() {
    const { relationHospitalList } = this.state;
    let render: JSX.Element | JSX.Element[];

    if (relationHospitalList && relationHospitalList.length > 0) {
      // tslint:disable-next-line:no-any
      render = relationHospitalList.map((v: any, index: number) => {
        return (
          <tr key={index}>
            <td>{v.name}</td>
            <td style={{ textAlign: 'right', paddingRight: '32px' }}>{v.usedCollectorNum ? v.usedCollectorNum : 0}</td>
            <td>
              <Switch checked={v.hasPermissionToUse} onChange={this.onPermissionChange.bind(this, v.code)}>
                <a>{v.code}</a>
              </Switch>
            </td>
          </tr>
        );
      });
    } else {
      render = <div />;
    }

    return (
      <div>
        <table className="mypharmacyhospitallist" style={{ tableLayout: 'fixed', width: '100%' }}>
          <tr>
            <th>医院名称</th>
            <th style={{ textAlign: 'right', paddingRight: '32px' }}>已收集病例</th>
            <th>使用权限</th>
          </tr>
          {render}
        </table>
        <Button
          className="back-btn"
          type="primary"
          style={{ float: 'right', marginRight: '24px' }}
          onClick={this.hospitalListClose}
        >
          返回
        </Button>
      </div>
    );
  }

  public render() {
    const { myPharmacyList, packageDetail, pharmacyPagination } = this.state;
    const detailJson = packageDetail && JSON.parse(packageDetail.jsonDesc);
    return (
      <IndexLayout>
        <div className="page-container" id="MyPharmacy">
          <RouteHeader
            title="我的数据"
            titles={['首页', '我的数据']}
            routes={['/', '/mypharmacy']}
            hiddenBreadcrumb={true}
          />

          <AuthComponent isShow={!!this.state.pharmacyPagination.total}>
            <Card bordered={false} className="myPharmacyCard">
              <Table
                columns={this.columns}
                dataSource={myPharmacyList}
                style={{ backgroundColor: 'white' }}
                pagination={{ ...pharmacyPagination }}
                rowKey="orderCode"
                onChange={this.handleTableChange}
              />
            </Card>
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.pharmacyPagination.total} />
          <Drawer
            title={this.state.currentPharmacyName + '---医院列表'}
            placement="right"
            closable={true}
            width="564px"
            className="mypharmacy-hospitallist"
            onClose={this.hospitalListClose}
            visible={this.state.hospitalListVisible}
          >
            {this.getHospitalsRender()}
          </Drawer>

          <Drawer
            title="数据包详情"
            placement="right"
            width={700}
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <TradeDetailDrawer
              showOrderMessage={false}
              showPackageView={this.state.visible}
              showSecurityBox={false}
              // tslint:disable-next-line:jsx-no-multiline-js
              dataPackage={detailJson as IDataPackageItem}
              // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
              onClose={() => {
                this.onClose();
              }}
            />
          </Drawer>
        </div>
      </IndexLayout>
    );
  }
}
const MyPharmacyList = Form.create()(MyPharmacy);
export default MyPharmacyList;
