import { Form, Icon, message, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import FileTree, { ITreeData } from '../../../../components/FileTree/FilePathTree';
import { IconFont } from '../../../../config/IconConfig';
import './DownloadPath.scss';
interface IPropsForPath extends FormComponentProps {
  path: ITreeData[];
  onCancel: () => void;
  onOk: (path: string) => void;
  visible: boolean;
  title?: string;
}
interface IStateForPath {
  pathStr: string;
}
/* 下载按钮弹出框组件 */
class DownloadPathForm extends React.PureComponent<IPropsForPath, IStateForPath> {
  public state = {
    pathStr: '',
  };
  /* 确定提交事件 */
  public handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (this.state.pathStr !== '') {
        this.props.onOk(this.state.pathStr); // 调用父组件给的onOk方法并传入Form的参数。
      } else {
        message.error('路径不能为空');
      }
    });
  };
  /* Cancel关闭modal框事件 */
  public onCancel = () => {
    this.props.form.resetFields(); // 重置Form表单的内容
    this.props.onCancel(); // 调用父组件给的方法
  };
  public fileTreeOnSelect = (filePath: string) => {
    this.setState({ pathStr: filePath });
  };

  public render = () => {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15, offset: 1 },
      },
    };
    const headerView = (
      <div style={{ flex: 1, display: 'flex' }}>
        <div style={{ flex: 1 }} />
        <div style={{ flex: 1, textAlign: 'center' }}> {this.props.title || '共享数据下载'} </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <IconFont type="medataicon-yidongyingpan-" style={{ fontSize: '20px', color: '#00a1e6' }} />
        </div>
      </div>
    );
    return (
      <Modal
        onOk={this.handleSubmit}
        onCancel={this.onCancel}
        visible={this.props.visible}
        okText="确定"
        cancelText="取消"
        title={headerView}
        closable={false}
        className="PathSet"
      >
        <Form {...formItemLayout}>
          <Form.Item className="textAreaForm" label={'文件存放目录'}>
            {// tslint:disable-next-line:jsx-no-multiline-js
            getFieldDecorator('id')(
              <FileTree treeData={this.props.path} interValue={this.state.pathStr} onSelect={this.fileTreeOnSelect} />
            )}
          </Form.Item>
          <div className="explain">
            <dd>
              <span>
                <Icon type="exclamation-circle" />
                数据下载过程中，请不要拔掉移动硬盘，以免中断下载。
              </span>
            </dd>
          </div>
        </Form>
      </Modal>
    );
  };
}
export const DownloadPath = Form.create()(DownloadPathForm);
