import { Col, Row } from 'antd';
import React from 'react';
import { IVmJsonItem } from '../../util/DataStruct';

interface IProps {
  vmJson: IVmJsonItem;
}
class SecurityBoxView extends React.PureComponent<IProps> {
  public render() {
    const vmJson = this.props.vmJson;
    return (
      <Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={8}>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold' }}>cpu</span>
            <span> | 数量: {vmJson.cpu_cores}核 </span>
          </Col>
          <Col span={8}>
            <span> 频率: {vmJson.cpu_frequency} </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={8}>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold' }}>内存</span>
            <span> | 容量: {vmJson.memory_size} </span>
          </Col>
          <Col span={8}>
            <span> 频率: {vmJson.memory_frequency} </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={8}>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold' }}>显卡 </span>
            <span> | 型号: {vmJson.gpu_core_type}</span>
          </Col>
          <Col span={8}>
            <span> GPU频率: {vmJson.gpu_core_frequency} </span>
          </Col>
          <Col span={8}>
            <span> 显存: {vmJson.gpu_memory_type} </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={8}>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold' }}>磁盘 </span>
            <span> | 空间: {vmJson.disk_space}TB</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={8}>
            <span style={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 'bold' }}>系统 </span>
            <span>
              | 版本: {vmJson.os_system} {vmJson.os_system_version}{' '}
            </span>
          </Col>
        </Row>
      </Row>
    );
  }
}
export default SecurityBoxView;
