import { Breadcrumb, Button, Col, DatePicker, Icon, Input, message, Row, Select, Table } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import Form, { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataManagerApi from '../../api/DataManager';
import ProjectMembersApi from '../../api/ProjectMembersApi';
import ProjectTasksApi from '../../api/ProjectTasksApi';
import AuthCmp from '../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import { ITreeData } from '../../components/FileTree/FilePathTree';
import NoDataImg from '../../components/NoDataImg/NoDataImg';
/* import NoDataImg from '../../components/NoDataImg/NoDataImg'; */
import IndexLayout from '../../layout/IndexLayout';
import { IPartParam, IProjectUserListResult } from '../ProjectMembers/CodeType';
import { IOptionUser } from '../ScientificManage/SciPackageComponet/CodeType';
import {
  ICreateDTO,
  IDictions,
  IOrder,
  IOriginal,
  IOriginalList,
  IOriginalResult,
  IPageParam,
  IParam,
  IState,
  ITaskList,
  ITasksResult,
} from './CodeType';
import { SeqSelectList } from './Component/Seq';

interface IProps extends FormComponentProps, RouteComponentProps<IPageParam> {}

const { RangePicker } = DatePicker;
const ListData = [
  {
    id: 1,
    name: '用户名1',
    description: '登录名1',
    memberName: '123123',
    originalTotal: 10,
    completedOriginalTotal: 11,
  },
  {
    id: 2,
    name: '用户名2',
    description: '登录名2',
    memberName: '1234',
    originalTotal: 19,
    completedOriginalTotal: 15,
  },
] as ITaskList[];
const OrderData = [
  {
    orderId: '5346546576',
    uploadTime: '2019-10-10 20:20:20',
    checkTime: '2019-10-10 20:20:20',
    fileType: '.dcm',
    type: 'CT',
    position: '胸部',
    status: false,
  },
  {
    orderId: '5346546574',
    uploadTime: '2019-10-11 20:20:20',
    checkTime: '2019-10-12 20:20:20',
    fileType: '.dci',
    type: 'CTI',
    position: '腰部',
    status: false,
  },
] as IOrder[];
const statusConst = [{ id: 0, name: '未使用' }, { id: 1, name: '已使用' }];

const { Option } = Select;
class ProjectTasks extends React.PureComponent<IProps, IState> {
  public state = {
    newModal: false,
    visible: false,
    visibleDownload: false,
    pagination: { total: 0, pageSize: 10, current: 1 },
    isLoading: true,
    list: [],
    originalList: [],
    originalPagination: { total: 0, pageSize: 10, current: 1 },
    orderList: OrderData,
    new: false,
    username: '',
    orderPagination: { total: 0, pageSize: 10, current: 1 },
    selectedRowKeys: [],
    partsDiction: [],
    members: [],
    idForSeq: 0,
    pathList: [],
    userItems: [],
  } as IState;
  public columns = [
    {
      title: '任务名称',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: ITaskList) => (
        <div className="lengthControl taskname" title={record.name}>
          {record.name}
        </div>
      ),
    },
    {
      title: '任务说明',
      dataIndex: 'description',
      key: 'description',
      render: (text: string, record: ITaskList) => (
        <div className="lengthControl description" title={record.description}>
          {record.description}
        </div>
      ),
    },
    {
      title: '所属成员',
      dataIndex: 'memberName',
      key: 'memberName',
    },
    {
      title: '任务样本数量',
      dataIndex: 'originalTotal',
      key: 'originalTotal',
    },
    {
      title: '已提交序列数',
      dataIndex: 'completedOriginalTotal',
      key: 'completedOriginalTotal',
    },
    {
      title: '操作',
      dataIndex: 'status',
      key: 'actions',
      render: (text: string, record: ITaskList) => (
        <a title="序列列表" onClick={() => this.showSeqList(record.id)}>
          序列列表
        </a>
      ),
    },
  ];
  public orderColumns = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUid',
      key: 'seriesInstanceUid',
    },
    {
      title: '上传时间',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IOriginalList) => {
        const partName = new Map();
        this.state.partsDiction.forEach(item => {
          partName.set(item.value, item.name);
        });
        return <div> {partName.get(record.bodyPart)} </div>;
      },
    },
    {
      title: '使用状态',
      dataIndex: 'statusStr',
      key: 'statusStr',
    },
  ];
  public statusOption = statusConst.map(item => (
    <Option key={item.id} value={item.id}>
      {item.name}
    </Option>
  ));
  public componentDidMount() {
    // tslint:disable-next-line:no-any
    const UnloadConfirm: { MSG_UNLOAD: string; set: any } = { MSG_UNLOAD: '', set: '' };
    // tslint:disable-next-line:only-arrow-functions
    // tslint:disable-next-line:no-any
    UnloadConfirm.set = (a: any) => {
      // tslint:disable-next-line:no-any
      window.onbeforeunload = (b: any) => {
        b = b || window.event;
        b.returnValue = a;
        return '';
      };
    };
    UnloadConfirm.set(UnloadConfirm.MSG_UNLOAD);
    this.getPartsDiction();
    this.getMembersOptions();
    this.getProjectMembers({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
    this.getDownLoadPath();
    this.setState({ list: ListData, pagination: { total: 0, pageSize: 10, current: 1 } });
    this.getTasks({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
  }
  /**
   *  查询表单提交
   */
  public handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      message.info(values);
    });
  };
  public onSelectChange = (selectedRowKeys: number[] | string[]) => {
    const { form } = this.props;
    form.setFieldsValue({ taskList: selectedRowKeys.join() });
    this.setState({ selectedRowKeys });
  };
  /* 确定提交事件 */
  public handleSearchForOrder = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields(['uploadTime', 'userId', 'status'], (err, values) => {
      if (values.uploadTime) {
        if (values.uploadTime[0] && values.uploadTime[1]) {
          const startTime = values.uploadTime[0].format('X');
          const endTime = values.uploadTime[1].format('X');
          this.getOriginal({
            pageSize: 10,
            pageNum: 1,
            projectId: Number(this.props.match.params.pId),
            type: 2,
            userId: values.userId,
            startTime,
            endTime,
            status: values.status,
          });
        } else {
          this.getOriginal({
            pageSize: 10,
            pageNum: 1,
            projectId: Number(this.props.match.params.pId),
            type: 2,
            userId: values.userId,
            status: values.status,
          });
        }
      } else {
        this.getOriginal({
          pageSize: 10,
          pageNum: 1,
          projectId: Number(this.props.match.params.pId),
          type: 2,
          userId: values.userId,
          status: values.status,
        });
      }
    });
  };
  public renderFormHeader = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const userItemsOptions = this.state.userItems.map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
    return (
      <Form layout="inline" className="table-header">
        <Row style={{ width: '100%', display: 'flex' }}>
          <Col md={8} sm={24} style={{ display: 'flex', alignItems: 'center', width: '350' }}>
            <Form.Item label={<span style={{ marginLeft: 4 }}>上传时间</span>}>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('uploadTime')(<RangePicker locale={locale} style={{ width: '250px' }} />)}
            </Form.Item>
          </Col>
          <Col md={7} sm={24} style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
            <Form.Item id="userId" label={<span style={{ marginLeft: 4 }}>用户名</span>}>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('userId')(
                <Select
                  className="original-input"
                  placeholder="选择用户名"
                  allowClear={true}
                  style={{ width: '200px' }}
                >
                  {userItemsOptions}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={7} sm={24} style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
            <Form.Item label={<span style={{ marginLeft: 4 }}>使用状态</span>}>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('status')(
                <Select className="ipt" style={{ width: '200px' }}>
                  {this.statusOption}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={2} sm={24} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              // htmlType="submit"
              className="normal-search-button"
              onClick={this.handleSearchForOrder}
              style={{ width: '96px' }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  public render() {
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const option = this.state.members.map(item => (
      <Option key={item.id} value={item.id}>
        {item.loginname}
      </Option>
    ));
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <IndexLayout>
        <div id="ProjectTasks">
          <div className="standard-p main">
            <div className="top">
              <a className="return-btn" onClick={this.gobackWithOption}>
                <Icon type="arrow-left" />
                <span>&nbsp;返回项目列表</span>
              </a>
              <Breadcrumb className="Breadcrumb">
                <Breadcrumb.Item href="javascript:void(0)" onClick={this.gobackWithOption}>
                  <Icon type="environment" />
                  <span>项目列表</span>
                </Breadcrumb.Item>
                <AuthComponent isShow={!this.state.visible}>
                  <Breadcrumb.Item>任务管理</Breadcrumb.Item>
                </AuthComponent>
                <AuthComponent isShow={!!this.state.visible}>
                  <Breadcrumb.Item>任务管理-创建任务</Breadcrumb.Item>
                </AuthComponent>
              </Breadcrumb>
              <AuthComponent isShow={!!this.state.visible}>
                <div className="title">
                  <span className="title-content">&nbsp;任务的基本信息</span>
                  <Button className="new-fin" type="primary" onClick={this.newTask}>
                    完成
                  </Button>
                </div>
                <Row className="newLine">
                  <Form layout="inline">
                    <Form.Item label={<span style={{ marginLeft: 4 }}>任务名称</span>}>
                      {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                      {getFieldDecorator('name', {
                        /* tslint:disable-next-line:jsx-no-multiline-js */
                        rules: [{ required: true, message: '请填写任务名称' }],
                      })(<Input className="underline" style={{ width: '400px' }} />)}
                    </Form.Item>
                  </Form>
                </Row>
                <Row className="newLine">
                  <Form layout="inline">
                    <Form.Item label={<span style={{ marginLeft: 4 }}>任务说明</span>}>
                      {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                      {getFieldDecorator('description')(
                        <TextArea className="textArea" rows={4} style={{ width: '400px' }} />
                      )}
                    </Form.Item>
                  </Form>
                </Row>
                <Row className="newLine">
                  <AuthCmp permission={'USER_PROJECT_MANAGE'} projectId={Number(this.props.match.params.pId)}>
                    <Form.Item label={<span style={{ marginLeft: 4 }}>选择成员</span>}>
                      {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                      {getFieldDecorator('memberId', {
                        /* tslint:disable-next-line:jsx-no-multiline-js */
                        rules: [{ message: '请选择成员', required: false }],
                      })(<Select style={{ width: '400px' }}>{option}</Select>)}
                    </Form.Item>
                  </AuthCmp>
                </Row>
                <Row className="hide">
                  <Form layout="inline">
                    <Form.Item label={<span style={{ marginLeft: 4 }}>序列列表</span>}>
                      {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                      {getFieldDecorator('taskList', {
                        /* tslint:disable-next-line:jsx-no-multiline-js */
                        rules: [{ required: true, message: '请选择序列' }],
                      })(<Input style={{ width: '400px' }} />)}
                    </Form.Item>
                  </Form>
                </Row>
                <div className="seqArea">
                  <span className="title-content">&nbsp;选择序列</span>
                  <div>{this.renderFormHeader()}</div>
                  <Table
                    rowSelection={rowSelection}
                    columns={this.orderColumns}
                    dataSource={this.state.originalList}
                    rowKey="id"
                    pagination={{ ...this.state.pagination }}
                    onChange={this.handleSeqChange}
                  />
                </div>
              </AuthComponent>
            </div>
            <AuthComponent isShow={!this.state.visible}>
              <div>
                <div className="title">
                  <span className="title-content">&nbsp;「{this.props.match.params.name}」项目任务列表</span>
                  <AuthCmp permission={'USER_PROJECT_MANAGE'} projectId={Number(this.props.match.params.pId)}>
                    <Button className="new-task" type="primary" onClick={this.newonShow}>
                      新建任务
                    </Button>
                  </AuthCmp>
                </div>
                <AuthComponent isShow={!!this.state.pagination.total}>
                  <Table
                    columns={this.columns}
                    dataSource={this.state.list}
                    style={{ backgroundColor: 'white' }}
                    pagination={{ ...this.state.pagination }}
                    rowKey="id"
                    onChange={this.handleTableChange}
                  />
                </AuthComponent>
                <NoDataImg isLoading={this.state.isLoading} total={this.state.pagination.total} />
              </div>
            </AuthComponent>
          </div>
        </div>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {this.state.visibleDownload ? (
          <div>
            <SeqSelectList
              taskId={this.state.idForSeq}
              onOk={this.hideSeqList}
              onCancel={this.hideSeqList}
              visible={this.state.visibleDownload}
              partsDiction={this.state.partsDiction}
              pathList={this.state.pathList}
              param={this.props.match.params}
            />
          </div>
        ) : (
          ''
        )}
      </IndexLayout>
    );
  }
  /**
   * 分页处理
   */
  private handleTableChange = (paginations: PaginationConfig) => {
    const { pagination } = this.state;
    pagination.current = paginations.current ? paginations.current : 1;
    this.setState(
      {
        pagination,
      },
      () => {
        this.getTasks({
          pageSize: 10,
          pageNum: this.state.pagination.current,
          researchProjectId: Number(this.props.match.params.pId),
        });
      }
    );
  };
  /**
   *  已共享数据下载
   */

  private getDownLoadPath = async () => {
    const { result } = await DataManagerApi.getdownloadPathList<ITreeData[]>();
    if (result.length > 0) {
      this.setState({ pathList: result });
    } else {
      message.warning('没有路径可以存放');
    }
  };

  private handleSeqChange = (paginations: PaginationConfig) => {
    const { originalPagination } = this.state;
    originalPagination.current = paginations.current ? paginations.current : 1;
    this.setState(
      {
        originalPagination,
      },
      () => {
        this.getOriginal({
          pageSize: 10,
          pageNum: this.state.originalPagination.current,
          projectId: Number(this.props.match.params.pId),
        });
      }
    );
  };
  /**
   * 获取身体部位字典
   */
  private getPartsDiction = async () => {
    const { result } = await DataManagerApi.getPartsInfo<IDictions[]>();
    if (result) {
      this.setState({
        partsDiction: result,
      });
    }
  };
  /**
   *  异步请求
   */
  private getTasks = async (iparam: IParam) => {
    try {
      const { result } = await ProjectTasksApi.getTaskList<ITasksResult, IParam>(iparam);
      this.setState({
        list: result.records,
        pagination: { current: result.current, total: result.total, pageSize: result.size },
      });
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };

  /**
   *  获取原始数据
   */
  private getOriginal = async (original: IOriginal) => {
    try {
      const { result } = await ProjectTasksApi.getOriginalData<IOriginalResult, IOriginal>(original);
      this.setState({
        originalList: result.records,
        originalPagination: { current: result.current, total: result.total, pageSize: result.size },
      });
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };

  /*  打开权限列表模态框 */
  private newonShow = () => {
    this.getOriginal({ pageSize: 10, pageNum: 1, projectId: Number(this.props.match.params.pId), type: 2 });
    this.setState({ visible: true });
  };
  /* 新建任务 */
  private newTask = () => {
    this.props.form.validateFields(['name', 'description', 'taskList'], (err, values) => {
      if (!err) {
        const memberId = this.props.form.getFieldValue('memberId');
        const taskList = values.taskList.split(',').map((item: number) => item - 0);
        delete values.taskList;
        const param = {
          ...values,
          originalDataIds: taskList,
          memberId,
          researchProjectId: Number(this.props.match.params.pId),
        };
        this.newTaskAsync(param);
      } else {
        if (!!err.taskList) {
          message.error('请选择序列');
        }
      }
    });
  };
  /* 新建任务清求 */
  private newTaskAsync = async (param: ICreateDTO) => {
    try {
      await ProjectTasksApi.taskCreate<{}, ICreateDTO>(param);
      this.setState({ visible: false }, () => {
        this.getTasks({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
      });
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };
  /**
   *  获取成员列表
   */
  private getProjectMembers = async (iparam: IPartParam) => {
    try {
      const { result } = await ProjectMembersApi.getProjectUserList<IProjectUserListResult, IPartParam>(iparam);
      this.setState({
        members: result.records,
      });
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };

  /* 显示序列 */
  private showSeqList = (id: number) => {
    this.setState({ idForSeq: id }, () => {
      this.setState({ visibleDownload: true });
    });
  };
  /* 关闭 */
  private hideSeqList = () => {
    this.setState({ visibleDownload: false });
    this.getTasks({ pageSize: 10, pageNum: 1, researchProjectId: Number(this.props.match.params.pId) });
  };
  /**
   * 点击返回 按钮
   */
  private gobackWithOption = () => {
    this.props.history.goBack();
  };
  /**
   *  获取用户列表
   */

  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({
        userItems: [...result],
      });
    }
  };
}
const ProjectTasksList = Form.create()(ProjectTasks);
export default ProjectTasksList;
