interface IConfig {
  [key: string]: string;
}
const config = {
  default: {
    SENTRY_DSN: 'http://e4277e4433e84c52ab021de0801c49c4@sentry.cloudmvd.com/5',
    SENTRY_ENVIRONMENT: process.env.REACT_APP_STAGE,
  },
  dev: {
    REACT_APP_DOMAIN: 'http://service-bajie.cloudmvd.com/api/web',
    SENTRY_DSN: '',
  },
  wukong: {
    REACT_APP_DOMAIN: 'http://service-wukong.cloudmvd.com/api/web',
  },
  bajie: {
    REACT_APP_DOMAIN: 'http://service-bajie.cloudmvd.com/api/web',
  },
  staging: {
    REACT_APP_DOMAIN: 'http://service-staging.cloudmvd.com/api/web',
  },
  prod: {
    REACT_APP_DOMAIN: 'http://service.cloudmvd.com/api/web',
  },
} as { [key: string]: IConfig };

let activeConfig: IConfig = {};
if (process.env.REACT_APP_STAGE) {
  activeConfig = { ...config.default, ...config[process.env.REACT_APP_STAGE] };
}
export default activeConfig;
