import axios from './RequestApi';

export default class DataSearchApi {
  /*
   * 获取数据列表
   */
  public static getPackages<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getPackagePageByES', data);
  }
  /*
   * 获取数据类型分类
   */
  public static getDataCategorys<T>() {
    return axios.post<T, {}>('/common/getDataCategoryList');
  }
  /*
   * 获取数据详情
   */
  public static getMallInfos<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getMallInfo', data);
  }
}
