import { Button, DatePicker, Form, Input, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { PaginationConfig, TableRowSelection } from 'antd/lib/table';
import { Moment } from 'moment';
import React from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import DataManagerApi from '../../../api/DataManager';
import ProxAiManageApi from '../../../api/ProxAiManageApi';
import SciManageApi from '../../../api/SciManageApi';
import AuthCmp from '../../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import { IDictions, INormalList } from '../../DataManager/CodeType';
import { IMemeberItem, IPTaskSaveReq, ISicOriginaldReq } from '../../ScientificManage/CodeType';
import { IOptionUser } from '../../ScientificManage/SciPackageComponet/CodeType';
import { ICreateSciTaskState, initalCreatSicTaskState, ISciTaskSeqItem } from './CodeType';
import './CreateSciTask.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
interface ICreateSciTaskPageProps extends FormComponentProps, RouteComponentProps<{ pId: string }> {}
class CreateSciTaskPage extends React.Component<ICreateSciTaskPageProps, ICreateSciTaskState> {
  public projectColumns = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUid',
      key: 'seriesInstanceUid',
      render: (text: string, record: ISciTaskSeqItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '上传时间',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: ISciTaskSeqItem) => {
        return <div className="assigned-name"> {this.state.partDics.get(text)} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: ISciTaskSeqItem) => {
        return <div className="assigned-name"> {this.state.partDics.get(text)} </div>;
      },
    },
  ];
  public state = initalCreatSicTaskState as ICreateSciTaskState;
  public componentDidMount() {
    this.getPartsDiction();
    this.getAvailableSeqs();

    this.getMembersOptions();

    window.onbeforeunload = () => {
      this.setState({ isBack: true });
      return false;
    };
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    const { members, userItems } = this.state;
    // 已选序列按钮点击
    const rowSelection: TableRowSelection<ISciTaskSeqItem> = {
      // 待选序列类表
      selectedRowKeys: this.state.selectedSeqs,
      onChange: this.selectedSeqsChange,
    };
    const OptionsMembers = members
      ? members.map(item => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })
      : null;

    const userItemsOptions = userItems
      ? userItems.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      : null;

    return (
      <div className="sci-task-container">
        <IndexLayout>
          <Prompt when={!this.state.isBack} message={location => '您的信息尚未填写完成，确定离开吗？'} />
          <Form>
            <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}
              >
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.gobackWithOption}>
                  <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                  <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回鉴影管理</span>
                </div>

                <div style={{ color: 'rgba(0,0,0,0.45)' }}>
                  <IconFont type={'medataicon-icon_location'} className="located-icon" />
                  <span onClick={() => this.props.history.go(-3)} style={{ marginLeft: '10px', marginRight: '5px' }}>
                    项目列表
                  </span>
                  /
                  <span style={{ marginLeft: '5px' }} onClick={() => this.props.history.go(-2)}>
                    应用工具
                  </span>
                  /
                  <span style={{ marginLeft: '5px' }} onClick={() => this.props.history.go(-1)}>
                    鉴影管理
                  </span>
                  /<span style={{ marginLeft: '5px' }}>创建鉴影任务</span>
                </div>
              </div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '7px' }}
              >
                <div className="project-message-title">
                  <div className="dot" /> 鉴影任务基本信息
                </div>
                <Button className="complete-btn" onClick={this.handleAddProjectBtnClick}>
                  完成
                </Button>
              </div>
              <div style={{ display: 'flex' }}>
                <div className="project-input-title ">任务名称:</div>
                <Form.Item colon={false} style={{ width: '400px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-task-tag', {
                    rules: [{ required: true, message: '点击输入' }],
                  })(<Input className="project-input" placeholder="点击输入" />)}
                </Form.Item>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }} className="project-discription-area">
                <div className="project-input-title" style={{ alignItems: 'flex-start' }}>
                  任务说明:
                </div>
                <Form.Item colon={false} style={{ width: '400px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-task-discription', {
                    rules: [{ required: true, message: '请输入任务描述' }],
                  })(<TextArea placeholder="点击输入" style={{ minHeight: '100px' }} />)}
                </Form.Item>
              </div>
              <AuthCmp permission={'USER_PROJECT_MANAGE'} projectId={Number(this.props.match.params.pId)}>
                <div style={{ display: 'flex', marginTop: '11px' }}>
                  <div className="project-input-title" style={{ display: 'inline-block', paddingTop: '9px' }}>
                    选择成员:
                  </div>
                  <Form.Item colon={false} style={{ width: '300px' }}>
                    {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                    {getFieldDecorator('project-member', {
                      rules: [{ required: false, message: '点击选择' }],
                    })(
                      <Select className="project-input" style={{ width: '300px' }} placeholder="请选择">
                        {OptionsMembers}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </AuthCmp>
              <div style={{ marginTop: '35px' }}>
                <div className="project-message-title">
                  <div className="dot" /> 选择序列
                </div>
              </div>
            </div>
            <TableHeader>
              <Form.Item label="上传时间:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-data-picker-input')(
                  <RangePicker
                    className="project-data-picker-input"
                    placeholder={['开始时间', '结束时间']}
                    format={'YYYY-MM-DD HH:mm'}
                  />
                )}
              </Form.Item>
              <AuthComponent permission="USER_ALL_DATA_READ">
                <Form.Item label="用户名:" style={{ marginLeft: '54px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-user-name')(
                    <Select className="" placeholder="请选择" allowClear={true} style={{ width: '240px' }}>
                      {userItemsOptions}
                    </Select>
                  )}
                </Form.Item>
              </AuthComponent>

              <Button className="normal-search-button" onClick={this.searchClick} style={{ marginLeft: '30px' }}>
                查询
              </Button>
            </TableHeader>
            <AuthComponent isShow={!!this.state.availableSeqs.length}>
              <Table
                columns={this.projectColumns}
                dataSource={this.state.availableSeqs}
                style={{ backgroundColor: 'white' }}
                pagination={{ ...this.state.seqPagination }}
                rowKey="seriesInstanceUid"
                rowSelection={rowSelection}
                onChange={this.handleHasTableChanage}
              />
            </AuthComponent>
            <NoDataImg isLoading={this.state.isLoading} total={this.state.seqPagination.total} />
          </Form>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { seqPagination } = this.state;
    seqPagination.current = pagination.current || 1;
    this.setState({ seqPagination });
  };
  /**
   * 点击完成按钮
   */
  private handleAddProjectBtnClick = () => {
    const { validateFields } = this.props.form;
    validateFields(['project-task-tag', 'project-task-discription', 'project-member'], (err, values) => {
      if (!err) {
        const projectReq: IPTaskSaveReq = {
          name: values['project-task-tag'],
          projectId: Number(this.props.match.params.pId),
          remark: values['project-task-discription'],
          vendorId: values['project-member'],
          seriesInstanceUidList: [...this.state.selectedSeqs],
        } as IPTaskSaveReq;
        this.addProjectTask(projectReq);
      }
    });
  };
  private addProjectTask = async (taskReq: IPTaskSaveReq) => {
    const { result } = await SciManageApi.saveSciPkgTask<string, IPTaskSaveReq>(taskReq);
    if (result) {
      this.setState({ isBack: true });
      this.props.history.goBack();
    }
  };
  /**
   *  条件查询
   */
  private searchClick = () => {
    //
    const { getFieldValue } = this.props.form;
    const time: Moment[] = getFieldValue('project-data-picker-input');
    const startTime = time && time.length > 0 ? time[0].valueOf() : undefined;
    const endTime = time && time.length > 1 ? time[1].valueOf() : undefined;
    this.getAvailableSeqs(startTime, endTime);
  };
  /**
   * 列表选择框
   */
  private selectedSeqsChange = (selectedRowKeys: string[] | number[], selectedRows: ISciTaskSeqItem[]) => {
    this.setState({
      selectedSeqs: selectedRowKeys as number[],
    });
  };

  /**
   * 获取可用序列
   */
  private getAvailableSeqs = async (startTime?: number, endTime?: number) => {
    const { seqPagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const projectId = Number(this.props.match.params.pId);
      const { result } = await SciManageApi.getAppToolsTaskSeqs<INormalList<ISciTaskSeqItem[]>, ISicOriginaldReq>({
        pageNum: 1,
        pageSize: 1000,
        projectId,
        startTime,
        endTime,
        type: 2,
      });
      if (result) {
        seqPagination.pageSize = 10;
        seqPagination.total = result.total;
        this.setState({
          seqPagination,
          availableSeqs: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };

  private getMembers = async () => {
    const { result } = await ProxAiManageApi.getProjectMembers<IMemeberItem[]>();
    if (result) {
      this.setState({
        members: result,
      });
    }
  };
  /**
   * 获取用户列表
   */
  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({
        userItems: [...result],
      });
    }
  };

  /**
   * 获取身体部位字典
   */
  private getPartsDiction = async () => {
    const { result } = await DataManagerApi.getPartsInfo<IDictions[]>();
    const newPartsDiction = new Map();
    if (result) {
      result.forEach(item => {
        newPartsDiction.set(item.value, item.name);
      });
      this.setState({
        partDics: newPartsDiction,
      });
    }
    this.getDevicesDiction();
  };

  /**
   * 获取检查设备列表
   */
  private getDevicesDiction = async () => {
    const { partDics } = this.state;
    const { result } = await DataManagerApi.getDevicesInfo<IDictions[]>();

    if (result) {
      result.forEach(item => {
        partDics.set(item.value, item.name);
      });

      this.setState({
        partDics,
      });
    }
    this.getMembers();
  };

  /**
   * 点击返回 按钮
   */
  private gobackWithOption = () => {
    this.props.history.goBack();
  };
}
const CreateSciTask = Form.create()(CreateSciTaskPage);
export default CreateSciTask;
