import { Button, Form, Icon, message, Modal, Row, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';

import {
  IDictions,
  IDownParam,
  IOriginalList,
  IOriginalResult,
  IPageParam,
  IPagination,
  IParamForSeq,
} from '../CodeType';

import { PaginationConfig } from 'antd/lib/table';
import ProjectTasksApi from '../../../api/ProjectTasksApi';
import FileTree, { ITreeData } from '../../../components/FileTree/FilePathTree';
import './Seq.scss';

interface IPropsForSelect extends FormComponentProps {
  taskId: number;
  partsDiction: IDictions[];
  onCancel: () => void;
  onOk: (arr: string[]) => void;
  visible: boolean;
  pathList: ITreeData[];
  param: IPageParam;
}
interface IStateForSelect {
  loading: boolean;
  selectedRowKeys: number[] | string[];
  pagination: IPagination;
  records: IOriginalList[];
  visible: boolean;
  pathStr: string;
}
export const roleList = ['管理员', '标注人员', '数据分析人员'];
/* 修改权限弹出框组件 */
class SeqSelect extends React.PureComponent<IPropsForSelect, IStateForSelect> {
  public state = {
    loading: false,
    selectedRowKeys: [],
    records: [],
    pagination: { total: 0, pageSize: 10, current: 1 },
    visible: false,
    pathStr: '',
  } as IStateForSelect;
  public columns = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUid',
      key: 'seriesInstanceUid',
      render: (text: string, record: IOriginalList) => (
        <div className="lengthControl seriesInstanceUid" title={record.seriesInstanceUid}>
          {record.seriesInstanceUid}
        </div>
      ),
    },
    {
      title: '检查号',
      dataIndex: 'studyInstanceUid',
      key: 'studyInstanceUid',
      render: (text: string, record: IOriginalList) => (
        <div className="lengthControl studyInstanceUid" title={record.studyInstanceUid}>
          {record.studyInstanceUid}
        </div>
      ),
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IOriginalList) => {
        const partName = new Map();
        this.props.partsDiction.forEach(item => {
          partName.set(item.value, item.name);
        });
        return <div> {partName.get(record.bodyPart)} </div>;
      },
    },
    {
      title: '状态',
      dataIndex: 'statusStr',
      key: 'statusStr',
      render: (text: string, record: IOriginalList) =>
        record.statusStr === '已完成' ? (
          <span className="open">{record.statusStr}</span>
        ) : (
          <span className="close">{record.statusStr}</span>
        ),
    },
  ];
  public componentDidMount() {
    /* this.setState({ list: ListData, pagination: { total: 1, pageSize: 10, current: 1 } }); */
    if (this.props.taskId !== 0) {
      this.getSeqs({ pageSize: 10, pageNum: 1, researchProjectTaskId: this.props.taskId });
    }
  }
  /* 确定提交事件 */
  public handleSubmit = (e: React.SyntheticEvent) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ selectedRowKeys: [] });
        this.props.onOk(values); // 调用父组件给的onOk方法并传入Form的参数。
      } else {
        if (!values.memList) {
          message.error('请选择用户');
        }
      }
    });
  };
  /* Cancel关闭modal框事件 */
  public onCancel = () => {
    this.setState({ selectedRowKeys: [] });
    this.props.form.resetFields(); // 重置Form表单的内容
    this.props.onCancel(); // 调用父组件给的方法
  };

  public onSelectChange = (selectedRowKeys: number[] | string[]) => {
    this.setState({ selectedRowKeys });
  };

  public render = () => {
    const { selectedRowKeys } = this.state;
    const { getFieldDecorator } = this.props.form;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15, offset: 1 },
      },
    };
    return (
      <Modal
        onOk={this.handleSubmit}
        onCancel={this.onCancel}
        visible={this.props.visible}
        okText="完成"
        cancelText="取消"
        title={!this.state.visible ? <h4 className="memsel-title">「{this.props.param.name}」的序列列表</h4> : ''}
        className="memSel"
        destroyOnClose={true}
        maskClosable={false}
        width={'70%'}
        // tslint:disable-next-line:jsx-no-multiline-js
        footer={
          !this.state.visible
            ? [
                // tslint:disable-next-line:jsx-key
                <div key="step1">
                  <Button key="download" type="primary" className="whiteBg" onClick={this.downLoad}>
                    下载
                  </Button>

                  <Button key="submit" type="primary" className="whiteBg" onClick={this.toFinish}>
                    已完成序列
                  </Button>

                  <Button key="cancel" onClick={this.onCancel}>
                    取消
                  </Button>
                </div>,
              ]
            : [
                // tslint:disable-next-line:jsx-key
                <div key="step2">
                  <Button key="submitFordl" type="primary" onClick={this.downLoadEvent}>
                    确定
                  </Button>
                  <Button key="mainReturn" type="primary" className="whiteBg" onClick={this.mainReturn}>
                    上一步
                  </Button>
                </div>,
              ]
        }
      >
        <div className="secondStep">
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {!this.state.visible ? (
            <div>
              <span className="seqhead">请先勾选序列，您可以对已勾选的序列进行下载/标记已完成。</span>
              <Table
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={this.state.records}
                rowKey="originalDataId"
                pagination={{ ...this.state.pagination }}
                onChange={this.handleTableChange}
              />
            </div>
          ) : (
            <Row>
              <a className="return-btn" onClick={this.mainReturn}>
                <Icon type="arrow-left" />
                <span>&nbsp;返回序列列表</span>
              </a>
              <div className="pathForPJ center downMain">
                <Form {...formItemLayout}>
                  <h4>
                    您已选择<span className="seqNum">{this.state.selectedRowKeys.length}</span>个序列
                  </h4>

                  <Form.Item className="textAreaForm" label={'文件存放目录'}>
                    {// tslint:disable-next-line:jsx-no-multiline-js
                    getFieldDecorator('pathList')(
                      <FileTree
                        treeData={this.props.pathList}
                        interValue={this.state.pathStr}
                        onSelect={this.fileTreeOnSelect}
                      />
                    )}
                  </Form.Item>
                  <div className="explain">
                    <dd>
                      <span>注意： 数据下载过程中，请不要拔掉移动硬盘，以免中断下载。</span>
                    </dd>
                  </div>
                </Form>
              </div>
            </Row>
          )}
        </div>
      </Modal>
    );
  };
  /**
   * 下载
   */
  public down = async (param: IDownParam) => {
    await ProjectTasksApi.seqDown<{}, IDownParam>(param);
    message.success('下载完成');
    this.setState({ visible: false });
  };
  public downLoadEvent = () => {
    const ids = [...this.state.selectedRowKeys].map(item => Number(item));
    if (ids.length > 0) {
      if (this.state.pathStr) {
        this.down({ ids, projectName: this.props.param.name || '', targetPath: this.state.pathStr });
      } else {
        message.error('请选择路径');
      }
    } else {
      message.error('请选择序列');
    }
  };
  /**
   * 获取路径
   */
  public fileTreeOnSelect = (filePath: string) => {
    this.setState({ pathStr: filePath });
  };
  /**
   * 分页处理
   */
  public handleTableChange = (paginations: PaginationConfig) => {
    const { pagination } = this.state;
    pagination.current = paginations.current ? paginations.current : 1;
    this.setState(
      {
        pagination,
      },
      () => {
        this.getSeqs({
          pageNum: this.state.pagination.current,
          pageSize: 10,
          researchProjectTaskId: this.props.taskId,
        });
      }
    );
  };
  /**
   *  完成状态设置点击事件
   */
  private toFinish = () => {
    this.toFin({
      originalDataIds: this.state.selectedRowKeys,
      researchProjectTaskId: this.props.taskId,
      status: 1,
    });
  };
  /**
   *  完成状态设置
   */
  private toFin = async (param: {
    originalDataIds: number[] | string[];
    researchProjectTaskId: number;
    status: number;
  }) => {
    try {
      await ProjectTasksApi.seqToFin<
        {},
        { originalDataIds: number[] | string[]; researchProjectTaskId: number; status: number }
      >(param);
      message.info('设置成功');
      this.getSeqs({ pageSize: 10, pageNum: 1, researchProjectTaskId: this.props.taskId });
    } catch (err) {
      this.setState({
        loading: true,
      });
    }
  };
  /**
   *  下载跳转
   */
  private downLoad = () => {
    this.setState({ visible: true });
  };
  /**
   *  返回按钮
   */
  private mainReturn = () => {
    this.setState({ visible: false });
  };
  /**
   *  异步请求
   */
  private getSeqs = async (iparam: IParamForSeq) => {
    try {
      const { result } = await ProjectTasksApi.gettaskOriginalData<IOriginalResult, IParamForSeq>(iparam);
      this.setState({
        records: result.records,
        pagination: { current: result.current, total: result.total, pageSize: result.size },
      });
    } catch (err) {
      this.setState({
        loading: true,
      });
    }
  };
}
export const SeqSelectList = Form.create()(SeqSelect);
