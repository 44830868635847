import { Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { ITreeData } from '../../../components/FileTree/FilePathTree';
import { IGrant, IPagination } from '../../DataManager/CodeType';
const { Option } = Select;
import React from 'react';
export const SequencesColumsNoDetail = [
  {
    title: '文件编号',
    dataIndex: 'fileCode',
    key: 'fileCode',
    columnWidth: '60px',
    width: '100px',
  },
  {
    title: '文件名',
    dataIndex: 'fileName',
    key: 'fileName',
    width: '100px',
  },
  {
    title: '病例编号',
    dataIndex: 'patientCode',
    key: 'patientCode',
    width: '100px',
  },
  {
    title: '病例名称',
    dataIndex: 'disease',
    key: 'disease',
    width: '100px',
  },
];
export interface IPackageSeqs {
  disease: string;
  fileCode: string;
  fileName: string;
  patientCode: string;
  descJson: string;
}

export interface IPkgItemsReq {
  pageNum: number;
  pageSize: number;
  projectId: number;
  type?: number;
}
export interface IPkgItem {
  amount: number;
  dataFormat: string;
  disease: string;
  jsonDesc: string;
  packageId: number;
  projectName: string;
  researchProjectId: number;
  status: number;
  statusStr: string;
  type: string;
}

export interface ISciPkgProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  projectId: number;

  goSecurityBox: () => void;
}

export interface ISciPkgState {
  // tslint:disable-next-line:no-any
  item: any;
  shareSeqPagination: IPagination;
  pkgPagination: IPagination;
  pkgItems: IPkgItem[]; // 数据包list
  grants: IGrant[];
  authModal: boolean;
  pathModal: boolean;
  pathList: ITreeData[];
  code: string;
  detailVisible: boolean;
  detailString: string;
  clickItem?: IPkgItem;
  clickItemSeqs: IPackageSeqs[];
  packageEditVisible: boolean;
  packageSelectedStatus: number;
  diseaseItems: string[];
  userItems: IOptionUser[];
  packageDrawerVisible: boolean;
  isLoading: boolean; // 是否显示加载loading
  loadModalVisable: boolean;
}

export interface IOptionUser {
  id: number;
  name: string;
}
export enum ISciPkgRowAction {
  pkgDetail, // 显示数据详情
  download, // 下载数据包
  loadpackage, //  挂在数据,
}

export const InitState = {
  item: {},
  pkgItems: [],
  pkgPagination: { total: 0, pageSize: 7, current: 1 },
  shareSeqPagination: { total: 0, pageSize: 10, current: 1 },
  grants: [],
  authModal: false,
  pathList: [],
  pathModal: false,
  code: '',
  clickItemSeqs: [],
  packageEditVisible: false,
  packageSelectedStatus: 1,
  diseaseItems: [],
  packageDrawerVisible: false,
  isLoading: true,
  userItems: [],
  detailVisible: false,
  detailString: '',
  loadModalVisable: false,
};

export const TAB_ROW_ATIONS = [
  { title: '数据详情', type: ISciPkgRowAction.pkgDetail },
  { title: '下载', type: ISciPkgRowAction.download },
  { title: '挂载数据', type: ISciPkgRowAction.loadpackage },
];

export const PKG_DATA_TYPE_OPTION = ['基因', '影像'].map((item, index) => {
  return (
    <Option value={index + 1} key={index}>
      {item}
    </Option>
  );
});
