import React, { SyntheticEvent } from 'react';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import { IconFont } from '../../../../config/IconConfig';
import { IProps, IState } from './CodeType';
import './ToolCard.scss';
/**
 * @param { icon } 图标
 * @param { title } 标题
 * @param { description } 内容
 * @param { onClick } 点击事件
 * @param { isDisable } 是否可以点击
 */
class ToolCard extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      mouseOn: false,
    };
  }
  public render() {
    const { icon, title, description } = this.props;
    return (
      <div
        onClick={this.isOnClick}
        className={this.props.isDisable ? 'tool-card-container' : 'isDisable tool-card-container'}
      >
        <div className="tool-card-header" style={{ cursor: 'pointer' }}>
          <IconFont type={icon} className="tool-card-icon" style={{ fontSize: '31px' }} />
          <div className="card-title">{title}</div>

          <AuthComponent permission="USER_JY_MANAGE">
            <div style={{ flex: 1, textAlign: 'right' }}>
              <span
                className="card-action"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={e => {
                  this.actionClick(e);
                }}
              >
                鉴影管理
              </span>
            </div>
          </AuthComponent>

          <AuthComponent isShow={!this.props.isDisable}>
            <div className="card-disable">暂时无法使用</div>
          </AuthComponent>
        </div>
        <div>
          <div className="card-discription">{description} </div>
        </div>
      </div>
    );
  }

  /**
   * 如果 isDisable 为 true  则可以点击
   */
  private isOnClick = () => {
    if (this.props.isDisable) {
      this.props.onClick();
    }
  };
  /**
   * 按钮的点击事件
   */
  private actionClick = (e: SyntheticEvent) => {
    this.props.action();
    e.stopPropagation();
  };
}

export default ToolCard;
