import { Button, Divider, Modal, Radio, Select, Tooltip } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';

import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import DataManagerApi from '../../../../api/DataManager';
import SciManageApi from '../../../../api/SciManageApi';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import { IconFont } from '../../../../config/IconConfig';
// import { ILoadDataSaveReq } from '../../../DataManager/CodeType';
import FormItem, { IItemType } from '../../../DataManager/Component/FormItem/FormItem';
import { ITempItem } from '../CodeType';
// import { guid } from '../../../DataManager/DataMSetting';
import {
  dataFormatList,
  geneticFormTests,
  genomeList,
  IFormItemState,
  imageFromTests,
  relevantAreaList,
  sampleRangeList,
  sampleTypeList,
  techPlatformList,
  typeList,
} from './CodeType';
import './PkgFormCmp.scss';

const { Option } = Select;
interface ILoadComonentProps {
  visiable: boolean;
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  clickItem?: ITempItem;
  showGuide?: () => void;
  submit: (id: number) => void;
  closeClick: () => void;
  projectId: number;
}

class PkgFormCmp extends React.PureComponent<ILoadComonentProps, IFormItemState> {
  public timer?: NodeJS.Timer;
  constructor(props: ILoadComonentProps) {
    super(props);
    this.state = {
      packageType: 1,
      steps: 0,
      isShare: 'SHARE',
      shareType: 1,
      dataTypeForExel: 1,
      visiable: false,
      usbAvailable: false,
    };
  }
  public componentDidMount() {
    this.getUsbStatus();
  }
  public componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
  public render() {
    const packFooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="theme-primary-btn" onClick={this.completeSubmit}>
          完成
        </Button>
        <Button className="theme-normal-btn" onClick={this.props.closeClick} style={{ marginLeft: '31px' }}>
          取消
        </Button>
      </div>
    );
    const headerView = (
      <div style={{ flex: 1, display: 'flex' }} className="tit">
        <div style={{ flex: 1 }} />
        <div style={{ flex: 1, textAlign: 'center' }}>{'项目数据包信息'}</div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div className="yp" style={{ display: 'flex', alignItems: 'center' }}>
            <AuthComponent isShow={!this.state.usbAvailable}>
              <div className="ti_b">硬盘未接入</div>
            </AuthComponent>
            <IconFont
              className={this.state.usbAvailable ? 'headerIcon' : ''}
              type="medataicon-yidongyingpan-"
              style={{ fontSize: '20px' }}
            />
            {/* <Divider type="vertical" style={{ marginLeft: '10px', marginRight: '10px' }} /> */}
          </div>

          <IconFont
            onClick={this.showGuide}
            type="medataicon-asmkticon0247"
            style={{ fontSize: '24px', color: '#00a1e6', display: 'none' }}
            className="help"
          />
        </div>
      </div>
    );
    return (
      <Modal
        visible={this.props.visiable}
        className="pkg-form-modal-component"
        width="880px"
        centered={true}
        cancelText="确认"
        okText="取消"
        title={headerView}
        closable={false}
        onCancel={this.cancelBtnClick}
        footer={packFooter}
        maskClosable={false}
      >
        {this.shareFormView()}
      </Modal>
    );
  }

  /**
   * 判断是否有数据上传权限
   */

  /**
   * 分享 view
   */
  public shareFormView = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div style={{ marginTop: '24px' }}>
          {this.inputFromItem('项目名称', true, '输入项目名称', 'project_name', '', '提供一句话或短语，阐述项目内容')}
        </div>

        <div style={{ display: 'flex', flex: 1, marginTop: '16px' }}>
          <FormItem
            title={'项目说明'}
            itemType={IItemType.TextArea}
            required={true}
            message={'请输入项目说明'}
            fieldId={'project_overview'}
            initialValue={''}
            form={this.props.form}
            hintMessage={'提供一段说明文字，阐述项目的研究目标及相关信息'}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, marginTop: '16px', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.64)' }} className="project-item-title">
              数据类型
            </div>
            <div style={{ fontWeight: 'bold', marginLeft: '5px', marginRight: '5px' }}>:</div>
          </div>
          <Form.Item style={{ paddingLeft: '16px' }}>
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('project_data_type', {
              rules: [{ required: true, message: '请输入数据类型' }],
              initialValue: 1,
            })(
              <Radio.Group onChange={this.packageGroupChange}>
                <Radio value={1} style={{ color: 'rgba(0,0,0,0.64)' }}>
                  基因
                </Radio>
                <Divider type="vertical" style={{ marginLeft: '8px' }} />
                <Radio value={2} style={{ marginLeft: '16px', color: 'rgba(0,0,0,0.64)' }}>
                  影像
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </div>
        {this.state.packageType === 1 ? this.geneticFromView() : this.imageFormView()}
      </div>
    );
  };
  /**
   * 基因
   */
  public geneticFromView = () => {
    return (
      <div>
        <div className="genetic-form-view">
          <span style={{ height: '1px', width: '110px', backgroundColor: 'rgba(214,214,214,0.64)' }} />
          <span className="">
            <span className="top">
              <i className="top-arrow1" />
              <i className="top-arrow2" />
            </span>
          </span>
          <span style={{ height: '1px', flex: 1, backgroundColor: 'rgba(214,214,214,0.64)' }} />
        </div>
        <div style={{ display: 'flex', marginTop: '12px', flexFlow: 'row wrap' }} className="form-content">
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '相关领域',
            true,
            '请选择相关领域',
            'project_relevant_area',
            this.optionCreat(relevantAreaList),
            '填写该项目隶属哪个领域'
          )}
          {this.autoCompleteFromItem('项目类别', true, '请选择项目类别', 'project_type', '填写该项目的类别', typeList)}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '样品范围',
            true,
            '请选择样品范围',
            'project_sample_range',
            this.optionCreat(sampleRangeList),
            '填写项目样本的来源'
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.selectedFromItem(
            '样本类型',
            true,
            '请选择样本类型',
            'project_sample_type',
            this.optionCreat(sampleTypeList),
            '填写项目样本的类型'
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '数据格式',
            true,
            '请选择数据格式',
            'project_data_format',
            '填写项目样本数据的存储格式',
            dataFormatList
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '技术平台',
            true,
            '请选择技术平台',
            'project_tech_platform',
            '填写项目样本数据生成的技术平台',
            techPlatformList
          )}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.autoCompleteFromItem(
            '参考基因组',
            true,
            '请选择参考基因组',
            'project_genome',
            '填写项目研究所用的参考基因组版本号',
            genomeList
          )}
          {this.inputFromItem('物种', true, '请输入物种', 'project_organism', '', '填写该项目研究对象的种属关系')}
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {this.inputFromItem(
            '疾病类型',
            true,
            '请输入疾病类型',
            'project_disease_type',
            '',
            ' 填写项目研究的目标表型'
          )}
        </div>
      </div>
    );
  };
  /**
   * 影像
   */
  public imageFormView = () => {
    return (
      <div>
        <div className="genetic-form-view">
          <span style={{ height: '1px', width: '110px', backgroundColor: 'rgba(214,214,214,0.64)' }} />
          <span className="">
            <b className="top-image">
              <i className="top-arrow1" />
              <i className="top-arrow2" />
            </b>
          </span>
          <span style={{ height: '1px', flex: 1, backgroundColor: 'rgba(214,214,214,0.64)' }} />
        </div>
        <div style={{ display: 'flex', marginTop: '0px', flexDirection: 'column' }}>
          {this.inputFromItem('疾病名称', true, '请输入疾病名称', 'project_disease_type', '', ' 描述疾病名称')}
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.inputFromItem(
            '标注方式',
            true,
            '请输入标注方式',
            'project_label_format',
            '',
            '标注文件的格式信息 如 .nii .xml .csv等'
          )}
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.inputFromItem(
            '数据格式',
            true,
            '请输入数据格式',
            'project_data_format',
            '',
            '数据文件的格式信息 如 .dicom .jpg .png'
          )}
          {// tslint:disable-next-line:jsx-no-multiline-js
          this.inputFromItem(
            '检测手段',
            true,
            '请输入检测手段',
            'project_testing_method',
            '',
            '影像文件的检测方式 如 CT MRI 眼底镜 等'
          )}
        </div>
      </div>
    );
  };

  /**
   * packageGroupChange
   */

  public packageGroupChange = (e: RadioChangeEvent) => {
    this.setState({
      packageType: e.target.value,
    });
  };

  public completeSubmit = () => {
    // 下一步 点击
    const { packageType /* filePath, isShare, excelPath*/ } = this.state;
    const { validateFields } = this.props.form;
    // const path = filePath ? filePath : '';
    const testArr = packageType === 1 ? geneticFormTests : imageFromTests;
    validateFields([...testArr], (err, values) => {
      if (!err) {
        //  表单验证通过  把结果打包成字符串 传出去
        const jsonDesc = String(JSON.stringify(values));
        this.transformTemparyPackage(jsonDesc);
        // const item = {
        //   uploadFilePath: path,
        //   isShare,
        //   jsonDescStr: packJsonStr,
        //   taskType: '1',
        //   taskId: guid(path),
        //   source: 'WEB',
        //   excelFilePath: excelPath,
        // } as ILoadDataSaveReq;
        // console.log(item.taskId);
      }
    });
  };
  /**
   * 取消按钮
   */
  public cancelBtnClick = () => {
    // 重置
    this.setState({
      visiable: false,
    });
  };
  /**
   *  选中框
   */
  public selectedFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    options?: JSX.Element[],
    hintMessage?: string,
    placeholder?: string
  ) => {
    return (
      <FormItem
        title={title}
        required={required}
        message={message}
        fieldId={ckId}
        itemType={IItemType.Select}
        options={options}
        form={this.props.form}
        hintMessage={hintMessage}
        placeholder={placeholder}
      />
    );
  };
  /**
   * 输入框
   */
  public inputFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    initialValue?: string,
    hintMessage?: string
  ) => {
    return (
      <FormItem
        title={title}
        itemType={IItemType.HintInput}
        required={required}
        message={message}
        fieldId={ckId}
        formItemStyle={{ justifyContent: 'flex-start' }}
        initialValue={initialValue}
        form={this.props.form}
        hintMessage={hintMessage}
      />
    );
  };

  /**
   *  选中框
   */
  public autoCompleteFromItem = (
    title: string,
    required: boolean,
    message: string,
    ckId: string,
    hintMessage?: string,
    autoArr?: Array<{ value: string; tip?: string }>
  ) => (
    <FormItem
      title={title}
      required={required}
      message={message}
      fieldId={ckId}
      itemType={IItemType.autoComplete}
      form={this.props.form}
      hintMessage={hintMessage}
      autoArr={autoArr}
    />
  );

  /**
   * 选择是否共享数据状态
   */
  public selectShareState = (isShare: string) => {
    this.setState({ isShare });
  };

  /**
   * select选项生成
   */
  public optionCreat = (param: Array<{ value: string; tip?: string }>) => {
    return param.map(item => (
      <Option key={item.value} value={item.value}>
        {item.tip ? (
          <Tooltip
            placement="rightTop"
            overlayClassName="optTips"
            // tslint:disable-next-line:jsx-no-multiline-js
            title={
              <div className="optips">
                <h4>
                  <IconFont type="medataicon-asmkticon0247" style={{ color: '#00a1e6' }} />
                </h4>
                <Divider />
                {item.tip}
              </div>
            }
          >
            {item.value}
          </Tooltip>
        ) : (
          item.value
        )}
      </Option>
    ));
  };

  /**
   * Excel模版选项生成
   */
  public excelCreat = (param: Array<{ id: number; name: string }>) => {
    return param.map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  };

  /**
   * 显示 指南
   */

  private showGuide = () => {
    if (this.props.showGuide) {
      this.props.showGuide();
    }
  };

  /**
   *  临时数据包转换换
   */

  private transformTemparyPackage = async (jsonDesc: string) => {
    const { clickItem } = this.props;
    const projectId = this.props.projectId;
    const { result } = await SciManageApi.transformTemparyPackage<
      string,
      { id: number; jsonDesc: string; projectId: number }
    >({
      id: clickItem!.id,
      jsonDesc,
      projectId,
    });
    if (result) {
      //
      this.props.submit(clickItem!.id);
    }
  };

  /**
   * 检查usb 状态
   */
  private getUsbStatus = async () => {
    this.timer = setInterval(async () => {
      try {
        const { result } = await DataManagerApi.checkUsbAvailabel();
        this.setState({
          usbAvailable: result,
        });
      } catch (err) {
        this.clearTimer();
      }
    }, 2000);
  };
  /**
   * 清除 计时器
   */
  private clearTimer = () => {
    if (this.timer) {
      this.setState({
        usbAvailable: false,
      });
      clearTimeout(this.timer);
    }
  };
}

export default PkgFormCmp; // 创建form实例
