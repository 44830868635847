import { Button, Modal } from 'antd';
import React from 'react';
import './HintComponent.scss';
interface IHintComponentProps {
  visiable: boolean;
  title: string;
  message: string;
  okClick: () => void;
}

class HintComponent extends React.PureComponent<IHintComponentProps, {}> {
  public render() {
    const footer = (
      // tslint:disable-next-line:jsx-wrap-multiline
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.props.okClick}>
          确定
        </Button>
      </div>
    );
    return (
      <Modal
        visible={this.props.visiable}
        className="hint-component"
        width="480px"
        centered={true}
        title={'提示'}
        closable={false}
        onCancel={this.props.okClick}
        footer={footer}
      >
        {this.props.message}
      </Modal>
    );
  }
}

export default HintComponent; // 创建form实例
