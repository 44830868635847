import React from 'react';
import UserAccount from '../../api/UserAccount';
import Storage, { USERPERMISSION } from '../../util/Storage';
import { useAuthStatus } from './Hooks';
/**
 * 控制子组件是否显示,permission和isShow不能同时传入
 * @param { children } 子组件
 * @param { permission } 当前组件代表的权限key 如果传入为!key的形式则为没有该key是显示类
 * @param { projectId } 项目的ID projectId 未赋值 则是平台权限走平台权限的逻辑
 */
export interface IProps {
  children: React.ReactElement;
  permission?: string;
  projectId?: number;
  permissions?: string[];
}
/**
 *
 * @param 检查 平台权限是否存在
 */
export function authAvailable(permission: string) {
  const permissions: string[] = JSON.parse(Storage.getStore(USERPERMISSION) || '[]');
  return permissions.includes(permission);
}

/**
 *
 * @param props 检测项目权限是否存在
 */
export async function authProjectAvailable(props: { permission: string; projectId: number }) {
  // const storageKey = `PRPJECTPERMISSIONID${props.projectId}`;
  // const localPermission: string[] = (Storage.getStore(storageKey) && JSON.parse(Storage.getStore(storageKey))) || [];
  // if(localPermission.length>0){
  // }else{
  // }
  const { result } = await UserAccount.getProjectAuth<string[], { projectId: number }>({
    projectId: (props.projectId && Number(props.projectId)) || 0,
  });

  return result.includes(props.permission);
}
function AuthCmp(props: IProps) {
  if (props.projectId) {
    const status = useAuthStatus({ projectId: props.projectId || 0, permissions: props.permission! });
    return status ? props.children : null;
  }
  const localPermission: string[] = JSON.parse(Storage.getStore(USERPERMISSION) || '[]');
  if (props.permissions) {
    let auth = false;
    props.permissions.forEach(item => {
      const ret = localPermission.includes(item);
      if (ret) {
        auth = true;
        return;
      }
    });
    return auth ? props.children : null;
  }

  return localPermission.includes(props.permission!) ? props.children : null;
}
export default AuthCmp;
