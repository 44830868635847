import { Button, DatePicker, message, Modal, Select, Table } from 'antd';
import Form from 'antd/lib/form/Form';
import { PaginationConfig, TableRowSelection } from 'antd/lib/table';
import { Moment } from 'moment';
import React from 'react';
import DataManagerApi from '../../../api/DataManager';
import SciManageApi from '../../../api/SciManageApi';
import AuthCmp from '../../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import FileTree, { ITreeData } from '../../../components/FileTree/FilePathTree';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../config/IconConfig';
import { INormalList, IOriginalShareDataItem } from '../../DataManager/CodeType';
import { ISicOriginaldReq } from '../CodeType';
import { IImportOriginalDatasReq, IOriginalSharedProp, IOriginalState } from './CodeType';
import './SciOrignalCmp.scss';
const { RangePicker } = DatePicker;
const { Option } = Select;
export class SciOrignalCmp extends React.PureComponent<IOriginalSharedProp, IOriginalState> {
  public originalShareDataColums = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUid',
      key: 'seriesInstanceUid',
      render: (text: string, record: IOriginalShareDataItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '上传时间',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: '检查号',
      dataIndex: 'studyInstanceUID',
      key: 'studyInstanceUID',
      render: (text: string, record: IOriginalShareDataItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },

    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
      render: (text: string, record: IOriginalShareDataItem) => {
        return <div> {text ? text : '未知'} </div>;
      },
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IOriginalShareDataItem) => {
        const partName = this.props.devicesDiction.get(record.modality);
        return <div> {partName ? partName.name : ''} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IOriginalShareDataItem) => {
        const partName = this.props.partsDiction.get(record.bodyPart);
        return <div> {partName ? partName.name : ''} </div>;
      },
    },
  ];

  constructor(props: IOriginalSharedProp) {
    super(props);
    this.state = {
      originalPagination: { total: 0, pageSize: 10, current: 1 },
      normalList: [],
      isLoading: false,
      dowloadModalVisable: false,
      loadDataVisible: false,
      loadORrigPagination: { total: 0, pageSize: 10, current: 1 },
      loadOriginalDatas: [],
      selectedOriginalItems: [],
      fileTree: [],
      downloadOriginalItems: [],
    };
  }

  public componentWillReceiveProps(nextProps: IOriginalSharedProp) {
    if (nextProps.loadModalVisible !== this.props.loadModalVisible) {
      this.originalLoadDatas();
      this.setState({ loadDataVisible: nextProps.loadModalVisible });
    }
  }
  public componentDidMount() {
    this.getOriginalShareData();
  }
  public render() {
    const footer = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          type="primary"
          className="theme-primary-btn"
          onClick={this.moreModalOkClick}
          style={{ marginRight: '31px' }}
        >
          确认
        </Button>
        <Button className="theme-normal-btn" onClick={this.moreModalCancel}>
          取消
        </Button>
      </div>
    );
    const loadFooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="theme-primary-btn" onClick={this.loadModalOkClick}>
          完成
        </Button>
        <Button className="theme-normal-btn" onClick={this.loadModalCancel} style={{ marginLeft: '31px' }}>
          取消
        </Button>
      </div>
    );
    const rowSelection: TableRowSelection<IOriginalShareDataItem> = {
      // 待选序列类表
      selectedRowKeys: [...this.state.downloadOriginalItems],
      onChange: this.dowloadOriginalChange,
      onSelectAll: this.dowloadOriginalSelAll,
    };
    const userItemsOptions = this.props.userItems.map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="sci-orignal-component">
        <TableHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
              <Form.Item label="上传时间:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('sci-original-rangepciker')(
                  <RangePicker
                    className="project-data-picker-input"
                    placeholder={['开始时间', '结束时间']}
                    format={'YYYY-MM-DD HH:mm'}
                  />
                )}
              </Form.Item>
              <Form.Item label="用户名:" style={{ marginLeft: '40px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('sci_original_user')(
                  <Select className="original-input" placeholder="选择用户名" allowClear={true}>
                    {userItemsOptions}
                  </Select>
                )}
              </Form.Item>
              <Button
                className="normal-search-button"
                onClick={this.handleTableHeaderSearch}
                style={{ marginLeft: '40px', fontWeight: 'bold', marginRight: '60px' }}
              >
                查询
              </Button>
            </div>
            <AuthComponent isShow={!!this.state.originalPagination.total}>
              <div className="dowload-container">
                <IconFont
                  type={'medataicon-icon_download'}
                  style={{ fontSize: '16px', display: 'flex', alignItems: 'center', color: '#00a1e6' }}
                />
                <div
                  onClick={this.dowloadOrignalDatas}
                  style={{ marginLeft: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
                >
                  批量下载
                </div>
              </div>
            </AuthComponent>
          </div>
        </TableHeader>
        <AuthComponent isShow={!!this.state.originalPagination.total}>
          <Table
            columns={this.originalShareDataColums}
            dataSource={this.state.normalList}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.originalPagination }}
            rowSelection={rowSelection}
            rowKey="id"
            onChange={this.handleOriginalTableChanage}
          />
        </AuthComponent>
        <NoDataImg
          isLoading={this.state.isLoading}
          total={this.state.originalPagination.total}
          msg="暂时还没有引入原始数据..."
        />
        <Modal
          visible={this.state.dowloadModalVisable}
          className="dowload-data-modal"
          width="560px"
          centered={true}
          title={'下载原始数据'}
          footer={footer}
          closable={true}
          onCancel={this.moreModalCancel}
        >
          {this.updataModalView()}
        </Modal>
        <AuthComponent isShow={!this.state.originalPagination.total}>
          <AuthCmp projectId={this.props.projectId} permission="USER_PROJECT_MANAGE">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className="theme-normal-btn"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => {
                  this.originalLoadDatas();
                  this.setState({ loadDataVisible: true });
                }}
              >
                引入原始数据
              </Button>
            </div>
          </AuthCmp>
        </AuthComponent>
        <Modal
          visible={this.state.loadDataVisible}
          className="load-data-modal"
          width="1122px"
          centered={true}
          title={'引入原始数据'}
          footer={loadFooter}
          closable={true}
          onCancel={this.loadModalCancel}
        >
          {this.loadDataModleView()}
        </Modal>
      </div>
    );
  }

  /*
   *原始数据列表 网络请求
   */
  public getOriginalShareData = async (origSearchSeqId?: string) => {
    const { originalPagination } = this.state;
    const { getFieldValue } = this.props.form;
    const time: Moment[] = getFieldValue('sci-original-rangepciker');
    const userId = getFieldValue('sci_original_user');
    const startTime = time && time.length > 0 ? time[0].valueOf() : undefined;
    const endTime = time && time.length > 1 ? time[1].valueOf() : undefined;
    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getOriginalShareDataList<
        INormalList<IOriginalShareDataItem[]>,
        ISicOriginaldReq
      >({
        pageNum: 1,
        pageSize: 10000,
        projectId: this.props.projectId,
        type: 2,
        userId,
        startTime,
        endTime,
      });
      if (result) {
        originalPagination.total = result.total;
        const records = result.records;

        this.setState({
          normalList: [...records],
          originalPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /*
   *获取未导入的数据
   */
  private originalLoadDatas = async () => {
    const { loadORrigPagination } = this.state;
    const { getFieldValue } = this.props.form;
    const time: Moment[] = getFieldValue('project_modal_times');
    const userId = getFieldValue('sci_laod_original_user');
    const startTime = time && time.length > 0 ? time[0].valueOf() : undefined;
    const endTime = time && time.length > 1 ? time[1].valueOf() : undefined;
    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getOriginalShareDataList<
        INormalList<IOriginalShareDataItem[]>,
        ISicOriginaldReq
      >({
        pageNum: 1,
        pageSize: 1000,
        projectId: this.props.projectId,
        type: 1,
        endTime,
        startTime,
        userId,
      });
      if (result) {
        loadORrigPagination.total = result.total;
        const records = result.records;
        this.setState({
          loadOriginalDatas: [...records],
          loadORrigPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * @function  导入原始数据到科研项目
   */

  private importOriginalDatas = async () => {
    const { selectedOriginalItems } = this.state;

    const { result } = await SciManageApi.importSciProject<string, IImportOriginalDatasReq>({
      originalDataIds: [...selectedOriginalItems],
      projectId: this.props.projectId,
    });
    if (result) {
      this.setState({ selectedOriginalItems: [], loadOriginalDatas: [] });
      this.loadModalCancel();
      this.getOriginalShareData();
    }
  };

  /**
   * 列表分页处理
   */
  private handleOriginalTableChanage = (pagination: PaginationConfig) => {
    const { originalPagination } = this.state;
    originalPagination.current = (pagination && pagination.current) || 1;
    this.setState(
      {
        originalPagination,
      },
      () => {
        this.forceUpdate();
      }
    );
    // this.originalLoadDatas();
  };
  /**
   * 原始数据 按照序列号查询
   */
  private handleTableHeaderSearch = () => {
    const origSearchSeqId = this.props.form.getFieldValue('originalPatientSequenceNum');
    this.getOriginalShareData(origSearchSeqId);
  };

  /**
   * 模态框 导入列表 分页切换
   */

  private handleLoadOriginalTableChanage = (pagination: PaginationConfig) => {
    const { loadORrigPagination } = this.state;
    loadORrigPagination.current = (pagination && pagination.current) || 1;
    this.setState(
      {
        loadORrigPagination,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  /**
   * 模态框确认
   */
  private moreModalOkClick = () => {
    if (this.state.targetPath) {
      this.downlaodOriginalDatas();
      this.setState({ dowloadModalVisable: false });
    } else {
      message.error('请选择下载路径');
    }
  };
  /**
   * 批量下载原始数据
   */
  private downlaodOriginalDatas = async () => {
    const { result } = await SciManageApi.downloadOriginalDatas<
      string,
      { ids: number[]; targetPath: string; projectName: string }
    >({
      ids: [...this.state.downloadOriginalItems],
      targetPath: this.state.targetPath!,
      projectName: this.props.projectName,
    });
    if (result) {
      this.moreModalCancel();
    }
  };

  /**
   * 模态框取消
   */
  private moreModalCancel = () => {
    this.setState({ dowloadModalVisable: false });
  };
  /**
   * 下载原始数据页面
   */
  private updataModalView = () => {
    const { targetPath, fileTree } = this.state;
    return (
      <div className="modal-content-view">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color: 'rgba(0,0,0,0.64)', fontWeight: 'bold', marginRight: '20px' }}>文件存放目录:</div>
          {<FileTree treeData={fileTree} onSelect={this.dataSelectPath} interValue={targetPath} />}
        </div>
        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.5)', marginTop: '40px' }}>
          注意：数据下载过程中，请不要拔掉移动硬盘，以免中断下载。
        </div>
      </div>
    );
  };

  /**
   * 选中的上传文件
   */
  private dataSelectPath = (item: string) => {
    this.setState({ targetPath: item });
  };
  /**
   * Mode 里面的列表
   */
  private loadDataModleView = () => {
    const { getFieldDecorator } = this.props.form;
    const { selectedOriginalItems } = this.state;
    // 已选序列按钮点击
    const rowNoSelection: TableRowSelection<IOriginalShareDataItem> = {
      // 待选序列类表
      selectedRowKeys: [...selectedOriginalItems],
      onChange: this.moreModalSelSeqTableChange,
      onSelectAll: this.importOriginalAllData,
    };
    const userItemsOptions = this.props.userItems.map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
    return (
      <div>
        <TableHeader>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
              <Form.Item label="上传时间:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project_modal_times')(
                  <RangePicker
                    className="project-data-picker-input"
                    placeholder={['开始时间', '结束时间']}
                    format={'YYYY-MM-DD HH:mm'}
                  />
                )}
              </Form.Item>
              <Form.Item label="用户名:" style={{ marginLeft: '40px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('sci_laod_original_user')(
                  <Select className="original-input" placeholder="请输入" allowClear={true}>
                    {userItemsOptions}
                  </Select>
                )}
              </Form.Item>
              <Button
                className="normal-search-button"
                onClick={this.handleLoaddTableHeaderSearch}
                style={{ marginLeft: '40px', fontWeight: 'bold' }}
              >
                查询
              </Button>
            </div>
          </div>
        </TableHeader>
        <Table
          columns={this.originalShareDataColums}
          dataSource={this.state.loadOriginalDatas}
          style={{ backgroundColor: 'white' }}
          pagination={{ ...this.state.loadORrigPagination }}
          rowSelection={rowNoSelection}
          rowKey="id"
          onChange={this.handleLoadOriginalTableChanage}
        />
      </div>
    );
  };
  /**
   * 引入数据列表查询
   */
  private handleLoaddTableHeaderSearch = () => {
    //
    this.originalLoadDatas();
  };
  /**
   * 引入数据模态框确认
   */
  private loadModalOkClick = () => {
    this.importOriginalDatas();
    // this.setState({ loadDataVisible: false });
  };
  /**
   * 引入模态框取消
   */
  private loadModalCancel = () => {
    this.props.loadModalClose();
    this.setState({ loadDataVisible: false });
  };
  /**
   * 原始数据下载
   */
  private dowloadOrignalDatas = () => {
    if (this.state.downloadOriginalItems.length > 0) {
      this.getUsbFileTree();
      this.setState({ dowloadModalVisable: true });
    } else {
      message.error('请先选择需要下载的数据');
    }
  };
  /**
   * 原始数据选取Action
   */
  private dowloadOriginalChange = (selectedRowKeys: string[] | number[], selectedRows: IOriginalShareDataItem[]) => {
    this.setState({ downloadOriginalItems: selectedRows.map(item => item.id) });
  };

  /**
   * 原始数据下载全选
   */

  private dowloadOriginalSelAll = (
    selected: boolean,
    selectedRows: IOriginalShareDataItem[],
    changeRows: IOriginalShareDataItem[]
  ) => {
    const ids = this.state.normalList.map(item => {
      return item.id;
    });
    this.setState({ downloadOriginalItems: selected ? ids : [] });
  };
  /**
   * 处理 原始导入选中改变
   */
  private moreModalSelSeqTableChange = (
    selectedRowKeys: string[] | number[],
    selectedRows: IOriginalShareDataItem[]
  ) => {
    this.setState({ selectedOriginalItems: selectedRowKeys as number[] });
  };

  /**
   * 原始数据导入全选
   */
  private importOriginalAllData = (
    selected: boolean,
    selectedRows: IOriginalShareDataItem[],
    changeRows: IOriginalShareDataItem[]
  ) => {
    const ids = this.state.loadOriginalDatas.map(item => {
      return item.id;
    });
    this.setState({ selectedOriginalItems: selected ? ids : [] });
  };

  /**
   * 获取 u盘文件目录
   *
   */
  private getUsbFileTree = async () => {
    const { result } = await DataManagerApi.getdownloadPathList<ITreeData[]>();
    if (result.length > 0) {
      this.setState({ fileTree: result });
    } else {
      message.warning('没有路径可以存放');
    }
  };
}

export default SciOrignalCmp;
