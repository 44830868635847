import { IAllocatePermissions } from '../pages/MyData/HospitalDetail/CodeType';
import axios from './RequestApi';
/**
 * @class 我的数据，包括节点用户和云端用户
 */
export default class HttpRequest {
  /**
   *
   * 获取原始数据列表
   */
  public static getOriginalShareDataList<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/dr/rawDataInfo', data);
  }

  /*
   *项目详情
   */
  public static getHospitalDetailPage<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/hospitalData/getProjectDetail', data);
  }
  /**
   * 问卷详情
   */
  public static getQuestionDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/hospitalData/getQuestionnaireAnswer', data);
  }
  /**
   *
   */
  public static getDetectionLog<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/hospitalData/getDetectionLogList', data);
  }
  /**
   *  获取数据包信息
   */
  public static getPackageInfo<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getInfo', data);
  }
  /**
   *  我的数据列表
   */
  public static getMyDatas<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getPage', data);
  }
  /**
   *  我的医院列表
   */
  public static getMyHospitals<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/hospitalData/getProjectPage', data);
  }
  /**
   *  我的药厂列表
   */
  public static getMyPharmacys<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyData/getProjectPage', data);
  }
  /**
   *  相关医院列表
   */
  public static getRelationHospitals<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyData/getHospitalWithPermissionPage', data);
  }
  /**
   *  药厂详情
   */
  public static getPharmacyDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyData/getProjectDetail', data);
  }
  /**
   *  药厂问卷详情
   */
  public static getPharmacyQuestionDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyData/getQuestionnaireAnswer', data);
  }
  /**
   *  药厂Detection 列表
   */
  public static getPharmacyDetections<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyData/getDetectionLogList', data);
  }
  /**
   *  药厂获取相关药厂
   */
  public static getHospitalRelatedPharmacys<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyHospital/getHospitalRelatedPharmacyPage', data);
  }
  /**
   *  获取项目医院
   */
  public static getHospitalFiter<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/pharmacyData/getProjectHospitalPage', data);
  }
  /**
   * 启动安全盒
   */
  public static allocatePermissions(data: IAllocatePermissions) {
    return axios.post<string, IAllocatePermissions>(`/user/logined/pharmacyData/allocatePermissions`, data);
  }
}
