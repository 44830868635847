import { Button, Drawer } from 'antd';
import React from 'react';
import { IQuestionnaireDetail } from './CodeType';
import './QuestionDetail.scss';
interface IState {
  collapsed?: boolean;
}
interface IProps {
  title: string;
  visible: boolean;
  questionDetail: IQuestionnaireDetail;
  onClose: () => void;
}
class QuestionDetail extends React.PureComponent<IProps, IState> {
  public state = {};

  /**
   * questionDetai
   */
  public questionDetai() {
    const { questionDetail, onClose } = this.props;
    let render: JSX.Element;

    if (questionDetail.questionnaire) {
      const answerJson = JSON.parse(questionDetail.answerJson);
      const tagJson = JSON.parse(questionDetail.questionnaire.tagJson);
      // tslint:disable-next-line:no-any
      const questionList = tagJson.questions.map((v: any, index: number) => {
        let anserRender: JSX.Element;

        if (typeof answerJson.answers[v.key] === 'object') {
          // tslint:disable-next-line:no-any
          anserRender = answerJson.answers[v.key].map((x: any, answerIndex: number) => {
            return (
              <div style={{ height: '32px', lineHeight: '32px', float: 'left', width: '220px' }} key={answerIndex}>
                {x}
              </div>
            );
          });
        } else {
          anserRender = <div style={{ height: '32px', lineHeight: '32px' }}>{answerJson.answers[v.key]}</div>;
        }

        return (
          <div key={index}>
            <div className="clear" />
            <li style={{ float: 'left', width: '100%' }}>
              <div style={{ fontWeight: 'bold', height: '64px', lineHeight: '64px' }}>
                {index + 1 + '、' + v.content};
              </div>
              {anserRender}
            </li>
          </div>
        );
      });

      render = (
        <div>
          <ul className="hospital-questionDetail">
            <li>
              <table className="questionDetaiTable">
                <tr>
                  <td>性别：{questionDetail.patient.sexStr}</td>
                  <td>名族：{questionDetail.patient.nation}</td>
                </tr>
                <tr>
                  <td>出生日期：{questionDetail.patient.birthDate.slice(0, 10)}</td>
                  <td>地址：{questionDetail.patient.birthPlace}</td>
                </tr>
              </table>
            </li>
            {questionList}

            <Button
              className="back-btn"
              type="primary"
              style={{ float: 'right', marginRight: '24px' }}
              onClick={onClose}
            >
              返回
            </Button>
          </ul>
        </div>
      );
    } else {
      render = <div />;
    }

    return render;
  }
  public render() {
    const { title, onClose, visible } = this.props;

    return (
      <Drawer
        title={title}
        placement="right"
        closable={true}
        width="500px"
        className="questiondrawer"
        onClose={onClose}
        visible={visible}
      >
        {this.questionDetai()}
      </Drawer>
    );
  }
}
export default QuestionDetail;
