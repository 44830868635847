import { ITreeData } from '../../../../components/FileTree/FilePathTree';
import { IAlreadyShareDataItem, IGrant, IPagination } from '../../CodeType';

export const SequencesColumsNoDetail = [
  {
    title: '文件编号',
    dataIndex: 'fileCode',
    key: 'fileCode',
    columnWidth: '60px',
    width: '100px',
  },
  {
    title: '文件名',
    dataIndex: 'fileName',
    key: 'fileName',
    width: '100px',
  },
  {
    title: '病例编号',
    dataIndex: 'patientCode',
    key: 'patientCode',
    width: '100px',
  },
  {
    title: '病例名称',
    dataIndex: 'disease',
    key: 'disease',
    width: '100px',
  },
];
export interface IPackageSeqs {
  disease: string;
  fileCode: string;
  fileName: string;
  patientCode: string;
  descJson: string;
}

export interface IHasSharedState {
  isJD: boolean;
  // tslint:disable-next-line:no-any
  item: any;
  shareSeqPagination: IPagination;
  hasSharedpagination: IPagination;
  hasSharedItems: IAlreadyShareDataItem[]; // 已分享数据要本地筛选
  grants: IGrant[];
  authModal: boolean;
  pathModal: boolean;
  pathList: ITreeData[];
  id: number;
  code: string;
  seqVisible: boolean;
  detailVisible: boolean;
  detailString: string;
  clickItem?: IAlreadyShareDataItem;
  clickItemSeqs: IPackageSeqs[];
  packageEditVisible: boolean;
  packageSelectedStatus: number;
  diseaseItems: string[];
  userItems: IOptionUser[];
  packageDrawerVisible: boolean;
  isLoading: boolean; // 是否显示加载loading
}

export interface IOptionUser {
  id: number;
  name: string;
}
