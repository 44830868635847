import { Popover, Steps } from 'antd';
import React from 'react';
import './ProjectStatus.scss';

interface IProps {
  status: number;
}
interface IState {
  type: object;
}
const { Step } = Steps;

const statusList = [1, 2, 3, 4, 5, 6, 7];
class ProjectStatus extends React.PureComponent<IProps, IState> {
  public state = {
    type: {},
  };

  public getStatusStr(currentStatus: number) {
    switch (currentStatus) {
      case 1:
        return '绑定唾液盒';
      case 2:
        return '寄送唾液盒';
      case 3:
        return '确认收样';
      case 4:
        return '样本质检';
      case 5:
        return '芯片检测实验';
      case 6:
        return '数据分析审核';
      case 9:
        return '检测结果生成';
      case 10:
        return '已失效';
      case 8:
        return '检测失败';
      default:
        return '';
    }
  }

  public getColor() {
    const { status } = this.props;
    switch (status) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return '#108ee9';
      case 9:
        return '#87d068';
      default:
        return '#f50';
    }
  }
  public getStepStatus() {
    const { status } = this.props;
    switch (status) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return 'process';
      case 8:
      case 10:
        return 'error';
      case 9:
        return 'finish';
    }
  }

  public render() {
    const { status } = this.props;
    const steps = statusList.map((v, index) => {
      if (v === 7) {
        if (status >= 8) {
          return <Step title={this.getStatusStr(status)} key={index} />;
        } else {
          return <Step title={'检测结果生成'} key={index} />;
        }
      } else {
        return <Step title={this.getStatusStr(v)} key={index} />;
      }
    });
    const content = (
      <div>
        <Steps current={status - 1} progressDot={true} size="small" status={this.getStepStatus()}>
          {steps}
        </Steps>
      </div>
    );

    return (
      <div className="projectdetail-status">
        <Popover content={content}>{this.getStatusStr(status)}</Popover>
      </div>
    );
  }
}
export default ProjectStatus;
