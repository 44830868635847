export const geneticFormTests = [
  'project_name',
  'project_overview',
  'project_data_type',
  'project_relevant_area',
  'project_type',
  'project_organism',
  'project_sample_range',
  'project_sample_type',
  'project_data_format',
  'project_tech_platform',
  'project_disease_type',
  'project_genome',
];
export const imageFromTests = [
  'project_data_format',
  'project_disease_type',
  'project_name',
  'project_overview',
  'project_testing_method',
  'project_data_format',
  'project_label_format',
  'project_data_type',
];
export interface IFormItemState {
  filePath?: string; // 文件路径
  excelPath?: string;
  packageType: number;
  steps: number; // 点击下一步 0 开始
  isShare: string;
  shareType: number; // 1立即共享2 是暂不共享
  dataTypeForExel: number;
  visiable: boolean;
  usbAvailable: boolean;
}

export const relevantAreaList = [
  { value: 'Agricultural', tip: '农业' },
  { value: 'Medical', tip: '医学' },
  { value: 'Industrial', tip: '医疗' },
  { value: 'Environmental', tip: '环境' },
  { value: 'Evolution', tip: '进化' },
  { value: 'Model Organism', tip: '模式生物' },
  { value: 'Unknown', tip: '未知' },
];

export const typeList = [
  { value: 'Whole Genome sequencing', tip: '全部基因组测序项目' },
  { value: 'Clone ends', tip: '克隆末端测序项目' },
  { value: 'Epigenomics DNA', tip: '甲基化，组蛋白修饰，染色质数据集项目' },
  { value: 'Exome', tip: '转录组测序项目' },
  { value: 'Map', tip: '无测序数据的图位数据项目，图位克隆测序项目，辐射杂种细胞图项目' },
  { value: 'Random Survey', tip: '从收集到的样品的随机取样的测序项目，即不打算对材料进行全面取样' },
  { value: 'Targeted Locus (Loci)', tip: '特定位点的测序项目，如 16S rRNA 测序' },
  { value: 'Targeted loci cultured', tip: '来自培养样本的靶向位点测序项目' },
  { value: 'Targeted loci environmental', tip: '来自环境样本（未培养）的靶向位点测序项目' },
  { value: 'Transcriptome or Gene Expression', tip: '大规模 RNA 测序或表达分析。包括基因的 EST，RNA-seq，和芯片' },
  { value: 'Variation', tip: '项目的主要目标是识别人群中的序列变异' },
  { value: 'Genome sequencing and assembly', tip: '全部基因组测序和组装项目' },
  { value: 'Raw sequence reads', tip: '原始组学数据项目' },
  { value: 'Genome sequencing', tip: '基因组测序项目' },
  { value: 'Assembly', tip: '基因组组装项目' },
  { value: 'Metagenomic assembly', tip: '由环境样品测序产生的基因组组装测序项目' },
  { value: 'Proteome', tip: '大规模蛋白质组学项目，包括质谱分析' },
  { value: 'Other', tip: '其他数据类型，需要用户做文字描述' },
];

export const sampleRangeList = [
  { value: 'Monoisolate', tip: '同一物种且同一基因型样本集，主要用于模式动物/植物或细胞系等' },
  { value: 'Multisolate', tip: '同一物种的多基因型的个体样本集' },
  { value: 'Multi-species', tip: '多物种样本集' },
  { value: 'Environment', tip: '环境样本集，多用于宏基因组研究' },
  { value: 'Synthetic', tip: '在实验室里制造/合成的样本集' },
  { value: 'Single cell', tip: '单个细胞测序样本集' },
  { value: 'Other', tip: '其他样本集或无法归入以上类别的特殊样本集' },
];

export const sampleTypeList = [
  { value: 'Clinical or host-associated pathogen', tip: '临床或宿主相关病原体' },
  { value: 'Environmental, food or other pathogen', tip: '环境、食物或其他病原体' },
  { value: 'Microbe', tip: '微生物' },
  { value: 'Model organism or animal sample', tip: '模型生物或动物样本' },
  { value: 'Human', tip: '人类' },
  { value: 'Plant', tip: '植物' },
  { value: 'Virus', tip: '病毒' },
];

export const dataFormatList = [{ value: 'VCF' }, { value: 'txt' }, { value: 'FASTQ' }, { value: 'BAM' }];

export const techPlatformList = [{ value: 'Illumina Hiseq 2000' }, { value: 'Ion torrent PGM' }];

export const genomeList = [{ value: 'hg19' }, { value: 'GRCh38' }];
