import { Card, Col, Row } from 'antd';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import IndexLayout from '../../layout/IndexLayout';
interface IState {
  collapsed?: boolean;
}
class IndexPage extends React.PureComponent<IState, {}> {
  public state = {
    collapsed: false,
  };
  constructor(props: Readonly<IState>) {
    super(props);
  }

  public render() {
    return (
      <IndexLayout type="Index">
        <Col className="main-standard" id="IndexPage">
          <Row className="main-container">
            <Col span={4} className="indexItem">
              <Card className="indexCard center">
                <Row className="center">
                  <IconFont className="indexIcon" type="medataicon-icon-data1" />
                  <IconFont className="indexIconUp" type="medataicon-icon-data" />
                </Row>
                <Row>
                  <h2>数据安全</h2>
                </Row>
                <Row className="itemContent">
                  <p>-本地服务器模式：数据与外界物理隔离</p>
                  <p>-云端模式：安全加密</p>
                  <p>未经用户授权任何一方无法接触数据</p>
                </Row>
              </Card>
            </Col>
            <Col span={4} className="indexItem center">
              <Card className="indexCard center">
                <Row className="center">
                  <IconFont className="indexIcon" type="medataicon-icon-lianjie1" />
                  <IconFont className="indexIconUp" type="medataicon-icon-lianjie" />
                </Row>
                <Row>
                  <h2>链接数据孤岛</h2>
                </Row>
                <Row className="itemContent">
                  <p>通过分布式存储、分布式训练链接各节点数据，解决各节点互信问题</p>
                </Row>
              </Card>
            </Col>
            <Col span={4} className="indexItem center">
              <Card className="indexCard center">
                <Row className="center">
                  <IconFont className="indexIcon" type="medataicon-icon-anquanhe1" />
                  <IconFont className="indexIconUp" type="medataicon-icon-anquanhe" />
                </Row>
                <Row>
                  <h2>安全盒</h2>
                </Row>
                <Row className="itemContent">
                  <p>特有虚拟训练环境，用后即焚，在分享数据使用权、实现数据价值的同时，确保各方的数据安全</p>
                </Row>
              </Card>
            </Col>
            <Col span={4} className="indexItem center">
              <Card className="indexCard center">
                <Row className="center">
                  <IconFont className="indexIcon" type="medataicon-icon-block1" />
                  <IconFont className="indexIconUp" type="medataicon-icon-block" />
                </Row>
                <Row>
                  <h2>区块链</h2>
                </Row>
                <Row className="itemContent">
                  <p>去中心化的记账模式，记录所有交易和使用过程，无法篡改</p>
                </Row>
              </Card>
            </Col>
            <Col span={4} className="indexItem center">
              <Card className="indexCard center">
                <Row className="center">
                  <IconFont className="indexIcon" type="medataicon-icon-keyan1" />
                  <IconFont className="indexIconUp" type="medataicon-icon-keyan" />
                </Row>
                <Row>
                  <h2>科研工具</h2>
                </Row>
                <Row className="itemContent">
                  <p>丰富的科研工具：标注软件、统计分析软件、AI算法等等，满足科研工作者的日常科研需求</p>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </IndexLayout>
    );
  }
}
export default IndexPage;
