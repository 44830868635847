import { Button } from 'antd';
import React from 'react';
import { IVmJsonItem } from '../../../util/DataStruct';
import { IVmBoxItem } from '../CodeType';
import './SecBoxCard.scss';
interface ISecBoxCardProps {
  boxItem: IVmBoxItem;
  key: number;
  goToBoxClick: (boxItem: IVmBoxItem) => void;
}
class SecBoxCard extends React.Component<ISecBoxCardProps, {}> {
  public render() {
    const { boxItem } = this.props;
    const boxSetJson: IVmJsonItem = boxItem.distributeSetupJson && JSON.parse(boxItem.distributeSetupJson);
    const cupSet = ` \xa0| \xa0数量: \xa0${boxSetJson.cpu_cores}核  \xa0 \xa0 \xa0 \xa0 \xa0 频率: \xa0${
      boxSetJson.cpu_frequency
    }G  \xa0HZ`;
    const memeroySet = ` \xa0| \xa0容量: \xa0${boxSetJson.memory_size}  \xa0 \xa0 \xa0 \xa0 \xa0 频率: \xa0${
      boxSetJson.memory_frequency
    }G  \xa0HZ `;
    const gpuSet = ` \xa0| \xa0型号: \xa0${boxSetJson.gpu_memory_type}  \xa0 \xa0 \xa0 \xa0 \xa0 GPU频率: \xa0${
      boxSetJson.gpu_core_frequency
    }G  \xa0HZ \xa0 \xa0 \xa0 \xa0 \xa0 显卡:${boxSetJson.gpu_core_type}`;
    const diskSet = ` \xa0| \xa0空间: \xa0${boxSetJson.disk_space}TB `;
    const systemSet = ` \xa0| \xa0版本: \xa0${boxSetJson.os_system_version}  `;
    return (
      <div key={this.props.key} className="sec-box-card">
        <div className="header">
          <div style={{ display: 'flex', flex: 1 }}>
            安全盒编号:
            <div className="nowarp-elips" title={boxItem.vmInstanceNumber}>
              {`\xa0${boxItem.vmInstanceNumber}`}
            </div>
          </div>
          <div style={{ flex: 1, display: 'flex' }} className="nowarp-elips">
            项目名称:<div> {`\xa0${boxItem.researchProjectName}`} </div>
          </div>
        </div>
        <div className="set-container">
          <div style={{ color: '#000', fontWeight: 'bold' }}>cpu </div>
          {cupSet}
        </div>
        <div className="set-container">
          <div style={{ color: '#000', fontWeight: 'bold' }}>内存 </div>
          {memeroySet}
        </div>
        <div className="set-container">
          <div style={{ color: '#000', fontWeight: 'bold' }}>显卡 </div>
          {gpuSet}
        </div>
        <div className="set-container">
          <div style={{ color: '#000', fontWeight: 'bold' }}>磁盘 </div>
          {diskSet}
        </div>
        <div className="set-container">
          <div style={{ color: '#000', fontWeight: 'bold' }}>系统 </div>
          {systemSet}
        </div>
        <div className="set-container" style={{ paddingTop: '24px' }}>
          <Button className="theme-normal-btn" onClick={this.gotoVmBox}>
            进入安全盒
          </Button>
        </div>
      </div>
    );
  }
  /**
   * 前往安全点击事件
   */
  private gotoVmBox = () => {
    this.props.goToBoxClick(this.props.boxItem);
  };
}

export default SecBoxCard;
