import axios from './RequestApi';
/**
 * @class 我的数据，包括节点用户和云端用户
 */
export default class DataManagerApi {
  /**
   *
   * 获取原始数据列表
   */
  public static getOriginalShareDataList<T, D>(data: D) {
    // return axios.get<T, D>('/user/logined/originalData/page', data);
    return axios.post<T, D>('/user/logined/apricot/dr/rawDataInfo', data);
  }
  /**
   * 获取鉴影登录 url
   */
  public static getApricotUrl<T>() {
    return axios.get<T, {}>('/user/logined/apricot/jy/loginUrl');
  }
  /**
   * 获取用户列表
   */
  public static getPackageGrantList<T, D>(packageCode: D) {
    return axios.get<T, {}>(`/user/logined/member/packageGrantList/${packageCode}`);
  }
  /**
   * 提交权限变更
   */
  public static savePackageGrantList<T, D, E>(data: D, packageCode: E) {
    return axios.post<T, D>(`/user/logined/member/savePackageGrantList/${packageCode}`, data);
  }
  /**
   * 获取下载path
   */
  public static getdownloadPathList<T>() {
    return axios.get<T, {}>(`/user/logined/usb/download/content`);
  }
  /**
   *
   * @param 获取Usb 文件目录
   */
  public static getUsbContent<T>() {
    return axios.get<T, {}>('/user/logined/usb/content');
  }

  /**
   * 获取 excel 文件
   */
  public static getExcelFile<T>() {
    return axios.get<T, {}>('/user/logined/usb/excel/content');
  }
  /**
   * 确定下载路径
   */
  public static setDownloadPath<T, D>(data: D) {
    return axios.post<T, D>(`/user/logined/package/download`, data);
  }
  /**
   * 获取已共享列表数据
   */
  public static getHasSharedList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getPage', data);
  }
  /**
   *
   * @param  待共享 列表
   */
  public static getWaitSharedList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/waitShareData', data);
  }
  /**
   *
   * 用TaskId 获取序列列表
   */
  public static getSeqsWithTaskId<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/getTaskList', data);
  }
  /**
   *
   * 分享数据包
   */
  public static startShareTaskPackage<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/jy/saveShareData', data);
  }
  /***
   * 获取设备字典
   */
  public static getDevicesInfo<T>() {
    return axios.get<T, {}>('/user/logined/apricot/dr/detectDeviceInfo');
  }
  /***
   * 获取部位字典
   */
  public static getPartsInfo<T>() {
    return axios.get<T, {}>('/user/logined/apricot/dr/detectPartInfo');
  }

  /**
   * 下载数据保存
   */
  public static loadingDataSave<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/usb/importData', data);
  }

  /**
   * 检查USB 设备状态
   */
  public static checkUsbAvailabel() {
    return axios.get<boolean, {}>('/user/logined/usb/available');
  }
  /**
   * 已选数据包序列列表
   *
   */
  public static getPackageSeqs<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getPackageSerialPage', data);
  }
  /**
   * edit package Status
   */

  public static updatePackageStatus<D>(data: D) {
    return axios.post<boolean, D>('/user/logined/package/updateStatus', data);
  }
  /**
   * 获取疾病列表
   */
  public static getDiseases() {
    return axios.get<string[], {}>('/user/logined/package/diseaseList');
  }
  /**
   * 获取用户列表
   */
  public static getMembers<T>(data?: { type: number }) {
    return axios.get<T, {}>('/user/logined/getNodeUserList', data);
  }

  /**
   * 获取样本类型/疾病列表
   */
  public static getListForTmp<T, D>(data: D) {
    return axios.get<T, {}>('/user/logined/excelTemplate/downLoadTemplate', data);
  }
  /**
   * Excel下载模板
   *
   */
  public static toDownload<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/excelTemplate/downLoad', data);
  }
}
