import { Button, Card, Divider, Drawer, Form, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HttpRequest from '../../../api/MyDataApi';
import InfoDrawer from '../../../components/InfoDrawer/InfoDrawer';
import ProjectStatus from '../../../components/ProjectStatus/ProjectStatus';
import QuestionDetail from '../../../components/QuestionDetail/QuestionDetail';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import DataSet from '../../../util/DataSet';
import { IDataPackageItem } from '../../../util/DataStruct';

import {
  IHospitalDetailstate,
  IItemList,
  IMyDataProjectDetail,
  INormalList,
  IProjectDetailReq,
  IQuestionnaireDetail,
} from './CodeType';
import './HospitalDetail.scss';

interface IHospitalDetailprops extends FormComponentProps, RouteComponentProps {}

class DataSearch extends React.PureComponent<IHospitalDetailprops, IHospitalDetailstate> {
  public columns = [
    {
      title: '病例',
      dataIndex: 'name',
      render: (text: string, record: IMyDataProjectDetail) => {
        return <div>{record.patient.genochainCode}</div>;
      },
    },
    {
      title: '医院',
      dataIndex: 'hospital',
      render: (text: string, record: IMyDataProjectDetail) => {
        return <div>{record.hospital.name}</div>;
      },
    },
    {
      title: '唾液盒状态',
      key: 'statusStr',
      width: 400,
      render: (record: IMyDataProjectDetail) => {
        return <ProjectStatus status={record.step} />;
      },
    },
    {
      title: <div style={{ paddingLeft: '1em' }}>操作</div>,
      className: 'table-opt-area',
      key: 'action',
      render: (text: string, record: IMyDataProjectDetail) => {
        const readHidden = !record.hasPermissionToUse;
        const generHidden = record.hasPermissionToUse && record.step === 9 ? false : true;
        const btnGroup = (
          <div>
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={this.handleQuestion.bind(
                this,
                record.project.code,
                record.patient.id,
                record.project.questionnaire.name
              )}
              hidden={readHidden}
            >
              查看问卷
            </a>
            {readHidden ? null : <Divider type="vertical" className="optline" />}

            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={this.handelDetection.bind(
                this,
                record.detectNo,
                record.patient.id,
                record.patient.genochainCode
              )}
            >
              检测记录
            </a>

            {generHidden ? null : <Divider type="vertical" className="optline" />}
            <a
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.handlePackage(record.project.pkg.id);
              }}
              hidden={generHidden}
            >
              基因信息
            </a>
          </div>
        );
        return btnGroup;
      },
    },
  ];

  constructor(props: IHospitalDetailprops) {
    super(props);
    this.state = {
      current: 1,
      questionVisible: false,
      questionName: '',
      detectionVisible: false,
      patientName: '',
      packageVisible: false,
      headerTitle: '',
      pkgDiscription: '',
      pharmacyName: '',
      hasPermissionToUse: '',
      hospitalDetailList: [],
      detectionLog: [],
      pagination: { total: 0, pageSize: 10, current: 1 },
    };
  }
  /**
   * handleQuestion
   */
  public handleQuestion = async (code: string, patientId: number, questionName: string) => {
    const { result } = await HttpRequest.getQuestionDetail<IQuestionnaireDetail, { code: string; patientId: number }>({
      code,
      patientId,
    });
    if (result) {
      this.setState({
        questionDetail: result,
        questionName,
        questionVisible: true,
      });
    }
  };

  /**
   * handelDetection
   */
  public handelDetection = async (detectCode: string, patientId: number, patientName: string) => {
    const { result } = await HttpRequest.getDetectionLog<
      IQuestionnaireDetail[],
      { detectCode: string; patientId: number }
    >({
      detectCode,
      patientId,
    });
    if (result) {
      this.setState({
        detectionLog: [...result],
        patientName,
        detectionVisible: true,
      });
    }
  };

  /**
   * 基因信息详情
   */
  public handlePackage = async (id: number) => {
    const { result } = await HttpRequest.getPackageInfo<IItemList, { id: number }>({
      id,
    });
    if (result) {
      this.setState({
        packageJson: result,
        packageVisible: true,
      });
    }
  };
  /**
   * questionClose
   */
  public questionClose = () => {
    this.setState({
      questionVisible: false,
    });
  };
  /**
   * detectionClose
   */
  public detectionClose = () => {
    this.setState({
      detectionVisible: false,
    });
  };
  /**
   * packageClose
   */
  public packageClose = () => {
    this.setState({
      packageVisible: false,
    });
  };

  /**
   *  获取医院详情列表
   */
  public getHospitals = async (pageNum?: number) => {
    const { pagination } = this.state;
    pageNum = pageNum ? pageNum : 1;
    const code = sessionStorage.getItem('hospital-code');
    const { result } = await HttpRequest.getHospitalDetailPage<INormalList<IMyDataProjectDetail[]>, IProjectDetailReq>({
      pageNum,
      pageSize: pagination.pageSize ? pagination.pageSize : 10,
      code,
    });
    if (result) {
      //
      pagination.current = result.current;
      pagination.total = result.total;
      if (result.records.length > 0) {
        const item: IMyDataProjectDetail = result.records[0];
        const headerTitle = item.project.name;
        const pkg: IDataPackageItem = item.project.pkg.jsonDesc ? JSON.parse(item.project.pkg.jsonDesc) : undefined;
        this.setState({
          headerTitle,
          pkgDiscription: pkg ? (pkg.project_overview ? pkg.project_overview : '') : '',
          pagination,
          hospitalDetailList: [...result.records],
        });
      }
    }
  };

  /**
   * 页码切换
   */
  public handleTableChange = (pagination: PaginationConfig) => {
    this.getHospitals(pagination.current);
  };
  public componentDidMount() {
    const pharmacyName = sessionStorage.getItem('hospital-pharmacyName');
    const hasPermissionToUse = sessionStorage.getItem('hospital-hasPermissionToUse');
    this.setState({
      pharmacyName,
      hasPermissionToUse,
    });
    this.getHospitals();
  }

  /**
   * 测试记录
   */
  public detectionLogRender = () => {
    const { detectionLog } = this.state;
    // tslint:disable-next-line:no-any
    const render = detectionLog.map((item: any, index: number) => {
      return (
        <div className="logList" key={index}>
          <span>{item.created}</span>
          <span className="lineContainer">
            <Divider style={{ width: '100px', marginTop: '0px', marginBottom: '0px' }} dashed={true} />
          </span>

          <span>{item.statusStr}</span>
        </div>
      );
    });

    const nerender = (
      <div>
        {render}

        <Button
          className="back-btn"
          type="primary"
          style={{ float: 'right', marginRight: '24px' }}
          onClick={this.detectionClose}
        >
          返回
        </Button>
      </div>
    );

    return nerender;
  };
  public render() {
    const { hospitalDetailList, packageJson, questionDetail, pagination } = this.state;

    const packageJsonInfo = packageJson ? DataSet.dataInfoSet(packageJson) : {};

    const btnGroup = (
      <div>
        <Button onClick={this.packageClose} type="primary">
          返回
        </Button>
      </div>
    );
    const header = '项目:' + this.state.headerTitle;
    return (
      <IndexLayout>
        <div className="page-container hospitalDetail">
          <RouteHeader
            title={header}
            titles={['首页', '我的数据', '项目详情']}
            routes={['/', '/myhospital', '/hospitalDetail']}
          />
          <Card bordered={false} className="prjectinfo">
            <label>所属药厂：</label>
            {this.state.pharmacyName}
            <a className="prjectinfo-line" />
            <label>使用权限：</label>
            <a style={this.state.hasPermissionToUse === '有权限' ? { color: '#52C41A' } : { color: 'red' }}>
              {this.state.hasPermissionToUse}
            </a>
          </Card>
          <Card bordered={false} className="prjectdesc">
            <label>{this.state.pkgDiscription}</label>
          </Card>
          <Card bordered={false} className="hospitalDetailCard">
            <Table
              columns={this.columns}
              dataSource={hospitalDetailList}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...pagination }}
              rowKey="orderCode"
              onChange={this.handleTableChange}
            />
          </Card>
          <QuestionDetail
            title={this.state.questionName}
            questionDetail={questionDetail ? questionDetail : ({} as IQuestionnaireDetail)}
            onClose={this.questionClose}
            visible={this.state.questionVisible}
          />
          <Drawer
            title={'检测记录' + this.state.patientName}
            placement="right"
            closable={true}
            width="410px"
            className="detectionLog"
            onClose={this.detectionClose}
            visible={this.state.detectionVisible}
          >
            {this.detectionLogRender()}
          </Drawer>
          <InfoDrawer
            data={packageJsonInfo}
            visible={this.state.packageVisible}
            onClose={this.packageClose}
            btnGroup={btnGroup}
            demoUrl={packageJson ? packageJson.demoUrl : ''}
            caseNumberHidden={true}
            exampleHidden={true}
            allData={packageJson}
          />
        </div>
      </IndexLayout>
    );
  }
}
const DataSearchList = Form.create()(DataSearch);
export default DataSearchList;
