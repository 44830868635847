import { Form, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SubscriptionAPI } from 'dva';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataManagerApi from '../../api/DataManager';
import AuthCmp from '../../components/AuthCmp/AuthCmp';
import { ITreeData } from '../../components/FileTree/FilePathTree';
import { IconFont } from '../../config/IconConfig';
import IndexLayout from '../../layout/IndexLayout';
import Storage, { PARTDICTIONSKEY } from '../../util/Storage';
import downFileApi from './../../api/Downfile';
import AuthComponent from './../../components/AuthComponent/AuthComponent';
import { IDictions, IExcel, ILoadDataSaveReq } from './CodeType';
import HasSharedDataComponent from './Component/HasSharedDataComponent/HasSharedDataComponent';
import HintComponent from './Component/HintComponent/HintComponent';
import LoadComonent from './Component/LoadDataComponent/LoadComonent';
import OriginalDataComponent from './Component/OriginalDataComponent/OriginalDataComponent';
import SwiperGuide from './Component/SwiperGuide/SwiperGuide';
import ToolCard from './Component/ToolCard/ToolCard';
import WaitShareComponent from './Component/WaitSharedComponent/WaitShareComponent';
import { defaultState, IIDataManagerState } from './DataMSetting';

interface IDataManagerProps extends FormComponentProps, RouteComponentProps, SubscriptionAPI {}

class DataManagerPage extends React.Component<IDataManagerProps, IIDataManagerState> {
  public timer?: NodeJS.Timer;
  public state = { ...defaultState } as IIDataManagerState;
  public componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    //  部位,机型 这些存起来
    const dics: IDictions[] = [];
    this.state.devicesDiction.forEach((v, k) => {
      dics.push(v);
    });
    this.state.partsDiction.forEach((v, k) => {
      dics.push(v);
    });
    if (dics.length > 0) {
      Storage.setStore(PARTDICTIONSKEY, JSON.stringify(dics));
    }
  }

  public componentDidMount() {
    this.getUsbStatus();
    this.getDevicesDiction();
    this.getPartsDiction();
    this.setState({
      isJD: Storage.getStore('usertype') === '5',
    });
    const permissions: string[] = JSON.parse(Storage.getStore('permissions') || '[]');
    // 两种权限都没有 disable
    if (!permissions.includes('USER_SINGLE_DATA_WRITE') && !permissions.includes('USER_PACKAGE_WRITE')) {
      this.setState({
        isAuth: false,
        exportShow: true,
      });
    }
    // 两种都有的话 出现hover效果
    if (permissions.includes('USER_SINGLE_DATA_WRITE') && permissions.includes('USER_PACKAGE_WRITE')) {
      this.setState({
        isAllExpoet: true,
      });
    }
    if (permissions.includes('USER_SINGLE_DATA_WRITE') && !permissions.includes('USER_PACKAGE_WRITE')) {
      this.setState({
        exportShow: false,
      });
    }
  }
  public render() {
    return (
      <div id="DataManager">
        <IndexLayout>
          <div className="data-manager-container">
            <div className="tools-container ">
              <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.64)', marginRight: '42px' }}> 应用工具:</span>
              <ToolCard
                isDisable={this.state.isLinkJY}
                onClick={this.linkPage}
                icon={'medataicon-jianying'}
                title={'鉴影'}
                description={'提供影像文件标注服务的科研工具'}
                action={this.proxaiClick}
              />
            </div>
            {this.typeSelectedLineView()}
            <div style={{ marginTop: '32px' }}> {this.showListView()}</div>
            {this.updateModalShow()}
            <HintComponent
              title={'提示'}
              message={'正在上传数据，请不要拔出移动硬盘，以免中断数据上传，完成后，可通过通知上看上传结果。'}
              visiable={this.state.hintShow}
              okClick={this.hintClose}
            />
            <SwiperGuide
              isUsb={this.state.usbAvailable}
              onCloseModal={this.complateGuide}
              isShow={this.state.isSwiperGuide}
            />
          </div>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 鉴影管理click
   */

  public proxaiClick = () => {
    this.props.history.push('/proxAiManage');
  };
  /**
   * 获取数据包列表
   */
  public getTmpList = async (type: string) => {
    const { result } = await DataManagerApi.getListForTmp<IExcel[], { type: string }>({ type });
    if (type === 'GENE') {
      this.setState({ geneList: result });
    } else {
      this.setState({ imageList: result });
    }
  };
  /* 共享数据导入底部按钮 */
  public excelBtn = () => (
    <div style={{ height: '2rem', marginTop: '1rem' }}>
      <a
        href="javascript:void(0)"
        className="excel-dlbtn"
        // tslint:disable-next-line:jsx-no-multiline-js
        onClick={() => {
          this.getTmpList('GENE');
          this.getTmpList('IMAGE');
          this.updataOriginalDataActioin(3);
        }}
      >
        Excel模版下载
      </a>
    </div>
  );
  /**
   * 导入输入模态框
   */
  public updateModalShow = () => {
    const { partsDiction, devicesDiction } = this.state;
    return (
      // <AuthComponent isShow={this.state.updateDataViewVisiable}>
      <LoadComonent
        form={this.props.form}
        treeData={this.state.fileTree}
        excelData={this.state.excelTrees}
        loadDataClick={this.updataBtnClick}
        cancelUpdataClick={this.cancelUpdataClick}
        visiable={this.state.updateDataViewVisiable}
        partDictions={partsDiction}
        deviceDictions={devicesDiction}
        dataType={this.state.dataType}
        showGuide={this.showGuide}
        isUsb={this.state.usbAvailable}
        bottomBtn={this.excelBtn()}
        geneList={this.state.geneList}
        imageList={this.state.imageList}
        submit={this.downloadSubmit}
      />
      // </AuthComponent>
    );
  };

  /**
   * @todo  NetWorking  Action
   */

  /**
   * 获取 u盘文件目录
   *
   */
  private loadOrignialDataChange = async () => {
    //  FileTree
    const { result } = await DataManagerApi.getUsbContent<ITreeData[]>();
    this.getExcelFile();
    this.setState({
      showLoadingDataComponnet: true,
      fileTree: result,
    });
  };
  /**
   * 获取excel
   */

  private getExcelFile = async () => {
    const { result } = await DataManagerApi.getExcelFile<ITreeData[]>();

    this.setState({
      showLoadingDataComponnet: true,
      excelTrees: result,
    });
  };

  /**
   * 获取身体部位字典
   */
  private getPartsDiction = async () => {
    const { result } = await DataManagerApi.getPartsInfo<IDictions[]>();
    const newPartsDiction = new Map();
    if (result) {
      result.forEach(item => {
        newPartsDiction.set(item.value, item);
      });
      this.setState({
        partsDiction: newPartsDiction,
      });
    }
  };

  /**
   * 获取检查设备列表
   */
  private getDevicesDiction = async () => {
    const { result } = await DataManagerApi.getDevicesInfo<IDictions[]>();
    const newDevicesDiction = new Map();
    if (result) {
      result.forEach(item => {
        newDevicesDiction.set(item.value, item);
      });

      this.setState({
        devicesDiction: newDevicesDiction,
      });
    }
  };
  /**
   * 检查usb 状态
   */
  private getUsbStatus = async () => {
    this.timer = setInterval(async () => {
      try {
        const { result } = await DataManagerApi.checkUsbAvailabel();
        this.setState({
          usbAvailable: result,
        });
      } catch (err) {
        this.clearTimer();
      }
    }, 2000);
  };

  private clearTimer = () => {
    if (this.timer) {
      this.setState({
        usbAvailable: false,
      });
      clearTimeout(this.timer);
    }
  };
  /**
   * 打开上传数据弹框，判断用户是否已经看过引导页
   */
  // tslint:disable-next-line:no-any
  private updataOriginalDataActioin = (dataType: any) => {
    this.loadOrignialDataChange();
    const permissions: string[] = JSON.parse(Storage.getStore('permissions') || '[]');
    if (permissions.includes('USER_SINGLE_DATA_WRITE') || permissions.includes('USER_PACKAGE_WRITE')) {
      if (!Storage.getStore('isSwiperGuide')) {
        this.setState({
          isSwiperGuide: true,
        });
        Storage.setStore('isSwiperGuide', '1');
      }
    }
    if (typeof dataType === 'number') {
      this.setState({ dataType });
    }
    this.setState({
      updateDataViewVisiable: true,
    });
  };

  /**
   * 上传数据
   */
  private updataBtnClick = async (item: ILoadDataSaveReq) => {
    this.setState({
      updateDataViewVisiable: false,
      hintShow: true,
    });

    const { result } = await DataManagerApi.loadingDataSave<string, ILoadDataSaveReq>({ ...item });
    if (result) {
      this.setState({
        showLoadingDataComponnet: false,
      });
    }
  };

  private cancelUpdataClick = () => {
    // 取消上传数据
    this.setState({
      updateDataViewVisiable: false,
    });
    if (this.state.dataType === 3) {
      this.updataOriginalDataActioin(1);
    }
  };

  /**
   *  切换 数据列表 类表 1 原始数据 2 待共享数据 3 已共享数据
   */
  private handleTypeGroupChanage = (e: RadioChangeEvent) => {
    // 数据类型
    const selectType = e.target.value;
    this.setState({
      currentListType: selectType,
    });
  };

  private hintClose = () => {
    this.setState({
      hintShow: false,
    });
  };
  private typeSelectedLineView = () => {
    return (
      <div className="list-type-header">
        <div className="list-type-left-container">
          <div style={{ width: '100%', height: '1px', backgroundColor: 'rgba(214, 214, 214, 0.6)' }} />
        </div>
        <div className="list-type-center-container">
          <Radio.Group
            defaultValue={'1'}
            value={this.state.currentListType}
            buttonStyle="solid"
            onChange={this.handleTypeGroupChanage}
          >
            <Radio.Button value="1">原始数据</Radio.Button>
            <AuthComponent isShow={!this.state.isJD}>
              <Radio.Button value="2">待共享数据</Radio.Button>
            </AuthComponent>
            <Radio.Button value="3">{this.state.isJD ? '项目数据包' : '已共享数据'}</Radio.Button>
          </Radio.Group>
        </div>

        <div className="list-type-right-container" style={{}}>
          <div style={{ flex: '1px', height: '1px', backgroundColor: 'rgba(214, 214, 214, 0.6)' }} />
          <AuthCmp permissions={['USER_SINGLE_DATA_WRITE', 'USER_PACKAGE_WRITE']}>
            <div>
              {/* 如果没有批量共享，那么就会显示，原始数据导入，弹出的框里面也只有原始数据这个选项 */}
              <AuthComponent isShow={this.state.isAllExpoet}>
                <div className="hover_cor">
                  <div className="hover_show">
                    <div style={{ cursor: 'pointer' }}>
                      <IconFont
                        type={this.state.isAuth ? 'medataicon-upload' : 'medataicon-UploadGray'}
                        style={{ fontSize: '28px' }}
                        className={this.state.isAuth ? 'type-header-icon' : ''}
                      />
                    </div>
                    <div className={this.state.isAuth ? 'export' : 'export disable'}>
                      {this.state.isJD ? '原始数据导入' : '数据导入'}
                    </div>
                  </div>
                  <div className="hover_show_all">
                    <div className="exp_box">
                      <div>导入</div>
                      <AuthComponent isShow={!this.state.isJD}>
                        <div className="tit_b" onClick={() => this.updataOriginalDataActioin(1)}>
                          <div className="tit_r" />
                          <div className="tit_n">共享数据</div>
                        </div>
                      </AuthComponent>
                      <div className="tit_b" onClick={() => this.updataOriginalDataActioin(2)}>
                        <div className="tit_r" />
                        <div className="tit_n">原始数据</div>
                      </div>
                    </div>
                  </div>
                </div>
              </AuthComponent>
              <AuthComponent isShow={!this.state.isAllExpoet}>
                <div className="hover_show1">
                  <div style={{ cursor: 'pointer' }}>
                    <IconFont
                      type={this.state.isAuth ? 'medataicon-upload' : 'medataicon-UploadGray'}
                      style={{ fontSize: '28px' }}
                      className={this.state.isAuth ? 'type-header-icon' : ''}
                    />
                  </div>
                  <AuthComponent isShow={this.state.exportShow}>
                    <div
                      className={this.state.isAuth ? 'export' : 'export disable'}
                      onClick={() => this.updataOriginalDataActioin(this.state.isJD ? 2 : 1)}
                    >
                      {this.state.isJD ? '原始数据导入' : '数据导入'}
                    </div>
                  </AuthComponent>
                  <AuthComponent permission="!USER_PACKAGE_WRITE">
                    <AuthComponent permission="USER_SINGLE_DATA_WRITE">
                      <div
                        className="export"
                        style={{ marginLeft: '8px', color: 'rgba(0, 161, 230, 1)', cursor: 'pointer' }}
                        onClick={() => this.updataOriginalDataActioin(2)}
                      >
                        原始数据导入
                      </div>
                    </AuthComponent>
                  </AuthComponent>
                </div>
              </AuthComponent>
            </div>
          </AuthCmp>
        </div>
      </div>
    );
  };
  /**
   *  条件显示列表组件
   */
  private showListView = () => {
    const type = Number(this.state.currentListType);

    if (type === 1) {
      return (
        <OriginalDataComponent
          form={this.props.form}
          partsDiction={this.state.partsDiction}
          devicesDiction={this.state.devicesDiction}
        />
      );
    } else if (type === 2) {
      return (
        <WaitShareComponent
          form={this.props.form}
          sharedAction={this.sharedSuccessrefesh}
          partsDiction={this.state.partsDiction}
          devicesDiction={this.state.devicesDiction}
        />
      );
    } else {
      return <HasSharedDataComponent form={this.props.form} />;
    }
  };
  /**
   * 分享成功后切换 页面\
   */

  private sharedSuccessrefesh = () => {
    this.setState({
      currentListType: '3',
    });
  };
  /**
   * 下载
   */

  private downloadSubmit = async (id: number) => {
    /* await DataManagerApi.toDownload<string, { id: number }>({ id }); */
    downFileApi.get(`/user/logined/excelTemplate/downLoad`, { params: { id } });
    this.cancelUpdataClick();
  };
  /**
   * 显示教程
   */

  private showGuide = () => {
    this.setState({
      guidType: 1,
      updateDataViewVisiable: false,
      isSwiperGuide: true,
    });
  };
  /**
   * 跳转鉴影
   */
  private linkPage = async () => {
    try {
      const { result } = await DataManagerApi.getApricotUrl<string>();
      const token = Storage.getStore('token');
      window.open(result + token);
    } catch (err) {
      this.setState({ isLinkJY: false, iconToolCard: 'medataicon-toolGray' });
    }
  };

  /**
   * 引导完成，出现上传数据弹框
   */
  private complateGuide = () => {
    this.setState({ isSwiperGuide: false });
    // 直接写在一起有点晃眼，所以延迟一下
    setTimeout(() => {
      if (this.state.guidType === 2) {
        this.setState({ showDowloadModes: true });
      } else {
        this.setState({ updateDataViewVisiable: true });
      }
    }, 300);
  };
}
const DataManager = Form.create()(DataManagerPage);
export default DataManager;
