import { Button, DatePicker, Form, Input, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { PaginationConfig, TableRowSelection } from 'antd/lib/table';
import { Moment } from 'moment';
import React from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import DataManagerApi from '../../../../api/DataManager';
import ProxAiManageApi from '../../../../api/ProxAiManageApi';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../../config/IconConfig';
import IndexLayout from '../../../../layout/IndexLayout';
import Storage, { PARTDICTIONSKEY } from '../../../../util/Storage';
import { IDictions, INormalList } from '../../../DataManager/CodeType';
import { IOptionUser } from '../../../DataManager/Component/HasSharedDataComponent/CodeType';
import {
  IAvailableSeqItem,
  IMemeberItem,
  INewProjectTaskState,
  initalNewProjectTaskState,
  IProjectSeqsReq,
  IPTaskSaveReq,
} from '../../CodeType';
// import ProxAiManageApi from '../../../../api/ProxAiManageApi';
import './NewProjectTask.scss';
const { Option } = Select;
const { RangePicker } = DatePicker;
interface INewProjectTaskPageProps extends FormComponentProps, RouteComponentProps<{ pId: string }> {}
class NewProjectTaskPage extends React.Component<INewProjectTaskPageProps, INewProjectTaskState> {
  public projectColumns = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUID',
      key: 'seriesInstanceUID',
      render: (text: string, record: IAvailableSeqItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '上传时间',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IAvailableSeqItem) => {
        return <div className="assigned-name"> {this.state.partDics.get(text)} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IAvailableSeqItem) => {
        return <div className="assigned-name"> {this.state.partDics.get(text)} </div>;
      },
    },
    {
      title: '使用状态',
      dataIndex: 'assigned',
      key: 'assigned',
      render: (text: string, record: IAvailableSeqItem) => {
        return <div className="assigned-name"> {record.assigned ? '是' : '否'} </div>;
      },
    },
  ];
  public state = initalNewProjectTaskState as INewProjectTaskState;
  public componentDidMount() {
    const { partDics } = this.state;
    this.getAvailableSeqs();
    this.getMembers();
    this.getMembersOptions();
    const parts: IDictions[] = JSON.parse(Storage.getStore(PARTDICTIONSKEY));
    parts.forEach(item => {
      partDics.set(item.value, item.name);
    });
    this.setState({ partDics });
    window.onbeforeunload = () => {
      this.setState({ isBack: true });
      return false;
    };
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    const { members, userItems } = this.state;
    // 已选序列按钮点击
    const rowSelection: TableRowSelection<IAvailableSeqItem> = {
      // 待选序列类表
      selectedRowKeys: this.state.selectedSeqs,
      onChange: this.selectedSeqsChange,
      onSelectAll: this.onSelectedAll,
    };
    const OptionsMembers = members
      ? members.map(item => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })
      : null;

    const userItemsOptions = userItems
      ? userItems.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      : null;
    const useStatus = [{ key: '1', value: '否' }, { key: '2', value: '是' }].map(item => (
      <Option key={item.key} value={item.key}>
        {item.value}
      </Option>
    ));
    return (
      <div className="new-project-task-container">
        <IndexLayout>
          <Prompt when={!this.state.isBack} message={location => '您的信息尚未填写完成，确定离开吗？'} />
          <Form>
            <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}
              >
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.gobackWithOption}>
                  <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                  <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回项目列表</span>
                </div>

                <div style={{ color: 'rgba(0,0,0,0.45)' }}>
                  <IconFont type={'medataicon-icon_location'} className="located-icon" />
                  <span onClick={this.props.history.goBack} style={{ marginLeft: '10px', marginRight: '5px' }}>
                    项目列表
                  </span>
                  /<span style={{ marginLeft: '5px' }}>创建任务</span>
                </div>
              </div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '7px' }}
              >
                <div className="project-message-title">
                  <div className="dot" /> 任务基本信息
                </div>
                <Button className="complete-btn" onClick={this.handleAddProjectBtnClick}>
                  完成
                </Button>
              </div>
              <div style={{ display: 'flex' }}>
                <div className="project-input-title ">任务标题:</div>
                <Form.Item colon={false} style={{ width: '400px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-task-tag', {
                    rules: [{ required: true, message: '点击输入' }],
                  })(<Input className="project-input" />)}
                </Form.Item>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div className="project-input-title " style={{ alignItems: 'flex-start' }}>
                  任务描述:
                </div>
                <Form.Item colon={false} style={{ width: '400px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-task-discription', {
                    rules: [{ required: true, message: '请输入任务描述' }],
                  })(<TextArea placeholder="点击输入" style={{ minHeight: '100px' }} />)}
                </Form.Item>
              </div>
              <div style={{ display: 'flex', marginTop: '11px' }}>
                <div className="project-input-title" style={{ display: 'inline-block', paddingTop: '9px' }}>
                  选择成员:
                </div>
                <Form.Item colon={false} style={{ width: '300px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-member', {
                    rules: [{ required: true, message: '点击选择' }],
                  })(
                    <Select className="project-input" style={{ width: '300px' }}>
                      {OptionsMembers}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div style={{ marginTop: '35px' }}>
                <div className="project-message-title">
                  <div className="dot" /> 选择序列
                </div>
              </div>
            </div>
            <TableHeader>
              <Form.Item label="上传时间:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-data-picker-input')(
                  <RangePicker
                    className="project-data-picker-input"
                    placeholder={['开始时间', '结束时间']}
                    format={'YYYY-MM-DD HH:mm'}
                  />
                )}
              </Form.Item>
              <AuthComponent permission="USER_ALL_DATA_READ">
                <Form.Item label="用户名:" style={{ marginLeft: '54px' }}>
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-user-name')(
                    <Select className="" placeholder="请选择" allowClear={true}>
                      {userItemsOptions}
                    </Select>
                  )}
                </Form.Item>
              </AuthComponent>
              <Form.Item label="是否被使用:" style={{ marginLeft: '34px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-task-status')(
                  <Select className="" placeholder="请选择" allowClear={true}>
                    {useStatus}
                  </Select>
                )}
              </Form.Item>
              <Button className="normal-search-button" onClick={this.searchClick} style={{ marginLeft: '30px' }}>
                查询
              </Button>
            </TableHeader>
            <AuthComponent isShow={!!this.state.availableSeqs.length}>
              <Table
                columns={this.projectColumns}
                dataSource={this.state.availableSeqs}
                style={{ backgroundColor: 'white' }}
                pagination={{ ...this.state.seqPagination }}
                rowKey="seriesInstanceUID"
                rowSelection={rowSelection}
                onChange={this.handleHasTableChanage}
              />
            </AuthComponent>
            <NoDataImg isLoading={this.state.isLoading} total={this.state.seqPagination.total} />
          </Form>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { seqPagination } = this.state;
    seqPagination.current = pagination.current || 1;
    this.setState({ seqPagination });
  };
  /**
   * 点击完成按钮
   */
  private handleAddProjectBtnClick = () => {
    const { validateFields } = this.props.form;
    validateFields(['project-task-tag', 'project-task-discription', 'project-member'], (err, values) => {
      if (!err) {
        const projectReq: IPTaskSaveReq = {
          name: values['project-task-tag'],
          projectId: Number(this.props.match.params.pId),
          remark: values['project-task-discription'],
          vendorId: values['project-member'],
          seriesInstanceUidList: [...this.state.selectedSeqs],
        } as IPTaskSaveReq;
        this.addProjectTask(projectReq);
      }
    });
  };
  private addProjectTask = async (taskReq: IPTaskSaveReq) => {
    const { result } = await ProxAiManageApi.addTask<string, IPTaskSaveReq>(taskReq);
    if (result) {
      this.setState({ isBack: true });
      this.props.history.goBack();
    }
  };
  /**
   *  条件查询
   */
  private searchClick = () => {
    //
    const { getFieldValue } = this.props.form;
    const time: Moment[] = getFieldValue('project-data-picker-input');
    const keyword = getFieldValue('project-user-name');
    let startTime: string = '';
    let endTime: string = '';
    if (time && time.length > 0) {
      startTime = String(time[0].valueOf());
      endTime = String(time[1].valueOf());
    }
    this.getAvailableSeqs(String(startTime), endTime, keyword);
  };
  /**
   * 列表选择框
   */
  private selectedSeqsChange = (selectedRowKeys: string[] | number[], selectedRows: IAvailableSeqItem[]) => {
    this.setState({
      selectedSeqs: selectedRows.map(e => e.seriesInstanceUID),
    });
  };

  /**
   * 获取可用序列
   */
  private getAvailableSeqs = async (startTime?: string, endTime?: string, keyword?: string) => {
    const { getFieldValue } = this.props.form;
    const assigned = getFieldValue('project-task-status')
      ? getFieldValue('project-task-status') === '1'
        ? false
        : true
      : '';
    const { seqPagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const projectId = Number(this.props.match.params.pId);
      const { result } = await ProxAiManageApi.getProjectSeqs<INormalList<IAvailableSeqItem[]>, IProjectSeqsReq>({
        page: 1,
        pageSize: 1000,
        projectId,
        keyword,
        startTime,
        endTime,
        assigned,
      });
      if (result) {
        seqPagination.pageSize = 10;
        seqPagination.total = result.total;
        this.setState({
          seqPagination,
          availableSeqs: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };

  private getMembers = async () => {
    const { result } = await ProxAiManageApi.getProjectMembers<IMemeberItem[]>();
    if (result) {
      this.setState({
        members: result,
      });
    }
  };
  /**
   * 获取用户列表
   */
  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({
        userItems: [...result],
      });
    }
  };
  /**
   * 选中所有
   */
  private onSelectedAll = (selected: boolean, selectedRows: IAvailableSeqItem[], changeRows: IAvailableSeqItem[]) => {
    const { availableSeqs } = this.state;
    this.setState({
      selectedSeqs: selected ? [...availableSeqs.map(e => e.seriesInstanceUID)] : [],
    });
  };
  /**
   * 点击返回 按钮
   */
  private gobackWithOption = () => {
    this.props.history.goBack();
  };
}
const NewProjectTask = Form.create()(NewProjectTaskPage);
export default NewProjectTask;
