import { Table } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import HttpRequest from '../../../../api/DataManager';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../../components/NoDataImg/NoDataImg';
import DataShareHeader, { IHeaderType } from '../DataShareHeader/DataShareHeader';
import { IDictions, INormalList, IOriginalShareDataItem, IOriginalSharedReq, IPagination } from './../../CodeType';

interface IOriginalSharedProp {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  // devicesDiction: Map<string, IDictions>; // 网络返回的 设备字典
  partsDiction: Map<string, IDictions>; // 网络返回的 部位字典
  devicesDiction: Map<string, IDictions>; // 设备
}
interface IOriginalState {
  originalPagination: IPagination;
  normalList: IOriginalShareDataItem[];
  isLoading: boolean;
}

export class OriginalDataComponent extends React.PureComponent<IOriginalSharedProp, IOriginalState> {
  public originalShareDataColums = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUID',
      key: 'seriesInstanceUID',
      render: (text: string, record: IOriginalShareDataItem) => {
        return (
          <div className="sequences-uuid-table divTitle" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '上传时间',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: '检查号',
      dataIndex: 'studyInstanceUID',
      key: 'studyInstanceUID',
      render: (text: string, record: IOriginalShareDataItem) => {
        return (
          <div className="sequences-uuid-table" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
      render: (text: string, record: IOriginalShareDataItem) => {
        return <div> {text ? text : '未知'} </div>;
      },
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IOriginalShareDataItem) => {
        const partName = this.props.devicesDiction.get(record.modality);
        return <div> {partName ? partName.name : ''} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IOriginalShareDataItem) => {
        const partName = this.props.partsDiction.get(record.bodyPart);
        return <div> {partName ? partName.name : ''} </div>;
      },
    },
  ];

  constructor(props: IOriginalSharedProp) {
    super(props);
    this.state = {
      originalPagination: { total: 0, pageSize: 10, current: 1 },
      normalList: [],
      isLoading: false,
    };
  }
  public componentDidMount() {
    this.getOriginalShareData();
  }
  public render() {
    return (
      <div>
        <DataShareHeader
          type={IHeaderType.Original}
          searchClick={this.handleTableHeaderSearch}
          form={this.props.form}
        />

        <AuthComponent isShow={!!this.state.originalPagination.total}>
          <Table
            columns={this.originalShareDataColums}
            dataSource={this.state.normalList}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.originalPagination }}
            rowKey="seriesInstanceUID"
            onChange={this.handleOriginalTableChanage}
          />
        </AuthComponent>
        <NoDataImg isLoading={this.state.isLoading} total={this.state.originalPagination.total} />
      </div>
    );
  }

  /*
   *原始数据列表 网络请求
   */
  public getOriginalShareData = async (origSearchSeqId?: string) => {
    const { originalPagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const { result } = await HttpRequest.getOriginalShareDataList<
        INormalList<IOriginalShareDataItem[]>,
        IOriginalSharedReq
      >({
        page: originalPagination.current,
        seriesInstanceUID: origSearchSeqId,
      });
      if (result) {
        originalPagination.current = result.current;
        originalPagination.total = result.total;
        const records = result.records;

        this.setState({
          normalList: [...records],
          originalPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * @todo OriginalDataComponent Action
   */

  /**
   * 列表分页处理
   */
  private handleOriginalTableChanage = (pagination: PaginationConfig) => {
    const { originalPagination } = this.state;
    originalPagination.current = pagination ? (pagination.current ? pagination.current : 1) : 1;
    this.setState({
      originalPagination,
    });
    this.getOriginalShareData();
  };
  /**
   * 原始数据 按照序列号查询
   */

  private handleTableHeaderSearch = () => {
    const origSearchSeqId = this.props.form.getFieldValue('originalPatientSequenceNum');

    this.getOriginalShareData(origSearchSeqId);
  };
}

export default OriginalDataComponent;
