import { Spin } from 'antd';
import React from 'react';
import './NoDataImg.scss';
/**
 *  数据没有时展示信息
 *  数据加载loding
 *  @param { isLoading } 是否在加载中
 *  @param { isLoading } 是否有数据
 */
class NoDataImg extends React.Component<{ isLoading: boolean; total: number; msg?: string }> {
  public render() {
    return (
      <div id="NoDataImg">
        {this.isShowData()}
        <div className="tran">
          <div className={this.props.isLoading ? 'none' : 'load'}>
            <Spin />
          </div>
        </div>
      </div>
    );
  }

  private isShowData = () => {
    if (this.props.isLoading && !this.props.total) {
      return (
        <div className="no-data-container">
          <img src={require('./../../resource/no-data.svg')} className="no-img" />
          <div className="no-txt">{this.props.msg || '暂时还没有数据...'}</div>
        </div>
      );
    }
  };
}
export default NoDataImg;
