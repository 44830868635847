// 缓存常量
export const COLLAPSED = 'collapsed';
export const TOKEN = 'token';
export const USERID = 'userid';
export const LOGIN = 'login';
export const LOGINNAME = 'loginname';
export const NODENAME = 'nodeName';
export const USERTYPE = 'usertype';
export const PARTDICTIONSKEY = 'PARTDICTIONSKEY'; // 保存部位,设备key
export const USERPERMISSION = 'permissions';
export const USERROLEITEMS = 'userRoleItems';
class Storage {
  public static setStore = (key: string, content: string) => {
    if (!key) {
      return;
    } else {
      localStorage.setItem(key, content);
    }
  };
  public static getStore = (key: string) => localStorage.getItem(key) || '';
  public static delStore = (key: string) => {
    localStorage.removeItem(key);
  };
  public static clearStore = () => localStorage.clear;
}
export default Storage;
export interface IParentOrder {
  // 总数据使用
  code: string;
  contractNumber: number;
  created: string;
  dataOrderId: number;
  days: number;
  deleted: string;
  id: number;
  coinPrice: number;
  availableDays: number;
  updated: string;
  vmOrderId: number;
}
