import { Button, Divider, Modal, Radio, Select, Table } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { TableRowSelection } from 'antd/lib/table';
import React from 'react';
import { IDictions, IShareSequenceItem, IWaitingShareDataItem } from '../../CodeType';
import './SeqsModalComponent.scss';
const { Option } = Select;

export enum ISeqsModalType {
  OneTask,
  MoreTasks,
}
interface ISeqModalProps {
  type: ISeqsModalType;
  visiable: boolean;
  waitItem?: IWaitingShareDataItem; // 记录传入的待共享数据
  waitItems: IWaitingShareDataItem[]; // 记录多任务
  onCloseModal: () => void;
  onOkModal: (item: IWaitingShareDataItem) => void;
  onOkMoreModal: (items: IWaitingShareDataItem[]) => void;
  totalSeqs: number;
  startShared: boolean;
  partsDiction: Map<string, IDictions>; // 网络返回的 部位字典
  devicesDiction: Map<string, IDictions>; // 网络返回的 设备字典
  // updataWaitItem: (item: IWaitingShareDataItem) => void; // 更新当前待共享任务
}
interface ISeqModalState {
  seqTableType: number; // 记录当前显示的是哪个列表
  modalItems: IWaitingShareDataItem[];
  seqDatas: IShareSequenceItem[]; // 当前任务的序列
  selModalItem?: IWaitingShareDataItem; // 当前显示的任务序列

  //   modalItems?: IWaitingShareDataItem[];
}

class SeqsModalComponent extends React.PureComponent<ISeqModalProps, ISeqModalState> {
  public SequencesColums = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUID',
      key: 'seriesInstanceUID',
      render: (text: string, record: IShareSequenceItem) => {
        return (
          <div
            className="sequences-uuid-table"
            title={text}
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '200px' }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: '检查号',
      dataIndex: 'studyInstanceUID',
      key: 'studyInstanceUID',
      render: (text: string, record: IShareSequenceItem) => {
        return (
          <div
            className="sequences-uuid-table"
            title={text}
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '200px' }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IShareSequenceItem) => {
        const partName = this.props.devicesDiction.get(record.modality);

        return <div> {partName ? partName.name : ''} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IShareSequenceItem) => {
        const partName = this.props.partsDiction.get(record.bodyPart);

        return <div> {partName ? partName.name : ''} </div>;
      },
    },
  ];
  constructor(props: ISeqModalProps) {
    super(props);
    this.state = {
      seqTableType: 1,
      modalItems: [],
      seqDatas: [],
    };
  }

  public componentWillReceiveProps(nextProps: ISeqModalProps) {
    // prop 改变是重新赋值
    const { type, waitItem, waitItems } = nextProps;
    if (type === ISeqsModalType.OneTask) {
      // 只显示一个任务时
      if (waitItem) {
        this.setState({
          seqDatas: [...waitItem.seqs],
          selModalItem: waitItem,
        });
      }
    } else {
      // 同时显示多个任务时

      if (waitItems.length > 0) {
        this.setState({
          modalItems: [...waitItems],
          selModalItem: waitItems[0],
          seqDatas: waitItems[0].seqs,
          seqTableType: 2,
        });
      }
    }
  }
  public render() {
    const { type, visiable } = this.props;
    if (!visiable) {
      // 如果是为不显示状态,就不 render 了

      return null;
    }
    return type === ISeqsModalType.OneTask ? this.oneTaskSeqModal() : this.moreTaskSeqsModal();
  }
  /**
   * 只显示一个任务时的Modal
   */
  private oneTaskSeqModal = () => {
    const footer = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '24px' }}>
        <Button type="primary" className="action-btn" onClick={this.seqModalOkClick}>
          确认
        </Button>
        <Button className="ant-btn-no " onClick={this.seqModalCancelClick}>
          取消
        </Button>
      </div>
    );
    return (
      <Modal
        visible={this.props.visiable}
        className="seqs-modal-component"
        width="1170px"
        centered={true}
        cancelText="确认"
        okText="取消"
        title={this.props.waitItem ? this.props.waitItem.name : ''}
        closable={false}
        onCancel={this.seqModalCancelClick}
        footer={footer}
      >
        {this.oneTaskSeqTable()}
      </Modal>
    );
  };

  /**
   * 多任务 序列模态框
   */

  private moreTaskSeqsModal = () => {
    const { waitItems } = this.props;
    const { selModalItem } = this.state;

    if (!selModalItem) {
      return null;
    }

    const selSeq: IShareSequenceItem[] = selModalItem.seqs.filter(s => {
      return s.noChected === false;
    });

    // 已选序列按钮点击
    const rowNoSelection: TableRowSelection<IShareSequenceItem> = {
      // 待选序列类表
      selectedRowKeys: selSeq.map((v: IShareSequenceItem) => v.seriesInstanceUID),
      onChange: this.moreModalSelSeqTableChange,
    };

    const HeaderRadio = waitItems.map(item => (
      <Option value={item.id} key={item.id}>
        {item.name}
      </Option>
    ));

    const footer = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '24px' }}>
        <Button type="primary" className="action-btn" onClick={this.moreModalOkClick}>
          确认
        </Button>
        <Button className="ant-btn-no " onClick={this.moreModalCancelClick}>
          取消
        </Button>
      </div>
    );
    const title = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span>已选序列</span>
        <div style={{ width: '1px', marginLeft: '7px', marginRight: '7px', backgroundColor: 'rgba(214,214,214,1)' }} />
        <span>{this.props.totalSeqs} </span>
      </div>
    );

    return (
      <Modal
        visible={this.props.visiable}
        className="seqs-modal-component"
        width="1170px"
        centered={true}
        cancelText="确认"
        okText="取消"
        title={title}
        footer={footer}
        onCancel={this.seqModalCancelClick}
        closable={false}
      >
        <Divider>
          <div className="radio-container">
            <Select
              defaultValue={this.state.selModalItem!.id}
              onChange={this.taskSelectChange}
              style={{ minWidth: '100px' }}
            >
              {HeaderRadio}
            </Select>
          </div>
        </Divider>

        <Table
          columns={this.SequencesColums}
          dataSource={selModalItem.seqs}
          style={{ backgroundColor: 'white' }}
          pagination={{ total: selModalItem.seqs.length, pageSize: 5 }}
          rowSelection={selModalItem!.check ? rowNoSelection : undefined}
          rowKey="seriesInstanceUID"
          // onChange={this.morehandleTableChange}
        />
      </Modal>
    );
  };
  /**
   * 只显示一个任务时的序列列表
   */
  private oneTaskSeqTable = () => {
    const { seqDatas } = this.state;
    if (!seqDatas) {
      return null;
    }
    const selSeq = seqDatas.filter(v => {
      // 选中的序列
      return v.noChected === false;
    });
    const rowSelection: TableRowSelection<IShareSequenceItem> = {
      // 已选序列列表
      onChange: this.sequencesTableChange,
      selectedRowKeys: selSeq.map((v: IShareSequenceItem) => {
        return v.seriesInstanceUID;
      }),
    };
    return (
      <div>
        <Divider>
          <div className="list-type-center-container ">
            <Radio.Group
              defaultValue={1}
              buttonStyle="solid"
              onChange={this.seqRadioTypeChanage}
              className="seq-radio-container"
            >
              <Radio.Button value={1}>可选列表</Radio.Button>

              <Radio.Button value={2}>已选列表</Radio.Button>
            </Radio.Group>
          </div>
        </Divider>
        {// tslint:disable-next-line:jsx-no-multiline-js
        this.state.seqTableType === 1 ? (
          <Table
            columns={this.SequencesColums}
            dataSource={seqDatas}
            style={{ backgroundColor: 'white' }}
            pagination={{ total: selSeq.length, pageSize: 5 }}
            rowSelection={this.props.startShared ? rowSelection : undefined}
            rowKey="seriesInstanceUID"
            // onChange={this.handleTableChange}
          />
        ) : (
          <Table
            columns={this.SequencesColums}
            dataSource={selSeq}
            style={{ backgroundColor: 'white' }}
            pagination={{ total: selSeq.length, pageSize: 5 }}
            rowSelection={rowSelection}
            rowKey="seriesInstanceUID"
            // onChange={this.handleTableChange}
          />
        )}
      </div>
    );
  };

  /**
   * 切换可选列表,已选列表
   */
  private seqRadioTypeChanage = (e: RadioChangeEvent) => {
    this.setState({
      seqTableType: e.target.value,
    });
  };

  /**
   * seqModal 确认按钮click
   */

  private seqModalOkClick = () => {
    const { selModalItem, seqDatas } = this.state;
    const selSeq = seqDatas.filter(s => !s.noChected);
    selModalItem!.check = selSeq.length > 0 ? true : false;

    this.props.onOkModal(selModalItem!); // 把修改后的值 传回去 修改 待共享页面的选中状态
  };

  /**
   * seqModal cancel按钮click
   */
  private seqModalCancelClick = () => {
    //
    this.props.onCloseModal();
  };
  /**
   * 列表变化
   */
  private sequencesTableChange = (selectedRowKeys: string[] | number[], selectedRows: IShareSequenceItem[]) => {
    // 修改当前的列表内容
    const { seqDatas, selModalItem } = this.state;

    seqDatas.map(s => {
      s.noChected = true;
    });
    seqDatas.map(s => {
      selectedRows.forEach(sel => {
        if (s.seriesInstanceUID === sel.seriesInstanceUID) {
          s.noChected = false;
        }
      });
      return s;
    });

    // modalItem!.seqs = [...seqDatas];
    this.setState({ selModalItem, seqDatas: [...seqDatas] });
  };
  /**
   * 任务切换
   *
   */
  private taskSelectChange = (value: string) => {
    const { modalItems } = this.state;

    modalItems.map(v => {
      if (v.id === value) {
        this.setState({
          selModalItem: v,
        });
      }
    });
  };
  /**
   *  多任务 Modal 列表的 选中变化
   */
  private moreModalSelSeqTableChange = (selectedRowKeys: string[] | number[], selectedRows: IShareSequenceItem[]) => {
    //
    // 修改当前的列表内容
    const { seqDatas, selModalItem, modalItems } = this.state;
    seqDatas.map(s => {
      s.noChected = true;
    });
    seqDatas.map(s => {
      selectedRows.forEach(sel => {
        if (s.seriesInstanceUID === sel.seriesInstanceUID) {
          s.noChected = false;
        }
      });
      return s;
    });

    // modalItem!.seqs = [...seqDatas];
    const tasks = modalItems.map(item => {
      if (item.id === selModalItem!.id) {
        item.seqs = [...seqDatas];
      }
      return item;
    });
    this.setState({ selModalItem, seqDatas: [...seqDatas], modalItems: [...tasks] });
  };
  /**
   * 多任务 Modal  确认
   */
  private moreModalOkClick = () => {
    //
    const { modalItems } = this.state;
    const newModalItems = modalItems.map(item => {
      const seqs = item.seqs.filter(s => !s.noChected);
      item.check = seqs.length > 0 ? true : false;
      return item;
    });
    this.props.onOkMoreModal([...newModalItems]);
  };
  /**
   * 多任务取消
   */
  private moreModalCancelClick = () => {
    //
    this.props.onCloseModal();
  };
}

export default SeqsModalComponent; // 创建form实例
