import { Button, Col, Drawer, Form, Input, Row, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { PaginationConfig } from 'antd/lib/table';
import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import HttpRequest from '../../../api/MyDataApi';
import InfoDrawer from '../../../components/InfoDrawer/InfoDrawer';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';
import DataSet, { IDataItem } from '../../../util/DataSet';

import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import { IDataList, IItemList, IMyDatastate } from './CodeType';
import './MyData.scss';
interface IMyDataprops extends FormComponentProps, RouteComponentProps {}

const { Option } = Select;

class DataSearch extends React.PureComponent<IMyDataprops, IMyDatastate> {
  public state = {
    visible: false,
    visibleDownload: false,
    list: [],
    listbk: [],
    diseaseList: [],
    pagination: { total: 0, pageSize: 10, current: 1 },
    isLoading: true,
  } as IMyDatastate;
  public columns = [
    {
      title: '项目名称',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: '数据格式',
      dataIndex: 'project_data_format',
      key: 'project_data_format',
    },
    {
      title: '等级',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: '病种',
      dataIndex: 'disease',
      key: 'disease',
    },
    {
      title: <div className="myDataAmountTitle">数量</div>,
      dataIndex: 'project_amount',
      key: 'project_amount',
      width: 80,
      className: 'myDataAmount',
    },
    {
      title: '状态',
      dataIndex: 'statusStr',
      key: 'statusStr',
    },
    {
      title: <div style={{ paddingLeft: '12px' }}>操作</div>,
      width: 100,
      key: 'action',
      className: 'myDataOpt',
      render: (text: string, record: { [index: string]: string | number | object | undefined }) => {
        // eslint-disable-next-line no-nested-ternary
        const btnGroup = (
          <Fragment>
            <div className="myDataOptBtn">
              <a
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => {
                  this.detailViewRender(Number(record.id));
                }}
                title="查看详情"
              >
                <span style={{ display: 'none' }}>{record.id}</span>
                查看详情
              </a>
            </div>
          </Fragment>
        );
        return btnGroup;
      },
    },
  ];
  /**
   * 显示抽屉
   */
  public showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  public onClose = () => {
    this.setState({
      visible: false,
    });
  };
  /**
   * 数据详情
   */
  public detailViewRender = async (id: number) => {
    const { result } = await HttpRequest.getPackageInfo<IItemList, { id: number }>({
      id,
    });
    if (result) {
      this.setState({
        detail: result,
      });
      this.showDrawer();
    }
  };

  /**
   * 打开下载详情
   */
  public handelDownload = () => {
    this.setState({
      visibleDownload: true,
    });
  };
  /**
   * 下载详情关闭
   */
  public handelClose = () => {
    this.setState({
      visibleDownload: false,
    });
  };
  /**
   *  我的数据查询
   */
  public handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      let listnew: IDataItem[] = this.state.listbk;
      if (values.type) {
        listnew = this.state.listbk.filter((v: IDataItem) => values.type.toString() === v.type.toString());
      }
      if (values.disease) {
        listnew = this.state.listbk.filter((v: IDataItem) => values.disease.toString() === v.disease.toString());
      }
      this.setState({
        list: listnew,
      });
    });
  };

  /**
   * 分页处理
   */
  public handleTableChange = (paginations: PaginationConfig) => {
    const { pagination } = this.state;
    pagination.current = paginations.current ? paginations.current : 1;
    this.setState({
      pagination,
    });
  };
  public componentDidMount() {
    this.getMyDatas();
  }

  /**
   * 获取我的数据列表
   */

  public getMyDatas = async () => {
    const { pagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const { result } = await HttpRequest.getMyDatas<
        IDataList,
        { pageNum: number; pageSize: number; status?: number }
      >({
        pageNum: pagination.current,
        pageSize: pagination.pageSize ? pagination.pageSize : 10,
      });
      if (result && result.records) {
        pagination.current = result.current!;
        pagination.total = result.total!;
        const list = DataSet.dataArrSet(result);
        const diseaseList = list.map(v => {
          return v.disease;
        });
        this.setState({
          list,
          listbk: list,
          diseaseList: Array.from(new Set(diseaseList)),
          pagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 下载表格 render
   */
  public renderDownLoadForm() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <div>
        <Form layout="inline">
          <Form.Item label="状态">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('t1')(<Input placeholder="Username" />)}
          </Form.Item>
          <Form.Item label="状态">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('t2')(<Input placeholder="Username" />)}
          </Form.Item>
        </Form>
      </div>
    );
  }
  /**
   * 表头 render
   */

  public renderFormHeader = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const statusType = [{ key: '1', value: '基因' }, { key: '2', value: '影像' }];
    const statusSelect = statusType.map(item => (
      <Option key={item.key} value={item.key}>
        {item.value}
      </Option>
    ));

    const diseaseSelect = this.state.diseaseList.map(item => (
      <Option key={item} value={item}>
        {item}
      </Option>
    ));
    return (
      <Form layout="inline" className="table-header">
        <Row style={{ width: '100%', display: 'flex' }}>
          <Col style={{ display: 'flex', alignItems: 'center', width: '250px', marginRight: '54px' }}>
            <Form.Item label="数据类型">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('type')(
                <Select style={{ width: '156px' }} placeholder="请选择" allowClear={true}>
                  {statusSelect}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center', width: '240px', marginRight: '54px' }}>
            <Form.Item label="病种">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('disease')(
                <Select style={{ width: '156px' }} placeholder="请选择" allowClear={true}>
                  {diseaseSelect}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col md={8} sm={24} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              // htmlType="submit"
              className="normal-search-button"
              onClick={this.handleSubmit}
              style={{ width: '96px' }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  public render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { detail } = this.state;
    const detailJson = detail ? DataSet.dataInfoSet(detail) : {};
    const { pagination } = this.state;
    const btnGroup = (
      <div>
        <Button onClick={this.onClose} type="primary">
          返回
        </Button>
      </div>
    );

    return (
      <IndexLayout>
        <div id="MyData" className="myDatas">
          <RouteHeader title="我的数据" hiddenBreadcrumb={true} />
          <div>
            <div>{this.renderFormHeader()}</div>

            <AuthComponent isShow={!!this.state.pagination.total}>
              <Table
                columns={this.columns}
                dataSource={this.state.list}
                style={{ backgroundColor: 'white' }}
                pagination={{ ...pagination }}
                rowKey="orderCode"
                onChange={this.handleTableChange}
              />
            </AuthComponent>
            <NoDataImg isLoading={this.state.isLoading} total={this.state.pagination.total} />
          </div>
          <div>
            <InfoDrawer
              data={detailJson}
              visible={this.state.visible}
              onClose={this.onClose}
              btnGroup={btnGroup}
              demoUrl={detail ? detail.demoUrl : ''}
              allData={detail}
            />
            <Drawer
              title="下载申请"
              placement="right"
              closable={false}
              width={564}
              visible={this.state.visibleDownload}
              onClose={this.handelClose}
            >
              <Form layout="inline" className="DataSearchForm" style={{ width: '100%' }}>
                <Form.Item label="请选择数据使用编号：">
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('t1')(
                    <Select style={{ width: '6em' }} placeholder="请选择" allowClear={true}>
                      <Option value="jack">Jack</Option>
                    </Select>
                  )}
                </Form.Item>
                <div>
                  <Form.Item style={{ width: '100%' }}>
                    {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                    {getFieldDecorator('t2')(
                      <TextArea rows={4} placeholder="点击输入下载说明" style={{ width: '100%' }} />
                    )}
                  </Form.Item>
                </div>
              </Form>
            </Drawer>
          </div>
        </div>
      </IndexLayout>
    );
  }
}
const DataSearchList = Form.create()(DataSearch);
export default DataSearchList;
