import axios from './RequestApi';
/**
 * @class 科研管理
 */
export default class SciManageApi {
  /**
   * @function 查询科研项目列表
   *
   */
  public static getSciResearchProjects<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/researchProject/page', data);
  }
  /**
   *  @function 新建一个科研项目
   * @param data 科研项目信息
   */
  public static createSciProject<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/researchProject/create', data);
  }

  /**
   *
   * @function 获取原始数据列表
   */
  public static getOriginalShareDataList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/originalData/page', data);
  }
  /**
   *  @function 科研项目引入原始数据
   * @param data 科研项目信息
   */
  public static importSciProject<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/originalData/importOriginalData', data);
  }

  /**
   * @function 批量下载原始数据
   */
  public static downloadOriginalDatas<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/originalData/download', data);
  }

  /**
   * @function 创建临时数据
   */
  public static createTemparyDatas<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/intermediateData/create', data);
  }
  /**
   *
   * @function 获取临时数据列表
   */
  public static getTemplateDatas<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/intermediateData/page', data);
  }

  public static getProjectPackageDatas<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/researchProjectPackage/page', data);
  }
  /**
   * @function 批量下载临时数据
   */
  public static downloadTemparyDatas<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/intermediateData/download', data);
  }
  /**
   * @function 临时数据转换成数据包
   */
  public static transformTemparyPackage<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/intermediateData/transformPackageData', data);
  }
  /**
   *
   * @param 鉴影已完成任务列表
   */
  public static getProxAiProjectPackages<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/privateNode/waitShareData', data);
  }

  /**
   * @function 临时数据转换成数据包
   */
  public static saveProxAiData<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/jy/privatedNode/saveShareData', data);
  }
  /**
   * 科研数据包下载
   */
  public static sciPkgDownload<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/researchProjectPackage/download', data);
  }
  /**
   * 获取应用工具 -创建鉴影任务序列列表
   */
  public static getAppToolsTaskSeqs<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/originalData/page', data);
  }
  /**
   * 获取应用工具 -鉴影任务列表
   */
  public static getAppToolsProxaiTasks<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/apricot/jy/privatedNode/project/taskPage', data);
  }
  /**
   * 创建鉴影任务
   */
  public static saveSciPkgTask<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/apricot/jy/privatedNode/task/save', data);
  }
  /**
   *  获取安全盒订单安全盒控制台详情
   */
  public static getVmOrderConsoleInfo<T, D>(data: D) {
    return axios.get<T, D>(`/user/logined/vmInstance/getVmConsoleInfo`, data);
  }
  /**
   *
   * 获得科研项目下所有虚拟机信息
   */
  public static getVNDetail<T, D>(data: D) {
    return axios.get<T, D>(`/user/logined/orderVm/getOrderVmUserInfo`, data);
  }

  /**
   * 获取数据分析人员数据
   */
  public static getDataUserList<T, D>(data: D) {
    return axios.get<T, D>(`/user/logined/researchProject/selectProjectUserList`, data);
  }

  /**
   *  修改网关状态
   */
  public static changeNetworkStatus(data: { vmInstanceId: number }) {
    return axios.delete(`/user/logined/vmInstance/deleteNetworkCard`, data);
  }
}
