import { Button, Divider, Drawer, Dropdown, Menu, message, Modal, Radio, Table } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import DataManagerApi from '../../../../api/DataManager';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import { ITreeData } from '../../../../components/FileTree/FilePathTree';
import TradeDetailDrawer from '../../../../components/InfoDrawer/TradeDetailDrawer';
import NoDataImg from '../../../../components/NoDataImg/NoDataImg';
import { IconFont } from '../../../../config/IconConfig';
import DataShareHeader, { IHeaderType } from '../DataShareHeader/DataShareHeader';
import { DownloadPath } from '../DownloadPath/DownloadPath';
import { GrantSet } from '../GrantSet/GrantSet';
import Storage from './../../../../util/Storage';
import { IAlreadyShareDataItem, IGrant, IHasSharedReq, INormalList } from './../../CodeType';
import { guid, hasShareType } from './../../DataMSetting';
import { IHasSharedState, IOptionUser, IPackageSeqs, SequencesColumsNoDetail } from './CodeType';
import './HasSharedDataComponent.scss';
// import Item from 'antd/lib/list/Item';
/**
 * @param { pagination } 分页信息
 * @param { datas }  列表显示数据
 * @param { handleTableChange } 列表分页点击事件处理
 * @param { handleTableHeaderSearch } 表头的查询事件
 * @param { form } 用from 进行输入数据获取,可以直接获取子元素里的form 表单
 */

export enum IHasSharedCellClickType {
  ShowSharePackageDetail, // 显示 已共享数据详情
  AuthDistribution, // 权限分配
  DowloadPackage, // 下载数据包
  ShowSeqs, // 显示序列列表
}

interface IHasSharedProp {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
}
export class HasSharedDataComponent extends React.PureComponent<IHasSharedProp, IHasSharedState> {
  public state = {
    isJD: false,
    item: {},
    hasSharedItems: [],
    hasSharedpagination: { total: 0, pageSize: 10, current: 1 },
    shareSeqPagination: { total: 0, pageSize: 10, current: 1 },
    grants: [],
    authModal: false,
    pathList: [],
    pathModal: false,
    id: -1,
    code: '',
    seqVisible: false,
    clickItemSeqs: [],
    packageEditVisible: false,
    packageSelectedStatus: 1,
    diseaseItems: [],
    packageDrawerVisible: false,
    isLoading: true,
    userItems: [],
    detailVisible: false,
    detailString: '',
  } as IHasSharedState;
  public SequencesColums = [
    {
      title: '文件编号',
      dataIndex: 'fileCode',
      key: 'fileCode',
      columnWidth: '60px',
      width: '100px',
    },
    {
      title: '文件名',
      dataIndex: 'fileName',
      key: 'fileName',
      width: '100px',
    },
    {
      title: '病例编号',
      dataIndex: 'patientCode',
      key: 'patientCode',
      width: '100px',
    },
    {
      title: '病例名称',
      dataIndex: 'disease',
      key: 'disease',
      width: '100px',
    },
    {
      title: '序列详情',
      dataIndex: 'detail',
      key: 'detail',
      render: (text: string, record: IPackageSeqs) =>
        record.descJson && record.descJson !== '{}' ? (
          <a href="javascript:void(0)" onClick={() => this.detailShow(record.descJson)}>
            详情
          </a>
        ) : null,
      width: '100px',
    },
  ];

  public hasShareColumns = [
    {
      title: '项目名称',
      dataIndex: 'package.project_name',
      key: 'package.project_name',
      width: '150px',
    },
    {
      title: '数据格式',
      dataIndex: 'package.project_data_format',
      key: 'package.project_data_format',
    },
    {
      title: '数据类型',
      dataIndex: 'package.project_data_type',
      key: 'package.project_data_type',
      render: (type: number, record: IAlreadyShareDataItem) => {
        const text = type === 1 ? '基因' : '影像';
        return <span> {text}</span>;
      },
    },
    {
      title: '数量',
      dataIndex: 'package.project_amount',
      key: 'package.project_amount',
    },
    {
      title: '病种',
      dataIndex: 'package.project_disease_type',
      key: 'package.project_disease_type',
      width: '100px',
    },
    {
      title: '等级',
      dataIndex: 'package.project_score',
      key: 'package.project_score',
      // defaultSortOrder: 'descend' as SortOrder,
      // sorter: (a: IAlreadyShareDataItem, b: IAlreadyShareDataItem) => a.package.score - b.package.score,
    },
    {
      title: '积分',
      dataIndex: 'coinPrice',
      key: 'coinPrice',
    },

    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',

      render: (type: number, record: IAlreadyShareDataItem) => {
        const color = type === 1 ? 'rgba(208, 2, 27, 1)' : 'rgba(0, 0, 0, 0.65)';
        return (
          <span
            style={{ color }}
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              if (type === 4) {
                this.changePackageStatus(record);
              }
            }}
          >
            {hasShareType[type - 1]}
            <span hidden={type !== 4} style={{ marginLeft: '5px' }}>
              <IconFont type="medataicon-xiugai" style={{ color: '#00a1e6' }} />
            </span>
          </span>
        );
      },
    },
    {
      title: <div style={{ paddingLeft: '12px' }}>操作</div>,
      key: 'action',

      className: 'row-action-item',
      render: (text: string, record: IAlreadyShareDataItem) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className="dm-table-action"
              onClick={
                // tslint:disable-next-line:jsx-no-multiline-js
                () => {
                  this.cellClick(record, IHasSharedCellClickType.ShowSharePackageDetail);
                }
              }
            >
              数据详情
            </div>
            <Divider type="vertical" />
            <div
              className="dm-table-action"
              onClick={
                // tslint:disable-next-line:jsx-no-multiline-js
                () => {
                  this.cellClick(record, IHasSharedCellClickType.DowloadPackage);
                }
              }
            >
              下载
            </div>

            <Divider type="vertical" />
            <Dropdown overlay={this.moreAction(record)} placement="bottomRight" trigger={['click']}>
              <div style={{ display: 'flex', alignItems: 'center', width: '80px', cursor: 'pointer' }}>
                <IconFont type="medataicon-gengduo" className="down" />
              </div>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  public componentDidMount() {
    this.getHasSharedData();
    this.getDiseasesOptions();
    this.getMembersOptions();
    this.setState({
      isJD: Storage.getStore('usertype') === '5',
    });
  }
  // tslint:disable-next-line:no-any
  public objToArr = (obj: any) => {
    const arr = [];
    for (const i in obj) {
      if (obj[i]) {
        arr.push({ title: i, content: obj[i] });
      }
    }
    return arr;
  };
  public render() {
    const records = this.state.hasSharedItems;
    const noDetail: boolean = (this.state.clickItemSeqs.filter(s => s.descJson !== '{}').length === 0) as boolean;

    const permissions: string[] = JSON.parse(Storage.getStore('permissions') || '[]');
    if (
      permissions.includes('USER_ALL_DATA_READ') &&
      !this.hasShareColumns.some(item => item.dataIndex === 'userName')
    ) {
      this.hasShareColumns.splice(4, 0, {
        title: '用户名',
        dataIndex: 'userName',
        key: 'userName',
        width: '100px',
      });
    }
    const footer = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.seqModalOkClick}>
          确认
        </Button>
        <Button className="ant-btn-no " onClick={this.seqModalCancelClick}>
          取消
        </Button>
      </div>
    );
    const footerEditPackage = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '60px' }}>
        <Button type="primary" className="action-btn" onClick={this.packageEditOkClose}>
          确认
        </Button>
        <Button className="ant-btn-no " onClick={this.packageEditClose}>
          取消
        </Button>
      </div>
    );
    const tableCol = this.hasShareColumns.filter(item => {
      if (this.state.isJD) {
        return item.title !== '积分' && item.title !== '等级' && item.title !== '状态';
      } else {
        return true;
      }
    });
    return (
      <div className="has-shared-data-component">
        <DataShareHeader
          type={IHeaderType.HasShare}
          searchClick={this.handleHasShareSearchAction}
          diseaseTypeNameOptions={this.state.diseaseItems}
          userItems={this.state.userItems}
          form={this.props.form}
        />

        <AuthComponent isShow={!!this.state.hasSharedpagination.total}>
          <Table
            columns={tableCol}
            dataSource={records}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.hasSharedpagination }}
            rowKey="id"
            onChange={this.handleHasTableChanage}
          />
        </AuthComponent>
        <NoDataImg isLoading={this.state.isLoading} total={this.state.hasSharedpagination.total} />
        <GrantSet
          grants={this.state.grants}
          onOk={this.authDistributionOK}
          onCancel={this.authDistributionHide}
          visible={this.state.authModal}
        />
        <DownloadPath
          path={this.state.pathList}
          onOk={this.downloadHasSharedDataOK}
          onCancel={this.downloadHasSharedDataHide}
          visible={this.state.pathModal}
          title="项目数据包下载"
        />
        <Modal
          visible={this.state.seqVisible}
          className="seqs-modal-component"
          width="1170px"
          centered={true}
          cancelText="确认"
          okText="取消"
          title={this.state.clickItem ? this.state.clickItem.package.project_name : ''}
          closable={false}
          onCancel={this.seqModalCancelClick}
          footer={footer}
        >
          <Table
            columns={noDetail ? SequencesColumsNoDetail : this.SequencesColums}
            dataSource={this.state.clickItemSeqs}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.shareSeqPagination }}
            rowKey={(record, index) => record.fileCode + index}
            onChange={this.handleTableChange}
          />

          <Modal className="seqs-detail" visible={this.state.detailVisible} onCancel={this.detailHide} footer={false}>
            {this.detailRender(this.state.detailString)}
          </Modal>
        </Modal>
        <Modal
          visible={this.state.packageEditVisible}
          className="hint-modal-component"
          width="480px"
          centered={true}
          title={'共享状态'}
          closable={false}
          onCancel={this.packageEditClose}
          footer={footerEditPackage}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginRight: '20px', fontWeight: 'bold', color: 'rgba(0,0,0,0.64)' }}>共享状态选择: </div>
            <Radio.Group onChange={this.radioGroupChange} value={this.state.packageSelectedStatus}>
              <Radio value={1}>暂不共享</Radio>
              <Divider type="vertical" style={{ marginLeft: '8px', marginRight: '8px' }} />
              <Radio value={2} style={{ marginLeft: '16px' }}>
                立即共享
              </Radio>
            </Radio.Group>
          </div>
        </Modal>

        <Drawer
          title="数据包详情"
          placement="right"
          width={700}
          closable={false}
          onClose={this.packageDrawerClose}
          visible={this.state.packageDrawerVisible}
        >
          <TradeDetailDrawer
            showOrderMessage={false}
            showPackageView={this.state.packageDrawerVisible}
            showSecurityBox={false}
            dataPackage={this.state.clickItem ? this.state.clickItem.package : undefined}
            // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
            onClose={() => {
              this.packageDrawerClose();
            }}
          />
        </Drawer>
      </div>
    );
  }

  private handleTableChange = async (num: PaginationConfig) => {
    const { shareSeqPagination } = this.state;
    shareSeqPagination.current = num.current ? num.current : 1;
    this.setState(
      {
        shareSeqPagination,
      },
      () => this.getClickItemSeqs()
    );
  };

  /**
   * 已共享数据 网络请求
   */
  private getHasSharedData = async () => {
    const { hasSharedpagination } = this.state;
    const { getFieldsValue } = this.props.form;
    const searchs = getFieldsValue(['hasSharedDataType', 'hasSharedDiseaseType', 'userName']);

    try {
      this.setState({ isLoading: false });
      const { result } = await DataManagerApi.getHasSharedList<INormalList<IAlreadyShareDataItem[]>, IHasSharedReq>({
        pageNum: hasSharedpagination.current,
        pageSize: hasSharedpagination.pageSize ? hasSharedpagination.pageSize : 10,
        type: searchs.hasSharedDataType,
        disease: searchs.hasSharedDiseaseType,
        userId: searchs.userName,
      });
      if (result) {
        hasSharedpagination.current = result.current;
        hasSharedpagination.total = result.total;
        result.records.map(item => {
          const json = item.jsonDesc ? JSON.parse(item.jsonDesc) : {};
          item.package = json;
          return item;
        });
        const permissions: string[] = JSON.parse(Storage.getStore('permissions') || '[]');
        result.records.forEach(val => {
          val.isShow = permissions.includes('USER_PACKAGE_GRANT_MANAGE') || val.isShare;
        });
        this.setState({
          hasSharedItems: [...result.records],
          hasSharedpagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 获取疾病列表
   */
  private getDiseasesOptions = async () => {
    const { result } = await DataManagerApi.getDiseases();
    if (result) {
      this.setState({
        diseaseItems: [...result],
      });
    }
  };
  /**
   * 显示序列详情
   */
  // tslint:disable-next-line:no-any
  private detailShow = (record: any) => {
    this.setState({ detailString: record }, () => {
      this.setState({ detailVisible: true });
    });
  };
  /**
   * 详情内容编辑
   */
  // tslint:disable-next-line:no-any
  private detailRender = (detailString: any) => {
    // 这里临时这么写，由于方开始想错了，懒得重新再写，直接倒转一下数组
    const images = ['数据编号', '病例编号', '病例名称', '性别', '出生年月', '年龄'].reverse();
    const gens = ['样本编号', '样本名称', '物种', '样本特征', '年龄', '样本提供者', '性别'].reverse();
    if (detailString) {
      const arr = this.objToArr(JSON.parse(detailString));
      const els = arr.map(item => {
        if (!images.includes(item.title) && !gens.includes(item.title)) {
          return (
            // tslint:disable-next-line:jsx-key
            <div key={item.title} className="detail_box">
              <div className="detail-title">{item.title}</div>
              <div className="detail-content">{item.content}</div>
            </div>
          );
        }
      });
      const datas = JSON.parse(this.state.item.jsonDesc).project_data_type === 1 ? gens : images;

      datas.map(item => {
        arr.map(k => {
          if (item === k.title) {
            els.unshift(
              <div key={k.title} className="detail_box">
                <div className="detail-title">{k.title}</div>
                <div className="detail-content">{k.content}</div>
              </div>
            );
          }
        });
      });
      return els;
    }
  };
  /**
   * 关闭序列详情
   */
  private detailHide = () => {
    this.setState({ detailString: '' }, () => {
      this.setState({ detailVisible: false });
    });
  };
  /**
   * 获取用户列表
   */

  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({
        userItems: [...result],
      });
    }
  };
  /**
   * 列表分页处理
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { hasSharedpagination } = this.state;
    hasSharedpagination.current = pagination ? (pagination.current ? pagination.current : 1) : 1;
    this.setState({
      hasSharedpagination,
    });
    this.getHasSharedData();
  };

  /**
   *  已共享数据 筛选
   */
  private handleHasShareSearchAction = () => {
    this.getHasSharedData();
  };
  /**
   * 原始数据row  点击事件, 针对不同按钮点击事件,用IHasSharedCellClickType 枚举进行区分
   */
  private cellClick = (item: IAlreadyShareDataItem, type: IHasSharedCellClickType) => {
    this.setState({
      clickItem: item,
    });
    switch (type) {
      case IHasSharedCellClickType.ShowSharePackageDetail:
        this.showHasSharePackageDetail(item);
        break;
      case IHasSharedCellClickType.AuthDistribution:
        this.authDistribution(item);
        break;
      case IHasSharedCellClickType.DowloadPackage:
        this.downloadHasSharedData(item);
        break;
      case IHasSharedCellClickType.ShowSeqs:
        this.setState(
          {
            item,
            seqVisible: true,
          },
          () => this.getClickItemSeqs()
        );

        break;
      default:
        return;
    }
  };

  private changePackageStatus = (item: IAlreadyShareDataItem) => {
    // 修改状态
    this.setState({
      clickItem: item,
      packageEditVisible: true,
    });
  };
  /**
   *  已共享数据下载
   */

  private downloadHasSharedData = async (item: IAlreadyShareDataItem) => {
    const { result } = await DataManagerApi.getdownloadPathList<ITreeData[]>();
    if (result.length > 0) {
      this.setState({ pathList: result, id: item.id });
      this.downloadHasSharedDataShow();
    } else {
      message.warning('没有路径可以存放');
    }
  };

  /**
   * 下载序列
   */

  private getClickItemSeqs = async () => {
    const { shareSeqPagination } = this.state;
    interface ISeqs {
      id: number;
      pageNum: number;
      pageSize: number;
    }
    const { result } = await DataManagerApi.getPackageSeqs<INormalList<IPackageSeqs[]>, ISeqs>({
      id: this.state.item.id,
      pageNum: shareSeqPagination.current,
      pageSize: 10,
    });
    if (result) {
      shareSeqPagination.total = result.total;
      this.setState({
        clickItemSeqs: [...result.records],
        shareSeqPagination,
      });
    }
  };

  /**
   * 下载组件 Action
   */
  /*  打开下载模态框 */
  private downloadHasSharedDataShow = () => {
    this.setState({ pathModal: true });
  };
  /*  点击下载确定按钮 */
  private downloadHasSharedDataOK = async (path: string) => {
    // await HttpRequest.savePackageGrantList<null, string[], string>(arr, this.state.code);
    const { result } = await DataManagerApi.setDownloadPath<{}, { id: number; targetPath: string; taskId: string }>({
      id: this.state.id,
      targetPath: path,
      taskId: guid(String(this.state.id)),
    });
    this.setState({ pathModal: false });
    message.success(result);
  };
  /*  关闭下载模态框 */
  private downloadHasSharedDataHide = () => {
    this.setState({ pathModal: false });
  };

  /**
   * 显示已共享数据包详情
   */
  private showHasSharePackageDetail = (item: IAlreadyShareDataItem) => {
    //
    this.setState({ packageDrawerVisible: true });
  };
  /**
   * 已共享数据 权限分配
   */
  private authDistribution = async (item: IAlreadyShareDataItem) => {
    const { result } = await DataManagerApi.getPackageGrantList<IGrant[], string>(item.code);
    if (!result.length) {
      message.warning('没有账号权限可以设置');
      return;
    }
    this.setState({ grants: result, code: item.code });
    this.authDistributionShow();
  };
  /*  打开权限列表模态框 */
  private authDistributionShow = () => {
    this.setState({ authModal: true });
  };

  /**
   * @todo  权限修改Component  Action
   */

  /*
   * 点击权限列表确定按钮
   */
  private authDistributionOK = async (arr: string[]) => {
    await DataManagerApi.savePackageGrantList<null, string[], string>(arr, this.state.code);

    this.setState({ authModal: false });
    message.success('设置成功！');
  };
  /*  关闭权限列表模态框 */
  private authDistributionHide = () => {
    this.setState({ authModal: false });
  };
  /**
   * 序列modal 展开
   */
  private seqModalOkClick = () => {
    this.setState({
      seqVisible: false,
    });
  };
  /**
   * 序列Modal 关闭
   */
  private seqModalCancelClick = () => {
    this.setState({
      seqVisible: false,
    });
  };
  /**
   * 关闭数据包编辑模态框
   */
  private packageEditClose = () => {
    this.setState({
      packageEditVisible: false,
    });
  };

  /**
   *
   */
  private radioGroupChange = (e: RadioChangeEvent) => {
    this.setState({
      packageSelectedStatus: e.target.value,
    });
  };
  /**
   * 确定修改数据包
   */
  private packageEditOkClose = async () => {
    //
    if (this.state.packageSelectedStatus === 2) {
      // 只在暂不共享改成立即共享时候发出请求
      const { result } = await DataManagerApi.updatePackageStatus<{ id: number; status: string }>({
        id: this.state.clickItem!.id,
        status: 'WAIT_AUDIT',
      });
      const hasSharedItems = this.state.hasSharedItems.map(item => {
        if (item.id === this.state.clickItem!.id) {
          item.status = 1;
        }
        return item;
      });
      if (result) {
        this.setState({
          packageSelectedStatus: 1,
          packageEditVisible: false,
          hasSharedItems: [...hasSharedItems],
        });
      }
    }
  };
  /**
   * 关闭数据包详情抽屉
   */
  private packageDrawerClose = () => {
    this.setState({ packageDrawerVisible: false });
  };

  /**
   * row 更多按钮
   */
  private moreAction = (record: IAlreadyShareDataItem) => {
    return (
      <Menu className="user-msg">
        <Menu.Item
          key="b"
          className="center menu-right-item"
          // tslint:disable-next-line:jsx-no-multiline-js
          onClick={() => {
            this.cellClick(record, IHasSharedCellClickType.ShowSeqs);
          }}
          style={{ color: '#00a1e6' }}
        >
          序列列表
        </Menu.Item>
        {record.canGrant ? (
          <Menu.Item
            key="a"
            className="center menu-right-item bottom-line"
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              this.cellClick(record, IHasSharedCellClickType.AuthDistribution);
            }}
          >
            权限分配
          </Menu.Item>
        ) : null}
      </Menu>
    );
  };

  /**
   * 下拉框挂载位置
   */
  // private dropdownBind = (trigger: Element) => {
  //   return trigger.parentElement || document.body;
  // };
}

export default HasSharedDataComponent;
