import { Button, Form, Modal, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SciManageApi from '../../api/SciManageApi';
import AuthCmp, { authAvailable } from '../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../components/NoDataImg/NoDataImg';
import { IconFont } from '../../config/IconConfig';
import IndexLayout from '../../layout/IndexLayout';
import Storage from '../../util/Storage';
import { INormalList } from '../DataManager/CodeType';
import {
  initalState,
  IProxAiManageState,
  ISciProjectItem,
  PROJECTITEMSLISTACTION,
  RowActionArrs,
  RowActions,
} from './CodeType';
import './ScientificManage.scss';

interface IProxAiManageProps extends FormComponentProps, RouteComponentProps {}
class ScientificManagePage extends React.Component<IProxAiManageProps, IProxAiManageState> {
  public projectColumns = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: ISciProjectItem) => {
        return (
          <div className="nowarp-elips" style={{ width: '160px' }} title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '项目说明',
      dataIndex: 'description',
      key: 'description',
      render: (text: string, record: ISciProjectItem) => {
        return (
          <div className="nowarp-elips" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '操作',
      key: 'taskCountNum',
      render: (text: string, record: ISciProjectItem) => {
        return (
          <div className="table-row-action-container">
            <div
              onClick={() => this.projectRowClick(record, RowActionArrs[0].type)}
              className="action firstAction"
              hidden={!record.permissions.includes(PROJECTITEMSLISTACTION[0])}
            >
              {RowActionArrs[0].title}
            </div>
            <div onClick={() => this.projectRowClick(record, RowActionArrs[1].type)} className="action noleft">
              {RowActionArrs[1].title}
            </div>
            <div
              onClick={() => this.projectRowClick(record, RowActionArrs[2].type)}
              className="action"
              hidden={!record.permissions.includes(PROJECTITEMSLISTACTION[2])}
            >
              {RowActionArrs[2].title}
            </div>

            <div
              onClick={() => this.projectRowClick(record, RowActionArrs[3].type)}
              className="action"
              hidden={!record.permissions.includes(PROJECTITEMSLISTACTION[3])}
            >
              {RowActionArrs[3].title}
            </div>
            <div
              onClick={() => this.projectRowClick(record, RowActionArrs[4].type)}
              className="action"
              hidden={!record.permissions.includes(PROJECTITEMSLISTACTION[4])}
            >
              {RowActionArrs[4].title}
            </div>
          </div>
        );
      },
    },
  ];
  // public state = initalState as IProxAiManageState;
  constructor(props: IProxAiManageProps) {
    super(props);
    this.state = { ...initalState };
  }

  public componentDidMount() {
    this.getProjects();
  }

  public render() {
    const { clickItem } = this.state;
    const laodPkgfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.loadModalOkClick}>
          前往安全盒
        </Button>
        <Button className="ant-btn-no " onClick={this.loadModalCancelClick}>
          取消
        </Button>
      </div>
    );
    const boxInfo = clickItem && clickItem.vmBoxInfo && clickItem.vmBoxInfo[0];
    const smbInfo = (
      <p>
        4.smb/ftp账号: {boxInfo && boxInfo.smbUsername}, 密码: {boxInfo && boxInfo.smbPassword}
      </p>
    );
    const children = (
      <div className="add-project" onClick={this.addOneProject}>
        <div>
          <IconFont type={'medataicon-icon_add-project'} className="add-project-icon" />
        </div>
        创建项目
      </div>
    );

    const noDataShow = authAvailable('USER_ALL_DATA_READ');
    return (
      // tslint:disable-next-line:jsx-wrap-multiline
      <div className="scientific-manage-continer ">
        <IndexLayout>
          <AuthCmp permission={'USER_ALL_DATA_READ'} children={children} />
          <AuthComponent isShow={!!this.state.projectItems.length}>
            <div className="project-list-title ">
              <div className="dot" /> 项目列表
            </div>
            <Table
              columns={this.projectColumns}
              dataSource={this.state.projectItems}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...this.state.proPagination }}
              rowKey="id"
              onChange={this.handleHasTableChanage}
            />
          </AuthComponent>
          {// tslint:disable-next-line:jsx-no-multiline-js
          noDataShow ? null : <NoDataImg isLoading={this.state.isLoading} total={this.state.proPagination.total} />}

          <Modal
            visible={this.state.safetyBoxVisible}
            className="load-pkg-modal"
            width="560px"
            centered={true}
            title={'提示'}
            footer={laodPkgfooter}
            closable={true}
            onCancel={this.loadModalCancelClick}
          >
            <div style={{ paddingTop: '30px', paddingLeft: '150px' }}>
              <p>
                1.用户名：{boxInfo && boxInfo.username}, 密码:{boxInfo && boxInfo.password}
              </p>
              <p>
                2.FTP地址: {boxInfo && boxInfo.ftpAddress}, FTP端口:{boxInfo && boxInfo.ftpPort}
              </p>
              <p>3.数据挂载地址: {boxInfo && boxInfo.ftpDataDir}</p>
              {boxInfo && boxInfo.smbUsername ? smbInfo : ''}
            </div>
          </Modal>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 项目列表行点击
   */
  private projectRowClick = (record: ISciProjectItem, action: RowActions) => {
    let url = `/sciMemberList/${record.id}/${record.name}`;
    switch (action) {
      case RowActions.memberList:
        url = `/ProjectMembers/${record.id}/${record.name}`;
        this.props.history.push(url);
        break;
      case RowActions.projectManager:
        url = `/sciDataManage/${record.id}/${record.name}`;
        this.props.history.push(url);
        break;
      case RowActions.taskManager:
        url = `/ProjectTasks/${record.id}/${record.name}`;
        this.props.history.push(url);
        break;
      case RowActions.appTool:
        url = `/sciAppTools/${record.id}/${record.name}`;
        this.props.history.push(url);
        break;
      case RowActions.securityBoxManage:
        url = `/secBoxManage/${record.id}/${record.name}`;
        this.props.history.push(url);
        break;
    }
  };

  /**
   * 获取项目列表
   */
  private getProjects = async (page: number = 1) => {
    const { proPagination } = this.state;
    proPagination.current = page;
    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getSciResearchProjects<
        INormalList<ISciProjectItem[]>,
        { pageNum: number; pageSize: number }
      >({
        pageNum: proPagination.current,
        pageSize: proPagination.pageSize,
      });

      if (result) {
        proPagination.total = result.total;
        this.setState({
          proPagination,
          isLoading: true,
          projectItems: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { proPagination } = this.state;
    proPagination.current = pagination.current || 1;
    this.setState({ proPagination });
    this.getProjects(proPagination.current);
  };
  /**
   * 点击添加项目
   */
  private addOneProject = () => {
    Storage.setStore('PROJECTITEMS', JSON.stringify(this.state));
    this.props.history.push('/newSCIProject');
  };

  /**
   * 挂载模态框OKCLick
   */
  private loadModalOkClick = () => {
    this.setState({
      safetyBoxVisible: false,
    });
  };
  /**
   * 挂载模态框 关闭
   */
  private loadModalCancelClick = () => {
    this.setState({
      safetyBoxVisible: false,
    });
  };
}
const ScientificManage = Form.create()(ScientificManagePage);
export default ScientificManage;
