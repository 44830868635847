import { Button, Form, Input, Radio, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import DataManagerApi from '../../../api/DataManager';
import ProxAiManageApi from '../../../api/ProxAiManageApi';
import SciManageApi from '../../../api/SciManageApi';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import { IOptionUser } from '../../DataManager/Component/HasSharedDataComponent/CodeType';
import { INewProjectState, initalNewProjectState, IProxAiTemplateItem } from '../../ProxAiManage/CodeType';
import { ISciProjectReq } from '../CodeType';
import './NewSCIProject.scss';

const { Option } = Select;
interface INewProjectPageProps extends FormComponentProps, RouteComponentProps {}
class NewSCIProjectPage extends React.Component<INewProjectPageProps, INewProjectState> {
  public state = initalNewProjectState as INewProjectState;
  public componentDidMount() {
    this.getProxAITemplates();
    this.getMembersOptions();
    window.onbeforeunload = () => {
      //
      this.setState({ isBack: true });
      return false;
    };
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    const { toolTemplates, userItems } = this.state;
    const OptionsTools = toolTemplates
      ? toolTemplates.map(item => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })
      : null;

    const userItemsOptions = userItems
      ? userItems.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      : null;
    return (
      <div className="new-project-container">
        <Prompt when={!this.state.isBack} message={location => '您的信息尚未填写完成，确定离开吗？'} />
        <IndexLayout>
          <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginTop: '27px' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.gobackWithOption}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="project-message-title">
                <div className="dot" /> 项目基本信息
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="project-input-title ">项目名称:</div>
              <Form.Item colon={false} style={{ width: '400px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project_name', {
                  rules: [{ required: true, message: '点击输入' }],
                })(<Input className="project-input" />)}
              </Form.Item>
            </div>
            <div style={{ display: 'flex', marginTop: '24px' }}>
              <div className="project-input-title " style={{ alignItems: 'flex-start' }}>
                项目说明:
              </div>
              <Form.Item colon={false} style={{ width: '400px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project_discription', {
                  rules: [{ required: true, message: '请输入项目描述' }],
                })(<TextArea placeholder="点击输入" style={{ minHeight: '100px', borderRadius: '8px' }} />)}
              </Form.Item>
            </div>
            <div style={{ display: 'flex', marginTop: '24px' }}>
              <div className="project-input-title">工具模板:</div>
              <Form.Item colon={false} style={{ width: '300px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project_tools', {
                  rules: [{ required: true, message: '点击选择工具' }],
                })(
                  <Select className="project-input" style={{ width: '300px' }}>
                    {OptionsTools}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div style={{ display: 'flex', marginTop: '24px' }}>
              <div className="project-input-title" style={{ justifyContent: 'flex-end' }}>
                管理员:
              </div>
              <Form.Item colon={false} style={{ width: '300px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project_user_manager', {
                  rules: [{ required: true, message: '请选择' }],
                })(
                  <Select className="project-input" style={{ width: '300px' }}>
                    {userItemsOptions}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div style={{ display: 'flex', marginTop: '24px' }}>
              <div className="project-input-title" style={{ display: 'inline-block', paddingTop: '10px' }}>
                课题类型:
              </div>
              <Form.Item style={{ paddingLeft: '28px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project_data_type', {
                  rules: [{ required: true, message: '请输入数据类型' }],
                  initialValue: 1,
                })(
                  <Radio.Group>
                    <Radio value={1} style={{ color: 'rgba(0,0,0,0.64)' }}>
                      基因
                    </Radio>
                    <Radio value={2} style={{ marginLeft: '16px', color: 'rgba(0,0,0,0.64)' }}>
                      影像
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </div>

            <Button className="complete-btn" onClick={this.handleAddProjectBtnClick} style={{ marginTop: '80px' }}>
              完成
            </Button>
          </div>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 点击完成按钮
   */
  private handleAddProjectBtnClick = () => {
    const { validateFields } = this.props.form;
    validateFields(
      ['project_name', 'project_discription', 'project_tools', 'project_data_type', 'project_user_manager'],
      (err, values) => {
        if (!err) {
          const projectReq: ISciProjectReq = {
            name: values.project_name,
            description: values.project_discription,
            toolTemplateId: values.project_tools,
            adminId: values.project_user_manager,
            type: values.project_data_type,
          } as ISciProjectReq;

          this.addProject(projectReq);
        }
      }
    );
  };
  /**
   * 开始添加项目
   */
  private addProject = async (projectReq: ISciProjectReq) => {
    const { result } = await SciManageApi.createSciProject<string, ISciProjectReq>(projectReq);
    if (result) {
      this.setState({ isBack: true });
      this.props.history.goBack();
    }
  };
  /**
   * 获取鉴影模板列表
   */
  private getProxAITemplates = async () => {
    const { result } = await ProxAiManageApi.getProxAiTemplates<IProxAiTemplateItem[]>();
    if (result) {
      // 完成
      this.setState({ toolTemplates: result });
    }
  };

  /**
   * 获取用户列表
   */
  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>({ type: 1 });
    if (result) {
      this.setState({ userItems: [...result] });
    }
  };
  /**
   * 点击返回 按钮
   */
  private gobackWithOption = () => {
    this.props.history.goBack();
  };
}
const NewSCIProject = Form.create()(NewSCIProjectPage);

export default NewSCIProject;
