import React from 'react';

import { Button, Pagination } from 'antd';
import VMManage from '../../api/VMManage';
import AuthComponent from '../../components/AuthComponent/AuthComponent';

import { ModalCmp } from '../../components/ModalCmp/ModalCmp';
import NoDataImg from '../../components/NoDataImg/NoDataImg';
import IndexLayout from '../../layout/IndexLayout';
import { INormalList } from '../DataManager/CodeType';
import { ISafetyBoxInfo } from '../OrderPromise/VMOrders/CodeType';
import { InitState, ISecurityBoxState, IVmBoxConsoleInfo, IVmBoxItem } from './CodeType';
import SecBoxCard from './Component/SecBoxCard';
import './SecurityBox.scss';
class SecurityBox extends React.Component<{}, ISecurityBoxState> {
  public state = InitState as ISecurityBoxState;

  public componentDidMount() {
    this.getAllBoxs();
  }
  public render() {
    const { boxPagination, clickItem } = this.state;
    // const boxCards =
    //   vmBoxItems &&
    //   vmBoxItems.map((item, index) => (

    //   ));
    const laodPkgfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.loadModalOkClick}>
          前往安全盒
        </Button>
        <Button className="ant-btn-no " onClick={this.loadModalCancelClick}>
          取消
        </Button>
      </div>
    );
    const boxInfo = clickItem && clickItem.vmBoxInfo && clickItem.vmBoxInfo[0];
    const smbInfo = (
      <p>
        4.smb/ftp账号: {boxInfo && boxInfo.smbUsername}, 密码: {boxInfo && boxInfo.smbPassword}
      </p>
    );

    return (
      <div className="security-box-container">
        <IndexLayout>
          <div className="box-header"> 安全盒</div>
          <div>
            <div style={{ display: 'flex' }}>
              {this.getBoxCard(0)}
              <div style={{ marginLeft: '34px' }}>{this.getBoxCard(1)}</div>
            </div>
            <div style={{ display: 'flex', marginTop: '24px' }}>
              {this.getBoxCard(2)}
              <div style={{ marginLeft: '34px' }}>{this.getBoxCard(3)}</div>
            </div>
          </div>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.boxPagination.total} />
          <AuthComponent isShow={!!this.state.boxPagination.total}>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '40px', paddingBottom: '10px' }}>
              <Pagination
                defaultCurrent={1}
                pageSize={boxPagination.pageSize}
                total={boxPagination.total}
                current={boxPagination.current}
                onChange={this.vmBoxTableCardsChange}
              />
            </div>
          </AuthComponent>

          <ModalCmp
            visible={this.state.safetyBoxVisible}
            footerView={laodPkgfooter}
            title={'提示'}
            colseModal={this.loadModalCancelClick}
          >
            <div style={{ paddingTop: '30px', paddingLeft: '150px' }}>
              <p>
                1.用户名：{boxInfo && boxInfo.username}, 密码:{boxInfo && boxInfo.password}
              </p>
              <p>
                2.FTP地址: {boxInfo && boxInfo.ftpAddress}, FTP端口:{boxInfo && boxInfo.ftpPort}
              </p>
              <p>3.数据挂载地址: {boxInfo && boxInfo.ftpDataDir}</p>
              {boxInfo && boxInfo.smbUsername ? smbInfo : ''}
            </div>
          </ModalCmp>
        </IndexLayout>
      </div>
    );
  }
  /**
   * 获取安全盒card
   */
  private getBoxCard = (setp: number) => {
    const { vmBoxItems, boxPagination } = this.state;
    const index = 4 * (boxPagination.current - 1) + setp;
    if (vmBoxItems.length > index) {
      return <SecBoxCard boxItem={vmBoxItems[index]} key={Number(vmBoxItems[index])} goToBoxClick={this.openBox} />;
    } else {
      return null;
    }
  };
  /**
   * 获取安全盒列表
   */
  private getAllBoxs = async () => {
    const { boxPagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const { result } = await VMManage.getAllVmItems<INormalList<IVmBoxItem[]>>({
        pageSize: 10000,
        pageNum: 1,
      });
      if (result) {
        boxPagination.total = result.total;
        this.setState({
          boxPagination,
          vmBoxItems: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
  /**
   * 安全盒分页
   */
  private vmBoxTableCardsChange = (page: number) => {
    const { boxPagination } = this.state;
    boxPagination.current = page;
    this.setState({ boxPagination }, () => {
      this.forceUpdate();
    });
  };

  /**
   * 打开安全盒
   */
  private openBox = async (item: IVmBoxItem) => {
    const { result } = await VMManage.getVmInfo<ISafetyBoxInfo[], { researchProjectId: number }>({
      researchProjectId: item.researchProjectId,
    });
    if (result) {
      item.vmBoxInfo = result.filter(s => s.ownUserId === item.ownUserId);
      this.setState({
        clickItem: item,
        safetyBoxVisible: true,
      });
    }
  };

  /**
   * 安全盒弹框的确认按钮
   */
  private handleOk = async () => {
    const { clickItem } = this.state;
    if (clickItem) {
      const { result } = await VMManage.getVmInstanceConsoleInfo<IVmBoxConsoleInfo>({
        vmInstanceId: (clickItem && Number(clickItem.id)) || 0,
      });

      window.open(
        result.novncUrl +
          '?CSRFPreventionToken=' +
          result.csrfPreventionToken +
          '&node=' +
          result.node +
          '&PVEAuthCookie=' +
          result.pveAuthCookie +
          '&vmid=' +
          result.vmid
      );
      this.setState({ safetyBoxVisible: false });
    }
  };

  /**
   * 挂载模态框OKCLick
   */
  private loadModalOkClick = () => {
    this.handleOk();
    this.setState({
      safetyBoxVisible: false,
    });
  };
  /**
   * 挂载模态框 关闭
   */
  private loadModalCancelClick = () => {
    this.setState({
      safetyBoxVisible: false,
    });
  };
}

export default SecurityBox;
