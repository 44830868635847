import { IOptionUser } from '../DataManager/Component/HasSharedDataComponent/CodeType';
import { ISafetyBoxInfo } from '../OrderPromise/VMOrders/CodeType';

export interface IPagination {
  total: number;
  pageSize: number;
  current: number;
}
export interface IProjectSaveReq {
  name: string;
  remark: string;
  seriesInstanceUidList: string[];
  templateId: number;
}

export interface IPTaskSaveReq {
  name: string;
  projectId: number;
  remark: string;
  seriesInstanceUidList: number[] | string[];
  vendorId: number;
}

export interface IProxAiManageState {
  projectItems: ISciProjectItem[];
  proPagination: IPagination;
  isLoading: boolean;
  safetyBoxVisible: boolean;
  clickItem?: ISciProjectItem;
}
export const initalState = {
  projectItems: [],
  proPagination: { total: 0, pageSize: 10, current: 1 },
  isLoading: true,
  safetyBoxVisible: false,
};

export interface INewProjectState {
  toolTemplates: IProxAiTemplateItem[];
  availableSeqs: IAvailableSeqItem[];
  selectedSeqs: string[];
  seqPagination: IPagination;
  userItems: IOptionUser[];
  partDics: Map<string, string>;
  isLoading: boolean;
  isBack: boolean;
}
export const initalNewProjectState: INewProjectState = {
  toolTemplates: [],
  selectedSeqs: [],
  availableSeqs: [],
  userItems: [],
  partDics: new Map(),
  isLoading: true,
  seqPagination: { total: 0, pageSize: 5, current: 1 },
  isBack: false,
};

export interface IProjectTask {
  id: string;
  name: string;
  remark: string;
  seriesSubmittedNum: number;
  seriesTotalNum: number;
  userName: string;
}

export interface ISciMemberListState {
  taskPagination: IPagination;
  projectTasks: IProjectTask[];
  isLoading: boolean;
  laodMemberVisible: boolean;
  noLoadMemberlist: IProjectTask[];
  noLoadPagination: IPagination;
  members: IMemeberItem[];
}
export const initSciMemberListState: ISciMemberListState = {
  taskPagination: { total: 0, pageSize: 10, current: 1 },
  projectTasks: [],
  members: [],
  isLoading: true,
  noLoadMemberlist: [],
  laodMemberVisible: false,
  noLoadPagination: { total: 0, pageSize: 10, current: 1 },
};
export interface INewProjectTaskState {
  seqPagination: IPagination;
  availableSeqs: IAvailableSeqItem[];
  selectedSeqs: string[];
  members: IMemeberItem[];
  userItems: IOptionUser[];
  partDics: Map<string, string>;
  isLoading: boolean;
  isBack: boolean;
}
export const initalNewProjectTaskState: INewProjectTaskState = {
  selectedSeqs: [],
  members: [],
  availableSeqs: [],
  userItems: [],
  seqPagination: { total: 0, pageSize: 10, current: 1 },
  partDics: new Map(),
  isLoading: true,
  isBack: false,
};

export interface ISciProjectReq {
  name: string;
  description: string;
  toolTemplateId: string;
  adminId: string;
  type: number;
  createdId?: number;
}
export interface ISciProjectItem {
  adminId: number;
  description: string;
  id: number;
  name: string;
  toolTemplateId: number;
  type: number;
  projectName: string;
  joinUserCount: number;
  permissions: string[];
  vmBoxInfo: ISafetyBoxInfo[];
}

export interface IProjectItem {
  createTime: string;
  id: number;
  modality: string;
  name: string;
  projectFunction: string;
  remark: string;
  sicknessType: string;
  status: number; // 1-未启用，2-已启用;
  taskCount: number;
}

export interface IProxAiTemplateItem {
  id: number;
  name: string;
}

export interface IAvailableSeqReq {
  bodyPart?: string;
  importDateBegin?: string;
  importDateEnd?: string;
  jpgValid?: false;
  keyword?: string;
  modality?: string;
  page: number;
  pageSize: number;
  seriesInstanceUID?: string;
  studyInstanceUID?: string;
  type?: string;
  valid?: false;
  assigned: boolean;
}
export interface IAvailableSeqItem {
  bodyPart: string;
  createDate: string;
  fileType: string;
  modality: string;
  seriesInstanceUID: string;
  studyDate: string;
  studyInstanceUID: string;
  assigned: boolean;
}

export interface IProjectSeqsReq {
  page: number;
  pageSize: number;
  projectId: number;
  startTime?: string;
  endTime?: string;
  keyword?: string;
  assigned?: boolean | string;
}

export interface IMemeberItem {
  id: string;
  name: string;
}

export interface IProjectTasksReq {
  page: number;
  pageSize: number;
  projectId: string;
}

export interface ISicOriginaldReq {
  pageNum: number;
  pageSize: number;
  userId?: number;
  startTime?: number;
  endTime?: number;
  projectId: number;
  type: number; // 1:引入原始数据列表2:项目下原始数据列表
}

export const memberColumn = [
  {
    title: '创建时间',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '用户名',
    dataIndex: 'remark',
    key: 'remark',
  },
  {
    title: '账号',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: '角色名称',
    dataIndex: 'seriesTotalNum',
    key: 'seriesTotalNum',
  },
];

/**
 *
 * 项目管理(USER_PROJECT_MANAGE)
 * 原始数据管理(USER_PROJECT_ORIGINAL_MANAGE)
 * 临时数据管理(USER_PROJECT_INTERMEDIATE_MANAGE)
 * 鉴影任务管理(USER_PROJECT_JY_TASK_MANAGE)
 * 任务管理(USER_PROJECT_TASK_MANAGE)
 * 应用工具管理(USER_PROJECT_APPLICATION_MANAGE)
 */
// 科研项目类表操作的action 权限
export const PROJECTITEMSLISTACTION = [
  'USER_PROJECT_MANAGE',
  'pd',
  'USER_PROJECT_TASK_MANAGE',
  'USER_PROJECT_APPLICATION_MANAGE',
  'USER_PROJECT_MANAGE',
];

export enum RowActions {
  memberList,
  projectManager,
  taskManager,
  appTool,
  securityBoxManage,
}

export const RowActionArrs = [
  { title: '项目成员', type: RowActions.memberList },
  { title: '项目数据', type: RowActions.projectManager },
  { title: '任务管理', type: RowActions.taskManager },
  { title: '应用工具', type: RowActions.appTool },
  { title: '安全盒', type: RowActions.securityBoxManage },
];
