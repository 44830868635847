import axios from './RequestApi';
export default class OrderManageApi {
  /**
   * 数据包详情
   * @param data
   */
  public static getPackageDetail<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/package/getInfo', data);
  }
  /*  提交订单 */
  public static createOrder<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/orderDataVm/submit', data);
  }
  /** 当前 package 可用安全盒 */
  public static getOrderUserPackage<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/orderDataVm/getUsedVmOrderList', data);
  }

  /**
   * 预约单
   */
  public static getOrders<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/orderDataVm/getPage', data);
  }

  /**
   * 获取安全盒状态
   */
  public static getVNstatus<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/orderVm/getVmStatus', data);
  }

  /**
   * 获取安全盒详情
   */
  public static getVNDetail<T, D>(id: D) {
    return axios.post<T, {}>(`/user/logined/orderVm/getOrderVmUserInfo?id=${id}`);
  }

  /**
   *  获取安全盒订单安全盒控制台详情
   */
  public static getVNOrderConsoleDetail<T, D>(id: D) {
    return axios.post<T, {}>(`/user/logined/orderVm/getVmConsoleInfo?orderId=${id}`);
  }

  /**
   * 获取订单详情
   */
  public static getOrderDetail<T, D>(data: D) {
    return axios.get<T, D>(`/user/logined/detail/orderDataVm`, data);
  }

  /**
   *  申请下载
   */
  public static createDownLoad<T, D>(data: D) {
    return axios.post<T, D>(`/user/logined/userDownload/userDownloadSave`, data);
  }

  /**
   * 支付
   */
  public static pay<T, D>(code: D) {
    return axios.post<T, {}>(`/user/logined/orderDataVm/pay?code=${code}`);
  }
  /**
   * 用户积分
   */
  public static userGradeInfo<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/account/info', data);
  }
}
