import axios from './RequestApi';

export default class ProjectTasksApi {
  /*
   * 获取数据列表
   */
  public static getTaskList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/researchProjectTask/page', data);
  }

  /*
   * 获取原始数据
   */
  public static getOriginalData<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/originalData/page', data);
  }

  /*
   * 任务序列分页数据
   */
  public static gettaskOriginalData<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/researchProjectTask/taskOriginalDataPage', data);
  }

  /*
   * 创建科研项目任务
   */
  public static taskCreate<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/researchProjectTask/create', data);
  }

  /*
   * 批量更新任务序列状态
   */
  public static seqToFin<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/researchProjectTask/batchUpdateTaskStatus', data);
  }
  /*
   * 批量下载原始数据
   */
  public static seqDown<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/originalData/download', data);
  }
}
