import { Divider, Form, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../components/NoDataImg/NoDataImg';

import { IconFont } from '../../config/IconConfig';
import IndexLayout from '../../layout/IndexLayout';
import Storage from '../../util/Storage';
import { INormalList } from '../DataManager/CodeType';
import ProxAiManageApi from './../../api/ProxAiManageApi';
import { initalState, IProjectItem, IProxAiManageState } from './CodeType';
import './ProxAiManage.scss';
interface IProxAiManageProps extends FormComponentProps, RouteComponentProps {}
class ProxAiManagePage extends React.Component<IProxAiManageProps, IProxAiManageState> {
  public projectColumns = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '样本信息',
      dataIndex: 'modality',
      key: 'modality',
    },
    {
      title: '项目标签',
      dataIndex: 'sicknessType',
      key: 'sicknessType',
    },
    {
      title: '任务数量',
      dataIndex: 'taskCount',
      key: 'taskCount',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: IProjectItem) => {
        return <span>{record.status === 2 ? '已启用' : '未启用'} </span>;
      },
    },
    {
      title: '创建日期',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (text: string, record: IProjectItem) => {
        return <span>{moment(record.createTime).format('YYYY.MM.DD')} </span>;
      },
    },
    {
      title: '操作',
      dataIndex: 'taskCount',
      key: 'taskCountNum',
      render: (text: string, record: IProjectItem) => {
        return (
          <div className="project-row-action">
            <div className="project-row-action" hidden={!record.taskCount}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={
                  // tslint:disable-next-line:jsx-no-multiline-js
                  () => {
                    this.projectRowClick(record, 0);
                  }
                }
              >
                任务详情
              </div>
              <Divider type="vertical" />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={
                // tslint:disable-next-line:jsx-no-multiline-js
                () => {
                  this.projectRowClick(record, 1);
                }
              }
            >
              创建任务
            </div>
          </div>
        );
      },
    },
  ];
  // public state = initalState as IProxAiManageState;
  constructor(props: IProxAiManageProps) {
    super(props);
    this.state = { ...initalState };
  }
  public componentDidMount() {
    this.getProjects();
  }

  public render() {
    return (
      <div className="proxai-manage-continer">
        <IndexLayout>
          <div className="header-title"> 鉴影管理工具</div>

          <div className="add-project" onClick={this.addOneProject}>
            <div>
              <IconFont type={'medataicon-icon_add-project'} className="add-project-icon" />
            </div>
            创建项目
          </div>

          <div className="project-list-title ">
            <div className="dot" /> 项目列表
          </div>
          <AuthComponent isShow={!!this.state.projectItems.length}>
            <Table
              columns={this.projectColumns}
              dataSource={this.state.projectItems}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...this.state.proPagination }}
              rowKey="id"
              onChange={this.handleHasTableChanage}
            />
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.proPagination.total} />
        </IndexLayout>
      </div>
    );
  }

  /**
   * 项目列表行点击
   */
  private projectRowClick = (record: IProjectItem, action: number) => {
    const url = action === 0 ? `/projectTaskDeatil/${record.id}/${record.name}` : `/newProjectTask/${record.id}`;
    this.props.history.push(url);
  };
  /**
   * 获取项目列表
   */
  private getProjects = async (page: number = 1) => {
    const { proPagination } = this.state;
    proPagination.current = page;
    try {
      this.setState({ isLoading: false });
      const { result } = await ProxAiManageApi.getProjects<
        INormalList<IProjectItem[]>,
        { page: number; pageSize: number }
      >({
        page: proPagination.current,
        pageSize: proPagination.pageSize,
      });

      if (result) {
        proPagination.total = result.total;
        this.setState({
          proPagination,
          projectItems: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { proPagination } = this.state;
    proPagination.current = pagination.current || 1;
    this.setState({ proPagination });
    this.getProjects(proPagination.current);
  };
  /**
   * 点击添加项目
   */
  private addOneProject = () => {
    Storage.setStore('PROJECTITEMS', JSON.stringify(this.state));
    this.props.history.push('/newProject');
  };
}
const ProxAiManage = Form.create()(ProxAiManagePage);
export default ProxAiManage;
