import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router-dom';
import { IVmBoxItem } from '../SecurityBox/CodeType';
import { IOrderDetailInfo } from './../OrderPromise/VMOrders/CodeType';
export interface IVmJsonItem {
  // 安全盒 json
  cpu_type: string;
  cpu_cores: number;
  cpu_frequency: string;
  memory_size: number; // MB 2400 = 2.4G
  memory_frequency: number; // MHZ 2400 = 2.4G
  gpu_core_type: string;
  gpu_core_frequency: string;
  gpu_memory_type: string;
  disk_space: number; // MB
  os_system: string;
  os_system_version: string;
}

export interface IDowloadItem {
  code: string;
  created: string;
  downloadDir: string;
  id: number;
  serverId: number;
  status: number;
  strStatus: string;
  sysRemark: string;
  updated: string;
  userId: number;
  userRemark: string;
  orderVmCode: number;
  nodeName: string;
  contractCode: string;
}
export interface IModelState {
  dlist: INormalList<[IDowloadItem]>;
  // orderDataVmItems: IOrderDataVmItem[];
  orderItemDetail?: IOrderDetailInfo;
  dowloadMessage?: IDowloadMessage;
}
export interface INormalList<T> {
  // 以后列表都用这个
  pages: number;
  size: number;
  total: number;
  current: number;
  records: T;
}
export interface IProps extends FormComponentProps, RouteComponentProps {
  dowloadModles: IModelState;
}
export interface IDownDatas {
  pageNum: number;
  pageSize: number;
  status?: number | string;
}
export interface IDowloadMessage {
  id?: number;
  pveUserid?: string;
  password?: string;
  ftpAddress?: string;
  ftpPort?: number;
  smbUsername: string;
  smbPassword: string;
}
export interface IState {
  isJD: boolean;
  drawerVisible: boolean;
  isLoading: boolean;
  drawerDetailVisible: boolean;
  codeNumber?: string; // 下载编号
  dowloadDiscription?: string; // 下载说明
  // selectOrderItem?: IOrderDataVmItem;
  selectOptions?: JSX.Element[];
  modalVisible: boolean;
  drawerSpin: boolean;
  drawerDetailBtnVisible: boolean;
  submitBtnOn: boolean; //

  params: IDownDatas;
  datas: IDowloadItem[];
  total: number;
  finishDowloadData: IDowloadMessage;
  orderItemDetail?: IOrderDetailInfo;
  vmList: IVmBoxItem[];
  selectedVm?: IVmBoxItem;
}
export interface IItemList {
  [index: string]: string | number | undefined;
  jsonDesc: string;
  packageJsonDesc: string;
}
export interface IDowloadList {
  records?: IDowloadItem[];
}
export interface IDowloadItem {
  code: string;
  created: string;
  downloadDir: string;
  id: number;
  serverId: number;
  status: number;
  strStatus: string;
  sysRemark: string;
  updated: string;
  userId: number;
  userRemark: string;
  orderVmCode: number;
  nodeName: string;
  contractCode: string;
}

export const InitState = {
  drawerVisible: false,
  drawerDetailVisible: false,
  selectOptions: [],
  modalVisible: false,
  drawerSpin: false,
  drawerDetailBtnVisible: false,
  submitBtnOn: true,
  vmList: [],
  params: {
    pageNum: 1,
    pageSize: 10,
    status: '',
  },
  datas: [],
  total: 0,
  finishDowloadData: {},
  isLoading: false,
  isJD: false,
};
