import { Button, Form, Radio, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RadioChangeEvent } from 'antd/lib/radio';
import DataManagerApi from '../../../api/DataManager';

import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import { IDictions, IOriginalShareDataItem } from '../../DataManager/CodeType';

import UserAccount from '../../../api/UserAccount';
import AuthCmp from '../../../components/AuthCmp/AuthCmp';

import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import SciOrignalCmp from '../SciOrignalComponent/SciOrignalCmp';
import { IOptionUser } from '../SciPackageComponet/CodeType';
import SciPkgCmp from '../SciPackageComponet/SciPkgCmp';
import SciWaitCmp from '../SciWaitComponet/SciWaitCmp';
import TemporaryData from '../TemporaryData/TemporaryData';
import { initSciDataManageState, ISciDataManageState, PROJECTITEMSLIST } from './CodeType';
import './SciDataManage.scss';
interface ISciDataManageProps extends FormComponentProps, RouteComponentProps<{ pId: string; name: string }> {}
class SciDataManagePage extends React.Component<ISciDataManageProps, ISciDataManageState> {
  public originalShareDataColums = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUID',
      key: 'seriesInstanceUID',
      render: (text: string, record: IOriginalShareDataItem) => {
        return (
          <div className="sequences-uuid-table divTitle" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '上传时间',
      dataIndex: 'importDate',
      key: 'importDate',
    },
    {
      title: '检查号',
      dataIndex: 'studyInstanceUID',
      key: 'studyInstanceUID',
      render: (text: string, record: IOriginalShareDataItem) => {
        return (
          <div className="sequences-uuid-table" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
      render: (text: string, record: IOriginalShareDataItem) => {
        return <div> {text ? text : '未知'} </div>;
      },
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IOriginalShareDataItem) => {
        const partName = this.state.devicesDiction.get(record.modality);
        return <div> {partName ? partName.name : ''} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IOriginalShareDataItem) => {
        const partName = this.state.partsDiction.get(record.bodyPart);
        return <div> {partName ? partName.name : ''} </div>;
      },
    },
  ];
  constructor(props: ISciDataManageProps) {
    super(props);
    this.state = { ...initSciDataManageState };
  }
  public async componentDidMount() {
    //
    this.getPartsDiction();
    this.getDevicesDiction();
    this.getMembersOptions();
    this.getProjectAuth([
      'USER_PROJECT_ORIGINAL_MANAGE',
      'USER_PROJECT_INTERMEDIATE_MANAGE',
      'USER_PROJECT_JY_TASK_MANAGE',
    ]);
  }

  public render() {
    const { currentRadio } = this.state;
    const loadTitle = currentRadio === 1 ? '引入原始数据' : '上传临时文件';
    const loadPermission = currentRadio === 1 ? 'USER_PROJECT_MANAGE' : 'USER_PROJECT_INTERMEDIATE_MANAGE';
    const loadBtnHidden = currentRadio < 3 ? false : true;
    const locationTitle = ['原始数据', '临时数据', '鉴影已完成任务', '项目数据包'][currentRadio - 1];

    return (
      <div className="scientific-data-manage-continer">
        <IndexLayout>
          <Spin spinning={this.state.isLoading}>
            <div style={{ marginLeft: '24px', marginBottom: '8px' }} hidden={this.state.isLoading}>
              <div
                style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}
              >
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.props.history.goBack}>
                  <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                  <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回项目列表</span>
                </div>
                <div style={{ color: 'rgba(0,0,0,0.45)', cursor: 'pointer' }}>
                  <IconFont type={'medataicon-icon_location'} className="located-icon" />
                  <span onClick={this.props.history.goBack} style={{ marginLeft: '10px', marginRight: '5px' }}>
                    项目列表
                  </span>
                  /<span style={{ marginLeft: '5px' }}>项目数据-{locationTitle}</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="project-message-title">
                  <div style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                    <div className="dot" /> {`「${this.props.match.params.name}」项目数据列表`}
                  </div>
                </div>
                <div className="project-message-title">
                  <AuthComponent isShow={this.state.projectAuths.has(loadPermission)}>
                    <Button className="complete-btn" onClick={this.loadMembers} type="primary" hidden={loadBtnHidden}>
                      {loadTitle}
                    </Button>
                  </AuthComponent>
                </div>
              </div>
            </div>
            <div className="header">
              <div className="list-type-left-container">
                <div style={{ width: '100%', height: '1px', backgroundColor: 'rgba(214, 214, 214, 0.6)' }} />
              </div>
              <Radio.Group
                value={this.state.currentRadio}
                buttonStyle="solid"
                onChange={this.handleTypeGroupChanage}
                className="radio-more-type"
              >
                <AuthCmp permission={PROJECTITEMSLIST[1]} projectId={Number(this.props.match.params.pId)}>
                  <Radio.Button value={1}>原始数据</Radio.Button>
                </AuthCmp>
                <AuthCmp
                  permission={'USER_PROJECT_INTERMEDIATE_MANAGE'}
                  projectId={Number(this.props.match.params.pId)}
                >
                  <Radio.Button value={2}>临时数据</Radio.Button>
                </AuthCmp>
                <AuthCmp permission={PROJECTITEMSLIST[3]} projectId={Number(this.props.match.params.pId)}>
                  <Radio.Button value={3}>鉴影已完成任务</Radio.Button>
                </AuthCmp>
                <Radio.Button value={4}>项目数据包</Radio.Button>
              </Radio.Group>
              <div className="list-type-left-container">
                <div style={{ width: '100%', height: '1px', backgroundColor: 'rgba(214, 214, 214, 0.6)' }} />
              </div>
            </div>
            <div style={{ marginTop: '32px' }}> {this.showListView()}</div>
          </Spin>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 引入原始数据或者临时数据
   */
  private loadMembers = () => {
    if (this.state.currentRadio === 1) {
      this.setState({ loadDataVisible: true });
    } else if (this.state.currentRadio === 2) {
      this.setState({ updataModalVisable: true });
    }
  };
  /**
   *  条件显示列表组件
   */
  private showListView = () => {
    const type = Number(this.state.currentRadio);
    if (type === 1) {
      return (
        <SciOrignalCmp
          form={this.props.form}
          partsDiction={this.state.partsDiction}
          devicesDiction={this.state.devicesDiction}
          loadModalVisible={this.state.loadDataVisible}
          projectId={Number(this.props.match.params.pId)}
          projectName={this.props.match.params.name}
          userItems={this.state.userItems}
          // tslint:disable-next-line:jsx-no-multiline-js
          loadModalClose={() => {
            this.setState({ loadDataVisible: false });
          }}
        />
      );
    } else if (type === 2) {
      return (
        <TemporaryData
          form={this.props.form}
          partsDiction={this.state.partsDiction}
          devicesDiction={this.state.devicesDiction}
          updataModalVisable={this.state.updataModalVisable}
          projectId={Number(this.props.match.params.pId)}
          userItems={this.state.userItems}
          projectName={this.props.match.params.name}
          // tslint:disable-next-line:jsx-no-multiline-js
          updataModalClose={() => {
            this.setState({ updataModalVisable: false });
          }}
        />
      );
    } else if (type === 3) {
      return (
        <SciWaitCmp
          form={this.props.form}
          sharedAction={this.sharedSuccessrefesh}
          partsDiction={this.state.partsDiction}
          projectId={Number(this.props.match.params.pId)}
          devicesDiction={this.state.devicesDiction}
        />
      );
    } else {
      return (
        <SciPkgCmp
          form={this.props.form}
          projectId={Number(this.props.match.params.pId)}
          goSecurityBox={() => this.goToSecurityBox()}
        />
      );
    }
  };

  /**
   * 跳转到安全盒管理
   */
  private goToSecurityBox = () => {
    const url = `/secBoxManage/${this.props.match.params.pId}/${this.props.match.params.name}`;
    this.props.history.push(url);
  };
  /**
   * 分享成功后切换 页面\
   */
  private sharedSuccessrefesh = () => {
    this.setState({
      currentRadio: 3,
    });
  };
  /**
   * 处理页面Radio 切换
   */
  private handleTypeGroupChanage = (e: RadioChangeEvent) => {
    this.setState({
      currentRadio: e.target.value,
    });
  };

  /**
   * 获取身体部位字典
   */
  private getPartsDiction = async () => {
    const { result } = await DataManagerApi.getPartsInfo<IDictions[]>();
    const newPartsDiction = new Map();
    if (result) {
      result.forEach(item => {
        newPartsDiction.set(item.value, item);
      });
      this.setState({
        partsDiction: newPartsDiction,
      });
    }
  };

  /**
   * 获取检查设备列表
   */
  private getDevicesDiction = async () => {
    const { result } = await DataManagerApi.getDevicesInfo<IDictions[]>();
    const newDevicesDiction = new Map();
    if (result) {
      result.forEach(item => {
        newDevicesDiction.set(item.value, item);
      });
      this.setState({
        devicesDiction: newDevicesDiction,
      });
    }
  };
  /**
   *  获取用户列表
   */

  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({
        userItems: [...result],
      });
    }
  };
  /**
   * 获取权限 暂时调用后面会去掉,类组件无法调用hook
   */
  private getProjectAuth = async (permission: string[]) => {
    const { pId } = this.props.match.params;
    const { result } = await UserAccount.getProjectAuth<string[], { projectId: string }>({
      projectId: pId,
    });
    this.setState({ projectAuths: new Set([...result]) });
    permission.forEach((item, index) => {
      const ret = result.includes(item);
      if (!ret) {
        this.setState({ currentRadio: index + 2, isLoading: false });
        return;
      }
    });
    this.setState({ isLoading: false });
  };
}
const SciDataManage = Form.create()(SciDataManagePage);
export default SciDataManage;
