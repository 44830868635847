export interface IDicItem {
  [index: string]: string | number;
}
const dictionary: IDicItem[] = [
  {
    project_agency_name: '机构编号',
    project_score: '等级',
    project_data_type: '数据类型',
    project_amount: '数量',
    project_relevant_area: '相关领域',
    project_type: '项目类别',
    project_organism: '物种',
    project_sample_range: '样品范围',
    project_sample_type: '样本类型',
    project_data_format: '数据格式',
    project_tech_platform: '技术平台',
    project_disease_type: '疾病名称',
    project_genome: '参考基因组',
    project_name: '项目名称',
    project_overview: '项目描述',
    project_design: '项目设计',
  },
  {
    project_agency_name: '机构编号',
    project_score: '等级',
    project_data_type: '数据类型',
    project_amount: '数量',
    project_disease_type: '疾病类型',
    project_label_format: '标注方式',
    project_data_format: '数据格式',
    project_testing_method: '检测手段',
    project_name: '项目名称',
    project_overview: '项目描述',
  },
];
export default dictionary;
