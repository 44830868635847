import React from 'react';
import SockjsClient from 'sockjs-client';
import { over } from '../../../node_modules/stompjs-websocket';
import Config from '../../config/Config';
import Storage from './../../util/Storage';

interface IProps {
  // tslint:disable-next-line:no-any
  subscribeNotification?: () => any; // 订阅通知
}
/**
 * 订阅服务端的推送消息组件
 * 每个对应的订阅类型比如 "通知" 都会有对应的订阅返回
 */
class SockJsClient extends React.Component<IProps, {}> {
  public componentDidMount() {
    const userid = Storage.getStore('userid');
    const sockjsClient = new SockjsClient(`${Config.REACT_APP_DOMAIN}/ws-init`);
    const stompjs = over(sockjsClient);
    stompjs.connect({}, () => {
      // 订阅通知
      stompjs.subscribe(`/user/${userid}/user-notification`, res => {
        if (this.props.subscribeNotification) {
          this.props.subscribeNotification();
        }
      });
    });
  }

  public render() {
    return null;
  }
}
export default SockJsClient;
