import React from 'react';
import { IconFont } from '../../config/IconConfig';
import './RightMenu.scss';
interface IProps {
  title?: string;
  icon: string;
  className?: string;
  onClick?: () => void;
  iconTansform?: boolean;
}

interface IState {
  hover: boolean;
}

class RightMenu extends React.PureComponent<IProps, IState> {
  public state = {
    hover: false,
  };

  public render() {
    const containerClass = this.state.hover ? 'right-menus-active' : 'right-menus';
    const titileShow = this.props.title ? !this.state.hover : true;
    return (
      <div
        className={containerClass}
        onMouseOver={this.divMouseEnter}
        onMouseOut={this.divMouseLeave}
        onClick={this.props.onClick}
      >
        <div className="menu-title" hidden={titileShow}>
          {this.props.title}
        </div>
        <div className={this.state.hover ? 'icon-container-active' : 'icon-container'}>
          <IconFont
            type={this.props.icon}
            className="icon"
            style={{ transform: this.props.iconTansform ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: '18px' }}
          />
        </div>
      </div>
    );
  }

  public divMouseEnter = (e: React.SyntheticEvent) => {
    e.preventDefault();

    this.setState({
      hover: true,
    });
  };
  public divMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };
}

export default RightMenu;
