import { FormComponentProps } from 'antd/lib/form';

import { Button } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import './SciAppTools.scss';
interface ISciAppToolsProps extends FormComponentProps, RouteComponentProps<{ pId: string; name: string }> {}
class SciAppTools extends React.Component<ISciAppToolsProps, {}> {
  public render() {
    return (
      <div className="sci-app-container">
        <IndexLayout>
          <div style={{ marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.props.history.goBack}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回项目列表</span>
              </div>
              <div style={{ color: 'rgba(0,0,0,0.45)' }}>
                <IconFont type={'medataicon-icon_location'} className="located-icon" />
                <span onClick={this.props.history.goBack} style={{ marginLeft: '10px', marginRight: '5px' }}>
                  项目列表
                </span>
                /<span style={{ marginLeft: '5px' }}>应用工具</span>
              </div>
            </div>
            <div className="project-list-title">
              <div style={{ height: '32px', display: 'flex', alignItems: 'center' }}>
                <div className="dot" /> 应用工具
              </div>
            </div>
          </div>
          <div className="toos-container">
            <div className="tool-card">
              <IconFont type={'medataicon-jianying'} className="tool-card-icon" style={{ fontSize: '31px' }} />
              <div style={{ marginTop: '9px', fontWeight: 'bold', color: 'rgba(0,0,0,1)' }}> 鉴影 </div>
              <div style={{ marginTop: '36px', color: 'rgba(0,0,0,0.64)', marginBottom: '50px' }}>
                提供影像文件标注服务的科研工具
              </div>
              <Button className="theme-normal-btn" onClick={this.openProxAITasks}>
                鉴影管理
              </Button>
            </div>
          </div>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 鉴影管理点击事件
   *
   */
  private openProxAITasks = () => {
    this.props.history.push(`/sciProxAi/${this.props.match.params.pId}/${this.props.match.params.name}`);
  };
}

export default SciAppTools;
