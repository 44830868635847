import { Card, Divider } from 'antd';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import { IDataPackageItem } from '../../util/DataStruct';
import AuthComponent from './../AuthComponent/AuthComponent';
import { IMyOrders } from './CodeType';
import './TableCard.scss';
const StatueColors = ['#D0021B', '#52C41A', '#52C41A', '#52C41A', '#52C41A', '#FAAD14'];
interface IState {
  collapsed?: boolean;
}
interface IProps {
  btnGroup: JSX.Element;
  data: IMyOrders;

  typeColumn: Array<{ key: number; label: string }>;
}
class TableCard extends React.PureComponent<IProps, IState> {
  public state = {};

  /**
   * titleRender
   */
  public titleRender = () => {
    const { data } = this.props;

    return (
      <div className="tablecard-title">
        <span className="tablecard-info-title-msg">{data.created}</span>
        <span className="tablecard-smalltitle">数据使用编号：</span>
        <span className="tablecard-info-title-msg">{data.orderCode}</span>
        <AuthComponent permission="USER_ORDER_READ">
          <span className="tablecard-info-title-msg">用户名：{data.loginName}</span>
        </AuthComponent>
        <span className="tablecard-status success" style={{ color: StatueColors[data.status - 1] }}>
          {this.props.typeColumn[data.status - 1].label}
        </span>
      </div>
    );
  };
  /**
   * statusrender
   */
  public statusrender = () => {
    const { typeColumn, data } = this.props;
    let labelClass: string;
    let iconFontType: string;
    const statusGroup = typeColumn.map((res, index) => {
      labelClass = res.key > data.status ? 'unfix' : 'fix';
      iconFontType = res.key > data.status ? 'medataicon-xuanzhong' : 'medataicon-xuanzhong-copy';
      if (data.status === 6) {
        labelClass = 'unfix';
        iconFontType = 'medataicon-xuanzhong';
      }
      return (
        <label key={index} className={index === typeColumn.length - 1 ? 'laststatus ' + labelClass : labelClass}>
          <IconFont type={iconFontType} style={{ marginRight: '6px' }} className="statusfont" />
          {res.label}
        </label>
      );
    });
    return statusGroup;
  };

  public render() {
    const { btnGroup, data } = this.props;
    const pkg: IDataPackageItem = JSON.parse(data.packageJsonDesc || '{}');
    const hiddenOrderProgress = data.status === 1 ? true : false;
    return (
      <Card title={this.titleRender()} className="tablecard">
        <div className="tablecard-info-title">
          <span className="tablecard-info-title-msg left">{pkg.project_name} </span>
          <span className="tablecard-info-title-msg right">
            <span>总积分：</span>
            {data.coinPrice}积分
          </span>
        </div>
        <div className="tablecard-info-item">
          <span className="tablecard-info-title-msg">
            <span>机构编号：</span>
            {pkg.project_agency_no}
          </span>
          <span className="tablecard-info-title-msg">
            <span>病种：</span>
            {pkg.project_disease_type}
          </span>
          <span className="tablecard-info-title-msg">
            <span className="">数据格式：</span>
            {pkg.project_data_format}
          </span>

          <span className="tablecard-info-title-msg">
            <span>数量：</span>
            {pkg.project_amount}
          </span>
          <span className="tablecard-info-title-msg">
            <span>等级：</span>
            {pkg.project_score}
          </span>
          <span className="tablecard-info-title-msg">
            <span>可用时长：</span>
            {data.days} 天
          </span>
        </div>
        <div className="tablecard-info-item" hidden={hiddenOrderProgress}>
          <span className="tablecard-smalltitle">数据使用状态：</span> {this.statusrender()}
        </div>
        <div className="btngroup">
          <Divider /> {btnGroup}
        </div>
      </Card>
    );
  }
}
export default TableCard;
