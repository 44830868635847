import { Breadcrumb } from 'antd';

import React from 'react';
import './RouterHeader.scss';
interface IProps {
  title: string;
  hiddenBreadcrumb?: boolean;
  routes?: string[];
  titles?: string[];
}

interface IState {
  paths: string[];
  pathTitle: JSX.Element[];
}

// const pathName = location.href.split('#')[1];
class RouterHeader extends React.PureComponent<IProps, IState> {
  public state = {
    paths: [],
    pathTitle: [],
  };

  public render() {
    const containerClass = this.props.hiddenBreadcrumb ? 'router-header-hidden-breadcrumb' : 'routeHeaderContainer';
    return (
      <div className={containerClass}>
        <div hidden={this.props.hiddenBreadcrumb} className="router-breadcrumb-container">
          {this.breadcrumb()}
        </div>
        <div className="router-title-container">
          <span style={{ fontSize: '24px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.64)' }}>{this.props.title}</span>
        </div>
      </div>
    );
  }

  public breadcrumb = () => {
    if (!this.props.routes || !this.props.titles) {
      return null;
    }

    const routes = this.props.routes;
    const titles = this.props.titles;
    const items: JSX.Element[] = [];
    for (let index = 0; index < routes.length; index++) {
      const href = '#' + routes[index];

      const item = (
        <Breadcrumb.Item key={titles[index]}>
          <a href={href}>{titles[index]}</a>
        </Breadcrumb.Item>
      );
      items.push(item);
    }
    return <Breadcrumb>{items}</Breadcrumb>;
  };
}

export default RouterHeader;
