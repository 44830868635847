import axios from './RequestApi';
/**
 * @class 安全盒
 */
export default class HttpRequest {
  /**
   * 获取安全盒列表
   */
  public static getVMs<T>() {
    return axios.get<T, {}>('/common/getVmConfigList');
  }
  /**
   *
   * 获安全盒列表
   */
  public static getAllVmItems<T>(data: IVmListReq) {
    return axios.get<T, IVmListReq>('/user/logined/vmInstance/page', data);
  }

  /**
   * 获取安全盒信息详情
   */
  public static getVmInfo<T, D>(data: D) {
    return axios.get<T, D>(`/user/logined/vmInstance/getVmUserInfo`, data);
  }

  /**
   *  获取安全盒订单安全盒控制台详情
   */
  public static getVmOrderConsoleInfo<T, D>(data: D) {
    return axios.get<T, D>(`/user/logined/vmInstance/getVmConsoleInfo`, data);
  }

  /**
   *  获取安全盒详情
   */
  public static getSecurityBoxMsg(data: IVmMsgReq) {
    return axios.get<IVmJsonItem, IVmMsgReq>(`/user/logined/vmInstance`, data);
  }
  /**
   * 获取单个虚拟机配置接口
   *
   */
  public static getVmInstanceConsoleInfo<T>(data: IVmMsgReq) {
    return axios.get<T, IVmMsgReq>(`/user/logined/vmInstance/getVmConsoleInfo`, data);
  }
  /**
   * 启动一个安全盒
   *
   */

  public static startSecurityBox(url: string) {
    return axios.post<IVmJsonItem, {}>(url);
  }
  /*
   *
   数据包批量 挂载 
   */
  public static pkgLoad(data: IPkgLoadReq) {
    return axios.put<string[], IPkgLoadReq>(`/user/logined/researchProjectPackage/batchUpdate`, data);
  }
  /*
   *
  单个数据包挂载 
   */
  public static signalPkgLoad(data: ISignalPkgLoadReq) {
    return axios.post<string[], ISignalPkgLoadReq>(`/user/logined/researchProjectPackage/updateStatus`, data);
  }
}

export interface IVmListReq {
  researchProjectTaskId?: number;
  pageSize: number;
  pageNum: number;
}

export interface IVmMsgReq {
  vmInstanceId: number;
}

/*虚拟机*/

export interface IVmJsonItem {
  created: string;
  distributeSetupJson: string;
  distributeVmConfigCode: string;
  id: number;
  initPass: string;
  initUser: string;
  node: string;
  ownUserId: number;
  ownUserName: string;
  proxmoxVmId: string;
  proxmoxVmStatus: string;
  researchProjectId: number;
  researchProjectName: string;
  serverId: number;
  serverName: string;
  vmInstanceName: string;
  vmInstanceNumber: string;
}

export interface IStartBoxReq {
  researchProjectId: number;
  ownUserId: number;
}

export interface IPkgLoadReq {
  opUserId?: number;
  packageIds: number[];
  researchProjectId: number;
  status?: number;
}

export interface ISignalPkgLoadReq {
  packageId: number;
  researchProjectId: number;
  status: number;
}
