import { Button, Row } from 'antd';
import React from 'react';
import { IDataPackageItem, IParentOrder, IVmJsonItem } from '../../util/DataStruct';
import DescriptionItem from '../DescriptionItem/DescriptionItem';
import SecurityBoxView from './SecurityBoxView';
import './TradeDetailDrawer.scss';
interface IProps {
  onClose?: () => void;
  openBox?: () => void;
  showSecurityBox: boolean;
  showOrderMessage: boolean;
  showPackageView: boolean;
  orderDetailInfo?: IParentOrder;
  dataPackage?: IDataPackageItem;
  vmJson?: IVmJsonItem;
  statues?: number;
  hiddenFooter?: boolean;
}

class TradeDetailDrawer extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  /**
   * 基因
   */
  public geneticDrawer = () => {
    const packageJson = this.props.dataPackage;
    if (packageJson === undefined) {
      return <Row />;
    }
    return (
      <Row>
        <Row>
          <Row>
            <DescriptionItem title="机构编号" content={packageJson.project_agency_name || '暂无'} />
            <DescriptionItem title="评 分" content={packageJson.project_score || '暂无'} />
            <DescriptionItem title="数据类型" content={'基因'} />
            <DescriptionItem title=" 数  量 " content={packageJson.project_amount || '暂无'} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="相关领域" content={packageJson.project_relevant_area || '暂无'} />
            <DescriptionItem title="项目类别" content={packageJson.project_type || '暂无'} />
            <DescriptionItem title="物种" content={packageJson.project_organism || '暂无'} />
            <DescriptionItem title="样品范围" content={packageJson.project_sample_range || '暂无'} />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="样本类型" content={packageJson.project_sample_type || '暂无'} />
            <DescriptionItem title="数据格式" content={packageJson.project_data_format || '暂无'} />
            <DescriptionItem title="技术平台" content={packageJson.project_tech_platform || '暂无'} />
            <DescriptionItem title="疾病类型" content={packageJson.project_disease_type || '暂无'} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="参考基因组" content={packageJson.project_genome || '暂无'} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="课题描述:" content={packageJson.project_overview || '暂无'} type="long" />
          </Row>
        </Row>
      </Row>
    );
  };
  public imageDrawer = () => {
    const packageJson = this.props.dataPackage;
    if (packageJson === undefined) {
      return <Row />;
    }
    return (
      <Row>
        <Row>
          <Row>
            <DescriptionItem title="机构编号" content={packageJson.project_agency_no || '暂无'} />
            <DescriptionItem title="评 分" content={packageJson.project_score || '暂无'} />
            <DescriptionItem title="数据类型" content={packageJson.project_data_type === 1 ? '基因' : '影像'} />
            <DescriptionItem title=" 数 量" content={packageJson.project_amount || '暂无'} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="疾病类型" content={packageJson.project_disease_type || '暂无'} />
            <DescriptionItem title="标注方式" content={packageJson.project_label_format || '暂无'} />
            <DescriptionItem title="数据格式" content={packageJson.project_data_format || '暂无'} />
            <DescriptionItem title="检测手段" content={packageJson.project_testing_method || '暂无'} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="课题描述:" content={packageJson.project_overview || '暂无'} type="long" />
          </Row>
        </Row>
      </Row>
    );
  };

  /**
   * 数据使用
   */
  public orderMessageView = () => {
    //
    const { orderDetailInfo } = this.props;
    return (
      <Row>
        <Row style={{ marginBottom: '10px' }}>
          <DescriptionItem
            title="数据使用编号:"
            type="mid"
            content={orderDetailInfo ? (orderDetailInfo.code ? orderDetailInfo.code : '暂无') : '暂无'}
          />
          <DescriptionItem
            title="可用时间(天):"
            type="mid"
            content={orderDetailInfo ? (orderDetailInfo.availableDays ? orderDetailInfo.availableDays : '0') : '0'}
          />
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <DescriptionItem
            title="数据使用日期:"
            type="mid"
            content={orderDetailInfo ? (orderDetailInfo.created ? orderDetailInfo.created : '暂无') : '暂无'}
          />
        </Row>
      </Row>
    );
  };
  /**
   * 数据包
   */
  public packageMessageView = () => {
    const packageJson = this.props.dataPackage;
    if (packageJson === undefined) {
      return <Row />;
    }
    return packageJson.project_data_type === 1 ? this.geneticDrawer() : this.imageDrawer();
  };
  /**
   * 安全盒
   */
  public securityBox = () => {
    const vmJson = this.props.vmJson;
    if (vmJson === undefined) {
      return <Row />;
    }
    return (
      <Row>
        <SecurityBoxView vmJson={vmJson} />
      </Row>
    );
  };

  public render() {
    const orderStatue = this.props.statues ? this.props.statues : 0;
    let showBox = false;
    if (orderStatue > 2 && orderStatue < 6) {
      showBox = true;
    }
    return (
      <Row>
        {this.props.showOrderMessage ? this.orderMessageView() : <Row />}

        {// tslint:disable-next-line:jsx-no-multiline-js
        this.props.showPackageView ? (
          <Row className={this.props.showSecurityBox ? 'dividing-boreder' : ''}>{this.packageMessageView()} </Row>
        ) : (
          <Row />
        )}
        {this.props.showSecurityBox ? this.securityBox() : <Row />}
        <div className="button-group" hidden={this.props.hiddenFooter ? this.props.hiddenFooter : false}>
          <Button
            // tslint:disable-next-line:jsx-no-multiline-js jsx-no-lambda
            onClick={() => {
              this.props.openBox ? this.props.openBox() : this.doNothing();
            }}
            type="primary"
            style={{ width: '96px', height: '32px', marginRight: '15px' }}
            hidden={!showBox}
          >
            打开安全盒
          </Button>
          <Button onClick={this.props.onClose} style={{ width: '96px', height: '32px' }}>
            返回
          </Button>
        </div>
      </Row>
    );
  }

  public doNothing = () => {
    // this.props.openBox
  };
}
export default TradeDetailDrawer;
