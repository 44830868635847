import { ITreeData } from '../../components/FileTree/FilePathTree';
import Storage, { USERID } from './../../util/Storage';
import { IDictions, IExcel } from './CodeType';
export const geneticFormTests = [
  'project_name',
  'project_overview',
  'project_data_type',
  'project_agency_no',
  'project_relevant_area',
  'project_type',
  'project_organism',
  'project_sample_range',
  'project_sample_type',
  'project_data_format',
  'project_tech_platform',
  'project_disease_type',
  'project_genome',
];
export const imageFromTests = [
  'project_data_format',
  'project_disease_type',
  'project_name',
  'project_overview',
  'project_testing_method',
  'project_data_format',
  'project_label_format',
  'project_data_type',
  'project_agency_no',
];
export const hasShareType = ['待审核', '已共享', '未共享', '暂不共享'];

export const dataCategory = [{ key: 1, label: '基因' }, { key: 2, label: '影像' }];
export function guid(tId: string) {
  const userId = Storage.getStore(USERID);
  const date = new Date();
  const taskId = userId + date.getFullYear + date.getMonth + date.getDay + date.getHours + tId;
  const taskIdUid = new Buffer(taskId).toString('base64');
  return taskIdUid;
}

export interface IIDataManagerState {
  isJD: boolean;
  isSwiperGuide: boolean;
  exportShow: boolean;
  isAuth: boolean; // 是否有权限使用上传数据
  isLinkJY: boolean;
  currentListType: string; // 1 原始数据 2 待共享数据 3 已共享数据
  updateDataViewVisiable: boolean; // 上传组件是否显示
  shareJsonDecString: string;
  showDataDetail: boolean;
  iconToolCard: string; // 鉴影icon
  showLoadingDataComponnet: boolean;
  treeRoute?: string;
  hintShow: boolean;
  dataType: number;
  fileTree: ITreeData[]; // U盘  存储文件
  excelTrees: ITreeData[]; // excel 文件
  partsDiction: Map<string, IDictions>; // 网络返回的 部位字典
  devicesDiction: Map<string, IDictions>; // 网络返回的 设备字典
  usbAvailable: boolean;
  isAllExpoet: boolean; // 判断是否同时拥有两种数据的上传权限
  geneList: IExcel[];
  imageList: IExcel[];
  showDowloadModes: boolean; // 是否渲染下载Excel 模板
  excelType: number; // 1 基因, 2 影像
  guidType: number; // 1数据导入打开 guid 组件 2 excel 模板下载打开 guid
}

export const defaultState = {
  isJD: true,
  isAllExpoet: false,
  dataType: 1,
  isSwiperGuide: false,
  exportShow: true,
  iconToolCard: 'medataicon-jianying',
  isAuth: true,
  isLinkJY: true, // 是否允许跳转到鉴影
  currentListType: '1',
  updateDataViewVisiable: false,
  shareJsonDecString: '',
  showDataDetail: false,
  showLoadingDataComponnet: false,
  hintShow: false,
  fileTree: [],
  excelTrees: [],
  partsDiction: new Map(),
  devicesDiction: new Map(),
  usbAvailable: false,
  geneList: [{ id: -1, name: '' }],
  imageList: [{ id: -1, name: '' }],
  showDowloadModes: false, // 是否渲染下载Excel 模板
  excelType: 1,
  guidType: 1,
};
