import { Col } from 'antd';
import React from 'react';
import './DescriptionItem.scss';
interface IState {
  collapsed?: boolean;
}
interface IProps {
  title: string | number;
  content: string | number | string[];
  type?: 'long' | 'mid';
  valueColor?: string;
}
class DescriptionItem extends React.PureComponent<IProps, IState> {
  public state = {
    collapsed: false,
  };
  /* 抽屉详情内部固定样式组件 */
  public render() {
    const { valueColor } = this.props;
    const content = this.props.content ? this.props.content : '';
    const title = this.props.title ? this.props.title : '';
    return (
      <div className="DescriptionItem">
        <Col className="DescriptionItem" span={this.props.type === 'long' ? 24 : this.props.type === 'mid' ? 12 : 6}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {this.props.type !== 'mid' ? (
            <div>
              <div className="dataDetailsTitle">
                <b>{title}</b>
              </div>
              <div
                title={content.toString()}
                className={this.props.type === 'long' ? 'long dataDetailsInfo' : 'short lengthControl'}
                style={{ color: valueColor }}
              >
                {content}
              </div>
            </div>
          ) : (
            <div>
              <b>{title}</b>
              <span style={{ color: valueColor, marginLeft: '10px' }}>{content}</span>
            </div>
          )}
        </Col>
      </div>
    );
  }
}
export default DescriptionItem;
