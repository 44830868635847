import { Icon, Menu } from 'antd';
import { Link } from 'dva/router';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import Routes, { IRouteConfigItem } from '../../pages/Routes';
import Storage, { USERPERMISSION, USERTYPE } from './../../util/Storage';

interface IMenuItem {
  path: string;
  name: string;
  icon: string;
  hideInMenu?: string;
  routes?: IMenuItem[];
}
interface IProps {
  menuData?: IMenuItem[];
  openKeys?: string[];
  onOpenChange?: (openKeys: string[]) => void;
  mode: 'vertical' | 'vertical-left' | 'vertical-right' | 'horizontal' | 'inline' | undefined;
  theme: 'light' | 'dark' | undefined;
}

class SideMenu extends React.Component<IProps, {}> {
  public state = {
    collapsed: false,
  };
  public toIcon = (iconName: string | undefined) =>
    iconName ? (
      iconName.includes('icon-') ? (
        <IconFont className="icon-formenu" type={iconName} />
      ) : (
        <Icon className="icon-formenu" type={iconName} />
      )
    ) : (
      ''
    );
  public render() {
    const rolePermissions = JSON.parse(Storage.getStore(USERPERMISSION)) as string[];
    const newRoutes = Routes.filter((value, index) => {
      if (value.permissions) {
        return value.usrTypes.includes(Storage.getStore(USERTYPE)) && rolePermissions.includes(value.permissions);
      }
      return value.usrTypes.includes(Storage.getStore(USERTYPE));
    });

    const pathName = location.href.split('#')[1];
    const submenu = (routes: IRouteConfigItem[], path: string) => {
      const newChirldRoutes = routes.filter((value, index) => {
        return value.usrTypes.includes(Storage.getStore(USERTYPE));
      });
      return newChirldRoutes.map((item: IRouteConfigItem) => {
        if (item.status === 'show') {
          if (item.routes) {
            return (
              <Menu.SubMenu key={path + item.path} title={item.name} className="center">
                {submenu(item.routes, path + item.path)}
              </Menu.SubMenu>
            );
          } else {
            return (
              <Menu.Item key={path + item.path} title={item.name} className="center">
                {this.toIcon(item.icon)}
                <span>{item.name}</span>

                <Link to={path + item.path} />
              </Menu.Item>
            );
          }
        }
      });
    };
    /* menu构成 */
    const menus = newRoutes.map((route, i) => {
      const title = (item: IRouteConfigItem) => (
        <span>
          {this.toIcon(item.icon)}
          <Link to={route.path} />
        </span>
      );
      if (route.status === 'show') {
        if (route.routes) {
          return (
            <Menu.SubMenu className="top-item center" key={route.path} title={title(route)}>
              {submenu(route.routes, route.path)}
            </Menu.SubMenu>
          );
        } else {
          return (
            <Menu.Item className="top-item center" key={route.path}>
              {/* {this.toIcon(route.icon)} */}
              <span>
                {route.name}
                <div className="top-item-line" />
              </span>

              <Link to={route.path} />
            </Menu.Item>
          );
        }
      }
    });
    return (
      <Menu
        mode={this.props.mode}
        theme={this.props.theme}
        defaultOpenKeys={this.props.openKeys}
        openKeys={this.props.openKeys}
        onOpenChange={this.props.onOpenChange}
        selectedKeys={[pathName]}
        className="Menu"
      >
        {menus}
      </Menu>
    );
  }
}

export default SideMenu;
