import axios from './RequestApi';
/**
 * @class 用户管理，包括节点用户和云端用户
 */
export default class HttpRequest {
  /**
   * 登录
   */
  public static login<T, D>(data: D) {
    return axios.post<T, D>('/user/login', data);
  }

  /**
   * 用户积分
   */
  public static userGradeInfo<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/account/info', data);
  }
  /**
   * 更新用户密码
   */
  public static updataUserPsd<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/updatePassword', data);
  }

  /**
   *  获取项目权限
   */
  public static getProjectAuth<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/permission/project/permissionList', data);
  }

  /**
   * 获取角色列表
   */
  public static getRoleList() {
    return axios.get<IRoleItem[], {}>('/user/logined/role/projectRoleList');
  }
}

export interface IRoleItem {
  code: string;
  created: string;
  id: number;
  permissions: string[];
  roleName: string;
  status: number;
  statusStr: string;
}
