import { Row } from 'antd';
import React from 'react';

import { IOrderDetailInfo } from '../../pages/DataManager/CodeType';
import { IDataPackageItem, IParentOrder, IVmJsonItem } from '../../util/DataStruct';
import DescriptionItem from '../DescriptionItem/DescriptionItem';
import './GradesDetailDrawer.scss';
import SecurityBoxView from './SecurityBoxView';

interface IProps {
  showGradeOrder?: boolean;
  showSecurityBox: boolean;
  showPackageView: boolean;
  orderDetailInfo?: IParentOrder;
  dataPackage?: IDataPackageItem;
  vmJson?: IVmJsonItem;
  gradeOrder?: IOrderDetailInfo; // 数据退款
  hiddenContactNumber?: boolean;
  coinPrice: number;
}

class GradesDetailDrawer extends React.PureComponent<IProps> {
  /**
   * 基因
   */
  public geneticDrawer = () => {
    const packageJson = this.props.dataPackage;
    if (packageJson === undefined) {
      return <Row />;
    }

    return (
      <Row>
        <Row className="grade-box-header">
          <span style={{ fontWeight: 'bold', marginBottom: '23px', color: '#000000' }}>数据详情: </span>
        </Row>
        <Row>
          <Row>
            <DescriptionItem title="机构编号" content={packageJson.project_agency_no || ''} />
            <DescriptionItem title="评 分" content={packageJson.project_score || ''} />
            <DescriptionItem title="数据类型" content={packageJson.project_data_type === 1 ? '基因' : '影像'} />
            <DescriptionItem title=" 数  量 " content={packageJson.project_amount || ''} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="相关领域" content={packageJson.project_relevant_area || ''} />
            <DescriptionItem title="项目类别" content={packageJson.project_type || ''} />
            <DescriptionItem title="物种" content={packageJson.project_organism || ''} />
            <DescriptionItem title="样品范围" content={packageJson.project_sample_range || ''} />
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="样本类型" content={packageJson.project_sample_type || ''} />
            <DescriptionItem title="数据格式" content={packageJson.project_data_format || ''} />
            <DescriptionItem title="技术平台" content={packageJson.project_tech_platform || ''} />
            <DescriptionItem title="疾病类型" content={packageJson.project_disease_type || ''} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="参考基因组" content={packageJson.project_genome || ''} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="课题描述:" content={packageJson.project_overview || ''} type="long" />
          </Row>
        </Row>
      </Row>
    );
  };
  /**
   * 影像
   */
  public imageDrawer = () => {
    const packageJson = this.props.dataPackage;
    if (packageJson === undefined) {
      return <Row />;
    }

    return (
      <Row>
        <Row className="grade-box-header">
          <span style={{ fontWeight: 'bold', marginBottom: '24px', color: '#000000' }}>数据详情: </span>
        </Row>
        <Row>
          <Row>
            <DescriptionItem title="机构编号" content={packageJson.project_agency_no || ''} />
            <DescriptionItem title="评 分" content={packageJson.project_score || ''} />
            <DescriptionItem title="数据类型" content={packageJson.project_data_type === 1 ? '基因' : '影像'} />
            <DescriptionItem title=" 数 量" content={packageJson.project_amount || ''} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="疾病类型" content={packageJson.project_disease_type || ''} />
            <DescriptionItem title="标注方式" content={packageJson.project_label_format || ''} />
            <DescriptionItem title="数据格式" content={packageJson.project_data_format || ''} />
            <DescriptionItem title="检测手段" content={packageJson.project_testing_method || ''} />
          </Row>

          <Row style={{ marginBottom: '10px' }}>
            <DescriptionItem title="课题描述:" content={packageJson.project_overview || ''} type="long" />
          </Row>
        </Row>
      </Row>
    );
  };
  /**
   * 数据使用
   */
  public gradesOrderView = () => {
    const { gradeOrder, coinPrice } = this.props;
    const valueColor = coinPrice > 0 ? '#7ED321' : '#D0021B';

    return (
      <Row>
        <Row style={{ marginBottom: '10px' }} hidden={this.props.hiddenContactNumber}>
          <DescriptionItem
            title="数据使用编号:"
            type="mid"
            content={gradeOrder ? (gradeOrder.code ? gradeOrder.code : '') : ''}
          />
          <DescriptionItem
            title="合同编号:"
            type="mid"
            content={gradeOrder ? (gradeOrder.contractNumber ? gradeOrder.contractNumber : '') : ''}
          />
        </Row>
        <Row style={{ marginBottom: '10px' }} hidden={!this.props.hiddenContactNumber}>
          <DescriptionItem
            title="数据使用编号:"
            type="mid"
            content={gradeOrder ? (gradeOrder.code ? gradeOrder.code : '') : ''}
          />
          <DescriptionItem
            title="数据使用日期:"
            type="mid"
            content={gradeOrder ? (gradeOrder.created ? gradeOrder.created : '') : ''}
          />
        </Row>
        <Row style={{ marginBottom: '10px' }} hidden={this.props.hiddenContactNumber}>
          <DescriptionItem
            title="数据使用日期:"
            type="mid"
            content={gradeOrder ? (gradeOrder.created ? gradeOrder.created : '') : ''}
          />
          <DescriptionItem title="积分:" type="mid" content={this.props.coinPrice} valueColor={valueColor} />
        </Row>
        <Row style={{ marginBottom: '10px' }} hidden={!this.props.hiddenContactNumber}>
          <DescriptionItem
            title="积分:"
            type="mid"
            // tslint:disable-next-line:jsx-no-multiline-js
            content={coinPrice === 0 ? '0' : coinPrice}
            valueColor={valueColor}
          />
        </Row>
      </Row>
    );
  };
  /**
   * 数据包
   */
  public packageMessageView = () => {
    const packageJson = this.props.dataPackage;
    if (packageJson === undefined) {
      return <Row />;
    }

    return packageJson.project_data_type === 1 ? this.geneticDrawer() : this.imageDrawer();
  };
  /**
   * 安全盒
   */
  public securityBox = () => {
    const vmJson = this.props.vmJson;
    if (vmJson === undefined) {
      return <Row />;
    }
    return (
      <Row>
        <Row className="grade-box-header">
          <span style={{ fontWeight: 'bold', marginBottom: '24px' }}>安全盒详情: </span>
        </Row>
        <SecurityBoxView vmJson={vmJson} />
      </Row>
    );
  };
  public render() {
    return (
      <Row>
        {this.props.showGradeOrder && this.gradesOrderView()}
        {this.props.showPackageView && this.packageMessageView()}
        {this.props.showSecurityBox && this.securityBox()}
      </Row>
    );
  }
}
export default GradesDetailDrawer;
