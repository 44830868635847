import React from 'react';
import './TableHeader.scss';

interface IProps {
  id?: string;
}

interface IState {
  id?: string;
}

class TableHeader extends React.PureComponent<IProps, IState> {
  public render() {
    return <div className="table-header-container">{this.props.children}</div>;
  }
}

export default TableHeader;
