import { useEffect, useState } from 'react';
import UserAccount from '../../api/UserAccount';
export interface IPermissionProps {
  permissions: string;
  projectId: number;
}

/**
 *
 * 项目管理(USER_PROJECT_MANAGE)
 * 原始数据管理(USER_PROJECT_ORIGINAL_MANAGE)
 * 临时数据管理(USER_PROJECT_INTERMEDIATE_MANAGE)
 * 鉴影任务管理(USER_PROJECT_JY_TASK_MANAGE)
 * 任务管理(USER_PROJECT_TASK_MANAGE)
 * 应用工具管理(USER_PROJECT_APPLICATION_MANAGE)
 */

export function useAuthStatus(props: IPermissionProps) {
  const [projectId] = useState(props.projectId);
  useEffect(() => {
    getProjectAuth();
  }, [projectId]);
  const [isAuth, setIsAuth] = useState(false);
  /**
   * 根据projectId 获取权限状态
   */
  async function getProjectAuth() {
    const { result } = await UserAccount.getProjectAuth<string[], { projectId: number }>({
      projectId: props.projectId,
    });

    if (result) {
      const ret = result.includes(props.permissions);
      setIsAuth(ret);
    }
  }
  return isAuth;
}
