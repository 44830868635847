import { Button, Divider, Input, message, Radio, Table } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { PaginationConfig } from 'antd/lib/table';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MemberManageApi from '../../api/MemberManageApi';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../components/NoDataImg/NoDataImg';
import TableHeader from '../../components/TableHeader/TableHeader';
import IndexLayout from '../../layout/IndexLayout';
import Storage from '../../util/Storage';
import RouteHeader from './../../components/routeHeader/RouteHeader';
import {
  IListReq,
  IMemberAccount,
  IMemberListReq,
  IMemberNewReq,
  IMemberRole,
  IMemberRoleReq,
  IMMState,
  INormalList,
  IPermissionsItem,
  IResetPsdReq,
  IRoleListReq,
  stateInit,
} from './CodeType';
import MemberDrawer, { IDrawerType } from './MemberDrawer/MemberDrawer';
import ResetPassward from './ResetPassward/ResetPassward';
interface IMemberManageProps extends FormComponentProps, RouteComponentProps {}
class MemberManage extends React.Component<IMemberManageProps, IMMState> {
  /**
   *
   * @param base64
   */
  public static encodeBase64Content(content: string) {
    return new Buffer(content).toString('base64');
  }
  public state = stateInit as IMMState;
  public memberColumns = [
    {
      title: '创建时间',
      dataIndex: 'user.created',
      key: 'user.created',
    },
    {
      title: '用户名',
      dataIndex: 'user.loginname',
      key: 'user.loginname',
    },
    {
      title: '账号',
      dataIndex: 'user.mobile',
      key: 'user.mobile',
    },
    {
      title: '角色名称',
      dataIndex: 'role.roleName',
      key: 'role.roleName',
    },

    {
      title: '状态',
      dataIndex: 'user.status',
      key: 'user.status',
      render: (text: string, record: IMemberAccount) => {
        const classname = record.user.status === 1 ? 'status-green' : 'status-red';
        return <div className={classname}>{record.user.status === 1 ? '已启用' : '已禁用'}</div>;
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (text: string, record: IMemberAccount) => {
        return (
          <div className="memberAction">
            <div
              className="memberReset btn"
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.resetPassward(record);
              }}
            >
              密码重置
            </div>
            <Divider type="vertical" />
            <div
              className="memberUpdate btn"
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.editAccount(record);
              }}
            >
              账号编辑
            </div>
            <Divider type="vertical" />
            <div
              className="memberSet btn"
              // tslint:disable-next-line:jsx-no-multiline-js
              onClick={() => {
                this.forbiddenAccount(record);
              }}
            >
              {record.user.status === 1 ? '禁用' : '启用'}
            </div>
          </div>
        );
      },
    },
  ].filter(item => {
    if (this.state.isJD) {
      return item.title !== '角色名称';
    } else {
      return true;
    }
  });

  public roleColumns = [
    {
      title: '创建时间',
      dataIndex: 'created',
      key: 'created',
    },

    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName',
    },

    {
      title: '权限',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (pers: string[], record: IMemberRole) => {
        const perms = record.permissions.map((item, index) => {
          if (index < 1) {
            return (
              <div key={index} className="role-permissions ">
                {this.state.permissions.get(item)}
              </div>
            );
          } else if (index < 5) {
            return (
              <div key={index} className="role-permissions left-border">
                {this.state.permissions.get(item)}
              </div>
            );
          } else if (index < 6) {
            return (
              <div
                key={index}
                className="role-permissions-more left-border"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => {
                  this.roleItemEdit(record);
                }}
              >
                更多
              </div>
            );
          }
        });
        return <div className="toFlex">{perms}</div>;
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (text: string, record: IMemberRole) => {
        return (
          <div
            // tslint:disable-next-line:jsx-no-multiline-js
            onClick={() => {
              this.roleItemEdit(record);
            }}
            className="btn"
          >
            角色编辑
          </div>
        );
      },
    },
  ];

  /** accountTabel Action  */
  // 重置密码
  public resetPassward = (item: IMemberAccount) => {
    this.setState({
      clickItem: item,
      showResetPassward: true,
    });
  };
  /* 编辑账号 */
  public editAccount = (item: IMemberAccount) => {
    this.setState({
      clickItem: item,

      drawerType: IDrawerType.EditAccount,
    });
    this.openDrawer();
  };
  /* 禁用账号 */
  public forbiddenAccount = (item: IMemberAccount) => {
    this.setState({
      clickItem: item,
      updateAccountStatue: true,
    });

    const reqAccountItem: IMemberNewReq = {
      mobile: item.user.mobile,

      status: item.user.status === 1 ? 2 : 1,
    };
    this.updateMemberAccount(reqAccountItem);
  };
  public componentDidMount() {
    //
    this.getPermissionList();
    this.getMemberList();
    this.getRoleList();
    this.setState({
      isJD: Storage.getStore('usertype') === '5',
    });
  }
  public render() {
    const { taskType, showDrawer, drawerType, showResetPassward } = this.state;
    const { form } = this.props;

    const drawerTitle = '账户';
    return (
      <div id="MemberManage">
        <IndexLayout>
          <div className="member-manage" id="member-manage-id">
            <RouteHeader title="成员管理" hiddenBreadcrumb={true} />
            {this.pageHeaderViewRender()}
            {taskType === 1 ? this.memberTaskRender() : this.rolesTaskRender()}
          </div>
          <MemberDrawer
            visiable={showDrawer}
            title={drawerTitle}
            item={this.state.clickItem}
            permission={this.state.permissions}
            onClose={this.closeDrawer}
            okClick={this.drawerConfirmClick}
            type={drawerType}
            roles={this.state.allRoles}
            form={form}
          />
          <ResetPassward
            visible={showResetPassward}
            form={form}
            onClose={this.resetPswCancel}
            onConfirm={this.confirmPswReset}
          />
        </IndexLayout>
      </div>
    );
  }
  /* 页面头部 */
  public pageHeaderViewRender = () => {
    return (
      <div className="task-header">
        <Divider>
          <div className="list-type-center-container">
            <Radio.Group defaultValue={1} buttonStyle="solid" onChange={this.targetRdioChange}>
              <Radio.Button value={1}>账号管理</Radio.Button>
              <AuthComponent isShow={!this.state.isJD}>
                <Radio.Button value={2}>角色管理</Radio.Button>
              </AuthComponent>
            </Radio.Group>
          </div>
        </Divider>
      </div>
    );
  };
  /* 账号列表 */
  public memberTaskRender = () => {
    const { getFieldDecorator } = this.props.form;
    const { accountList, accountPagination } = this.state;
    return (
      <div className="mt30">
        <TableHeader>
          <div className="toFlexCenter">
            <Form.Item label="用户名:">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('memberAccountList')(
                <Input className="queryform-select" placeholder="请输入" allowClear={true} />
              )}
            </Form.Item>
            <Button className="normal-search-button btn-set" onClick={this.searchClickMember}>
              查询
            </Button>
            <Button type="primary" onClick={this.createAccount} className="btn-set">
              + 创建账号
            </Button>
          </div>
        </TableHeader>

        <AuthComponent isShow={!!this.state.accountPagination.total}>
          <Table
            columns={this.memberColumns}
            dataSource={accountList}
            className="whitebackground"
            pagination={{ ...accountPagination }}
            rowKey={item => item.user.id.toString()}
            onChange={this.handleMemberTablePageChange}
          />
        </AuthComponent>
        <NoDataImg isLoading={this.state.isLoading} total={this.state.accountPagination.total} />
      </div>
    );
  };
  /* 角色列表 */
  public rolesTaskRender = () => {
    const { getFieldDecorator } = this.props.form;
    const { roleList } = this.state;
    return (
      <div className="mt30">
        <TableHeader>
          <div className="toFlexCenter">
            <Form.Item label="角色名称:">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('roleAccount')(
                <Input className="queryform-select" placeholder="请输入" allowClear={true} />
              )}
            </Form.Item>
            <Button className="normal-search-button btn-set" onClick={this.searchClickRole}>
              查询
            </Button>
            <Button type="primary" onClick={this.createRoles} className="btn-set">
              + 创建角色
            </Button>
          </div>
        </TableHeader>

        <AuthComponent isShow={!!this.state.rolePagination.total}>
          <Table
            columns={this.roleColumns}
            dataSource={roleList}
            className="whitebackground"
            pagination={{ total: this.state.total }}
            rowKey="id"
            onChange={this.handleRoleTablePageChange}
          />
        </AuthComponent>
        <NoDataImg isLoading={this.state.isLoading} total={this.state.rolePagination.total} />
      </div>
    );
  };
  /* 检索*/
  private searchClickMember = () => {
    //
    this.getMemberList();
  };
  /**
   * 条件检索 角色
   */
  private searchClickRole = () => {
    this.getRoleList();
  };

  /** role Table Action  */
  // 角色编辑
  /* 角色编辑*/
  private roleItemEdit = (item: IMemberRole) => {
    this.setState({
      clickItem: item,
      drawerType: IDrawerType.EditRole,
    });
    this.openDrawer();
  };

  /* 用户列表获取*/
  private getMemberList = async () => {
    const { getFieldValue } = this.props.form;
    const { accountPagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const memberName = getFieldValue('memberAccountList');
      const { result } = await MemberManageApi.memberList<INormalList<IMemberAccount[]>, IMemberListReq>({
        pageNum: accountPagination.current,
        pageSize: accountPagination.pageSize,
        loginName: memberName,
      });
      if (result) {
        accountPagination.current = result.current;
        accountPagination.total = result.total;
        this.setState({
          accountList: result.records,
          accountPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /* 角色列表获取*/
  private getRoleList = async () => {
    // 直接把所有角色都拿回来
    const { getFieldValue } = this.props.form;
    const { rolePagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const roleAccount = getFieldValue('roleAccount');
      const { result } = await MemberManageApi.roleList<INormalList<IMemberRole[]>, IRoleListReq>({
        pageNum: rolePagination.current,
        pageSize: 10,
        roleName: roleAccount,
      });
      if (result) {
        //
        rolePagination.current = result.current;
        rolePagination.total = result.total;
        this.setState({
          roleList: result.records,
          allRoles: result.records,
          rolePagination,
          isLoading: true,
          total: result.total,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /* 权限列表获取*/
  private getPermissionList = async () => {
    const { permissions } = this.state;
    const { result } = await MemberManageApi.permissionList<INormalList<IPermissionsItem[]>, IListReq>({
      pageNum: 1,
      pageSize: 10,
    });
    if (result && result.records) {
      result.records.forEach(item => {
        if (item.status === 1) {
          permissions.set(item.code, item.name);
        }
      });
      this.setState({
        permissions,
      });
    }
  };
  /* 新建角色 */
  private addRoleAction = async (item: IMemberRoleReq) => {
    const result = await MemberManageApi.newRole<string, IMemberRoleReq>({
      roleName: item.roleName,
      permissions: item.permissions,
    });
    if (result) {
      this.clearDrawer();
      this.getRoleList();
      this.setState({
        showDrawer: false,
      });
    }
  };
  /* 添加账号*/
  private addMemberAccount = async (item: IMemberNewReq) => {
    if (item.roleItem !== undefined) {
      // 没有角色 id
      const roleItem = await MemberManageApi.newRole<string, IMemberRoleReq>(item.roleItem);
      if (roleItem) {
        item.roleId = Number(roleItem.result);
        item.roleItem = undefined;
        item.status = 1;
        const result = await MemberManageApi.newAccount<string, IMemberNewReq>(item);
        if (result) {
          // 创建成功
          this.clearDrawer();
          this.getMemberList();
          this.getRoleList();
          this.setState({
            showDrawer: false,
          });
        }
      }
    } else {
      const result = await MemberManageApi.newAccount<string, IMemberNewReq>(item);
      if (result) {
        // 创建成功
        this.clearDrawer();
        this.getMemberList();
        this.setState({
          showDrawer: false,
        });
      }
    }
  };
  /* 更新账号信息*/
  private updateMemberAccount = async (item: IMemberNewReq) => {
    if (item.roleItem !== undefined) {
      // 没有角色 id
      const roleItem = await MemberManageApi.newRole<string, IMemberRoleReq>(item.roleItem);
      if (roleItem) {
        item.roleId = Number(roleItem.result);
        item.roleItem = undefined;
        item.status = 1;
        const result = await MemberManageApi.editMemberAccount<string, IMemberNewReq>(item);
        if (result) {
          // 创建成功
          this.getMemberList();
          this.getRoleList();
          this.clearDrawer();
          this.setState({
            showDrawer: false,
          });
        }
      }
    } else {
      const result = await MemberManageApi.editMemberAccount<string, IMemberNewReq>(item);
      if (result) {
        // 更新账号
        const mes = this.state.updateAccountStatue ? (item.status === 1 ? '启用成功' : '禁用成功') : '更新成功';
        message.success(mes);
        this.clearDrawer();
        this.getMemberList();
        this.setState({
          showDrawer: false,
        });
      } else {
        const mes = this.state.updateAccountStatue ? (item.status === 1 ? '启用失败' : '禁用失败') : '更新失败';
        message.error(mes);
      }
    }
  };
  /*  抽屉确定按钮被点击 */
  private drawerConfirmClick = (type: IDrawerType, values: IMemberNewReq | IMemberRoleReq) => {
    //  抽屉处理
    if (type === IDrawerType.NewAccount) {
      // 创建账号
      this.addMemberAccount(values as IMemberNewReq);
    } else if (type === IDrawerType.EditAccount) {
      // 编辑账号
      this.updateMemberAccount(values as IMemberNewReq);
    } else if (type === IDrawerType.NewRole) {
      // 创建角色
      this.addRoleAction(values as IMemberRoleReq);
    } else {
      // 编辑角色
      this.updateRoleItem(values as IMemberRoleReq);
    }
  };

  /* 更新角色信息*/
  private updateRoleItem = async (item: IMemberRoleReq) => {
    const roleItem = await MemberManageApi.editRole<string, IMemberRoleReq>(item);
    if (roleItem) {
      this.setState({
        showDrawer: false,
      });
      this.clearDrawer();
      this.getRoleList();
    }
  };
  /* 关闭抽屉*/
  private closeDrawer = () => {
    const { rolePagination } = this.state;
    rolePagination.pageSize = 10;
    this.setState({
      showDrawer: false,
      rolePagination,
    });
  };
  private openDrawer = () => {
    const { rolePagination } = this.state;
    rolePagination.pageSize = 1000;
    this.setState({
      showDrawer: true,
      rolePagination,
    });
    this.getRoleList();
  };
  /**
   * 打开抽屉
   */
  /* 创建角色*/
  private createRoles = () => {
    this.setState({
      drawerType: IDrawerType.NewRole,
    });
    this.openDrawer();
  };
  /* 创建账号 */
  private createAccount = () => {
    this.setState({
      drawerType: IDrawerType.NewAccount,
    });
    this.openDrawer();
  };
  /* table切换Rdio 点击*/
  private targetRdioChange = (e: RadioChangeEvent) => {
    this.setState({
      taskType: e.target.value,
    });
  };
  /* 重置密码*/
  private resetPswCancel = () => {
    // 取消 修改密码
    this.setState({
      showResetPassward: false,
    });
  };
  /* 确认重置密码 */
  private confirmPswReset = async (psw: string) => {
    // 确认修改密码
    if (psw.length < 6) {
      message.error('密码不能少于 6 位');
    } else {
      const { clickItem } = this.state;

      const { result } = await MemberManageApi.updateMemberAccountPsd<string, IResetPsdReq>({
        mobile: (clickItem as IMemberAccount).user.mobile,
        pwd: MemberManage.encodeBase64Content(psw),
      });
      if (result) {
        //
        message.success('密码重置成功');
        this.setState({
          showResetPassward: false,
        });
        this.clearDrawer();
      } else {
        message.error('密码重置失败');
      }
    }
  };
  /**
   * member 列表分页处理
   */
  private handleMemberTablePageChange = (pagination: PaginationConfig) => {
    const { accountPagination } = this.state;
    accountPagination.current = pagination.current ? pagination.current : 1;
    this.setState({
      accountPagination,
    });
    this.getMemberList();
  };
  /**
   * member 角色列表分页处理
   */
  private handleRoleTablePageChange = (pagination: PaginationConfig) => {
    const { rolePagination } = this.state;
    rolePagination.current = pagination.current ? pagination.current : 1;
    this.setState({
      rolePagination,
    });
    this.getRoleList();
  };
  /**
   * 清空数据
   */
  private clearDrawer = () => {
    this.props.form.resetFields();
  };
}

export default Form.create()(MemberManage);
