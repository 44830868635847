import { Col, Collapse, Row } from 'antd';
import React from 'react';
import RouteHeader from '../../../components/routeHeader/RouteHeader';
import IndexLayout from '../../../layout/IndexLayout';

// import './CommonOperation.scss';

const { Panel } = Collapse;
const customPanelStyle = {
  background: 'white',
  border: 0,

  overflow: 'hidden',
};

interface IProps {
  id?: string;
}

interface IAchorColor {
  buyBox: string;
  buyDataColor: string;
  howGetResultColor: string;
  isSafeColor: string;
  howHandleDataColor: string;
  distributeAboutBoxColor: string;
  osTypeColor: string;
}
interface IState {
  activePanelKey: string;
  achorColor: IAchorColor;
}
class FAQPage extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activePanelKey: '1',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#00A1E6',
        howGetResultColor: '#000',
        isSafeColor: '#000',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#000',
      },
    };
  }
  public render() {
    return (
      <IndexLayout type="leftmenu" className="IntroductionMain">
        <Row className="FAQ-answer-container" id="FAQPage">
          <Col span={20}>
            <div className="FAQ-left-container">
              <Row>
                <Col>
                  <RouteHeader
                    title="FAQ"
                    titles={['首页', '使用指南', 'FAQ']}
                    routes={['/', '/guide/Introduction', '/guide/FQAPage']}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '5px' }} className="standard">
                <Collapse
                  accordion={true}
                  bordered={false}
                  onChange={this.panelValueChange}
                  activeKey={this.state.activePanelKey ? this.state.activePanelKey : ''}
                >
                  <Panel header="我如何预约数据呢？" key="1" showArrow={false} style={customPanelStyle}>
                    <div className="operation-guide-second-title"> 1）点击顶部导航栏”数据检索” </div>
                    <div style={{ marginTop: '21px' }}>
                      <img src={require('./../../../resource/dataBuy1.png')} style={{ width: '100%' }} />
                    </div>
                    <div className="operation-guide-second-title"> 2）选择所需要的数据包，点击”预约使用”</div>
                    <div style={{ marginTop: '21px' }}>
                      <img src={require('./../../../resource/dataBuy2.png')} className="peration-guide-image" />
                    </div>
                    <div style={{ marginTop: '16px' }}>
                      点击“查看详情”可以查看数据包的详细信息，也可以下载数据包内的“示例”；
                    </div>
                  </Panel>
                  <Panel header="为什么要购买安全盒？" key="2" showArrow={false} style={customPanelStyle}>
                    <div style={{ marginTop: '10px' }}>
                      当获得预约数据的数据使用权限后，”安全盒”提供了一套通过实现数据所有权和数据使用权分离，以确保数据流通过程安全可控的产品技术方案，购买的”安全盒”是为数据提供可以使用的媒介。
                    </div>
                  </Panel>
                  <Panel header="我如何获取我在安全盒内的运算结果？" key="3" showArrow={false} style={customPanelStyle}>
                    <div>
                      <div className="operation-guide-second-title"> 1）进入下载中心</div>
                      <div style={{ marginTop: '21px' }}>
                        <img src={require('./../../../resource/getDataImage1.png')} />
                      </div>
                      <div className="operation-guide-second-title"> 2) 点击下载申请</div>
                      <div style={{ marginTop: '21px' }}>
                        <img
                          src={require('./../../../resource/getDataImage2.jpg')}
                          className="peration-guide-image"
                          style={{ height: '300px', width: '570px' }}
                        />
                      </div>
                      <div className="operation-guide-second-title">
                        3) 选择需要申请的数据使用输入下载的说明点击提交等待审核
                      </div>

                      <div className="operation-guide-second-title"> 4) 当下载申请通过审核后点击可下载</div>
                      <div style={{ marginTop: '21px' }}>
                        <img src={require('./../../../resource/getDataImage3.png')} className="peration-guide-image" />
                      </div>
                      <div className="operation-guide-second-title">5）通过FTP 工具 登录后下载数据</div>
                    </div>
                  </Panel>
                  <Panel header=" 安全盒真的“安全”吗？" key="4" showArrow={false} style={customPanelStyle}>
                    <div style={{ marginTop: '10px', marginLeft: '10px' }}>
                      <div>1）完善的数据安全体系 </div>
                      <div>
                        安全盒实现了基于区块链的全流程审计、基于计算沙箱的实时操作监控和历史回放、基于结果使用规范的访问限制，可全方位保证数据的使用安全，确保数据不泄露。
                      </div>
                      <div> 2）开放的平台生态</div>
                      <div>
                        可支持数据源方、算法方、数据需求方、渠道方多种用户角色。作为中立的第三方，安全盒可支持多样且丰富的数据源，以创造更高价值。
                      </div>
                      <div> 3）完整的线上流程</div>
                      <div>
                        用户可在线完成数据申请、数据授权、数据分析、操作监控和审计、结果使用等操作。完整的线上流程，为用户提供了便利的操作，可极大提高工作效率。
                      </div>
                      <div> 4）灵活的算法实现模块</div>
                      <div>
                        用户不仅可基于大数据计算环境，提交算法、执行分析。还可以使用安全计算沙箱，在封闭、安全的计算环境中，实现更自由的建模分析。
                        我上传至安全盒的数据最后会如何处理？ 用户上传的数据在安全盒销毁，平台会销毁安全盒内所有数据。
                      </div>
                    </div>
                  </Panel>
                  <Panel
                    header="我上传至安全盒的数据最后会如何处理？"
                    key="5"
                    showArrow={false}
                    style={customPanelStyle}
                  >
                    <div style={{ marginTop: '10px', marginLeft: '15px' }}>
                      用户上传的数据在安全盒销毁，平台会销毁安全盒内所有数据。
                    </div>
                  </Panel>
                  <Panel header="不同类型的安全盒有什么区别呢？" key="6" showArrow={false} style={customPanelStyle}>
                    <div style={{ marginTop: '10px', marginLeft: '15px' }}>
                      <div>我们为用户提供专供多种类型安全盒：</div>
                      <div>初级安全盒：4核 64G 1T硬盘</div>
                      <div>中级安全盒：8核 128G 2T硬盘</div>
                      <div>高级安全盒：16核 128G 1T硬盘 单GPU</div>
                    </div>
                  </Panel>
                  <Panel
                    header="安全盒是什么操作系统？我在安全盒内可以做哪些事情呢？"
                    key="7"
                    showArrow={false}
                    style={customPanelStyle}
                  >
                    <div>
                      我们提供安全盒定制服务，可以联系我们定制各种环境,在环境定制完成后可以在安全盒内进行AI
                      技术，生信分析，神经网络模型训练等待操作
                    </div>
                  </Panel>
                </Collapse>
              </Row>
            </div>
          </Col>
          <Col span={4} style={{ height: '100%' }}>
            <div className="FAQ-right-container">
              <p className="lengthControl" style={{ width: '153px', color: '#00A1E6', paddingTop: '33px' }}>
                FAQ
              </p>
              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.buyDataColor }}
                onClick={this.howBuyDataClick}
              >
                我如何预约数据呢？
              </p>
              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.buyBox }}
                onClick={this.whyBuyBox}
              >
                为什么要购买安全盒？
              </p>
              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.howGetResultColor }}
                onClick={this.howGetResultClick}
              >
                我如何获取我在安全盒内的运算结果？
              </p>

              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.isSafeColor }}
                onClick={this.isSafe}
              >
                安全盒真的“安全”吗？
              </p>
              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.howHandleDataColor }}
                onClick={this.howHandleData}
              >
                我上传至安全盒的数据最后会如何处理？
              </p>
              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.distributeAboutBoxColor }}
                onClick={this.distributeAboutBox}
              >
                不同类型的安全盒有什么区别呢？
              </p>
              <p
                className="lengthControl"
                style={{ width: '153px', cursor: 'pointer', color: this.state.achorColor.osTypeColor }}
                onClick={this.osTypeClick}
              >
                安全盒是什么操作系统？我在安全盒内可以做哪些事情呢？
              </p>
            </div>
          </Col>
        </Row>
      </IndexLayout>
    );
  }
  public osTypeClick = () => {
    //
    this.setState({
      activePanelKey: '7',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#000',
        howGetResultColor: '#000',
        isSafeColor: '#000',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#00A1E6',
      },
    });
  };

  public distributeAboutBox = () => {
    this.setState({
      activePanelKey: '6',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#000',
        howGetResultColor: '#000',
        isSafeColor: '#000',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#00A1E6',
        osTypeColor: '#000',
      },
    });
  };

  public howHandleData = () => {
    this.setState({
      activePanelKey: '5',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#000',
        howGetResultColor: '#000',
        isSafeColor: '#000',
        howHandleDataColor: '#00A1E6',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#000',
      },
    });
  };
  public isSafe = () => {
    this.setState({
      activePanelKey: '4',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#000',
        howGetResultColor: '#000',
        isSafeColor: '#00A1E6',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#000',
      },
    });
  };
  public howGetResultClick = () => {
    this.setState({
      activePanelKey: '3',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#000',
        howGetResultColor: '#00A1E6',
        isSafeColor: '#000',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#000',
      },
    });
  };
  public whyBuyBox = () => {
    this.setState({
      activePanelKey: '2',
      achorColor: {
        buyBox: '#00A1E6',
        buyDataColor: '#000',
        howGetResultColor: '#000',
        isSafeColor: '#000',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#000',
      },
    });
  };
  public howBuyDataClick = () => {
    this.setState({
      activePanelKey: '1',
      achorColor: {
        buyBox: '#000',
        buyDataColor: '#00A1E6',
        howGetResultColor: '#000',
        isSafeColor: '#000',
        howHandleDataColor: '#000',
        distributeAboutBoxColor: '#000',
        osTypeColor: '#000',
      },
    });
  };

  public panelValueChange = (key: string | string[]) => {
    if (typeof key === 'string') {
      if (key === this.state.activePanelKey) {
        this.setState({
          activePanelKey: '-1',
        });
        return;
      }

      if (key === '1') {
        this.setState({
          activePanelKey: '1',
          achorColor: {
            buyBox: '#000',
            buyDataColor: '#00A1E6',
            howGetResultColor: '#000',
            isSafeColor: '#000',
            howHandleDataColor: '#000',
            distributeAboutBoxColor: '#000',
            osTypeColor: '#000',
          },
        });
      } else if (key === '2') {
        this.setState({
          activePanelKey: '2',
          achorColor: {
            buyBox: '#00A1E6',
            buyDataColor: '#000',
            howGetResultColor: '#000',
            isSafeColor: '#000',
            howHandleDataColor: '#000',
            distributeAboutBoxColor: '#000',
            osTypeColor: '#000',
          },
        });
      } else if (key === '3') {
        this.setState({
          activePanelKey: '3',
          achorColor: {
            buyBox: '#000',
            buyDataColor: '#000',
            howGetResultColor: '#00A1E6',
            isSafeColor: '#000',
            howHandleDataColor: '#000',
            distributeAboutBoxColor: '#000',
            osTypeColor: '#000',
          },
        });
      } else if (key === '4') {
        this.setState({
          activePanelKey: '4',
          achorColor: {
            buyBox: '#000',
            buyDataColor: '#000',
            howGetResultColor: '#000',
            isSafeColor: '#00A1E6',
            howHandleDataColor: '#000',
            distributeAboutBoxColor: '#000',
            osTypeColor: '#000',
          },
        });
      } else if (key === '5') {
        this.setState({
          activePanelKey: '5',
          achorColor: {
            buyBox: '#000',
            buyDataColor: '#000',
            howGetResultColor: '#000',
            isSafeColor: '#000',
            howHandleDataColor: '#00A1E6',
            distributeAboutBoxColor: '#000',
            osTypeColor: '#000',
          },
        });
      } else if (key === '6') {
        this.setState({
          activePanelKey: '6',
          achorColor: {
            buyBox: '#000',
            buyDataColor: '#000',
            howGetResultColor: '#000',
            isSafeColor: '#000',
            howHandleDataColor: '#000',
            distributeAboutBoxColor: '#00A1E6',
            osTypeColor: '#000',
          },
        });
      } else if (key === '7') {
        this.setState({
          activePanelKey: '7',
          achorColor: {
            buyBox: '#000',
            buyDataColor: '#000',
            howGetResultColor: '#000',
            isSafeColor: '#000',
            howHandleDataColor: '#000',
            distributeAboutBoxColor: '#000',
            osTypeColor: '#00A1E6',
          },
        });
      }
    } else {
      this.setState({
        activePanelKey: '-1',
        achorColor: {
          buyBox: '#000',
          buyDataColor: '#000',
          howGetResultColor: '#000',
          isSafeColor: '#000',
          howHandleDataColor: '#000',
          distributeAboutBoxColor: '#000',
          osTypeColor: '#000',
        },
      });
    }
  };
}
export default FAQPage;
