import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import Config from '../config/Config';
import { app } from '../index';
import Storage, { TOKEN } from '../util/Storage';
function changeSpinVisible(spinVisible: boolean) {
  app._store!.dispatch({
    type: 'globals/changeSpinVisible',
    payload: { spinVisible },
  });
}
const downFileApi = axios.create({
  baseURL: Config.REACT_APP_DOMAIN,
  responseType: 'arraybuffer',
  // baseURL: 'http://service-wukong.cloudmvd.com/api/web',
  /*  baseURL: 'http://192.168.1.114:9100/api/web', */
});
// 上电文拦截器
downFileApi.interceptors.request.use(
  config => {
    changeSpinVisible(true);
    if (Storage.getStore(TOKEN)) {
      config.headers.Authorization = Storage.getStore(TOKEN);
      /* config.headers['Content-Type'] = 'application/vnd.ms-excel;charset=UTF-8'; */
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 下电文拦截器
downFileApi.interceptors.response.use(
  // tslint:disable-next-line:no-any
  (response: any): any => {
    // const res = response.data;
    changeSpinVisible(false);
    down(response, 'excelTmp.xlsx');
  },
  (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          message.info('发出的请求有错误，服务器没有进行新建或修改数据的操作。');
          break;
        case 401: {
          message.info('用户没有权限（令牌、用户名、密码错误）。');
          Storage.delStore(TOKEN);
          window.location.href = '/';
          break;
        }

        case 403:
          message.info('用户得到授权，但是访问是被禁止的。');
          break;
        case 404:
          message.info('发出的请求针对的是不存在的记录，服务器没有进行操作。');
          break;
        case 406:
          message.info('请求的格式不可得。');
          break;
        case 410:
          message.info('请求的资源被永久删除，且不会再得到的。');
          break;
        case 422:
          message.info('当创建一个对象时，发生一个验证错误。');
          break;
        case 500:
          message.info('服务器发生错误，请检查服务器。');
          break;
        case 502:
          message.info('网关错误。');
          break;
        case 503:
          message.info('服务不可用，服务器暂时过载或维护。');
          break;
        case 504:
          message.info('网关超时。');
          break;
        default:
          message.info('未知错误');
      }
    }
    changeSpinVisible(false);
  }
);
// tslint:disable-next-line:no-any
const down = (data: any, fromName: string) => {
  const blob = new Blob([data.data], { type: 'application/vnd.ms-excel;charset=UTF-8' });
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fromName);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};
export default downFileApi;
