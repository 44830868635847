import { Button, message, Modal, Select, Table } from 'antd';
import Form from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { PaginationConfig, TableRowSelection } from 'antd/lib/table';
import React from 'react';
import DataManagerApi from '../../../api/DataManager';
import SciManageApi from '../../../api/SciManageApi';
import UserAccount from '../../../api/UserAccount';
import AuthCmp from '../../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import FileTree, { ITreeData } from '../../../components/FileTree/FilePathTree';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../config/IconConfig';
import { INormalList } from '../../DataManager/CodeType';
import {
  ICreateTempReq,
  IDownloadTempReq,
  IOriginalState,
  ITempItem,
  ITempItemReq,
  ITemporaryDataProp,
} from './CodeType';
import PkgFormCmp from './PackageFormComponent/PkgFormCmp';
import './TemporaryData.scss';
const { Option } = Select;
export class TemporaryDataComponent extends React.PureComponent<ITemporaryDataProp, IOriginalState> {
  public temporaryDataColums = [
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '上传时间',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: '文件说明',
      dataIndex: 'remark',
      key: 'remark',
      render: (text: string, record: ITempItem) => {
        return (
          <div className="sequences-uuid-table" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '转化状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: ITempItem) => {
        return (
          <div style={{ color: record.status === 1 ? '#a62024' : '#52c41a' }}>
            {record.status === 1 ? '未转化' : '已转化'}
          </div>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'studyDate',
      key: 'studyDate',

      render: (text: string, record: ITempItem) => {
        return (
          <AuthCmp permission={'USER_PROJECT_MANAGE'} projectId={this.props.projectId}>
            <div className="table-row-action" onClick={() => this.packageData(record)}>
              转为数据包
            </div>
          </AuthCmp>
        );
      },
    },
  ];

  constructor(props: ITemporaryDataProp) {
    super(props);
    this.state = {
      tempPagination: { total: 0, pageSize: 10, current: 1 },
      tempItems: [],
      isLoading: false,
      selectRows: [],
      updataModalVisable: false,
      pkgModalVisible: false,
      dowloadModalVisable: false,
      fileTree: [],
      updateFileTree: [],
      downloadAviable: true,
      tepAuth: false,
    };
  }
  public componentWillReceiveProps(nextProps: ITemporaryDataProp) {
    if (nextProps.updataModalVisable !== this.props.updataModalVisable) {
      this.setState({ updataModalVisable: nextProps.updataModalVisable });
    }
  }
  public componentDidMount() {
    this.getTemplateDatas();
    this.loadUsbFileTree();
    this.getAuthStatus('USER_PROJECT_MANAGE');
  }

  public render() {
    const { getFieldDecorator } = this.props.form;
    const rowSelection: TableRowSelection<ITempItem> = {
      // 已选序列列表
      onChange: this.tableSelectChange,
      selectedRowKeys: this.state.selectRows,
      onSelectAll: this.tempSelectAll,
    };
    const updateTempsfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          type="primary"
          className="theme-primary-btn"
          onClick={this.updateTempModalOK}
          style={{ marginRight: '31px' }}
        >
          完成
        </Button>
        <Button className="theme-normal-btn" onClick={this.updateTempModalCancel}>
          取消
        </Button>
      </div>
    );
    const loadFooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="theme-primary-btn" onClick={this.downModalOkClick}>
          完成
        </Button>
        <Button className="theme-normal-btn" onClick={this.closeDownloadModal} style={{ marginLeft: '31px' }}>
          取消
        </Button>
      </div>
    );
    const userItemsOptions = this.props.userItems.map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));

    return (
      <div className="temporary-container">
        {this.state.tepAuth ? (
          <TableHeader>
            <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
                <Form.Item label="用户名:">
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('temp_user_id')(
                    <Select
                      className="original-input"
                      placeholder="选择用户名"
                      style={{ width: '240px' }}
                      allowClear={true}
                    >
                      {userItemsOptions}
                    </Select>
                  )}
                </Form.Item>
                <Button
                  className="normal-search-button"
                  onClick={this.handleTableHeaderSearch}
                  style={{ marginLeft: '16px', fontWeight: 'bold' }}
                >
                  查询
                </Button>
              </div>

              <AuthComponent isShow={!!this.state.tempPagination.total}>
                <div style={{ display: 'flex', justifyItems: 'center', color: '#00a1e6', cursor: 'pointer' }}>
                  <IconFont
                    type={'medataicon-icon_download'}
                    style={{ fontSize: '16px', display: 'flex', alignItems: 'center', color: '#00a1e6' }}
                  />
                  <div onClick={this.dowloadOrignalDatas} style={{ marginLeft: '16px', fontWeight: 'bold' }}>
                    批量下载
                  </div>
                </div>
              </AuthComponent>
            </div>
          </TableHeader>
        ) : this.state.tempPagination.total === 0 ? null : (
          <TableHeader>
            <div
              // tslint:disable-next-line:jsx-no-multiline-js
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
                alignItems: 'center',
                flexDirection: 'row-reverse',
              }}
            >
              <div style={{ display: 'flex', justifyItems: 'center', color: '#00a1e6', cursor: 'pointer' }}>
                <IconFont
                  type={'medataicon-icon_download'}
                  style={{ fontSize: '16px', display: 'flex', alignItems: 'center', color: '#00a1e6' }}
                />
                <div onClick={this.dowloadOrignalDatas} style={{ marginLeft: '16px', fontWeight: 'bold' }}>
                  批量下载
                </div>
              </div>
            </div>
          </TableHeader>
        )}
        <AuthComponent isShow={!!this.state.tempPagination.total}>
          <Table
            columns={this.temporaryDataColums}
            dataSource={this.state.tempItems}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.tempPagination }}
            rowSelection={rowSelection}
            rowKey="id"
            onChange={this.handleOriginalTableChanage}
          />
        </AuthComponent>

        <NoDataImg
          isLoading={this.state.isLoading}
          total={this.state.tempPagination.total}
          msg="暂时还没有上传临时文件..."
        />
        <AuthComponent isShow={!this.state.tempPagination.total}>
          <AuthCmp permission="USER_PROJECT_INTERMEDIATE_MANAGE" projectId={this.props.projectId}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className="theme-normal-btn"
                // tslint:disable-next-line:jsx-no-multiline-js
                onClick={() => {
                  this.setState({ updataModalVisable: true });
                }}
              >
                上传临时文件
              </Button>
            </div>
          </AuthCmp>
        </AuthComponent>
        <Modal
          visible={this.state.updataModalVisable}
          className="updata-data-modal"
          width="560px"
          centered={true}
          title={'上传临时文件'}
          footer={updateTempsfooter}
          closable={true}
          onCancel={this.updateTempModalCancel}
        >
          {this.updataModalView()}
        </Modal>
        <PkgFormCmp
          visiable={this.state.pkgModalVisible}
          clickItem={this.state.clickedItem}
          submit={this.transformSuccess}
          form={this.props.form}
          // tslint:disable-next-line:jsx-no-multiline-js
          closeClick={() => {
            this.setState({ pkgModalVisible: false });
          }}
          projectId={this.props.projectId}
        />
        <Modal
          visible={this.state.dowloadModalVisable}
          className="dowload-data-modal"
          width="560px"
          centered={true}
          title={'下载临时数据'}
          footer={loadFooter}
          closable={true}
          onCancel={this.closeDownloadModal}
        >
          {this.downloadModalView()}
        </Modal>
      </div>
    );
  }

  /*
   *获取临时数据 网络请求
   */
  public getTemplateDatas = async (origSearchSeqId?: string) => {
    const { tempPagination } = this.state;
    const { getFieldValue } = this.props.form;
    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getTemplateDatas<INormalList<ITempItem[]>, ITempItemReq>({
        pageSize: 1000,
        pageNum: 1,
        projectId: this.props.projectId,
        userId: getFieldValue('temp_user_id'),
      });
      if (result) {
        tempPagination.total = result.total;

        const records = result.records;
        this.setState({
          tempItems: [...records],
          tempPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 列表分页处理
   */
  private handleOriginalTableChanage = (pagination: PaginationConfig) => {
    const { tempPagination } = this.state;
    tempPagination.current = (pagination && pagination.current) || 1;
    this.setState({ tempPagination }, () => {
      this.forceUpdate();
    });
    // this.getTemplateDatas();
  };
  /**
   * 原始数据 按照序列号查询
   */

  private handleTableHeaderSearch = () => {
    const origSearchSeqId = this.props.form.getFieldValue('originalPatientSequenceNum');

    this.getTemplateDatas(origSearchSeqId);
  };
  /**
   * 列表变化
   */
  private tableSelectChange = (selectedRowKeys: string[] | number[], selectedRows: ITempItem[]) => {
    // 修改当前的列表内容
    this.setState({
      selectRows: selectedRowKeys as number[],
    });
  };

  private tempSelectAll = (selected: boolean, selectedRows: ITempItem[], changeRows: ITempItem[]) => {
    const ids = this.state.tempItems.map(item => item.id);
    this.setState({
      selectRows: selected ? ids : [],
    });
  };

  /**
   * 批量下载
   */
  private dowloadOrignalDatas = () => {
    if (this.state.selectRows.length) {
      //
      this.setState({ dowloadModalVisable: true });
    } else {
      message.error('请选择需要下载的数据');
    }
  };

  /**
   * 获取临时文件
   *
   */
  private loadUsbTempFiles = async () => {
    const { result } = await DataManagerApi.getUsbContent<ITreeData[]>();

    if (result.length > 0) {
      this.setState({ updateFileTree: result });
    }
  };
  /**
   * 获取U盘文件夹
   *
   */
  private loadUsbFileTree = async () => {
    const { result } = await DataManagerApi.getdownloadPathList<ITreeData[]>();
    if (result.length > 0) {
      this.setState({ fileTree: result });
    } else {
      message.warning('没有路径可以存放');
    }
    this.loadUsbTempFiles();
  };

  /**
   * 批量下载临时数据
   */
  private downlaodTemparyDatas = async () => {
    const { result } = await SciManageApi.downloadTemparyDatas<string, IDownloadTempReq>({
      ids: [...this.state.selectRows],
      targetPath: this.state.targetPath!,
      projectName: this.props.projectName,
    });
    if (result) {
      //
    }
  };
  /**
   * 上传临时数据
   */
  private createTemparyDatas = async () => {
    const { getFieldValue } = this.props.form;
    const remark = getFieldValue('temp_remark');
    const { filePath } = this.state;
    const { result } = await SciManageApi.createTemparyDatas<string, ICreateTempReq>({
      filePath,
      projectId: this.props.projectId,
      remark,
    });

    if (result) {
      //
      this.getTemplateDatas();
    }
  };

  /**
   * 模态框确认
   */
  private updateTempModalOK = () => {
    this.createTemparyDatas();
    this.updateTempModalCancel();
  };
  /**
   * 模态框取消
   */
  private updateTempModalCancel = () => {
    this.props.updataModalClose();
    this.setState({ updataModalVisable: false });
  };

  /**
   * 下载临时数据页面
   */
  private downloadModalView = () => {
    const { targetPath, fileTree } = this.state;
    return (
      <div className="modal-content-view">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color: 'rgba(0,0,0,0.64)', fontWeight: 'bold', marginRight: '20px' }}>文件存放目录:</div>
          {<FileTree treeData={fileTree} onSelect={this.downloadFilePath} interValue={targetPath} />}
        </div>
        <div style={{ fontSize: '12px', color: 'rgba(0,0,0,0.5)', marginTop: '40px' }}>
          注意：数据下载过程中，请不要拔掉移动硬盘，以免中断下载。
        </div>
      </div>
    );
  };
  /**
   * 上传数据页面
   */
  private updataModalView = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="modal-content-view">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color: 'rgba(0,0,0,0.64)', fontWeight: 'bold', marginRight: '20px' }}>数据文件:</div>

          {
            // tslint:disable-next-line:jsx-no-multiline-js
            <FileTree
              treeData={this.state.updateFileTree}
              onSelect={this.dataSelectPath}
              interValue={this.state.filePath}
            />
          }
        </div>
        <div style={{ display: 'flex', marginTop: '32px' }}>
          <div style={{ color: 'rgba(0,0,0,0.64)', fontWeight: 'bold', marginRight: '20px' }}>文件说明:</div>
          <Form.Item colon={false}>
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('temp_remark', {
              rules: [{ required: true, message: '输入文件说明' }],
            })(<TextArea placeholder="点击输入" style={{ minHeight: '100px', width: '400px' }} />)}
          </Form.Item>
        </div>
      </div>
    );
  };
  /**
   * 准备上传文件的路径
   */
  private dataSelectPath = (filePath: string) => {
    this.setState({ filePath });
  };

  /**
   * 批量下载路径
   */
  private downloadFilePath = (targetPath: string) => {
    this.setState({ targetPath });
  };

  /**
   * 临时数据转换成数据包
   */
  private packageData = (item: ITempItem) => {
    this.setState({ clickedItem: item, pkgModalVisible: true });
  };
  /**
   * 关闭下载模态框
   */
  private closeDownloadModal = () => {
    this.setState({ dowloadModalVisable: false });
  };
  /**
   * 打开下载模态框
   */
  private downModalOkClick = () => {
    if (this.state.targetPath) {
      this.downlaodTemparyDatas();
      this.setState({
        dowloadModalVisable: false,
        downloadAviable: false,
      });
    } else {
      message.error('未选择下载路径');
    }
  };
  /**
   * 转换数据包成功
   */
  private transformSuccess = (id: number) => {
    this.setState({ pkgModalVisible: false });
    this.getTemplateDatas();
  };
  /**
   * 获取权限
   */
  private getAuthStatus = async (permission: string) => {
    const { result } = await UserAccount.getProjectAuth<string[], { projectId: number }>({
      projectId: (this.props.projectId && Number(this.props.projectId)) || 0,
    });
    const ret = result.includes(permission);
    this.setState({
      tepAuth: ret,
    });
  };
}

export default TemporaryDataComponent;
