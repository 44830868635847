import { Button, DatePicker, Form, Input, Select, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { PaginationConfig, TableRowSelection } from 'antd/lib/table';
import { Moment } from 'moment';
import React from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import DataManagerApi from '../../../../api/DataManager';
import ProxAiManageApi from '../../../../api/ProxAiManageApi';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../../config/IconConfig';
import IndexLayout from '../../../../layout/IndexLayout';
import { IDictions } from '../../../DataManager/CodeType';
import { IOptionUser } from '../../../DataManager/Component/HasSharedDataComponent/CodeType';
import { INormalList } from '../../../DownloadCenter/CodeType';
import {
  IAvailableSeqItem,
  IAvailableSeqReq,
  INewProjectState,
  initalNewProjectState,
  IProjectSaveReq,
  IProxAiTemplateItem,
} from '../../CodeType';
import Storage, { PARTDICTIONSKEY } from './../../../../util/Storage';
import './NewProject.scss';
const { RangePicker } = DatePicker;
const { Option } = Select;
interface INewProjectPageProps extends FormComponentProps, RouteComponentProps {}
class NewProjectPage extends React.Component<INewProjectPageProps, INewProjectState> {
  public projectColumns = [
    {
      title: '序列号',
      dataIndex: 'seriesInstanceUID',
      key: 'seriesInstanceUID',
      render: (text: string, record: IAvailableSeqItem) => {
        return (
          <div className="seq-column-title" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '上传时间',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: '检查时间',
      dataIndex: 'studyDate',
      key: 'studyDate',
    },
    {
      title: '文件类型',
      dataIndex: 'fileType',
      key: 'fileType',
    },
    {
      title: '检查机型',
      dataIndex: 'modality',
      key: 'modality',
      render: (text: string, record: IAvailableSeqItem) => {
        return <div className="assigned-name"> {this.state.partDics.get(text)} </div>;
      },
    },
    {
      title: '部位',
      dataIndex: 'bodyPart',
      key: 'bodyPart',
      render: (text: string, record: IAvailableSeqItem) => {
        return <div className="assigned-name"> {this.state.partDics.get(text)} </div>;
      },
    },
  ];
  public state = initalNewProjectState as INewProjectState;
  public componentDidMount() {
    const { partDics } = this.state;
    this.getAvailableSeqs({ page: 1, pageSize: 1000 } as IAvailableSeqReq);
    this.getProxAITemplates();
    this.getMembersOptions();
    const parts: IDictions[] = JSON.parse(Storage.getStore(PARTDICTIONSKEY));
    parts.forEach(item => {
      partDics.set(item.value, item.name);
    });
    this.setState({ partDics });
    window.onbeforeunload = () => {
      //
      this.setState({ isBack: true });
      return false;
    };
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    const { toolTemplates, userItems } = this.state;
    const OptionsTools = toolTemplates
      ? toolTemplates.map(item => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })
      : null;
    // 已选序列按钮点击
    const rowSelection: TableRowSelection<IAvailableSeqItem> = {
      // 待选序列类表
      selectedRowKeys: this.state.selectedSeqs,
      onChange: this.selectedSeqsChange,
      onSelectAll: this.onSelectedAll,
    };
    const userItemsOptions = userItems
      ? userItems.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      : null;
    return (
      <div className="new-project-container">
        <Prompt when={!this.state.isBack} message={location => '您的信息尚未填写完成，确定离开吗？'} />
        <IndexLayout>
          <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginTop: '27px' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.gobackWithOption}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回</span>
              </div>
              <div className="new-project-header-title" style={{ flex: 1 }}>
                鉴影管理工具-创建项目
              </div>
              <div style={{ flex: 1 }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="project-message-title">
                <div className="dot" /> 项目基本信息
              </div>
              <Button className="complete-btn" onClick={this.handleAddProjectBtnClick}>
                完成
              </Button>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="project-input-title ">项目名称:</div>
              <Form.Item colon={false} style={{ width: '400px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-name', {
                  rules: [{ required: true, message: '点击输入' }],
                })(<Input className="project-input" />)}
              </Form.Item>
            </div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <div className="project-input-title " style={{ alignItems: 'flex-start' }}>
                项目描述:
              </div>
              <Form.Item colon={false} style={{ width: '400px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-discription', {
                  rules: [{ required: true, message: '请输入项目描述' }],
                })(<TextArea placeholder="点击输入" style={{ minHeight: '100px' }} />)}
              </Form.Item>
            </div>
            <div style={{ display: 'flex', marginTop: '11px' }}>
              <div className="project-input-title" style={{ display: 'inline-block', paddingTop: '15px' }}>
                工具:
              </div>
              <Form.Item colon={false} style={{ width: '300px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-tools', {
                  rules: [{ required: true, message: '点击选择工具' }],
                })(
                  <Select className="project-input" style={{ width: '300px' }}>
                    {OptionsTools}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div style={{ marginTop: '35px' }}>
              <div className="project-message-title">
                <div className="dot" /> 选择序列
              </div>
            </div>
          </div>
          <TableHeader>
            <Form.Item label="上传时间:">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('project-data-picker-input')(
                <RangePicker
                  className="project-data-picker-input"
                  placeholder={['开始时间', '结束时间']}
                  format={'YYYY-MM-DD HH:mm'}
                />
              )}
            </Form.Item>
            <AuthComponent permission="USER_ALL_DATA_READ">
              <Form.Item label="用户名:" style={{ marginLeft: '40px' }}>
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('project-user-name')(
                  <Select className="" placeholder="请选择" allowClear={true}>
                    {userItemsOptions}
                  </Select>
                )}
              </Form.Item>
            </AuthComponent>
            <Button className="normal-search-button" onClick={this.searchClick}>
              查询
            </Button>
          </TableHeader>
          <AuthComponent isShow={!!this.state.availableSeqs.length}>
            <Table
              columns={this.projectColumns}
              dataSource={this.state.availableSeqs}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...this.state.seqPagination }}
              rowKey="seriesInstanceUID"
              rowSelection={rowSelection}
              onChange={this.handleHasTableChanage}
            />
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.seqPagination.total} />
        </IndexLayout>
      </div>
    );
  }
  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { seqPagination } = this.state;
    seqPagination.current = pagination.current || 1;
    this.setState({ seqPagination });
  };

  /**
   * 点击完成按钮
   */
  private handleAddProjectBtnClick = () => {
    const { validateFields } = this.props.form;
    validateFields(['project-name', 'project-discription', 'project-tools'], (err, values) => {
      if (!err) {
        const projectReq: IProjectSaveReq = {
          name: values['project-name'],
          remark: values['project-discription'],
          templateId: values['project-tools'],
          seriesInstanceUidList: [...this.state.selectedSeqs],
        } as IProjectSaveReq;
        this.addProject(projectReq);
      }
    });
  };
  /**
   * 开始添加项目
   */
  private addProject = async (projectReq: IProjectSaveReq) => {
    const { result } = await ProxAiManageApi.addProject<string, IProjectSaveReq>(projectReq);
    if (result) {
      this.setState({ isBack: true });
      this.props.history.goBack();
    }
  };
  /**
   * 获取鉴影模板列表
   */
  private getProxAITemplates = async () => {
    const { result } = await ProxAiManageApi.getProxAiTemplates<IProxAiTemplateItem[]>();
    if (result) {
      // 完成
      this.setState({ toolTemplates: result });
    }
  };
  /**
   * 获取可用序列
   */
  private getAvailableSeqs = async (seqReq: IAvailableSeqReq) => {
    const { seqPagination } = this.state;
    try {
      this.setState({ isLoading: false });
      const { result } = await ProxAiManageApi.getAvailableSeqs<INormalList<IAvailableSeqItem[]>, IAvailableSeqReq>(
        seqReq
      );
      if (result) {
        seqPagination.total = result.total;
        this.setState({
          seqPagination,
          availableSeqs: [...result.records],
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
  /**
   * 列表选择框
   */
  private selectedSeqsChange = (selectedRowKeys: string[] | number[], selectedRows: IAvailableSeqItem[]) => {
    this.setState({
      selectedSeqs: selectedRows.map(e => e.seriesInstanceUID),
    });
  };

  /**
   * 选中所有
   */
  private onSelectedAll = (selected: boolean, selectedRows: IAvailableSeqItem[], changeRows: IAvailableSeqItem[]) => {
    const { availableSeqs } = this.state;
    this.setState({
      selectedSeqs: selected ? [...availableSeqs.map(e => e.seriesInstanceUID)] : [],
    });
  };
  /**
   * 查询Action
   */
  private searchClick = () => {
    const { getFieldValue } = this.props.form;
    const time: Moment[] = getFieldValue('project-data-picker-input');
    const keyword = getFieldValue('project-user-name');
    let importDateBegin: string | undefined;
    let importDateEnd: string | undefined;
    if (time && time.length > 0) {
      importDateBegin = time[0].format('YYYY-MM-DD HH:mm:ss');
      importDateEnd = time[1].format('YYYY-MM-DD HH:mm:ss');
    }
    this.getAvailableSeqs({ page: 1, pageSize: 1000, keyword, importDateBegin, importDateEnd } as IAvailableSeqReq);
  };
  /**
   * 获取用户列表
   */
  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({ userItems: [...result] });
    }
  };
  /**
   * 点击返回 按钮
   */
  private gobackWithOption = () => {
    this.props.history.goBack();
  };
}
const NewProject = Form.create()(NewProjectPage);

export default NewProject;
