import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';

import { IMemberList, IMemberResult, IPagination, IParam } from '../CodeType';

import { PaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import ProjectMembersApi from '../../../api/ProjectMembersApi';
import { IRoleItem } from './CodeType';
import './MemberSelect.scss';

interface IPropsForSelect extends FormComponentProps {
  /* memberData: IAllMember[]; */
  onCancel: () => void;
  // tslint:disable-next-line:no-any
  onOk: (values: any) => void;
  visible: boolean;
  param: { pId?: string; name?: string };
  roleList: IRoleItem[];
  projectId: number;
}
interface IStateForSelect {
  loading: boolean;
  selectedRowKeys: number[] | string[];
  pagination: IPagination;
  records: IMemberList[];
  roleList: IRoleItem[];
}
/* 修改权限弹出框组件 */

class MemberSelect extends React.PureComponent<IPropsForSelect, IStateForSelect> {
  public state = {
    loading: false,
    selectedRowKeys: [],
    records: [],
    pagination: { total: 0, pageSize: 10, current: 1 },
    roleList: [],
  } as IStateForSelect;
  public columns = [
    {
      title: '创建时间',
      dataIndex: 'created',
      key: 'created',
      render: (text: string, record: IMemberList) => {
        return <span>{moment(record.created).format('YYYY.MM.DD')} </span>;
      },
    },
    {
      title: '用户名',
      dataIndex: 'loginname',
      key: 'loginname',
    },
    {
      title: '账号',
      dataIndex: 'mobile',
      key: 'mobile',
    },
  ];
  public componentDidMount() {
    this.getMembers({ pageSize: 10, pageNum: 1, researchProjectId: this.props.projectId });
  }
  public render = () => {
    const { getFieldDecorator } = this.props.form;
    /* checkbox列表获取 */
    // tslint:disable-next-line:no-any
    let options: any = [];
    options = this.props.roleList.map(item => {
      return {
        label: <span className="checkboxname">{item.roleName}</span>,
        value: item.id,
        key: item.id,
      };
    });
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <Modal
        onOk={this.handleSubmit}
        onCancel={this.onCancel}
        visible={this.props.visible}
        okText="完成"
        cancelText="取消"
        title={<h4 className="memsel-title">引入成员</h4>}
        className="memSel"
        destroyOnClose={true}
        maskClosable={false}
        width={700}
      >
        <Form {...formItemLayout}>
          <Form.Item className="roleList" label={<span>请先选择您需要设置的角色</span>}>
            {getFieldDecorator('roleList', {
              /* tslint:disable-next-line:jsx-no-multiline-js */
              rules: [{ required: true, message: '请选择角色' }],
            })(<Checkbox.Group className="checkboxGroup" options={options} />)}
          </Form.Item>
          <Form.Item className="hide memList">
            {getFieldDecorator('memList', {
              /* tslint:disable-next-line:jsx-no-multiline-js */
              rules: [{ required: true, message: '请选择用户' }],
            })(<Input />)}
          </Form.Item>
        </Form>
        <div>
          <div>{this.renderFormHeader()}</div>
          <Table
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={this.state.records}
            rowKey={item => item.id.toString()}
            pagination={{ ...this.state.pagination }}
            onChange={this.handleTableChange}
          />
        </div>
      </Modal>
    );
  };
  /**
   * 分页处理
   */
  public handleTableChange = (paginations: PaginationConfig) => {
    const { pagination } = this.state;
    pagination.current = paginations.current ? paginations.current : 1;
    this.setState(
      {
        pagination,
      },
      () => {
        this.getMembers({
          pageNum: this.state.pagination.current,
          pageSize: 10,
          researchProjectId: this.props.projectId,
        });
      }
    );
  };
  /* 确定提交事件 */
  private handleSubmit = (e: React.SyntheticEvent) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields(['memList', 'roleList'], (err, values) => {
      if (!err) {
        this.setState({ selectedRowKeys: [] });
        this.props.onOk(values); // 调用父组件给的onOk方法并传入Form的参数。
      } else {
        if (!values.memList) {
          message.error('请选择用户');
        }
      }
    });
  };
  /* Cancel关闭modal框事件 */
  private onCancel = () => {
    this.setState({ selectedRowKeys: [] });
    this.props.form.resetFields(); // 重置Form表单的内容
    this.props.onCancel(); // 调用父组件给的方法
  };

  private onSelectChange = (selectedRowKeys: number[] | string[]) => {
    const { form } = this.props;
    form.setFieldsValue({ memList: selectedRowKeys.join() });
    this.setState({ selectedRowKeys });
  };
  /*   public arrInit = () => {
    const optionsDefault: string[] = [];
    if (this.props.menberData) {
      this.props.menberData.forEach(item => {
        if (item.id) {
          optionsDefault.push(item.loginname);
        }
      });
    }
    return optionsDefault;
  }; */
  private renderFormHeader = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form layout="inline" className="table-header">
        <Row style={{ width: '100%', display: 'flex' }}>
          <Col style={{ display: 'flex', alignItems: 'center', width: '240px' }}>
            <Form.Item label={<span style={{ marginLeft: 4 }}>用户名</span>}>
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('username')(<Input className="ipt" style={{ width: '156px' }} />)}
            </Form.Item>
          </Col>
          <Col md={8} sm={24} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              // htmlType="submit"
              className="normal-search-button"
              onClick={this.handleSearch}
              style={{ width: '96px' }}
            >
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };
  /* 查询表单提交 */
  private handleSearch = (e: React.SyntheticEvent) => {
    this.props.form.validateFields(['username'], (err, values) => {
      this.getMembers({ ...values, ...{ pageNum: 1, pageSize: 10 }, researchProjectId: this.props.projectId });
    });
  };

  /**
   *  异步请求
   */
  private getMembers = async (iparam: IParam) => {
    try {
      const { result } = await ProjectMembersApi.getMemberList<IMemberResult, IParam>(iparam);
      this.setState({
        records: result.records,
        pagination: { current: result.current, total: result.total, pageSize: result.size },
      });
    } catch (err) {
      this.setState({
        loading: true,
      });
    }
  };
}
export const MemberSelectList = Form.create()(MemberSelect);
