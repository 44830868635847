import { Button, Input, Select } from 'antd';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import { dataCategory } from '../../DataMSetting';
import { IOptionUser } from '../HasSharedDataComponent/CodeType';
import './DataShareHeader.scss';
const { Option } = Select;
export enum IHeaderType {
  Original,
  Waitting,
  HasShare,
}

interface IFormItemProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  type: IHeaderType;
  diseaseTypeNameOptions?: string[];
  userItems?: IOptionUser[];
  searchClick?: () => void;
  seqBtnClick?: () => void;
  seqShow?: boolean;
  seqDisable?: boolean;
  totalSequencesNumber?: number;
  isBatchShareEnable?: boolean;
  batchShareAction?: () => void;
}
interface IFormItemState {
  id?: string;
}

class DataShareHeader extends React.PureComponent<IFormItemProps, IFormItemState> {
  public getItemsValue = () => {
    // 3、自定义方法，用来传递数据（需要在父组件中调用获取数据）
    const valus = this.props.form.getFieldsValue(); // 4、getFieldsValue：获取一组输入控件的值，如不传入参数，则获取全部组件的值
    return valus;
  };
  public render() {
    const { type } = this.props;
    if (type === 0) {
      //
      return this.originalFormHeader();
    } else if (type === 1) {
      //
      return this.isShareFormHeader();
    } else {
      return this.hasShareFormHeader();
    }
  }

  public hasShareFormHeader() {
    const { getFieldDecorator } = this.props.form;
    const { diseaseTypeNameOptions, userItems } = this.props;
    const dataCategorySelect = dataCategory.map(item => (
      <Option key={item.key} value={item.key}>
        {item.label}
      </Option>
    ));
    // dataCategory
    const diseaseOptions = diseaseTypeNameOptions
      ? diseaseTypeNameOptions.map((item, index) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))
      : null;

    const userItemsOptions = userItems
      ? userItems.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      : null;
    return (
      <TableHeader>
        <div className="searh_box">
          <Form.Item label="数据类型:">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('hasSharedDataType')(
              <Select className="queryform-select" placeholder="请选择" allowClear={true}>
                {dataCategorySelect}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="病种:">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('hasSharedDiseaseType')(
              <Select className="queryform-select" placeholder="请选择" allowClear={true}>
                {diseaseOptions}
              </Select>
            )}
          </Form.Item>
          <AuthComponent permission="USER_ALL_DATA_READ">
            <Form.Item label="用户名:">
              {/* tslint:disable-next-line:jsx-no-multiline-js*/}
              {getFieldDecorator('userName')(
                <Select className="queryform-select" placeholder="请选择" allowClear={true}>
                  {userItemsOptions}
                </Select>
              )}
            </Form.Item>
          </AuthComponent>
          <Button className="normal-search-button" onClick={this.props.searchClick}>
            查询
          </Button>
        </div>
      </TableHeader>
    );
  }
  public originalFormHeader() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <TableHeader>
        <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
          <Form.Item label="序列号:">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('originalPatientSequenceNum')(
              <Input className="original-input" placeholder="请输入" allowClear={true} type="text" />
            )}
          </Form.Item>
          <Button
            className="normal-search-button"
            onClick={this.props.searchClick}
            style={{ marginLeft: '16px', fontWeight: 'bold' }}
          >
            查询
          </Button>
        </div>
      </TableHeader>
    );
  }
  public isShareFormHeader() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <TableHeader>
        <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
          <Form.Item label="标注任务名称:">
            {/* tslint:disable-next-line:jsx-no-multiline-js*/}
            {getFieldDecorator('waitingTaskName')(
              <Input className="original-input" placeholder="请输入" allowClear={true} type={'text'} />
            )}
          </Form.Item>
          <Button
            className="normal-search-button"
            onClick={this.props.searchClick}
            style={{ marginLeft: '48px', marginRight: '64px' }}
          >
            查询
          </Button>
          <Button
            className="normal-search-button"
            onClick={this.props.seqBtnClick}
            style={{ marginLeft: '64px', paddingLeft: '11px', paddingRight: '11px' }}
            hidden={!this.props.seqShow}
            disabled={this.props.seqDisable}
          >
            已选序列|{this.props.totalSequencesNumber}
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }} hidden={this.props.isBatchShareEnable}>
          <AuthComponent permission="USER_PACKAGE_WRITE">
            <Button className="normal-search-button" onClick={this.props.batchShareAction}>
              批量共享
            </Button>
          </AuthComponent>
        </div>
      </TableHeader>
    );
  }
}

export default DataShareHeader; // 创建form实例
