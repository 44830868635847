import { Avatar, Badge, Button, Card, Col, Dropdown, Form, Layout, List, Menu, Modal, Row, Tabs } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import React, { PureComponent } from 'react';
import AuthComponent from '../components/AuthComponent/AuthComponent';
import InputPassword from '../components/InputPassword/InputPassword';
import LeftMenu from '../components/menu/LeftMenu';
import SideMenu from '../components/menu/SideMenu';
import RightMenu from '../components/RightMenu/RightMenu';
import { IconFont } from '../config/IconConfig';
import Storage, { LOGINNAME, TOKEN, USERID, USERTYPE } from '../util/Storage';
import GlobalManage from './../api/GlobalManage';
import HomeManage from './../api/HomeManage';
import UserAccount from './../api/UserAccount';
import SockJsClient from './../components/SockJsClient/SockJsClient';
import { IIndexData, INoticeItem, INoticeList, IProps, IState, IUpdataPsdReq } from './TypeCode';
import { stateInit } from './TypeCode';
const { TabPane } = Tabs;
const { Header, Content, Footer } = Layout;
const userId = Storage.getStore(USERID);

// tslint:disable-next-line:no-any
@connect(({ globals }: any) => ({
  globals,
}))
class IndexLayout extends PureComponent<IProps, IState> {
  /**
   *
   * 密码转base64 编码
   */
  public static encodeBase64Content(content: string) {
    return new Buffer(content).toString('base64');
  }
  public state = stateInit;
  public async componentDidMount() {
    this.setState({ isJD: Storage.getStore(USERTYPE) === '5' });
    this.unreadNotice();
    // 获取首页右边的数据
    const { result } = await HomeManage.getIndexData<IIndexData>();
    this.setState({
      homeData: result,
    });
  }

  public levelConfirm = (key: string | undefined) => (key ? key.split('/').length - 1 : 0);
  public openChange = (openKeys: string[]) => {
    const { dispatch } = this.props;
    const level1: string[] = [];
    const level2: string[] = [];
    openKeys.forEach(item => {
      if (this.levelConfirm(item) === 2) {
        level2.push(item);
      } else if (this.levelConfirm(item) === 1) {
        level1.push(item);
      }
    });
    openKeys = level2 ? [level1[level1.length - 1], level2[level2.length - 1]] : [level1[level1.length - 1]];
    dispatch({ type: 'globals/changeSubMenuConfig', payload: openKeys });
  };

  public leftType = (type?: 'leftmenu' | 'Index') => {
    const { globals } = this.props;
    return (
      <div className={`indexMenuContent`} style={{ display: 'flex', backgroundColor: 'white' }}>
        {/* tslint:disable-next-line:jsx-no-multiline-js */}
        {type === 'leftmenu' ? (
          <LeftMenu openKeys={globals.openSubId} onOpenChange={this.openChange} mode="inline" theme="light" />
        ) : (
          ''
        )}
        <Content style={type === 'leftmenu' ? { maxWidth: 936 } : { width: '100%' }} id="mainContent">
          {this.props.children}
        </Content>
      </div>
    );
  };

  public render() {
    const { type, form } = this.props;
    /* 模态框确定取消控件 */
    const footerRender = () => (
      <div className="psd-modal-footer">
        <Button type="primary" className="action-btn" onClick={this.updataPsd}>
          确定
        </Button>
        <Button className="action-btn" onClick={this.updataModalClose} style={{ marginLeft: '34px' }}>
          取消
        </Button>
      </div>
    );
    return (
      <Layout id="index-layout">
        <SockJsClient subscribeNotification={this.getUnReadMsg} />
        <div className={type === 'Index' ? 'index-layout' : 'main-layout'}>
          <div id="fullbg" onClick={this.dropdownClose} style={{ display: 'none' }} />
          <div className="index-bg">
            <Header className="header-index">
              <Row className="indexMenuContent standard">
                <div className="indexMenuContentNext">
                  <Col span={6} className="logo1-col" onClick={this.toMain}>
                    <IconFont type="medataicon-img_logo" className="logo1" />
                  </Col>
                  <Col span={16}>
                    <SideMenu mode="horizontal" theme={type === 'Index' ? 'dark' : 'light'} />
                  </Col>
                  <AuthComponent isShow={true}>{this.headNavRightRender()}</AuthComponent>
                </div>
              </Row>
              <div className="divideLine" />
            </Header>
            {type === 'Index' ? this.homeInfoRender() : ''}
          </div>
          <AuthComponent isShow={!this.state.isJD}>
            <div className="fixed-menu">
              <RightMenu title="数据使用" icon="medataicon-dd_active" onClick={this.toOrder} />
              <RightMenu title="使用帮助" icon="medataicon-shuomingx" onClick={this.toHelp} />
              <RightMenu
                icon="medataicon-xiala-copy-copy-copy"
                className="fixed-item"
                onClick={this.toTop}
                iconTansform={true}
              />
            </div>
          </AuthComponent>
          {/* tslint:disable-next-line:jsx-no-multiline-js */}
          {type !== 'Index' ? (
            this.leftType(type)
          ) : (
            <Content className="indexMenuContent">{this.props.children}</Content>
          )}
          <Footer className="footer center" style={{ fontSize: '12px' }}>
            <Row className="indexMenuContent">
              <Col span={24}>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ width: '384px', marginTop: '18px' }}>
                    <span style={{ marginRight: '45px', width: '113px' }}>沪ICP备18041253号</span>
                    <span>|</span>
                    <span style={{ marginLeft: '30px' }}>沪公网安备 31010102005090号</span>
                  </div>
                </Row>

                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  版权所有 Copyright 上海基诺联生物科技有限公司 All Rights Reserved
                </Row>
              </Col>
            </Row>
          </Footer>
          <Modal
            visible={this.state.updatePsdVisible}
            width="580px"
            className="reset-passward-container"
            title="修改密码"
            closable={false}
            footer={footerRender()}
          >
            <InputPassword title={'原密码'} errorMessage={'输原密码'} form={form} fileID={'oldIndexPsd'} />
            <InputPassword title={'新密码'} errorMessage={'输入密码'} form={form} fileID={'newIndexPwd'} />
            <InputPassword title={'确认密码'} errorMessage={'输入确认密码'} form={form} fileID={'comfirmIndexNewpwd'} />
          </Modal>
        </div>
      </Layout>
    );
  }
  /**
   * 去首页
   */
  private toMain = () => {
    return this.props.dispatch(routerRedux.push('/'));
  };
  /**
   * 首页上半部分内容渲染内容渲染
   */
  private homeInfoRender = () => (
    <Row style={{ color: '#FFF' }} className="indexMenuContent standard-plus">
      <Col span={9} style={{ marginTop: '37px' }}>
        <Row className="leftInItem" style={{ marginTop: 89 }}>
          <Col>让数据连接健康</Col>
        </Row>
        <Row className="img-row">
          <img src={require('./../resource/focus.png')} alt="" />
        </Row>
        <ul className="index-left-ul leftInItem">
          <li className="index-left-li">聚焦基因、影像、临床表型数据</li>
          <li className="index-left-li">依托人工智能、区块链和分布式计算等技术</li>
        </ul>
        <AuthComponent isShow={!this.state.isJD}>
          <div className="leftInItem">
            <Button onClick={this.toDatasearch}>
              数据检索
              <IconFont type="medataicon-gouwu" />
            </Button>
          </div>
        </AuthComponent>
      </Col>

      <AuthComponent isShow={!this.state.isJD}>
        <Col span={5} style={{ marginTop: '0.5em', float: 'right' }}>
          <ul className="index-right-ul">
            <li>
              <Card className="card-right">
                <h3>病例总数</h3>
                <p>{this.state.homeData.cases}</p>
              </Card>
            </li>
            <li>
              <Card className="card-right">
                <h3>影像数据</h3>
                <p>{this.state.homeData.image}</p>
              </Card>
            </li>
            <li>
              <Card className="card-right">
                <h3>基因数据</h3>
                <p>{this.state.homeData.genes}</p>
              </Card>
            </li>
            <li>
              <Card className="card-right">
                <h3>疾病种类</h3>
                <p>{this.state.homeData.diseaseType}</p>
              </Card>
            </li>
          </ul>
        </Col>
      </AuthComponent>
    </Row>
  );
  /**
   *
   * render 头部右侧导航栏
   */
  private headNavRightRender = () => (
    <Col span={7} className="logo1-col broCenter head-right">
      <div className="more-link-list more-link-list-index">
        <div className="hide-box" />
        <AuthComponent isShow={!this.state.isJD}>
          <AuthComponent permission="USER_ACCOUNT_MANAGE">
            <a href="#/userGrades" className="more-link">
              积分
            </a>
            <a className="more-link-line" />
          </AuthComponent>
        </AuthComponent>
        <Badge dot={this.state.unread} style={{ left: '24px', top: '-4px' }}>
          <a className="more-link" onClick={this.getNotices}>
            通知
          </a>
        </Badge>
        <a className="more-link-line" />
      </div>
      <Dropdown
        overlay={this.state.isJD ? this.userDrapRenderJD() : this.userDrapRender()}
        getPopupContainer={this.dropdownBind}
        placement="bottomRight"
      >
        <div style={{ display: 'flex', alignItems: 'center', width: 110, cursor: 'pointer' }}>
          <span className="user username">{Storage.getStore(LOGINNAME)}</span>
          <IconFont type="medataicon-xiala-copy-copy-copy" className="down" />
        </div>
      </Dropdown>
      <Dropdown
        overlay={this.noticesRender()}
        trigger={['click']}
        className="messageList"
        visible={this.state.visible}
        getPopupContainer={this.dropdownBind}
        placement="bottomRight"
      >
        <div className="pholder" />
      </Dropdown>
    </Col>
  );
  /**
   * 获取通知消息
   */
  private getNotices = async (e: { stopPropagation: () => void }) => {
    const fullbg = document.getElementById('fullbg') || document.body;
    e.stopPropagation();
    const { result } = await GlobalManage.getNotices<INoticeList, { userId: string }>({ userId: userId || '' });
    this.setState({ notices: result.records, visible: !this.state.visible });
    fullbg.style.display = '';
  };

  /**
   * 收到通知消息，改变通知栏
   */
  private getUnReadMsg = () => {
    this.setState({ unread: true });
  };

  /**
   * 关闭通知消息框
   */
  private dropdownClose = (e: React.MouseEvent) => {
    const fullbg = document.getElementById('fullbg') || document.body;
    this.setState({ visible: false });
    fullbg.style.display = 'none';
  };

  /**
   * 退出登录
   */
  private loginout = () => {
    Storage.delStore(TOKEN);
    this.props.dispatch(routerRedux.replace('/login'));
  };

  /**
   * 跳转对应的页面
   */
  private toTransactionLogs = () => {
    return this.props.dispatch(routerRedux.replace('/transactionLogs'));
  };
  /**
   * 跳转数据检索
   */
  private toDatasearch = () => {
    return this.props.dispatch(routerRedux.push('/datasearch'));
  };

  /**
   * 跳转使用指南
   */
  private toIntroduction = () => {
    return this.props.dispatch(routerRedux.replace('/guide/Introduction'));
  };

  /**
   * 通知消息全部已读
   */
  private updateNoticeAllRead = async () => {
    await GlobalManage.updateNoticeAllRead();
    this.setState({ unread: false, visible: false });
  };
  /**
   * 判断是否有未读消息
   */
  private unreadNotice = async () => {
    const { result } = await GlobalManage.getNotices<INoticeList, { userId: string }>({ userId: userId || '' });
    if (result.records) {
      this.setState({ unread: result.records.some((item: { unread: boolean }) => item.unread) });
    }
  };

  /**
   * 右侧回到顶部
   */
  private toTop = () => {
    const element = document.getElementById('root');
    if (element) {
      element.scrollIntoView();
    }
  };

  /**
   * 右侧去数据使用
   */
  private toOrder = () => {
    return this.props.dispatch(routerRedux.replace('/VMOrders'));
  };

  /**
   * 右侧去使用帮助
   */
  private toHelp = () => {
    return this.props.dispatch(routerRedux.replace('/guide/Introduction'));
  };

  /**
   * 修改密码
   */

  private showUpdataPsdModal = () => {
    this.setState({ updatePsdVisible: true });
  };

  private updataModalClose = () => {
    this.setState({ updatePsdVisible: false });
  };
  /**
   * 更新密码
   */
  private updataPsd = () => {
    const { getFieldValue, validateFields } = this.props.form;
    validateFields(['oldIndexPsd', 'newIndexPwd', 'comfirmIndexNewpwd'], async err => {
      if (!err) {
        const req: IUpdataPsdReq = {
          oldPassword: IndexLayout.encodeBase64Content(getFieldValue('oldIndexPsd')),
          newPassword: IndexLayout.encodeBase64Content(getFieldValue('newIndexPwd')),
          surePassword: IndexLayout.encodeBase64Content(getFieldValue('comfirmIndexNewpwd')),
        };

        const { result } = await UserAccount.updataUserPsd<string, IUpdataPsdReq>(req);
        if (result) {
          //
          this.setState({ updatePsdVisible: false });
          this.loginout();
        }
      }
    });
  };
  /**
   * 消息通知列表渲染
   */
  private noticesRender = () => (
    <div className="messageListDown">
      <div className="arrow-up" style={{ top: '-7px', left: '80%' }} />
      <Tabs defaultActiveKey="1" tabPosition="top" size="large">
        <TabPane tab="通知" key="1" style={{ width: 336 }}>
          <List
            itemLayout="horizontal"
            // tslint:disable-next-line:jsx-no-multiline-js
            footer={
              <div className="toRead center" onClick={this.updateNoticeAllRead}>
                全标已读
              </div>
            }
            bordered={true}
            dataSource={this.state.notices}
            renderItem={this.noticeTtemRender}
          />
        </TabPane>
      </Tabs>
    </div>
  );

  /**
   * 用户名下拉框的render  <AuthComponent isShow={!isJD}>
   */
  private userDrapRender = () => (
    <Menu className="user-msg">
      <div className="arrow-up" style={{ top: '-4px', left: '70%', zIndex: 11 }} />
      <Menu.Item key="a" onClick={this.toTransactionLogs} className="center menu-right-item">
        <IconFont type={'medataicon-log-a-04'} style={{ fontSize: '1.5em' }} />
        <span>我的日志</span>
      </Menu.Item>
      <Menu.Item key="b" onClick={this.toIntroduction} className="center menu-right-item">
        <IconFont type={'medataicon-bangzhu1'} style={{ fontSize: '1.5em' }} />
        <span>使用指南</span>
      </Menu.Item>
      <Menu.Item key="F" onClick={this.showUpdataPsdModal} className="center menu-right-item">
        <IconFont type={'medataicon-quanxian'} style={{ fontSize: '1.5em' }} />
        <span>修改密码</span>
      </Menu.Item>
      <Menu.Item key="c" onClick={this.loginout} className="center menu-right-item">
        <IconFont type={'medataicon-tuichudenglu'} style={{ fontSize: '1.5em' }} />
        <span>退出登录</span>
      </Menu.Item>
    </Menu>
  );
  private userDrapRenderJD = () => (
    <Menu className="user-msg">
      <div className="arrow-up" style={{ top: '-4px', left: '70%', zIndex: 11 }} />
      <Menu.Item key="F" onClick={this.showUpdataPsdModal} className="center menu-right-item">
        <IconFont type={'medataicon-quanxian'} style={{ fontSize: '1.5em' }} />
        <span>修改密码</span>
      </Menu.Item>
      <Menu.Item key="c" onClick={this.loginout} className="center menu-right-item">
        <IconFont type={'medataicon-tuichudenglu'} style={{ fontSize: '1.5em' }} />
        <span>退出登录</span>
      </Menu.Item>
    </Menu>
  );
  /**
   * reader消息通知列表item
   */
  private noticeTtemRender = (item: INoticeItem) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar icon="mail" />}
          // tslint:disable-next-line:jsx-no-multiline-js
          title={
            <span className={item.unread ? 'unreadTrue' : 'unreadFalse'} title={item.text}>
              {item.text}
            </span>
          }
          description={item.created}
        />
      </List.Item>
    );
  };

  /**
   * 下拉框挂载位置
   */
  private dropdownBind = (trigger: Element) => {
    return trigger.parentElement || document.body;
  };
}
export default connect()(Form.create()(IndexLayout));
