/* 用户积分交易记录 REq*/
export interface ITradeListReq {
  pageNum?: number;
  pageSize?: number;
  type?: number;
}
/* 用户积分交易记录*/
export interface IUserGradeTradeItem {
  refCode: string;
  type: number;
  typeStr: string;
  amount: number;
  remain: number;
  reason: string;
  created: string;
  contractNumber: string;
}
export interface INormalList<T> {
  // 以后列表都用这个
  pages: number;
  size: number;
  total: number;
  current: number;
  records?: T;
}

export interface IDowloadTradeDetail {
  id: number;
  userRemark: string;
  serverCode: string;
  orderVmCode: number;
}
export interface IParamForTrans {
  pageNum?: number;
  pageSize?: number;
  type: string;
  status?: number;
}
export interface IParentOrder {
  // 总预约单
  code: string;
  contractNumber: number;
  created: string;
  dataOrderId: number;
  days: number;
  deleted: string;
  id: number;
  coinPrice: number;
  availableDays: number;
  updated: string;
  vmOrderId: number;
}
export interface IPackageInfo {
  categoryName: string;
  demoUrl: string;
  id: number;
  jsonDesc: string;
  serverName: string;
  status: number;

  title: string;
  usedNum: number;
}
export interface IOrderDetailInfo extends IParentOrder {
  // 预约单 详情
  orderVm: IOrderVm; // 安全盒预约单
  orderData: IOrderData; // 数据预约单
}

export interface IOrderData {
  code: string;
  created: string;
  id: number;
  packageInfo: IPackageInfo;
  parentOrder?: IParentOrder;
  status: number;
  strStatus: string;
  updated: string;
}
export interface IOrderVm {
  // 安全盒 预约单
  id: number;
  code: string;
  node: string;
  userId: number;
  serverId: number;
  created: string;
  updated: string;
  setupJson: string;
  vmid: number;
  status: number;
  statusStr: string;
  parentOrder?: IParentOrder;
}
export interface IState {
  tradeList: INormalList<ITransactionItem[]>;
  orderList: INormalList<ITransactionItem[]>;
  dowloadList: INormalList<ITransactionItem[]>;
  tradeDataDetail?: IOrderDetailInfo; // 交易记录详情
  orderVmDetail?: IOrderVm;
  orderDataDetail?: IOrderData;
  dowloadItemDetail?: IDowloadTradeDetail;
  token?: string;
  userId?: number;
}

export interface ITransactionItem {
  consumerId?: string;
  refCode?: string;
  created: string;
  subType: number; // 如1为充值，2为数据被使用，3为数据检索，4为安全盒购买，5为续费
  intValue: number; // 积分 .积分
  status?: string;
  type?: number;
  nodeName: string;
  id: number;
}
export interface IPagination {
  pageSize: number;
  current: number;
  total: number;
}

/** 用户中心数据结构 */
// 用户积分情况 用户信息
export interface IUserGrade {
  coin: number;
  refillCoin: number;
  dataIncomeCoin: number;
  spendCoin: number;
}
export interface IResponse<T> {
  code: number;
  message?: string;
  result: T;
}
export interface IMyDatastate {
  visible: boolean;
  drawerType: number;
  typeList?: number;
  isLoading: boolean;
  total: number;
  userGrade?: IUserGrade;
  gradesList: IUserGradeTradeItem[];
  tradeVmDetail: IOrderDetailInfo;
  // 分页
  pagination: IPagination;
  clickItem?: IUserGradeTradeItem;
}

export const STATETYPE = [
  { key: '1', value: '充值' },
  { key: '2', value: '数据检索' },
  { key: '3', value: '安全盒购买' },
  { key: '4', value: '数据检索结算' },
  { key: '7', value: '数据积分返还' },
  { key: '8', value: '安全盒积分返还' },
];
export const iTransactionItem = {
  consumerId: '',
  refCode: '',
  created: '',
  subType: 0, // 如1为充值，2为数据被使用，3为数据检索，4为安全盒购买，5为续费
  intValue: 0, // 积分 .积分
  status: '',
  type: 0,
  nodeName: '',
  id: 0,
};
export const orderVm = {
  id: 0,
  code: '',
  node: '',
  userId: 0,
  serverId: 0,
  created: '',
  updated: '',
  setupJson: '',
  vmid: 0,
  status: 0,
  statusStr: '',
};
export const orderData = {
  code: '',
  created: '',
  id: 0,
  packageInfo: {
    categoryName: '',
    demoUrl: '',
    id: 0,
    jsonDesc: '',
    serverName: '',
    status: 0,
    title: '',
    usedNum: 0,
  },
  parentOrder: {
    code: '',
    contractNumber: 0,
    created: '',
    dataOrderId: 0,
    days: 0,
    deleted: '',
    id: 0,
    coinPrice: 0,
    availableDays: 0,
    updated: '',
    vmOrderId: 0,
  },
  status: 0,
  strStatus: '',
  updated: '',
};
export const stateInit = {
  visible: false,
  drawerType: 1,
  pagination: { total: 0, pageSize: 10, current: 1 },
  gradesList: [],
  typeList: undefined,
  isLoading: false,
  total: 0,
  userGrade: {
    coin: 0,
    refillCoin: 0,
    dataIncomeCoin: 0,
    spendCoin: 0,
  },
  clickItem: {
    refCode: '',
    type: 0,
    typeStr: '',
    amount: 0,
    remain: 0,
    reason: '',
    created: '',
    contractNumber: '',
  },
  tradeVmDetail: {
    orderVm,
    orderData,
    code: '',
    contractNumber: 0,
    created: '',
    dataOrderId: 0,
    days: 0,
    deleted: '',
    id: 0,
    coinPrice: 0,
    availableDays: 0,
    updated: '',
    vmOrderId: 0,
  },
};
