import { ISafetyBoxInfo } from '../OrderPromise/VMOrders/CodeType';
import { IPagination } from '../SciProxAiManage/CodeType';

export interface ISecurityBoxState {
  vmBoxItems: IVmBoxItem[];
  boxPagination: IPagination;
  isLoading: boolean;
  clickItem?: IVmBoxItem;
  safetyBoxVisible: boolean;
}

export interface IVmBoxConsoleInfo {
  csrfPreventionToken: string;
  node: string;
  novncUrl: string;
  pveAuthCookie: string;
  vmid: string;
}

export interface IVmBoxItem {
  researchProjectId: number;
  researchProjectName: string;
  vmInstanceId: string;
  vmInstanceName: string;
  distributeVmConfigCode: string;
  distributeSetupJson: string;
  initUser: string;
  initPass: string;
  vmBoxInfo: ISafetyBoxInfo[];
  vmInstanceNumber: string;
  id: number;
  ownUserId: number;
}

export const InitState = {
  vmBoxItems: [],
  isLoading: false,
  safetyBoxVisible: false,
  boxPagination: { total: 0, pageSize: 4, current: 1 },
};
