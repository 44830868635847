import { Button, Drawer, Form, message, Modal, Select, Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import DataManagerApi from '../../../api/DataManager';
import SciManageApi from '../../../api/SciManageApi';
import AuthCmp from '../../../components/AuthCmp/AuthCmp';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import { ITreeData } from '../../../components/FileTree/FilePathTree';
import TradeDetailDrawer from '../../../components/InfoDrawer/TradeDetailDrawer';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';

import { INormalList } from '../../DataManager/CodeType';
import { DownloadPath } from '../../DataManager/Component/DownloadPath/DownloadPath';
import { GrantSet } from '../../DataManager/Component/GrantSet/GrantSet';
// import { guid } from '../../DataManager/DataMSetting';
import {
  InitState,
  IOptionUser,
  IPkgItem,
  IPkgItemsReq,
  ISciPkgProps,
  ISciPkgRowAction,
  ISciPkgState,
  PKG_DATA_TYPE_OPTION,
  TAB_ROW_ATIONS,
} from './CodeType';
import './SciPkgCmp.scss';

export class SciPkgCmp extends React.PureComponent<ISciPkgProps, ISciPkgState> {
  public hasShareColumns = [
    {
      title: '项目数据包名称',
      dataIndex: 'projectName',
      key: 'projectName',
      width: '150px',
    },
    {
      title: '数据格式',
      dataIndex: 'dataFormat',
      key: 'dataFormat',
    },
    {
      title: '数据类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '数量',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '疾病类型',
      dataIndex: 'disease',
      key: 'disease',
      width: '100px',
    },
    {
      title: '数据包状态',
      dataIndex: 'status',
      key: 'status',
      render: (type: number, record: IPkgItem) => {
        const color = record.status === 0 ? 'rgba(208, 2, 27, 1)' : 'rgba(0, 0, 0, 0.65)';
        return <span style={{ color }}> {record.status === 0 ? '未挂载' : '已挂载'}</span>;
      },
    },
    {
      title: <div style={{ paddingLeft: '12px' }}>操作</div>,
      key: 'action',
      className: 'row-action-item',
      render: (text: string, record: IPkgItem) => {
        // const localPermission: string[] = JSON.parse(Storage.getStore(`PRPJECTPERMISSIONID${this.props.projectId}`));
        // const ret = localPermission.includes('USER_PROJECT_MANAGE');
        // const actionsArr = ret ? TAB_ROW_ATIONS : [TAB_ROW_ATIONS[0]];
        return (
          <div className="table-row-action-container">
            <div key={0} onClick={() => this.cellClick(record, TAB_ROW_ATIONS[0].type)}>
              {TAB_ROW_ATIONS[0].title}
            </div>
            <AuthCmp permission="USER_PROJECT_MANAGE" projectId={this.props.projectId}>
              <div style={{ display: 'flex' }}>
                <div key={1} onClick={() => this.cellClick(record, TAB_ROW_ATIONS[1].type)} className="action">
                  {TAB_ROW_ATIONS[1].title}
                </div>
                <div key={2} onClick={() => this.cellClick(record, TAB_ROW_ATIONS[2].type)} className="action">
                  {TAB_ROW_ATIONS[2].title}
                </div>
              </div>
            </AuthCmp>
          </div>
        );
      },
    },
  ];

  public state = { ...InitState } as ISciPkgState;

  public componentDidMount() {
    this.getSciPkgItems();
    this.getDiseasesOptions();
    this.getMembersOptions();
  }

  /**
   * 对象转数组
   */
  // tslint:disable-next-line:no-any
  public objToArr = (obj: any) => {
    const arr = [];
    for (const i in obj) {
      if (obj[i]) {
        arr.push({ title: i, content: obj[i] });
      }
    }
    return arr;
  };
  public render() {
    const records = this.state.pkgItems;
    const { getFieldDecorator } = this.props.form;
    const laodPkgfooter = (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button type="primary" className="action-btn" onClick={this.loadModalOkClick}>
          前往安全盒
        </Button>
        <Button className="ant-btn-no " onClick={this.loadModalCancelClick}>
          取消
        </Button>
      </div>
    );
    return (
      <div className="sci-package-component">
        <TableHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }} className="original-form-header">
              <Form.Item label="数据类型:">
                {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                {getFieldDecorator('sci_pkg_type')(
                  <Select className="original-input" placeholder="请选择" style={{ width: '240px' }} allowClear={true}>
                    {PKG_DATA_TYPE_OPTION}
                  </Select>
                )}
              </Form.Item>
              <Button
                className="normal-search-button"
                onClick={this.handlePkgSearchAction}
                style={{ marginLeft: '40px', fontWeight: 'bold', marginRight: '60px' }}
              >
                查询
              </Button>
            </div>
          </div>
        </TableHeader>
        <AuthComponent isShow={!!this.state.pkgPagination.total}>
          <Table
            columns={this.hasShareColumns}
            dataSource={records}
            style={{ backgroundColor: 'white' }}
            pagination={{ ...this.state.pkgPagination }}
            rowKey="packageId"
            onChange={this.handleHasTableChanage}
          />
        </AuthComponent>
        <NoDataImg
          isLoading={this.state.isLoading}
          total={this.state.pkgPagination.total}
          msg="暂时还没有项目数据包..."
        />
        <GrantSet
          grants={this.state.grants}
          onOk={this.authDistributionOK}
          onCancel={this.authDistributionHide}
          visible={this.state.authModal}
        />
        <DownloadPath
          path={this.state.pathList}
          onOk={this.downloadHasSharedDataOK}
          onCancel={this.downloadHasSharedDataHide}
          visible={this.state.pathModal}
          title={'项目数据包下载'}
        />
        <Drawer
          title="数据包详情"
          placement="right"
          width={700}
          closable={false}
          onClose={this.packageDrawerClose}
          visible={this.state.packageDrawerVisible}
        >
          <TradeDetailDrawer
            showOrderMessage={false}
            showPackageView={this.state.packageDrawerVisible}
            showSecurityBox={false}
            dataPackage={this.state.clickItem && JSON.parse(this.state.clickItem.jsonDesc)}
            // tslint:disable-next-line:jsx-no-lambda jsx-no-multiline-js
            onClose={() => {
              this.packageDrawerClose();
            }}
          />
        </Drawer>

        <Modal
          visible={this.state.loadModalVisable}
          className="load-pkg-modal"
          width="560px"
          centered={true}
          title={'提示'}
          footer={laodPkgfooter}
          closable={true}
          onCancel={this.moreModalCancel}
        >
          <div className="modal-content-view"> 为了确保数据安全，必须关闭安全盒网络后才能挂载数据 </div>
        </Modal>
      </div>
    );
  }

  /**
   * 已共享数据 网络请求
   */
  private getSciPkgItems = async () => {
    const { pkgPagination } = this.state;
    const { getFieldValue } = this.props.form;
    const pkgType = getFieldValue('sci_pkg_type');

    try {
      this.setState({ isLoading: false });
      const { result } = await SciManageApi.getProjectPackageDatas<INormalList<IPkgItem[]>, IPkgItemsReq>({
        pageNum: pkgPagination.current,
        pageSize: pkgPagination.pageSize,
        projectId: this.props.projectId,
        type: pkgType,
      });
      if (result) {
        pkgPagination.current = result.current;
        pkgPagination.total = result.total;
        this.setState({
          pkgItems: [...result.records],
          pkgPagination,
          isLoading: true,
        });
      }
    } catch (err) {
      this.setState({ isLoading: true });
    }
  };

  /**
   * 获取疾病列表
   */
  private getDiseasesOptions = async () => {
    const { result } = await DataManagerApi.getDiseases();
    if (result) {
      this.setState({
        diseaseItems: [...result],
      });
    }
  };

  /**
   * 获取用户列表
   */

  private getMembersOptions = async () => {
    const { result } = await DataManagerApi.getMembers<IOptionUser[]>();
    if (result) {
      this.setState({
        userItems: [...result],
      });
    }
  };
  /**
   * 列表分页处理
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    const { pkgPagination } = this.state;
    pkgPagination.current = pagination ? (pagination.current ? pagination.current : 1) : 1;
    this.setState({
      pkgPagination,
    });
    this.getSciPkgItems();
  };

  /**
   *  已共享数据 筛选
   */
  private handlePkgSearchAction = () => {
    this.getSciPkgItems();
  };
  /**
   * 原始数据row  点击事件, 针对不同按钮点击事件,用IHasSharedCellClickType 枚举进行区分
   */
  private cellClick = (item: IPkgItem, type: ISciPkgRowAction) => {
    this.setState({
      clickItem: item,
    });
    switch (type) {
      case ISciPkgRowAction.pkgDetail:
        this.showHasSharePackageDetail(item);
        break;
      case ISciPkgRowAction.download:
        this.downloadHasSharedData(item);
        break;

      case ISciPkgRowAction.loadpackage:
        this.setState({
          item,
          loadModalVisable: true,
        });

      default:
        return;
    }
  };

  /**
   *  已共享数据下载
   */

  private downloadHasSharedData = async (item: IPkgItem) => {
    const { result } = await DataManagerApi.getdownloadPathList<ITreeData[]>();
    if (result.length > 0) {
      this.setState({ pathList: result });
      this.downloadHasSharedDataShow();
    } else {
      message.warning('没有路径可以存放');
    }
  };

  /**
   * 下载组件 Action
   */
  /*  打开下载模态框 */
  private downloadHasSharedDataShow = () => {
    this.setState({ pathModal: true });
  };
  /*  点击下载确定按钮 */
  private downloadHasSharedDataOK = async (path: string) => {
    // await HttpRequest.savePackageGrantList<null, string[], string>(arr, this.state.code);
    const { result } = await SciManageApi.sciPkgDownload<{}, { id: number; targetPath: string }>({
      id: this.state.clickItem!.packageId,
      targetPath: path,
      // taskId: guid(String(this.state.id)),
    });
    this.setState({ pathModal: false });
    message.success(result);
  };
  /*  关闭下载模态框 */
  private downloadHasSharedDataHide = () => {
    this.setState({ pathModal: false });
  };

  /**
   * 显示已共享数据包详情
   */
  private showHasSharePackageDetail = (item: IPkgItem) => {
    //
    this.setState({ packageDrawerVisible: true });
  };

  /**
   * @todo  权限修改Component  Action
   */

  /*
   * 点击权限列表确定按钮
   */
  private authDistributionOK = async (arr: string[]) => {
    await DataManagerApi.savePackageGrantList<null, string[], string>(arr, this.state.code);

    this.setState({ authModal: false });
    message.success('设置成功！');
  };
  /*  关闭权限列表模态框 */
  private authDistributionHide = () => {
    this.setState({ authModal: false });
  };
  /**
   * 挂载模态框OKCLick
   */
  private loadModalOkClick = () => {
    this.setState({
      loadModalVisable: false,
    });

    this.props.goSecurityBox();
  };
  /**
   * 挂载模态框 关闭
   */
  private loadModalCancelClick = () => {
    this.setState({
      loadModalVisable: false,
    });
  };

  /**
   * 关闭数据包详情抽屉
   */
  private packageDrawerClose = () => {
    this.setState({ packageDrawerVisible: false });
  };

  /**
   * 下拉框挂载位置
   */

  private moreModalCancel = () => {
    this.setState({ loadModalVisable: false });
  };
}

export default SciPkgCmp;
