import axios from './RequestApi';
/**
 * @class 账号管理，包括节点用户和云端用户
 */
export default class MemberManageApi {
  /**
   *
   * @param data
   * // 账号列表
   */
  public static memberList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/member/memberList', data);
  }
  /**
   * // 创建账号
   * @param data
   */
  public static newAccount<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/member/addMember', data);
  }
  /**
   * // 更新账号
   * @param data
   */
  public static editMemberAccount<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/member/editMember', data);
  }
  /**
   * // 更新账号
   * @param data
   */
  public static updateMemberAccountPsd<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/member/resetPwd', data);
  }
  /**
   * // 角色列表
   * @param data
   */
  public static roleList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/role/roleList', data);
  }
  /**
   *  // 创建角色 /web/user/logined/role/editRole
   * @param data
   */
  public static newRole<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/role/addRole', data);
  }
  /**
   * // 角色编辑
   * @param data
   */
  public static editRole<T, D>(data: D) {
    // 角色编辑
    return axios.post<T, D>('/user/logined/role/editRole', data);
  }
  /**
   * 角色列表
   * @param data
   */
  public static permissionList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/permission/permissionList', data);
  }
}
