import { Form, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ProxAiManageApi from '../../../../api/ProxAiManageApi';
import AuthComponent from '../../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../../components/NoDataImg/NoDataImg';
import { IconFont } from '../../../../config/IconConfig';
import IndexLayout from '../../../../layout/IndexLayout';
import { INormalList } from '../../../DataManager/CodeType';
import { initProjectDetailState, IProjectTask, IProjectTaskDeatilState, IProjectTasksReq } from '../../CodeType';
import './ProjectTaskDeatil.scss';

interface IProjectTaskDeatilPageProps extends FormComponentProps, RouteComponentProps<{ pId: string; name: string }> {}
class ProjectTaskDeatilPage extends React.Component<IProjectTaskDeatilPageProps, IProjectTaskDeatilState> {
  public projectColumns = [
    {
      title: '任务标题',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IProjectTask) => {
        return (
          <div className="nowarp-elips" title={text} style={{ width: '200px' }}>
            {text}
          </div>
        );
      },
    },
    {
      title: '任务详情',
      dataIndex: 'remark',
      key: 'remark',
      render: (text: string, record: IProjectTask) => {
        return (
          <div className="nowarp-elips" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '所属成员',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '任务样本数量',
      dataIndex: 'seriesTotalNum',
      key: 'seriesTotalNum',
    },
    {
      title: '已提交序列数',
      dataIndex: 'seriesSubmittedNum',
      key: 'seriesSubmittedNum',
    },
  ];
  public state = initProjectDetailState as IProjectTaskDeatilState;
  public componentDidMount() {
    this.getProjectTasks();
  }
  public componentWillUnmount() {
    this.setState({ ...initProjectDetailState });
  }
  public render() {
    return (
      <div className="project-task-deatil-container">
        <IndexLayout>
          <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.props.history.goBack}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回项目列表</span>
              </div>
              <div style={{ color: 'rgba(0,0,0,0.45)' }}>
                <IconFont type={'medataicon-icon_location'} className="located-icon" />
                <span onClick={this.props.history.goBack} style={{ marginLeft: '10px', marginRight: '5px' }}>
                  项目列表
                </span>
                /<span style={{ marginLeft: '5px' }}>任务详情</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="project-message-title">
                <div className="dot" /> {`「${this.props.match.params.name}」的任务列表`}
              </div>
            </div>
          </div>

          <AuthComponent isShow={!!this.state.projectTasks.length}>
            <Table
              columns={this.projectColumns}
              dataSource={this.state.projectTasks}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...this.state.taskPagination }}
              rowKey="id"
              onChange={this.handleHasTableChanage}
            />
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.taskPagination.total} />
        </IndexLayout>
      </div>
    );
  }

  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    this.getProjectTasks(pagination.current);
  };

  /**
   * 获取项目下的任务类表
   */

  private getProjectTasks = async (page?: number) => {
    const { taskPagination } = this.state;
    taskPagination.current = page || 1;
    try {
      this.setState({ isLoading: false });
      const { result } = await ProxAiManageApi.getProjectTasks<INormalList<IProjectTask[]>, IProjectTasksReq>({
        page: taskPagination.current,
        pageSize: taskPagination.pageSize,
        projectId: this.props.match.params.pId,
      });
      if (result) {
        //
        taskPagination.total = result.total;
        taskPagination.current = result.current;
        this.setState({
          projectTasks: result.records,
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
}
const ProjectTaskDeatil = Form.create()(ProjectTaskDeatilPage);
export default ProjectTaskDeatil;
