import { Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Form, { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import { IconFont } from '../../config/IconConfig';
import './InputPassword.scss';
interface IInputPasswordProps extends FormComponentProps {
  // tslint:disable-next-line:no-any
  form: WrappedFormUtils<any>;
  title: string;

  fileID: string; // 检查 key
  errorMessage: string;
}

interface IInputPasswordState {
  statue: boolean; // 默认密文显示
}
class InputPassword extends React.PureComponent<IInputPasswordProps, IInputPasswordState> {
  public state = {
    statue: true,
  };

  public render() {
    const { getFieldDecorator } = this.props.form;
    const { title, fileID, errorMessage } = this.props;
    const { statue } = this.state;
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center' }} className="input-password">
        <div className="form-item-title">{title}</div>
        <div style={{ paddingLeft: '5px', fontWeight: 'bold' }}>:</div>
        <Form.Item style={{ flex: 1, paddingLeft: '8px' }}>
          {/* tslint:disable-next-line:jsx-no-multiline-js*/}
          {getFieldDecorator(fileID, {
            rules: [{ required: true, message: errorMessage }],
          })(
            <Input
              placeholder="点击输入"
              type={statue ? 'password' : 'text'}
              className="passward-input"
              // tslint:disable-next-line:jsx-no-multiline-js
              suffix={
                <div onClick={this.passwordClick}>
                  {<IconFont type={statue ? 'medataicon-bukejian' : 'medataicon-kejian'} />}
                </div>
              }
            />
          )}
        </Form.Item>
      </div>
    );
  }

  public passwordClick = () => {
    this.setState({
      statue: !this.state.statue,
    });
  };
}

export default InputPassword; // 创建form实例
