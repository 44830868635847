import axios from './RequestApi';

export default class ProjectMembersApi {
  /*
   * 成员列表显示
   */
  public static getMemberList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/researchProject/selectNotJoinProjectUser', data);
  }

  /*
   * 项目成员列表显示
   */
  public static getProjectUserList<T, D>(data: D) {
    return axios.get<T, D>('/user/logined/researchProject/selectProjectUserList', data);
  }

  /*
   * 科研项目加入用户-引入-编辑
   */
  public static jionUser<T, D>(data: D) {
    return axios.post<T, D>('/user/logined/researchProject/joinUser', data);
  }

  /*
   * 修改账号-状态变更
   */
  public static editMember<T>(url: string) {
    return axios.put(url);
  }
  /*
   * 获取角色权限
   */
  public static getRoleList<T>() {
    return axios.get<T, {}>('/user/logined/researchProject/getRoleList');
  }
}
