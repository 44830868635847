import { Badge, Button, Col, Divider, Drawer, Form, Input, Modal, Row, Select, Spin, Table } from 'antd';
import { PaginationConfig } from 'antd/lib/table';
import React, { Fragment } from 'react';
import Orders from '../../api/OrderManageApi';
import VMManage from '../../api/VMManage';
import TradeDetailDrawer from '../../components/InfoDrawer/TradeDetailDrawer';
import RouteHeader from '../../components/routeHeader/RouteHeader';
import { IconFont } from '../../config/IconConfig';
import IndexLayout from '../../layout/IndexLayout';
import { IDataPackageItem } from '../../util/DataStruct';
import Storage from '../../util/Storage';
import { IVmBoxItem } from '../SecurityBox/CodeType';
import DowloadManage from './../../api/DowloadManage';
import AuthComponent from './../../components/AuthComponent/AuthComponent';
import NoDataImg from './../../components/NoDataImg/NoDataImg';
import { IOrderDetailInfo } from './../OrderPromise/VMOrders/CodeType';
import {
  IDowloadItem,
  IDowloadList,
  IDowloadMessage,
  IDownDatas,
  InitState,
  INormalList,
  IProps,
  IState,
  IVmJsonItem,
} from './CodeType';

const { Option } = Select;
const statusMap: Array<'success' | 'processing' | 'default' | 'error' | 'warning'> = ['processing', 'success', 'error'];
const status = ['待审核', '已通过', '未通过'];
const actionStatus = ['待审核', '可下载', '未通过'];

class DowloadList extends React.PureComponent<IProps> {
  public columns = [
    {
      title: '下载编号',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: `${Storage.getStore('usertype') === '5' ? '安全盒编号' : '安全盒订单号'}`,
      dataIndex: `${Storage.getStore('usertype') === '5' ? 'vmInstanceNumber' : 'orderVmCode'}`,
      key: 'orderVmCode',
    },
    {
      title: '申请日期',
      dataIndex: 'created',
      key: 'created',
    },

    {
      title: '机构编号',
      dataIndex: 'serverCode',
      key: 'serverCode',
    },

    {
      title: '申请状态',
      dataIndex: 'status',
      key: 'status',
      render: (val: number) => {
        return <Badge status={statusMap[val - 1]} text={status[val - 1]} />;
      },
    },

    {
      title: '操作',
      dataIndex: 'status',
      key: 'actions',

      render: (val: number, record: IDowloadItem) => {
        // eslint-disable-next-line no-nested-ternary
        const btnGroup =
          record.status === 2 ? (
            <Fragment>
              <a onClick={() => this.getFinishDowloadData(record.orderVmCode + '')}>
                {/* <span style={{ display: 'none' }}>{record.orderVmCode}</span> */}
                {actionStatus[val - 1]}
              </a>
            </Fragment>
          ) : (
            <Fragment>
              <a style={{ color: '#000000' }}>
                <span style={{ display: 'none' }}>{record.id}</span>
                {actionStatus[val - 1]}
              </a>
            </Fragment>
          );
        return btnGroup;
      },
    },
  ];
  public state = (InitState as unknown) as IState;

  public componentDidMount() {
    this.getDownLoadDatas();
    this.setState({
      isJD: Storage.getStore('usertype') === '5',
    });
  }
  public render() {
    const { getFieldDecorator } = this.props.form;
    const smbInfo = (
      <p>
        <span style={{ fontWeight: 'bolder' }}>smb/ftp账号:</span> {this.state.finishDowloadData.smbUsername},
        <span style={{ fontWeight: 'bolder' }}>密码:</span> {this.state.finishDowloadData.smbPassword}
      </p>
    );
    return (
      <div className="constainer" id="DowloadList">
        <IndexLayout>
          <Row style={{ backgroundColor: 'white', height: '100%' }}>
            <RouteHeader
              title="下载中心"
              titles={['首页', '下载中心']}
              routes={['/', '/datas']}
              hiddenBreadcrumb={true}
            />
            <div style={{ backgroundColor: '#FAFAFA' }}>
              <Row>
                <Row className="DataSearchForm">
                  <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: '14px' }}>下载状态: </span>
                    <Select
                      placeholder="请选择"
                      style={{ width: '156px', height: '32px', marginLeft: '10px' }}
                      onChange={this.updateDataStatus}
                      allowClear={true}
                    >
                      <Option value={1}> 待审核</Option>
                      <Option value={2}> 已通过</Option>
                      <Option value={3}> 未通过</Option>
                    </Select>

                    <div
                      onClick={this.showDrawer}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                    >
                      <IconFont type="medataicon-jiahao" className="input-prefix" />
                      <span style={{ marginLeft: '5px' }}>下载申请</span>
                    </div>
                  </Col>
                </Row>
                <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
              </Row>
            </div>
            <AuthComponent isShow={!!this.state.total}>
              <Table
                columns={this.columns}
                dataSource={this.state.datas}
                style={{ backgroundColor: 'white' }}
                rowKey="id"
                pagination={{ total: this.state.total }}
                onChange={this.handleTableChange}
              />
            </AuthComponent>
            <NoDataImg isLoading={this.state.isLoading} total={this.state.total} />
          </Row>
        </IndexLayout>
        <Drawer
          title="下载申请"
          placement="right"
          width={700}
          closable={false}
          onClose={this.closeDrawer}
          visible={this.state.drawerVisible}
        >
          <Spin spinning={this.state.drawerSpin}>
            <div id="downLoadDrawer">
              <Form layout="vertical" hideRequiredMark={true}>
                <Row gutter={16}>
                  <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item
                      label={this.state.isJD ? '安全盒编号:' : '选择数据使用编号:'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      {/* tslint:disable-next-line:jsx-no-multiline-js */}
                      {getFieldDecorator('codeNumber', {
                        rules: [{ required: true, message: '请选择' }],
                      })(
                        <Select placeholder="请选择" className="sleOr" onChange={this.selectValueChange}>
                          {this.state.selectOptions}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <AuthComponent isShow={this.state.drawerDetailBtnVisible}>
                      <span className="detailShow" onClick={this.showDrawerOrderDetail}>
                        {this.state.drawerDetailVisible ? '收起详情' : '查看数据使用详情'}
                      </span>
                    </AuthComponent>
                  </Col>
                </Row>
                <Row> {this.drawerOrderDetail()} </Row>
                <Row>
                  <Col span={24} style={{ marginTop: '20px' }}>
                    <Form.Item>
                      {/* tslint:disable-next-line:jsx-no-multiline-js */}
                      {getFieldDecorator('dowloadDiscription', {
                        rules: [
                          {
                            required: true,
                            message: '点击输入下载说明',
                          },
                        ],
                      })(<Input.TextArea rows={10} placeholder="点击输入下载说明" onChange={this.remarkInputChanga} />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="drawer-but-container">
                <Button
                  onClick={this.addDowloadItem}
                  style={{ marginRight: 8 }}
                  type={this.state.submitBtnOn ? 'default' : 'primary'}
                  disabled={this.state.submitBtnOn}
                >
                  提交
                </Button>
                <Button onClick={this.closeDrawer}>取消</Button>
              </div>
            </div>
          </Spin>
        </Drawer>
        <Modal
          title="下载说明"
          centered={true}
          okText="确定"
          cancelText="取消"
          visible={this.state.modalVisible}
          onOk={this.closeModalView}
          onCancel={this.closeModalView}
          style={{ height: '320px', width: '480px' }}
        >
          <p>
            <span style={{ fontWeight: 'bolder' }}>用户名:</span>
            {this.state.finishDowloadData.pveUserid}
            <span style={{ fontWeight: 'bolder', marginLeft: '10px' }}>密码:</span>
            {this.state.finishDowloadData.password}
          </p>
          <p>
            <span style={{ fontWeight: 'bolder' }}>FTP地址:</span>
            {this.state.finishDowloadData.ftpAddress}
          </p>
          <p>
            <span style={{ fontWeight: 'bolder' }}>端口:</span>
            {this.state.finishDowloadData.ftpPort}
          </p>
          {this.state.finishDowloadData.smbUsername ? smbInfo : ''}
        </Modal>
      </div>
    );
  }

  /**  上传筛选数据     */
  private addDowloadItem = () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        const { getFieldValue } = this.props.form;
        const userRemark: string = getFieldValue('dowloadDiscription');
        const orderVmCode: string = getFieldValue('codeNumber');
        if (this.state.isJD) {
          await DowloadManage.upLoadDataVmInstanceId({
            userRemark,
            vmInstanceId: Number(orderVmCode),
          });
        } else {
          await DowloadManage.upLoadData<IDowloadList, { userRemark: string; code: string }>({
            userRemark,
            code: orderVmCode,
          });
        }

        this.closeDrawer();
      }
    });
  };

  /**
   * 关闭下载说明框
   */
  private closeModalView = () => {
    this.setState({
      modalVisible: false,
    });
  };

  /**
   * 选择数据使用编号
   */
  private selectValueChange = async (code: string) => {
    if (this.state.isJD) {
      // 根据code找出虚拟机
      this.state.vmList.forEach(item => {
        if (item.id === Number(code)) {
          this.setState({ selectedVm: item, drawerDetailBtnVisible: true });
          return;
        }
      });

      return;
    }
    const { result } = await Orders.getOrderDetail<IOrderDetailInfo, { code: string }>({ code });
    this.setState({
      orderItemDetail: result,
      drawerSpin: false,
      drawerDetailBtnVisible: true,
    });
    this.props.form.validateFields(['dowloadDiscription'], err => {
      if (!err) {
        this.setState({
          //  submit 是否 disable
          submitBtnOn: false,
        });
      } else {
        this.setState({
          submitBtnOn: true,
        });
      }
    });
  };

  /**
   * 下载说明input监听事件
   */
  private remarkInputChanga = () => {
    this.props.form.validateFields(err => {
      if (!err) {
        //
        this.setState({
          //  submit 是否 disable
          submitBtnOn: false,
        });
      } else {
        this.setState({
          submitBtnOn: true,
        });
      }
    });
  };

  /**
   * 是否显示订单详情
   */
  private drawerOrderDetail = () => {
    if (this.state.drawerDetailVisible) {
      return this.state.isJD ? this.vmDetail() : this.advanceOrderHeaderRender();
    }
    return <Row />;
  };

  /**
   * 只显示虚拟机详情
   */

  private vmDetail = () => {
    const { selectedVm } = this.state;
    const vmJson = JSON.parse((selectedVm && selectedVm.distributeSetupJson) || '');
    return (
      <TradeDetailDrawer
        onClose={this.showDrawerOrderDetail}
        showOrderMessage={false}
        showPackageView={false}
        showSecurityBox={true}
        vmJson={vmJson}
        orderDetailInfo={this.state.orderItemDetail}
        hiddenFooter={true}
      />
    );
  };
  /**
   * 关闭下载抽屉
   */
  private closeDrawer = () => {
    this.setState({
      drawerVisible: false,
    });

    this.getDownLoadDatas();
  };

  private showDrawerOrderDetail = () => {
    this.setState({
      drawerDetailVisible: !this.state.drawerDetailVisible, // 点击取反
    });
  };
  /**
   * 显示下载申请抽屉
   */
  private showDrawer = async () => {
    // tslint:disable-next-line:no-any
    if (this.state.isJD) {
      this.getAllBoxs();
    } else {
      // tslint:disable-next-line:no-any
      const { result } = await Orders.getOrders<INormalList<any>, IDownDatas>({ pageNum: 1, pageSize: 1000 });
      const selectOptions = result.records.map((item: { orderCode: number | string }) => (
        <Option key={item.orderCode}>{item.orderCode}</Option>
      ));
      this.setState({
        selectOptions,
        drawerVisible: true,
      });
    }
  };

  /**
   * 获取安全盒列表
   */
  private getAllBoxs = async () => {
    const { result } = await VMManage.getAllVmItems<INormalList<IVmBoxItem[]>>({
      pageSize: 10000,
      pageNum: 1,
    });

    if (result) {
      const selectOptions = result.records.map((item: IVmBoxItem) => (
        <Option key={item.id} value={item.id}>
          {item.vmInstanceNumber}
        </Option>
      ));
      this.setState({
        selectOptions,
        vmList: [...result.records],
        drawerVisible: true,
      });
    }
  };

  /**
   * 下载中心分页
   */
  private handleTableChange = (pagination: PaginationConfig) => {
    this.setState({ params: { ...this.state.params, pageNum: pagination.current } }, () => this.getDownLoadDatas());
  };

  /**
   * 获取下载中心数据
   */
  private getDownLoadDatas = async () => {
    try {
      this.setState({
        isLoading: false,
      });
      const { result } = await DowloadManage.getDownLoadDatas<INormalList<[IDowloadItem]>, IDownDatas>(
        this.state.params
      );
      this.setState({
        total: result.total,
        datas: result.records,
        isLoading: true,
      });
    } catch (err) {
      this.setState({
        isLoading: true,
      });
    }
  };

  /**
   * 下载状态筛选
   */
  private updateDataStatus = (value: number) => {
    this.setState({ params: { ...this.state.params, status: value * 1 } }, () => this.getDownLoadDatas());
  };

  /**
   * 获取审核完的的下载数据
   */
  private getFinishDowloadData = async (orderVmCode: string) => {
    const { result } = await DowloadManage.dowloadData<IDowloadMessage, { orderVmCode: string }>({ orderVmCode });
    this.setState({
      finishDowloadData: result,
      modalVisible: true,
    });
  };

  /**
   * 渲染订单详情抽屉组件
   */
  private advanceOrderHeaderRender = () => {
    if (!this.state.orderItemDetail) {
      return;
    }
    const { orderData, orderVm } = this.state.orderItemDetail;
    const { packageInfo } = orderData;
    const packageJson: IDataPackageItem = packageInfo && JSON.parse(packageInfo.jsonDesc);
    const vmJson: IVmJsonItem = orderVm && JSON.parse(orderVm.setupJson);
    return (
      <TradeDetailDrawer
        onClose={this.showDrawerOrderDetail}
        showOrderMessage={true}
        showPackageView={true}
        showSecurityBox={true}
        dataPackage={packageJson}
        vmJson={vmJson}
        orderDetailInfo={this.state.orderItemDetail}
      />
    );
  };
}

export default Form.create()(DowloadList);
