import { Row } from 'antd';
import React from 'react';
import { IconFont } from '../../../config/IconConfig';
import { IVmJsonItem } from '../../../util/DataStruct';
import { IOrderUserVmItem } from '../OrderCheck/CodeType';

interface IState {
  collapsed?: boolean;
  selectStatus: boolean;
}
interface IProps {
  title?: string | number;
  content?: string | number | string[];
  type?: string;
  dataItem?: IOrderUserVmItem;
  selectStatus: boolean;
  selected: (item?: IOrderUserVmItem) => void;
}
class CheckVmListItemView extends React.PureComponent<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      collapsed: false,
      selectStatus: this.props.selectStatus,
    };
  }
  public render() {
    const dataItem = this.props.dataItem;
    if (dataItem === undefined) {
      return <Row />;
    }

    const packageJson: IVmJsonItem = dataItem.vmJsonDesc ? JSON.parse(dataItem.vmJsonDesc) : undefined;
    const selIcon = this.props.selectStatus ? 'medataicon-xuanzhong-copy-order' : 'medataicon-dot1';
    const className = this.props.selectStatus ? 'CheckVmListItemView-selected' : 'CheckVmListItemView';
    const iconClassName = this.props.selectStatus ? 'input-prefix-selected' : 'input-prefix';
    return (
      <div id="CheckVmListItemView">
        <Row style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} className={className}>
          <div style={{ flex: 3 }} onClick={this.rowSelected}>
            <IconFont type={selIcon} className={iconClassName} />
            <text style={{ marginLeft: '16px' }}> {dataItem ? dataItem.serverName : ''}</text>
          </div>

          <div style={{ flex: 2 }}>cpu:{packageJson ? packageJson.cpu_cores : 0}核 </div>

          <div style={{ flex: 2 }}>内存:{packageJson ? packageJson.memory_size : 0}G </div>

          <div style={{ flex: 2 }}>磁盘空间:{packageJson ? packageJson.disk_space : 0}T </div>

          <div style={{ flex: 2 }}>可用时长:{dataItem.days}天 </div>
        </Row>
      </div>
    );
  }

  public rowSelected = () => {
    this.setState({
      selectStatus: !this.state.selectStatus,
    });
    this.props.selected(this.props.dataItem);
  };
}

export default CheckVmListItemView;
