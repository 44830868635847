import { useCallback, useState } from 'react';
/**
 * @returns visible setVisible 控制显示状态
 * @returns colseModal 关闭模态框
 * @returns openModal 打开模态框
 */
export const useModalVisible = (vis: boolean) => {
  const [visible, setVisible] = useState(vis);
  const colseModal = useCallback(() => setVisible(false), [visible]);
  const openModal = useCallback(() => setVisible(true), [visible]);
  return { visible, setVisible, colseModal, openModal };
};
