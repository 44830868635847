import { RouterAPI } from 'dva';
import { Redirect, Route, Router, Switch } from 'dva/router';
import React from 'react';
import Routes, { IRouteConfigItem } from './pages/Routes';
import Storage, { USERTYPE } from './util/Storage';
function RouterConfig(api?: RouterAPI) {
  if (api) {
    const { history } = api;
    const routeResult: JSX.Element[] = [];
    const subRoute = (param: IRouteConfigItem[], mainPath: string, mainI: number) => {
      const newParam = param.filter((value, index) => {
        return value.usrTypes.includes(Storage.getStore(USERTYPE));
      });
      newParam.forEach((item: IRouteConfigItem, i) => {
        if (item.routes) {
          subRoute(item.routes, mainPath + item.path, Number(mainI + '0' + i));
        }
        routeResult.push(
          <Route path={mainPath + item.path} key={Number(mainI + '0' + i)} component={item.component} />
        );
      });
    };

    /**
     * 筛选第一层路由
     */
    const newRoutes = Routes.filter((value, index) => {
      if (value.path === '/login' || value.path === '/') {
        return true;
      }
      return value.usrTypes.includes(Storage.getStore(USERTYPE));
    });

    newRoutes.forEach((route, i) => {
      if (route.routes) {
        subRoute(route.routes, route.path, i);
      } else {
        routeResult.push(<Route path={route.path} key={i} exact={true} component={route.component} />);
      }
    });
    return (
      <Router history={history}>
        <Switch>
          {routeResult}
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  } else {
    return {};
  }
}

export default RouterConfig;
