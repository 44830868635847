import { IOptionUser } from '../DataManager/Component/HasSharedDataComponent/CodeType';

export interface IPagination {
  total: number;
  pageSize: number;
  current: number;
}

export interface IProjectSaveReq {
  name: string;
  remark: string;
  seriesInstanceUidList: string[];
  templateId: number;
}

export interface IProxAiManageState {
  projectItems: ISciProxAiTaskItem[];
  proPagination: IPagination;
  isLoading: boolean;
}
export const initalState = {
  projectItems: [],
  proPagination: { total: 0, pageSize: 10, current: 1 },
  isLoading: true,
};

export interface INewProjectState {
  toolTemplates: IProxAiTemplateItem[];
  availableSeqs: IAvailableSeqItem[];
  selectedSeqs: string[];
  seqPagination: IPagination;
  userItems: IOptionUser[];
  partDics: Map<string, string>;
  isLoading: boolean;
  isBack: boolean;
}
export const initalNewProjectState: INewProjectState = {
  toolTemplates: [],
  selectedSeqs: [],
  availableSeqs: [],
  userItems: [],
  partDics: new Map(),
  isLoading: true,
  seqPagination: { total: 0, pageSize: 5, current: 1 },
  isBack: false,
};
export interface IProjectTaskDeatilState {
  taskPagination: IPagination;
  projectTasks: IProjectTask[];
  isLoading: boolean;
}

export interface IProjectTask {
  id: string;
  name: string;
  remark: string;
  seriesSubmittedNum: number;
  seriesTotalNum: number;
  userName: string;
}

export const initProjectDetailState: IProjectTaskDeatilState = {
  taskPagination: { total: 0, pageSize: 10, current: 1 },
  projectTasks: [],
  isLoading: true,
};
export interface INewProjectTaskState {
  seqPagination: IPagination;
  availableSeqs: IAvailableSeqItem[];
  selectedSeqs: string[];
  members: IMemeberItem[];
  userItems: IOptionUser[];
  partDics: Map<string, string>;
  isLoading: boolean;
  isBack: boolean;
}
export const initalNewProjectTaskState: INewProjectTaskState = {
  selectedSeqs: [],
  members: [],
  availableSeqs: [],
  userItems: [],
  seqPagination: { total: 0, pageSize: 10, current: 1 },
  partDics: new Map(),
  isLoading: true,
  isBack: false,
};

export interface IProxAiTemplateItem {
  id: number;
  name: string;
}

export interface IAvailableSeqReq {
  bodyPart?: string;
  importDateBegin?: string;
  importDateEnd?: string;
  jpgValid?: false;
  keyword?: string;
  modality?: string;
  page: number;
  pageSize: number;
  seriesInstanceUID?: string;
  studyInstanceUID?: string;
  type?: string;
  valid?: false;
  assigned: boolean;
}
export interface IAvailableSeqItem {
  bodyPart: string;
  createDate: string;
  fileType: string;
  modality: string;
  seriesInstanceUID: string;
  studyDate: string;
  studyInstanceUID: string;
  assigned: boolean;
}

export interface IProjectSeqsReq {
  page: number;
  pageSize: number;
  projectId: number;
  startTime?: string;
  endTime?: string;
  keyword?: string;
  assigned?: boolean | string;
}

export interface IMemeberItem {
  id: string;
  name: string;
}

export interface IProjectTasksReq {
  page: number;
  pageSize: number;
  projectId: string;
}

export interface ISciProxAiTaskItem {
  createTime: number;
  id: string;
  name: string;
  remark: string;
  seriesSubmittedNum: number;
  seriesTotalNum: number;
  userName: string;
}
