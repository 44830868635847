import { TreeSelect } from 'antd';
import React from 'react';
const { TreeNode } = TreeSelect;
interface IProps {
  treeData: ITreeData[];
  componentStyle?: {};
  interValue?: string;
  onSelect: (item: string) => void;
}

export interface ITreeData {
  name: string;
  id: number | string;
  children?: ITreeData[];
}

let path = '';
/**
 *  树形组件，只支持单选
 *  @param { ITreeData } 接受一个格式的数组
 *  @param { componentStyle } 组件样式 具体些写法 同antd 组件的 syle
 *  点击后会触发  onSelect 返回对应的name和父节点name的值
 */
class FileTree extends React.Component<IProps, {}> {
  public state = {
    treeData: [],
  };

  public render() {
    return (
      <div>
        <TreeSelect
          dropdownStyle={{ height: '300px' }}
          style={this.props.componentStyle}
          onChange={this.onSelect}
          value={this.props.interValue}
          treeDefaultExpandAll={true}
        >
          {this.renderTreeNodes(this.props.treeData)}
        </TreeSelect>
      </div>
    );
  }

  private onSelect = (key: string) => {
    path = '';
    this.searchPath(this.props.treeData, key);
  };

  private searchPath(data: ITreeData[], key: string) {
    data.forEach(item => {
      if (item.id + '' === key) {
        path += `/${item.name}`;
        this.props.onSelect(path);
      } else {
        if (item.children) {
          path += `${item.name}`;
          this.searchPath(item.children, key);
        }
      }
    });
  }

  private renderTreeNodes = (data: ITreeData[]) => {
    if (!data.length) {
      return;
    }
    return data.map((item: ITreeData) => {
      if (item.children) {
        return (
          <TreeNode value={item.id + ''} title={item.name} key={item.id + ''}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode value={item.id + ''} title={item.name} key={item.id + ''} />;
    });
  };
}
export default FileTree;
