import { IDrawerType } from './MemberDrawer/MemberDrawer';

export interface IPagination {
  pageSize: number;
  current: number;
  total: number;
}
/**
 *
 * 列表 REq
 */
export interface IListReq {
  pageNum: number;
  pageSize: number;
}
/**
 * 权限
 */
export interface IPermissionsItem {
  code: string;
  created: string;
  name: string;
  status: number;
}
/**
 *  账户列表 Req
 */
export interface IMemberListReq {
  loginName?: string;
  pageNum: number;
  pageSize: number;
}

/**
 * 角色列表请求
 */
export interface IRoleListReq {
  roleName?: string;
  pageNum: number;
  pageSize: number;
}
/* 重置密码 REq*/
export interface IResetPsdReq {
  mobile: string;
  pwd: string;
}
export interface IMemberNewReq {
  loginName?: string;
  mobile?: string;
  nodeId?: string;
  pwd?: string;
  roleId?: number;
  roleItem?: IMemberRoleReq;
  status: number;
  type?: number;
}

export interface IMemberAccount {
  created: string;
  nodeName: string;
  role: IMemberRole;
  user: IMemberUser;
}

export interface IMemberRole {
  created: string;
  node: IMemberNode;
  permissions: string[];
  id: number;
  roleName: string;
}
export interface IMemberRoleReq {
  permissions: string[];
  id?: number;
  roleName: string;
}
export interface IMemberNode {
  // node
  code: string;
  name: string;
  user: IMemberUser;
}
/* user 对象*/
export interface IMemberUser {
  isMain: boolean;
  loginname: string;
  mobile: string;
  nickname: string;
  status: number;
  type: number;
  id: number;
}
export interface IMMState {
  isJD: boolean;
  total: number;
  clickItem: IMemberAccount | IMemberRole | undefined; // 点击的row
  showDrawer: boolean; // 是否显示抽屉
  taskType: number; // 当前 taskType
  drawerType: IDrawerType; // 展开抽屉类型
  showResetPassward: boolean; // 显示重置密码
  accountList?: IMemberAccount[];
  roleList?: IMemberRole[];
  permissions: Map<string, string>; // 所有权限
  allRoles?: IMemberRole[]; // 查出所有角色用来账户创建修改
  updateAccountStatue: boolean; // 更新账号状态
  accountPagination: IPagination; // 账户 分页
  rolePagination: IPagination; // 账户 分页
  isLoading: boolean; // 是否显示加载loading
}

export interface INormalList<T> {
  // 以后列表都用这个
  pages: number;
  size: number;
  total: number;
  current: number;
  records: T;
  // tslint:disable-next-line:no-any
  filter(arg0: (item: any) => boolean): Array<import('../DataManager/CodeType').IAlreadyShareDataItem>;
}

export const stateInit = {
  isJD: true,
  total: 0,
  clickItem: {
    created: '',
    nodeName: '',
    role: {
      created: '',
      node: {
        code: '',
        name: '',
        user: {
          isMain: false,
          loginname: '',
          mobile: '',
          nickname: '',
          status: 0,
          type: 0,
          id: 0,
        },
      },
      permissions: [''],
      id: 0,
      roleName: '',
    },
    user: {
      isMain: false,
      loginname: '',
      mobile: '',
      nickname: '',
      status: 0,
      type: 0,
      id: 0,
    },
  },
  showDrawer: false,
  taskType: 1,
  drawerType: IDrawerType.NewAccount,
  showResetPassward: false,
  permissions: new Map(),
  updateAccountStatue: false,
  accountPagination: { current: 1, total: 0, pageSize: 10 },
  rolePagination: { current: 1, total: 0, pageSize: 10 },
  allRoles: [],
  accountList: [],
  roleList: [],
  isLoading: true,
};
