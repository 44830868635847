import { Button, Form, Modal, Select, Table } from 'antd';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';
import { PaginationConfig } from 'antd/lib/table';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ProxAiManageApi from '../../../api/ProxAiManageApi';
import AuthComponent from '../../../components/AuthComponent/AuthComponent';
import NoDataImg from '../../../components/NoDataImg/NoDataImg';
import TableHeader from '../../../components/TableHeader/TableHeader';
import { IconFont } from '../../../config/IconConfig';
import IndexLayout from '../../../layout/IndexLayout';
import { INormalList } from '../../DataManager/CodeType';
import {
  IMemeberItem,
  initSciMemberListState,
  IProjectTask,
  IProjectTasksReq,
  ISciMemberListState,
  memberColumn,
} from '../CodeType';
import './SciMemberList.scss';
const Option = Select.Option;
interface IProjectTaskDeatilPageProps extends FormComponentProps, RouteComponentProps<{ pId: string; name: string }> {}
class SciMemberListPage extends React.Component<IProjectTaskDeatilPageProps, ISciMemberListState> {
  public projectColumns = [
    {
      title: '任务标题',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: IProjectTask) => {
        return (
          <div className="nowarp-elips" title={text} style={{ width: '200px' }}>
            {text}
          </div>
        );
      },
    },
    {
      title: '任务详情',
      dataIndex: 'remark',
      key: 'remark',
      render: (text: string, record: IProjectTask) => {
        return (
          <div className="nowarp-elips" title={text}>
            {text}
          </div>
        );
      },
    },
    {
      title: '所属成员',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: '任务样本数量',
      dataIndex: 'seriesTotalNum',
      key: 'seriesTotalNum',
    },
    {
      title: '已提交序列数',
      dataIndex: 'seriesSubmittedNum',
      key: 'seriesSubmittedNum',
    },
  ];
  public state = initSciMemberListState as ISciMemberListState;
  public componentDidMount() {
    this.getProjectTasks();
    this.getMembers();
  }
  public componentWillUnmount() {
    this.setState({ ...initSciMemberListState });
  }
  public render() {
    const { noLoadPagination, members } = this.state;
    const { getFieldDecorator } = this.props.form;
    const OptionsMembers = members
      ? members.map(item => {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        })
      : null;
    return (
      <div className="project-task-deatil-container">
        <IndexLayout>
          <div style={{ marginLeft: '24px', marginBottom: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '27px', justifyContent: 'space-between' }}>
              <div style={{ flex: 1, cursor: 'pointer' }} onClick={this.props.history.goBack}>
                <IconFont type={'medataicon-btn_back'} style={{ fontSize: '16px', color: 'black' }} />
                <span style={{ fontSize: '14px', color: 'black', marginLeft: '8px' }}> 返回项目列表</span>
              </div>
              <div style={{ color: 'rgba(0,0,0,0.45)' }}>
                <IconFont type={'medataicon-icon_location'} className="located-icon" />
                <span onClick={this.props.history.goBack} style={{ marginLeft: '10px', marginRight: '5px' }}>
                  项目列表
                </span>
                /<span style={{ marginLeft: '5px' }}>项目成员</span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="project-message-title">
                <div className="dot" /> {`「${this.props.match.params.name}」的任务列表`}
              </div>
              <div className="project-message-title">
                <Button className="complete-btn" onClick={this.loadMembers}>
                  引入成员
                </Button>
              </div>
            </div>
          </div>

          <AuthComponent isShow={!!this.state.projectTasks.length}>
            <Table
              columns={this.projectColumns}
              dataSource={this.state.projectTasks}
              style={{ backgroundColor: 'white' }}
              pagination={{ ...this.state.taskPagination }}
              rowKey="id"
              onChange={this.handleHasTableChanage}
            />
          </AuthComponent>
          <NoDataImg isLoading={this.state.isLoading} total={this.state.taskPagination.total} />
          <Modal
            visible={this.state.laodMemberVisible}
            className="member-modal"
            width="1170px"
            centered={true}
            cancelText="确认"
            okText="取消"
            title={'引入成员'}
            closable={false}
            // tslint:disable-next-line:jsx-no-multiline-js
            onCancel={() => {
              this.setState({ laodMemberVisible: false });
            }}
            // tslint:disable-next-line:jsx-no-multiline-js
            onOk={() => {
              this.setState({ laodMemberVisible: false });
            }}
          >
            <div style={{ paddingLeft: '24px' }}>
              请选择您需要设置的权限:
              <CheckboxGroup options={['管理员', '标注人员', '数据分析人员']} style={{ marginLeft: '20px' }} />
            </div>
            <div style={{ paddingLeft: '24px', paddingTop: '16px' }}>
              <TableHeader>
                <Form.Item label="用户名:">
                  {/* tslint:disable-next-line:jsx-no-multiline-js*/}
                  {getFieldDecorator('project-user-name')(
                    <Select className="" placeholder="请选择" allowClear={true} style={{ width: '200px' }}>
                      {OptionsMembers}
                    </Select>
                  )}
                </Form.Item>
              </TableHeader>
            </div>

            <Table
              columns={memberColumn}
              dataSource={this.state.noLoadMemberlist}
              style={{ backgroundColor: 'white' }}
              pagination={{ total: noLoadPagination.total, pageSize: noLoadPagination.pageSize }}
              rowKey="seriesInstanceUID"
              className=""
              // onChange={this.morehandleTableChange}
            />
          </Modal>
        </IndexLayout>
      </div>
    );
  }

  /**
   * 引入成员
   */
  private loadMembers = () => {
    this.setState({ laodMemberVisible: true });
  };
  /**
   * 用户名列表
   */
  private getMembers = async () => {
    const { result } = await ProxAiManageApi.getProjectMembers<IMemeberItem[]>();
    if (result) {
      this.setState({
        members: result,
      });
    }
  };

  /**
   * 处理列表切换分页
   */
  private handleHasTableChanage = (pagination: PaginationConfig) => {
    this.getProjectTasks(pagination.current);
  };

  /**
   * 获取项目下的任务类表
   */
  private getProjectTasks = async (page?: number) => {
    const { taskPagination } = this.state;
    taskPagination.current = page || 1;
    try {
      this.setState({ isLoading: false });
      const { result } = await ProxAiManageApi.getProjectTasks<INormalList<IProjectTask[]>, IProjectTasksReq>({
        page: taskPagination.current,
        pageSize: taskPagination.pageSize,
        projectId: this.props.match.params.pId,
      });
      if (result) {
        //
        taskPagination.total = result.total;
        taskPagination.current = result.current;
        this.setState({
          projectTasks: result.records,
        });
      }
    } finally {
      this.setState({ isLoading: true });
    }
  };
}
const SciMemberList = Form.create()(SciMemberListPage);
export default SciMemberList;
